import * as React from "react";
import styled from "styled-components";

interface Props {
  text: JSX.Element | null;
  materialIconName: string;
  showTextWindow: boolean;
  windowStyle?: React.CSSProperties;
  handleIconClick: () => void;
}

const Icon = styled.i`
  &:hover {
    cursor: pointer;
  }
`;

const TextWindow = styled.div`
  display: inline-block;
`;

const Text = styled.span`
  position: absolute;
  word-wrap: break-word;
  width: 180px;
  border-radius: 2px;
  background: #f9f9f9;
  border: 0.5px solid #e0e0e0;
  font-size: 0.9375em;
  line-height: 1.4em;
  padding: 10px;
  z-index: 2;
  text-align: left;
`;

const TextMobile = styled.div`
  position: fixed;
  word-wrap: break-word;
  max-width: 90%;
  background: #f9f9f9;
  border: 0.5px solid #e0e0e0;
  font-size: 14px;
  line-height: 1.4em;
  padding: 15px;
  z-index: 2;
  text-align: left;
  left: 0px;
  bottom: 0px;
`;

const TextHoverIcon: React.FC<Props> = ({
  materialIconName,
  text,
  showTextWindow,
  handleIconClick,
  windowStyle,
}) => (
  <div>
    <Icon className="material-icons" onClick={handleIconClick}>
      {materialIconName}
    </Icon>
    {showTextWindow && (
      <TextWindow style={windowStyle || {}}>
        <TextMobile className="visible-xs">{text}</TextMobile>
        <Text className="hidden-xs">{text}</Text>
      </TextWindow>
    )}
  </div>
);

export default TextHoverIcon;
