import { Box, Button } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { useClickAway } from "react-use";
import styled, { css } from "styled-components";
import { Hamburger } from "./Hamburger";
import LanguagePicker from "./LanguagePicker";
import Navlinks from "./Navlinks";

const Shadow = styled.div<{ isOpen: boolean }>`
  pointer-events: none;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transition: all 0.5s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: rgba(0, 0, 0, 0.7);
    `}
`;

const MenuDrawer: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  return (
    <div ref={ref}>
      <Shadow isOpen={isOpen} />
      <Box>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Box>
            <Button
              variant="unstyled"
              display="flex"
              justifyContent="flex-end"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Hamburger isOpen={isOpen} />
            </Button>
          </Box>
        </motion.div>
      </Box>
      <AnimatePresence>
        {isOpen && (
          <Box zIndex={1000} position="fixed" right={0} top="59px">
            <motion.div
              initial={{ x: "300px" }}
              animate={{ x: 0 }}
              transition={{ ease: "easeIn", duration: 0.2 }}
              exit={{ x: "300px" }}
            >
              <Box
                display="flex"
                backgroundColor="#FFF"
                height="calc(100vh - 60px)"
                width="300px"
                justifyContent="center"
                py={20}
              >
                <Box
                  display="flex"
                  height="100%"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box
                    h="full"
                    display="flex"
                    flexDir="column"
                    justifyContent="center"
                    pb="28"
                  >
                    <Navlinks onClick={() => setIsOpen(false)} />
                  </Box>
                  <LanguagePicker />
                </Box>
              </Box>
            </motion.div>
          </Box>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MenuDrawer;
