import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { TextField, RaisedButton } from 'material-ui';
import format, { formatToString } from 'utils/format';
import ChangePasswordFormUiStore from 'stores/ui/account/change-password-form';
import { PASSWORD_MIN_LENGTH, PASSWORD_MIN_DIGITS } from 'utils/forms/constants';

interface Props {
    formStore: ChangePasswordFormUiStore;
    hasFieldError: boolean;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    isSubmitting: boolean;
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const SubmitButton = styled(RaisedButton)`
    margin: 20px 0;
`;

const FormIcon = styled.i`
    margin-right: 15px;
`;

const TitleDiv = styled.div`
    display: flex;
    align-items: center;
`;

@observer
export default class ChangePasswordForm extends React.Component<Props, {}> {

    render () {
        let { formStore, hasFieldError, onSubmit, isSubmitting } = this.props;

        return (
            <Form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    onSubmit(e);
                }}
            >
                <TitleDiv>
                    <FormIcon className="material-icons">lock</FormIcon>
                    <h2>
                        {formatToString('settings.change.password')}
                    </h2>
                </TitleDiv>
                <TextField
                    type="password"
                    floatingLabelText={format('settings.contactInfo.changePassword.currentPassword.label')}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        
                        formStore.currentPassword.onChange(e.currentTarget.value);
                    }}
                    value={formStore.currentPassword.value}
                    errorText={format(
                        formStore.currentPassword.error,
                        { minLength: PASSWORD_MIN_LENGTH, minDigits: PASSWORD_MIN_DIGITS }
                    )}
                />
                <TextField
                    type="password"
                    floatingLabelText={format('register.password.label')}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        
                        formStore.newPassword.onChange(e.currentTarget.value);
                        formStore.validateRepeatedPassword();
                    }}
                    value={formStore.newPassword.value}
                    errorText={format(
                        formStore.newPassword.error,
                        { minLength: PASSWORD_MIN_LENGTH, minDigits: PASSWORD_MIN_DIGITS }
                    )}
                />
                <TextField
                    type="password"
                    floatingLabelText={format('register.repeatedPassword.label')}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        formStore.repeatedPassword.onChange(e.currentTarget.value)}
                    value={formStore.repeatedPassword.value}
                    errorText={format(formStore.repeatedPassword.error)}
                />
                <SubmitButton
                    primary={true}
                    type="submit"
                    label={format('settings.change.password')}
                    disabled={hasFieldError || isSubmitting}
                    style={{ width: '200px' }}
                />
            </Form>
        );
    }
}