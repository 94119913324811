import { Button } from "@chakra-ui/react";
import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

const BackButton: React.FC<{ path?: string; labelKey?: string }> = ({
  path,
  labelKey,
}) => {
  const history = useHistory();

  return (
    <>
      <Button
        className="hover-underline-animation"
        mb={6}
        leftIcon={<FiArrowLeft />}
        variant="link"
        onClick={() => (path ? history.push(path) : history.goBack())}
      >
        <FormattedMessage id={labelKey || "common.back"} />
      </Button>
    </>
  );
};

export default BackButton;
