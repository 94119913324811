import Api from './api';
import { Language } from 'stores/domain/locale';
import { formatToString } from 'utils/format';
import { saveAs } from 'file-saver';

export default class CaskOwnerProofService {
  static async getCaskOwnerProof(
    caskId: number,
    language: Language,
    timeZone: string
  ): Promise<void> {
    let response = await Api.get(
      `caskOwnerProof/${caskId}`,
      true,
      {},
      {
        timeZone: timeZone,
        language: language
      }
    );

    let buffer = await response.arrayBuffer();
    var file = new Blob([buffer], { type: 'application/pdf' });

    saveAs(file, formatToString('cask.info.fileName.caskOwnerProof'));
  }
}
