import { JsonApiResponse, toJsonApiResponse } from "utils/json-api";
import api from "./api";

export class RegisterPersonMemberViewModel {
  userId!: string;
  resetToken!: string;
  firstName!: string;
  lastName!: string;
  language!: string;
  password!: string;
  repeatedPassword!: string;
}

export class RegisterCompanyMemberViewModel extends RegisterPersonMemberViewModel {
  orgNumber!: string;
  orgName!: string;
  phoneNumber!: string;
}

export default class RegisterMemeberService {
  static async registerPerson(
    person: RegisterPersonMemberViewModel
  ): Promise<JsonApiResponse<string>> {
    const endpoint = "accounts/finish-person-registration";
    let response = await api.post(endpoint, false, {}, person);
    return await toJsonApiResponse<string>(response);
  }
  static async registerCompany(
    company: RegisterCompanyMemberViewModel
  ): Promise<JsonApiResponse<string>> {
    const endpoint = "accounts/finish-company-registration";
    let response = await api.post(endpoint, false, {}, company);
    return await toJsonApiResponse<string>(response);
  }

  static async resendEmail(
    userId: string,
    language: string
  ): Promise<JsonApiResponse<string>> {
    const endpoint = `accounts/resend-registration-invite/`;
    let response = await api.post(endpoint, false, {}, { language, userId });
    return await toJsonApiResponse<string>(response);
  }
}
