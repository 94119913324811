import { MobXProviderContext } from "mobx-react";
import React from "react";
import domainStores from "./domain";
import uiStores from "./ui";

export default {
  ...domainStores,
  ...uiStores,
};

export * from "./domain";
export * from "./ui";

export function useStores() {
  return React.useContext(MobXProviderContext) as typeof domainStores &
    typeof uiStores;
}
