import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import React, { Dispatch, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";
import { useCurrentCask } from "scenes/Cask/cask-hooks";

interface LabelAlertDialogProps {
  hookState: [
    (() => void | undefined) | undefined,
    Dispatch<SetStateAction<(() => void | undefined) | undefined>>
  ];
  disclosure: UseDisclosureReturn;
}

const LabelAlertDialog: React.FC<LabelAlertDialogProps> = ({
  hookState,
  disclosure,
}) => {
  const cancelRef = React.useRef<any>(undefined);
  const { isOpen, onClose, onOpen } = disclosure;
  const { cask } = useCurrentCask();
  const isGin = cask?.recipe?.id === 3;

  React.useEffect(() => {
    if (hookState[0] !== undefined) {
      onOpen();
    } else {
      onClose();
    }
  }, [hookState, onClose, onOpen]);

  const onConfirm = () => {
    hookState[1](undefined);
  };

  const onCancel = () => {
    if (hookState[0]) {
      hookState[0]();
      hookState[1](undefined);
    }
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="medium">
              <FormattedMessage id="cask.orders.labels.alert.title" />
            </AlertDialogHeader>

            <AlertDialogBody>
              {isGin ? (
                <FormattedMessage id="cask.orders.labels.alert.desc-gin" />
              ) : (
                <FormattedMessage id="cask.orders.labels.alert.desc" />
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="brand" ref={cancelRef} onClick={onCancel}>
                <FormattedMessage id="cask.orders.labels.alert.customize" />
              </Button>
              <Button onClick={onConfirm} ml={3}>
                <FormattedMessage id="cask.orders.labels.alert.confirm" />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default LabelAlertDialog;
