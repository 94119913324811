import {
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { getCaskName } from "models/cask";
import React from "react";
import { useCurrentCask } from "../cask-hooks";

const CaskImageModal: React.FC<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isControlled: boolean;
  getButtonProps: (props?: any) => any;
  getDisclosureProps: (props?: any) => any;
}> = ({ isOpen, onClose }) => {
  const { cask } = useCurrentCask();

  if (!cask) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      useInert={false}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalCloseButton bg="whiteAlpha.800" />
        <Image
          h="auto"
          w="auto"
          src={cask.imageFull}
          alt={getCaskName(cask!)}
        />
      </ModalContent>
    </Modal>
  );
};

export default observer(CaskImageModal);
