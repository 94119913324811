import {
  Box,
  Button,
  Image,
  Popover,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import userCircle from "assets/images/user-circle.svg";
import { observer } from "mobx-react";
import { useState } from "react";
import { HiExclamationTriangle } from "react-icons/hi2";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import { useStores } from "stores";
import styled from "styled-components";
import ProfileDrawer from "./ProfileDrawer";
import ProfilePopover from "./ProfilePopover";

const NameLabel = styled(Text)`
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfileButton = () => {
  const { sessionStore } = useStores();
  const [name, id] = sessionStore.nameWithId as [string, string];
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useMedia("(max-width: 48em)");
  const onClick = !sessionStore.isAuthenticated
    ? () => history.push("/account/login")
    : () => setIsOpen(!isOpen);
  const errorFetchingVismaUser = !sessionStore?.vismaUser;

  if (sessionStore.isFetchingUserData) return null;

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Button
            onClick={onClick}
            rightIcon={
              <Image boxSize={6} src={userCircle.toString()} alt="user" />
            }
            variant="outline"
            fontWeight={300}
            fontSize="1.125rem"
            p={[2]}
          >
            <Box textAlign="right">
              {!sessionStore.isAuthenticated ? (
                <Box>
                  <FormattedMessage id="navbar.login.label" />
                </Box>
              ) : (
                <>
                  <Box>
                    {errorFetchingVismaUser && (
                      <Box mt={-1}>
                        <HiExclamationTriangle size="1.75rem" color="#ff9f9f" />
                      </Box>
                    )}
                    <NameLabel fontSize="sm">{name}</NameLabel>
                    <Text mt={-1} fontSize="sm">
                      {id}
                    </Text>
                  </Box>
                </>
              )}
            </Box>
          </Button>
        </PopoverTrigger>
        {isMobile ? (
          <ProfileDrawer isOpenState={[isOpen, setIsOpen]} />
        ) : (
          <ProfilePopover isOpenState={[isOpen, setIsOpen]} />
        )}
      </Popover>
    </>
  );
};

export default observer(ProfileButton);
