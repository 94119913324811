import * as React from 'react';
import { DeliveryOccasionLocation } from 'models/delivery-occasion';
import BottlePriceListItem from './BottlePriceListItem';

interface Props {
  isOpen: boolean | undefined;

  country: DeliveryOccasionLocation;
  loanAmount: number;
  vatRate: number;
  alcoholDutyRate: number;
  exchangeRate: number;
  vendorMarginRate: number;
  vendorFlatRate: number;
  numberOfBottles: number;
  alcoholContent: number;
  bottleSize: number;
  onCountryClick: (country: DeliveryOccasionLocation) => void;
}

class BottlePriceListItemContainer extends React.Component<Props, {}> {
  toggleIsOpen = () => this.props.onCountryClick(this.props.country);

  getCountryName = (value: DeliveryOccasionLocation) => {
    switch (value) {
      case DeliveryOccasionLocation.Sweden:
        return 'orders.bottlingOrder.country.sweden';
      case DeliveryOccasionLocation.Denmark:
        return 'orders.bottlingOrder.country.denmark';
      case DeliveryOccasionLocation.Germany:
        return 'orders.bottlingOrder.country.germany';
      case DeliveryOccasionLocation.Estonia:
        return 'orders.bottlingOrder.country.estonia';
      default:
        return '';
    }
  }

  get net(): number {
    let { loanAmount, numberOfBottles } = this.props;
    return loanAmount / numberOfBottles;
  }

  get alcoholDuty(): number {
    let {
      alcoholDutyRate,
      exchangeRate,
      bottleSize,
      alcoholContent
    } = this.props;
    return alcoholDutyRate * exchangeRate * bottleSize * alcoholContent;
  }

  get vendorMarginRateDecimal(): number {
    let { vendorMarginRate } = this.props;
    return vendorMarginRate / 100;
  }

  get vendorCharge(): number {
    let { vendorFlatRate } = this.props;
    return this.net * this.vendorMarginRateDecimal + vendorFlatRate;
  }

  get vat(): number {
    let { vatRate } = this.props;
    return Math.round(
      (this.vendorCharge + this.alcoholDuty + this.net) * vatRate
    );
  }

  get sum(): number {
    return this.net + this.alcoholDuty + this.vendorCharge + this.vat;
  }

  render() {
    let { isOpen, country, vatRate, exchangeRate } = this.props;
    let {
      toggleIsOpen,
      net,
      getCountryName,
      alcoholDuty,
      vendorCharge,
      vat,
      sum
    } = this;
    return (
      <BottlePriceListItem
        isOpen={isOpen}
        onCountryClick={toggleIsOpen}
        net={Math.round(net)}
        exchangeRate={exchangeRate}
        country={country}
        countryName={getCountryName(country)}
        alcoholDuty={Math.round(alcoholDuty)}
        vendorCharge={Math.round(vendorCharge)}
        vat={vat}
        sum={Math.round(sum)}
        vatRate={vatRate}
      />
    );
  }
}

export default BottlePriceListItemContainer;
