import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { TextField, RaisedButton } from 'material-ui';
import { observer } from 'mobx-react';
import format, { formatToString } from 'utils/format';
import CompanyInfoFormUiStore from 'stores/ui/account/company-info-form';
import CountryPicker from 'components/CountryPickerContainer';
import { PHONE_MIN_LENGTH, PHONE_MAX_LENGTH } from 'utils/forms/constants';
import { FormattedMessage } from 'react-intl';
import LoadingIndicator from 'components/Loading/LoadingIndicator';

interface Props {
    formStore: CompanyInfoFormUiStore;
    hasFieldError: boolean;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    isSubmitting: boolean;
}

const SubmitButton = styled(RaisedButton)`
    margin: 20px 0;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormIcon = styled.i`
    margin-right: 15px;
`;

const TitleDiv = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.h2`
    margin: 40px 0 0px;
`;

const Message = styled.div`
    padding: 5px 15px;
`;

@observer
export default class CompanyInfoForm extends Component<Props, {}> {

  render() {
    let { formStore, hasFieldError, onSubmit, isSubmitting } = this.props;

    return (
        <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                onSubmit(e);
            }}
        >
            <TitleDiv>
                <FormIcon className="material-icons">location_city</FormIcon>
                <h2>
                    {formatToString('settings.contactInfo.company.label')}
                </h2>
            </TitleDiv>
            <TextField
                floatingLabelText={format('settings.contactInfo.orgNumber.label')}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    return formStore.orgNumber.onChange(e.currentTarget.value);
                }}
                value={formStore.orgNumber.value}
                errorText={format(formStore.orgNumber.error)}
            />
            <TextField
                floatingLabelText={format('settings.contactInfo.orgName.label')}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    return formStore.orgName.onChange(e.currentTarget.value);
                }}
                value={formStore.orgName.value}
                errorText={format(formStore.orgName.error)}
            />
            <TextField
                floatingLabelText={format('settings.contactInfo.address.label')}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    formStore.address.onChange(e.currentTarget.value)}
                value={formStore.address.value}
                errorText={format(formStore.address.error)}
            />
            <TextField
                floatingLabelText={format('settings.contactInfo.zipCode.label')}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    formStore.zipCode.onChange(e.currentTarget.value)}
                value={formStore.zipCode.value}
                errorText={format(formStore.zipCode.error)}
            />
            <TextField
                floatingLabelText={format('settings.contactInfo.city.label')}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    formStore.city.onChange(e.currentTarget.value)}
                value={formStore.city.value}
                errorText={format(formStore.city.error)}
            />
            <CountryPicker
                floatingLabelText={formatToString('settings.contactInfo.country.label')}
                onChange={(e: {}, i: {}, country: string) => formStore.country.onChange(country)}
                value={formStore.country.value}
                errorText={format(formStore.country.error)}
            />
            
            <Title>
                {formatToString('settings.contactInfo.company.contact.label')}
            </Title>
            <TextField
                floatingLabelText={format('settings.contactInfo.phoneNumber.label')}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    formStore.phoneNumber.onChange(e.currentTarget.value)}
                value={formStore.phoneNumber.value}
                errorText={format(
                    formStore.phoneNumber.error,
                    { minLength: PHONE_MIN_LENGTH, maxLength: PHONE_MAX_LENGTH }
                )}
            />
            <TextField
                floatingLabelText={format('settings.contactInfo.company.firstName.label')}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    formStore.firstName.onChange(e.currentTarget.value)}
                value={formStore.firstName.value}
                errorText={format(formStore.firstName.error)}
            />
            <TextField
                floatingLabelText={format('settings.contactInfo.company.lastName.label')}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    formStore.lastName.onChange(e.currentTarget.value)}
                value={formStore.lastName.value}
                errorText={format(formStore.lastName.error)}
            />
            <SubmitButton
                primary={true}
                type="submit"
                label={format('settings.contactInfo.save')}
                disabled={hasFieldError || isSubmitting}
                style={{ width: '200px' }}
            />

            {isSubmitting && 
                <Message>
                    <LoadingIndicator label={<FormattedMessage id="settings.updatingUser"/>}/>
                </Message>
            }
        </Form>
    );
  }
}