import { Box, Collapse } from "@chakra-ui/react";
import BrandButton from "components/BrandButton";
import CustomAlert from "components/CustomAlert";
import { Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react";
import Event from "models/event";
import { OrderType } from "models/order";
import { SamplingOrderType } from "models/sampling-order";
import { FormattedMessage } from "react-intl";
import { Prompt } from "react-router-dom";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import OrdersService from "services/orders";
import { useStores } from "stores";
import { formatToString } from "utils/format";
import * as Yup from "yup";
import OrderDone from "../../Index/OrderDone";
import SamplingEventsDistilleryFormPart from "./SamplingEventsDistilleryFormPart";
import SamplingStepper from "./SamplingStepper";
import SamplingSystembolagetFormPart from "./SamplingSystembolagetFormPart";
import SamplingTypePicker from "./SamplingTypePicker";

export interface SamplingFormValues {
  type?: SamplingOrderType;
  event?: Event;
  distilleryDateTime?: Date;
  amount?: number;
  systembolagetRequestNumber?: string;
  submitError?: boolean;
  newOrderNumber?: number;
}

export const samplingSchema = (format: (id: string) => any) =>
  Yup.object().shape({
    type: Yup.number().required(format("common.required")),
    event: Yup.object().when("type", {
      is: SamplingOrderType.Event,
      then: Yup.object().required(format("common.required")),
    }),
    distilleryDateTime: Yup.date().when("type", {
      is: SamplingOrderType.Distillery,
      then: Yup.date().required(format("common.required")),
    }),
    amount: Yup.number().when("type", {
      is: (type: SamplingOrderType) =>
        type === SamplingOrderType.Event ||
        type === SamplingOrderType.Distillery,
      then: Yup.number().required(format("common.required")),
    }),
    systembolagetRequestNumber: Yup.string().when("type", {
      is: SamplingOrderType.Systembolaget,
      then: Yup.string()
        .min(2, format("common.too-short"))
        .max(50, format("common.too-long"))
        .required(format("common.required")),
    }),
  });

const initialValues = {} as SamplingFormValues;

const SamplingForm = () => {
  const isMobile = useMedia("(max-width: 600px)");
  const { localeStore, sessionStore, casksStore } = useStores();
  const { cask } = useCurrentCask();
  if (!cask) return null;

  const onSubmit = async (
    values: SamplingFormValues,
    formikHelpers: FormikHelpers<SamplingFormValues>
  ) => {
    try {
      const orderResponse = await OrdersService.orderSampling({
        caskId: cask.id,
        language: localeStore.language,
        localVismaUserId: sessionStore.vismaUser.id,
        amount:
          values.type === SamplingOrderType.Distillery ||
          values.type === SamplingOrderType.Event
            ? values.amount
            : undefined,
        distilleryDateTime:
          values.type === SamplingOrderType.Distillery
            ? values.distilleryDateTime
            : undefined,
        eventId:
          values.type === SamplingOrderType.Event
            ? values.event?.id
            : undefined,
        systembolagetRequestNumber:
          values.type === SamplingOrderType.Systembolaget
            ? values.systembolagetRequestNumber
            : undefined,
        type: values.type,
      });

      if (orderResponse.hasErrors) {
        formikHelpers.setFieldValue("submitError", true);
      } else {
        await casksStore.fetchCasks(false);
        window.scrollTo(0, 0);
        formikHelpers.setFieldValue(
          "newOrderNumber",
          orderResponse.data.order.id
        );
      }
    } catch (error) {
      formikHelpers.setFieldValue("submitError", true);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={samplingSchema(formatToString)}
    >
      {(props) => (
        <>
          <Prompt
            when={props.dirty && !props.values.newOrderNumber}
            message={formatToString("common.unsaved-changes")}
          />
          <SamplingStepper />
          <Box maxW={isMobile ? undefined : 1024 - 400} w="full">
            {!!props.values.newOrderNumber && (
              <OrderDone
                type={OrderType.Sampling}
                orderNumber={props.values.newOrderNumber}
                email={sessionStore.user.email}
              />
            )}
            {!props.values.newOrderNumber && (
              <>
                <SamplingTypePicker />
                <SamplingSystembolagetFormPart />
                <SamplingEventsDistilleryFormPart />
                <Collapse in={props.values.submitError}>
                  <Box mt={8} mb={-4}>
                    <Box mb={4}>
                      <CustomAlert
                        status="error"
                        labelKey="orders.bottlingOrder.error.unknon"
                      />
                    </Box>
                  </Box>
                </Collapse>
                {props.values.type !== undefined && (
                  <>
                    <Box display="flex" justifyContent="center">
                      <BrandButton
                        large
                        w="full"
                        mt={12}
                        colorScheme="brand"
                        isDisabled={!props.isValid}
                        isLoading={props.isSubmitting}
                        onClick={() => props.submitForm()}
                      >
                        <FormattedMessage id="cask.orders.bottling.summary.confirm" />
                      </BrandButton>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </Formik>
  );
};

export default observer(SamplingForm);
