import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import CustomAlert from "components/CustomAlert";
import { EditorState } from "draft-js";
import { observer } from "mobx-react-lite";
import { DeliveryOccasionLocation } from "models/delivery-occasion";
import { MenuItemContent } from "models/menu-item";
import { FC, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { IoMdBoat } from "react-icons/io";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { useStores } from "stores";

const INFO_PATHS: any = {
  0: [31, 29], // sverige
  1: [33, 29], // tyskland
  2: [32, 29], // danmark
  3: [34, 29], // estland
};

const ModalInner: FC<{
  deliveryLocation: DeliveryOccasionLocation;
  onClose: () => void;
}> = ({ deliveryLocation, onClose }) => {
  const [localizedContent, setLocalizedContent] = useState<
    MenuItemContent | undefined
  >();
  const { informationStore, localeStore } = useStores();

  useEffect(() => {
    if (!informationStore.isLoaded) return;

    const path = INFO_PATHS[deliveryLocation];
    const contents = informationStore.find(path[0], path[1]);

    if (!contents) return;

    let localizedContent = contents.menuItemContent.find(
      (content) => content.language === localeStore.languageInt
    );
    if (!localizedContent) {
      localizedContent = contents.menuItemContent[0];
    }
    setLocalizedContent(localizedContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [informationStore.isLoaded, deliveryLocation]);

  useEffect(() => {
    if (!informationStore.isLoaded) informationStore.fetchInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContent mx={4}>
      {!localizedContent ? (
        <Center p="8">
          <Spinner />
        </Center>
      ) : (
        <>
          <ModalHeader fontWeight={500}>{localizedContent.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {localizedContent.state && (
              <Box mt={-6}>
                <Editor
                  toolbarHidden
                  readOnly
                  editorState={EditorState.createWithContent(
                    localizedContent.state
                  )}
                  onChange={() => {}}
                />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose}>
              <FormattedMessage id="common.close" />
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  );
};

const ModalInnerObserver = observer(ModalInner);

const DeliveryInfoWarningComponent: FC<{
  deliveryLocation: DeliveryOccasionLocation;
}> = ({ deliveryLocation }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <CustomAlert
        icon={
          deliveryLocation === DeliveryOccasionLocation.Estonia ? (
            <IoMdBoat size="1.75rem" color="#121212" />
          ) : undefined
        }
        status={
          deliveryLocation === DeliveryOccasionLocation.Estonia
            ? "info"
            : "warning"
        }
      >
        <Box>
          {deliveryLocation === DeliveryOccasionLocation.Sweden && (
            <Box>
              <FormattedHTMLMessage id="cask.orders.bottling.delivery.warning.sweden" />
            </Box>
          )}
          {deliveryLocation === DeliveryOccasionLocation.Denmark && (
            <Box>
              <FormattedHTMLMessage id="cask.orders.bottling.delivery.warning.denmark" />
            </Box>
          )}
          {deliveryLocation === DeliveryOccasionLocation.Germany && (
            <Box>
              <FormattedHTMLMessage id="cask.orders.bottling.delivery.warning.germany" />
            </Box>
          )}
          {deliveryLocation === DeliveryOccasionLocation.Estonia && (
            <Box>
              <FormattedHTMLMessage id="cask.orders.bottling.delivery.warning.estonia" />
            </Box>
          )}
          <Button mt={4} variant="link" onClick={onOpen}>
            <FormattedMessage id="cask.orders.bottling.delivery.warning.read-more" />
          </Button>
        </Box>
      </CustomAlert>
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        useInert
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalInnerObserver
          onClose={onClose}
          deliveryLocation={deliveryLocation}
        />
      </Modal>
    </>
  );
};

export default DeliveryInfoWarningComponent;
