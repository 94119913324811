import * as React from 'react';
import TextHoverIcon from './TextHoverIcon';

interface Props {
  text: JSX.Element | null;
  materialIconName: string;
  windowStyle?: React.CSSProperties;
}

interface State {
  showTextWindow: boolean;
}

class TextHoverIconContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showTextWindow: false,
    };
    this.handleIconClick = this.handleIconClick.bind(this);
    this.openTextWindow = this.openTextWindow.bind(this);
    this.closeTextWindow = this.closeTextWindow.bind(this);
  }

  handleIconClick() {
    if (this.state.showTextWindow) {
      this.closeTextWindow();
    } else {
      this.openTextWindow();
    }
  }

  openTextWindow() {
    this.setState(() => {
      return { showTextWindow: true };
    });
    setTimeout(() => {
      this.closeTextWindow();
    },         11000);
  }

  closeTextWindow() {
    this.setState(() => {
      return { showTextWindow: false };
    });
  }

  render() {
    return (
      <TextHoverIcon
        {...this.props}
        showTextWindow={this.state.showTextWindow}
        handleIconClick={this.handleIconClick}
      />
    );
  }
}

export default TextHoverIconContainer;