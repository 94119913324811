import { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface WrapperProps {
  theme?: __MaterialUI.Styles.MuiTheme;
}

const Wrapper = styled.div`
  position: relative;
  padding: 15px;

  &:after {
    content: url("/disc.svg");
    position: absolute;
    left: -29px;
    top: 12px;
  }

  &:before {
    content: "";
    position: absolute;
    left: -16px;
    border-left: 1px solid #92908a;
    height: 100%;
    width: 1px;
  }

  &:first-child:before {
    top: 26px;
  }

  &:last-child:before {
    height: 6px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(95, 95, 95, 0.1);
  }
`;

const OrderSettlementAnchor = styled.a`
  display: flex;
  width: 200px;
  text-decoration: none !important;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Item = styled.div``;

interface Props {
  itemName: string;
  onGetSettlement?: (e: React.MouseEvent<{}>) => void;
  onClick: () => void;
}

const HistoryTimelineItem: FC<Props> = ({
  itemName,
  onClick,
  onGetSettlement,
}) => (
  <Wrapper onClick={onClick}>
    <Item>{itemName}</Item>
    {onGetSettlement !== undefined && (
      <OrderSettlementAnchor onClick={onGetSettlement}>
        <i className="material-icons">file_download</i>
        <FormattedMessage id="cask.info.download.orderSettlement" />
      </OrderSettlementAnchor>
    )}
  </Wrapper>
);

export default HistoryTimelineItem;
