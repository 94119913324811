import { List, ListItem, makeSelectable } from "material-ui";
import { ActionInfo } from "material-ui/svg-icons";
import { MenuItem } from "models/menu-item";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { LanguageInt } from "stores/domain/locale";
import styled from "styled-components";
import formatMessage from "utils/format";

const UndecoratedLink = styled(Link)`
  text-decoration: none;
`;

interface Props {
  selectedItem: MenuItem;
  menuItems: MenuItem[];
  language: LanguageInt;
  includeInformationLink: boolean;
  onClick?: () => void;
}

const SelectableList = makeSelectable(List);

/**
 * This compnent can be used to display a list with links to all the pages in the information page
 */
const InformationListItems: React.FC<Props> = (props) => {
  const history = useHistory();

  const getListItem = (item: MenuItem, submenus: JSX.Element[] = []) => {
    const url = item.parentId ? `/${item.parentId}/${item.id}` : `/${item.id}`;
    const title = item.menuItemContent.find(
      (content) => content.language === props.language
    )!.title;

    return (
      <ListItem
        onClick={
          props.onClick
            ? () => {
                props.onClick!();
                history.push(`/information${url}`);
              }
            : () => history.push(`/information${url}`)
        }
        key={item.id}
        primaryText={title}
        nestedItems={submenus}
        primaryTogglesNestedList
      />
    );
  };

  const listItems = () => {
    return props.menuItems.map((item) => {
      let submenus: JSX.Element[] = [];
      if (item && item.children) {
        submenus = item.children.map((subItem) => {
          return getListItem(subItem);
        });
      }
      return getListItem(item, submenus);
    });
  };

  const informationItem = () => {
    return (
      <ListItem
        leftIcon={<ActionInfo />}
        onClick={() => history.push("/information")}
        primaryText={formatMessage("navbar.information.label")}
        primaryTogglesNestedList
        nestedItems={listItems()}
      />
    );
  };

  if (props.includeInformationLink && props.selectedItem) {
    return (
      <SelectableList value={props.selectedItem.id}>
        {informationItem()}
      </SelectableList>
    );
  } else if (props.selectedItem) {
    return (
      <SelectableList value={props.selectedItem.id}>
        {listItems()}
      </SelectableList>
    );
  } else {
    return <List>{informationItem()}</List>;
  }
};

export default InformationListItems;
