import { computed } from 'mobx';
import { routerStore } from 'stores/domain/router';

export enum Tab {
    Login, Register
}

class AccountUiStore {
    @computed get selectedTab(): Tab {
        let path = routerStore.history.location.pathname;
        return this.determineTab(path);
    }
    
    determineTab(location: string) {
        return location.indexOf('/register') >= 0 ? Tab.Register : Tab.Login;
    }

    @computed get showTabs(): Boolean {
        let path = routerStore.history.location.pathname;
        return path.indexOf('/company') >= 0 ? false : true;
    }
}
export default AccountUiStore;
export const accountUiStore = new AccountUiStore();