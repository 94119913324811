import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Divider,
  Heading,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import CaskOrderHistory, {
  CaskOrderHistoryType,
} from "models/cask-order-history";
import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { useMedia } from "react-use";
import OrderSettlementService from "services/order-settlement";
import { useStores } from "stores";
import { Language } from "stores/domain/locale";
import { formatToString } from "utils/format";
import { useCurrentCask } from "../cask-hooks";

const getSettlement = (orderId: number, language: Language) => {
  return OrderSettlementService.getOrderSettlement(
    orderId,
    language,
    moment.tz.guess()
  );
};

const getItemName = (caskOrderHistory: CaskOrderHistory) => {
  const firstOrderRowDescription = isEmpty(caskOrderHistory.orderRows)
    ? ""
    : caskOrderHistory.orderRows[0].description;

  if (caskOrderHistory.type === CaskOrderHistoryType.Purchase) {
    return formatToString("history.order.type.buy.label", {
      orderText: firstOrderRowDescription,
    });
  }

  if (caskOrderHistory.type === CaskOrderHistoryType.Production) {
    let texts = new Array<string>();

    if (!isEmpty(caskOrderHistory.actionName)) {
      texts.push(caskOrderHistory.actionName);
    }

    if (!isEmpty(caskOrderHistory.bottlingVolumeName)) {
      texts.push(caskOrderHistory.bottlingVolumeName);
    }

    if (!isEmpty(caskOrderHistory.bottlingRoundName)) {
      texts.push(caskOrderHistory.bottlingRoundName);
    }

    return formatToString("history.order.type.production.label", {
      orderText: texts.join(", "),
    });
  }

  return formatToString("history.order.type.unknown.label");
};

const ItemData: React.FC<{ label: ReactNode; value: ReactNode }> = ({
  value,
  label,
}) => (
  <WrapItem display="block" w="full">
    <Text fontSize="sm" textTransform="uppercase" fontWeight={500}>
      {label}
    </Text>
    <Text pb="2" fontSize="md" fontWeight={300}>
      {value}
    </Text>
  </WrapItem>
);

const HistoryListItem: React.FC<{
  item: CaskOrderHistory;
  isCaskOwner?: boolean;
  language: Language;
}> = ({ item, isCaskOwner, language }) => {
  const [loadingSettlement, setLoadingSettlement] = useState<boolean>(false);
  const isMobile = useMedia("(max-width: 500px)");

  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <AccordionButton px={isMobile ? 6 : undefined}>
            <Box as="span" flex="1" textAlign="left">
              {item.isSettlement && isCaskOwner && (
                <Box mb={2} display="flex">
                  <Badge fontWeight={400}>
                    <FormattedMessage id="cask.info.settlement" />
                  </Badge>
                </Box>
              )}
              <Text fontWeight={300} pr={4}>
                {getItemName(item)}
              </Text>
            </Box>
            {isExpanded ? (
              <MinusIcon fontSize="12px" />
            ) : (
              <AddIcon fontSize="12px" />
            )}
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box pr={8} px={isMobile ? 2 : undefined}>
              <Wrap spacing={4}>
                {item.vismaOrderId !== 0 && (
                  <ItemData
                    label={
                      <FormattedMessage id="history.modal.vismaOrderId.label" />
                    }
                    value={item.vismaOrderId}
                  />
                )}

                {!isEmpty(item.status) && (
                  <ItemData
                    label={<FormattedMessage id="history.modal.status.label" />}
                    value={item.status}
                  />
                )}

                {item.type && (item.type as number) !== 0 && (
                  <ItemData
                    label={<FormattedMessage id="history.modal.type.label" />}
                    value={
                      item.type === CaskOrderHistoryType.Purchase ? (
                        <FormattedMessage id="history.modal.type.purchase" />
                      ) : item.type === CaskOrderHistoryType.Production ? (
                        <FormattedMessage id="history.modal.type.production" />
                      ) : (
                        <FormattedMessage id="history.modal.type.unknown" />
                      )
                    }
                  />
                )}

                {!isEmpty(item.activityName) && (
                  <ItemData
                    label={
                      <FormattedMessage id="history.modal.activity.label" />
                    }
                    value={item.activityName}
                  />
                )}

                {!isEmpty(item.actionName) && (
                  <ItemData
                    label={<FormattedMessage id="history.modal.action.label" />}
                    value={item.actionName}
                  />
                )}

                {!isEmpty(item.bottlingVolumeName) && (
                  <ItemData
                    label={
                      <FormattedMessage id="history.modal.bottlingVolume.label" />
                    }
                    value={item.bottlingVolumeName}
                  />
                )}

                {!isEmpty(item.bottlingRoundName) && (
                  <ItemData
                    label={
                      <FormattedMessage id="history.modal.bottlingRound.label" />
                    }
                    value={item.bottlingRoundName}
                  />
                )}

                {/* Removed dilution because it is usually the same as alcohol
            !isEmpty(item.dilution) && (
            <ItemData
                label={<FormattedMessage id="history.modal.dilution.label" />}
                value={item.dilution}
            />
        )*/}

                {!!item.alcohol && item.alcohol !== 0 && (
                  <ItemData
                    label={
                      <FormattedMessage id="history.modal.alcohol.label" />
                    }
                    value={item.alcohol}
                  />
                )}

                {item.preferedDeliveryTime && (
                  <ItemData
                    label={
                      <FormattedMessage id="history.modal.preferedDeliveryTime.label" />
                    }
                    value={<FormattedDate value={item.preferedDeliveryTime} />}
                  />
                )}
              </Wrap>

              {item.orderRows &&
                item.orderRows.map((orderRow) => (
                  <div key={orderRow.id}>
                    <Divider my={4} />

                    <Wrap spacing={4}>
                      {!isEmpty(orderRow.description) && (
                        <ItemData
                          label={
                            <FormattedMessage id="history.modal.orderRow.description.label" />
                          }
                          value={orderRow.description}
                        />
                      )}

                      {!isEmpty(orderRow.finishDate) && (
                        <ItemData
                          label={
                            <FormattedMessage id="history.modal.orderRow.finishDate.label" />
                          }
                          value={<FormattedDate value={orderRow.finishDate} />}
                        />
                      )}

                      {item.type !== CaskOrderHistoryType.Production &&
                        !!orderRow.price &&
                        orderRow.price !== 0 && (
                          <ItemData
                            label={
                              <FormattedMessage id="history.modal.orderRow.price.label" />
                            }
                            value={<FormattedNumber value={orderRow.price} />}
                          />
                        )}

                      {/* !!orderRow.amount && orderRow.amount !== 0 && (
                    <ItemData
                        label={<FormattedMessage id="history.modal.orderRow.amount.label" />}
                        value={<FormattedNumber value={orderRow.amount} />}
                    />
                )*/}

                      {!!orderRow.quantity && orderRow.quantity !== 0 && (
                        <ItemData
                          label={
                            <FormattedMessage id="history.modal.orderRow.quantity.label" />
                          }
                          value={<FormattedNumber value={orderRow.quantity} />}
                        />
                      )}
                    </Wrap>
                  </div>
                ))}
            </Box>
            {item.isSettlement && isCaskOwner && (
              <Button
                onClick={async () => {
                  setLoadingSettlement(true);
                  await getSettlement(item.id, language);
                  setLoadingSettlement(false);
                }}
                isLoading={loadingSettlement}
                w={isMobile ? "full" : "auto"}
                mt={4}
                borderColor="#807F7F"
                variant="outline"
                rightIcon={<AiOutlineFilePdf size="1.25rem" />}
              >
                <FormattedMessage id="cask.info.settlement" />
              </Button>
            )}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

const CaskHistory: React.FC = () => {
  const { localeStore, caskHistoryStore } = useStores();
  const { cask, loading, isCaskOwner } = useCurrentCask();
  const isMobile = useMedia("(max-width: 500px)");

  useEffect(() => {
    if (cask) caskHistoryStore.fetchOrderHistory(cask.id);
  }, [cask, caskHistoryStore]);

  if (!cask || loading || caskHistoryStore.isLoading) return null;

  return (
    <Box my={8} maxW={632} mb={6} id="history">
      <Heading size="lg" fontWeight={500}>
        <FormattedMessage id="cask.history.title" />
      </Heading>
      <Stack mt={4} mx={isMobile ? -6 : undefined}>
        <Accordion allowMultiple>
          {caskHistoryStore.orderHistory.map((h) => (
            <HistoryListItem
              key={h.id}
              item={h}
              isCaskOwner={isCaskOwner}
              language={localeStore.language}
            />
          ))}
        </Accordion>
      </Stack>
    </Box>
  );
};

export default observer(CaskHistory);
