import { FieldState, FormState } from "formstate";
import { action } from "mobx";
import User from "models/user";
import { PHONE_MAX_LENGTH, PHONE_MIN_LENGTH } from "utils/forms/constants";
import {
  countryRequired,
  maxLength,
  minLengthOrEmpty,
  required,
} from "utils/forms/validators";

type CompanyInfoFormState = {
  orgNumber: FieldState<string>;
  orgName: FieldState<string>;
  address: FieldState<string>;
  city: FieldState<string>;
  zipCode: FieldState<string>;
  country: FieldState<string>;
  phoneNumber: FieldState<string>;
  firstName: FieldState<string>;
  lastName: FieldState<string>;
};

class CompanyInfoFormUiStore {
  orgNumber: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.orgNumber.error.required")
  );
  orgName: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.orgName.error.required")
  );
  address: FieldState<string> = new FieldState("");
  city: FieldState<string> = new FieldState("");
  zipCode: FieldState<string> = new FieldState("");
  phoneNumber: FieldState<string> = new FieldState("").validators(
    maxLength(
      PHONE_MAX_LENGTH,
      "settings.contactInfo.phoneNumber.error.maxLength"
    ),
    minLengthOrEmpty(
      PHONE_MIN_LENGTH,
      "settings.contactInfo.phoneNumber.error.minLength"
    ),
    required("settings.contactInfo.company.phoneNumber.error.required")
  );
  country: FieldState<string> = new FieldState("").validators(
    countryRequired("settings.contactInfo.company.country.error.required")
  );
  firstName: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.firstName.error.required")
  );
  lastName: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.lastName.error.required")
  );

  form = new FormState<CompanyInfoFormState>({
    orgNumber: this.orgNumber,
    orgName: this.orgName,
    address: this.address,
    city: this.city,
    zipCode: this.zipCode,
    country: this.country,
    phoneNumber: this.phoneNumber,
    firstName: this.firstName,
    lastName: this.lastName,
  });

  @action
  resetForm() {
    this.orgNumber.reset();
    this.orgName.reset();
    this.address.reset();
    this.city.reset();
    this.zipCode.reset();
    this.country.reset();
    this.phoneNumber.reset();
    this.firstName.reset();
    this.lastName.reset();
  }

  @action
  setForm(user: User) {
    this.orgNumber.value = user.vismaUser.orgNumber || "";
    this.orgName.value = user.vismaUser.orgName || "";
    this.address.value = user.vismaUser.address || "";
    this.city.value = user.vismaUser.city || "";
    this.zipCode.value = user.vismaUser.zipCode || "";
    this.country.value = user.vismaUser.country || "";
    this.phoneNumber.value = user.vismaUser.phoneNumber || "";
    this.firstName.value = user.vismaUser.firstName || "";
    this.lastName.value = user.vismaUser.lastName || "";
  }
}

export default CompanyInfoFormUiStore;
export const companyInfoFormUiStore = new CompanyInfoFormUiStore();
