import { RaisedButton, TextField } from "material-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import RegisterService from "services/register";
import LocaleStore from "stores/domain/locale";
import format from "utils/format";
import Validator from "validator";

interface State {
  value: string;
  isValid: boolean;
  errorText: string;
  emailSent: boolean;
}

interface Props {
  localeStore?: LocaleStore;
}

@inject("localeStore")
@observer
class VerifyPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: "",
      isValid: false,
      errorText: "",
      emailSent: false,
    };
  }

  get localeStore() {
    return this.props.localeStore!;
  }

  submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let response = await RegisterService.sendNewVerificationEmail(
      this.state.value,
      this.localeStore.language
    );

    let { succeeded, error } = response;
    if (!succeeded) {
      if (error && error.code === "notFound") {
        this.setState({
          isValid: false,
          errorText: "register.verify.new.email.error.notFound",
        });
        return;
      }

      if (error && error.code === "alreadyVerified") {
        this.setState({
          isValid: false,
          errorText: "register.verify.new.email.error.alreadyVerified",
        });
        return;
      }

      throw new Error("Couldn\t send a new verification email");
    }

    this.setState({ emailSent: true });
  };

  changeEmail = (e: React.FormEvent<HTMLInputElement>) => {
    let { value } = e.currentTarget;
    let isValid = Validator.isEmail(value);
    let errorText = "";

    if (!isValid) {
      errorText = "register.verify.new.email.error.invalid";
    }

    this.setState({ value, isValid, errorText, emailSent: false });
  };

  render() {
    let { value, isValid, errorText, emailSent } = this.state;
    return (
      <form onSubmit={this.submit}>
        <p>
          <FormattedMessage id="register.verify.new.intro" />
        </p>
        {emailSent ? (
          <div className="text-success">
            <FormattedMessage
              id="register.verify.new.emailSent"
              values={{ email: value }}
            />
          </div>
        ) : null}
        <TextField
          type="email"
          floatingLabelText={format("register.verify.new.email.label")}
          fullWidth={true}
          onChange={this.changeEmail}
          value={value}
          errorText={format(errorText)}
        />

        <RaisedButton
          primary={true}
          type="submit"
          label={format("register.verify.new.submit")}
          disabled={!isValid}
        />
      </form>
    );
  }
}

export default VerifyPage;
