import { action, makeObservable, observable } from "mobx";
import Event from "models/event";
import EventService from "services/events";

class EventsStore {
  @observable isLoading!: boolean;
  @observable events!: Array<Event>;

  constructor() {
    makeObservable(this);
  }

  @action
  async loadEvents() {
    this.isLoading = true;

    let response = await EventService.fetchEvents();
    if (response.hasErrors) {
      throw new Error(`Couldn't fetch events`);
    }

    this.events = this.sortEvents(response.data);

    this.isLoading = false;
  }

  private sortEvents(events: Event[]) {
    events.sort((e1, e2) => {
      try {
        const date1 = new Date(e1.dateTime).getTime();
        const date2 = new Date(e2.dateTime).getTime();
        const diff = date1 - date2;
        return diff;
      } catch (e) {
        console.warn(`Could not sort event: ${e}`);
        return 0;
      }
    });
    return events;
  }
}

export default EventsStore;
export const eventsStore = new EventsStore();
