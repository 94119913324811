import { RaisedButton } from "material-ui";
import * as React from "react";
import { Link } from "react-router-dom";
import LoginForm from "scenes/Account/scenes/Login/components/LoginFormContainer";
import styled from "styled-components";
import format from "utils/format";

interface Props {}

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 48em) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const BorderDiv = styled.div`
  border: #ebebeb;
  border-width: medium;
  border-style: solid;

  width: 400px;
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 45px 35px;
`;

const StyledHeader = styled.h3`
  margin: 0px;
`;

const StyledDiv = styled.div`
  margin-top: 30px;
`;

const StyledCenteredDiv = styled.div`
  text-align: center;
`;

const ActionButton = styled(RaisedButton)`
  margin-top: 93px;
  width: 100%;
`;

const ShoppingCartLoggedOut: React.FC<Props> = (props) => (
  <Wrapper>
    <BorderDiv>
      <StyledHeader>{format("caskShop.cart.loggedOut.login")}</StyledHeader>
      <LoginForm />
      <StyledCenteredDiv>
        <Link to="/reset-password">{format("login.resetPassword.text")}</Link>
      </StyledCenteredDiv>
    </BorderDiv>
    <BorderDiv>
      <StyledHeader>{format("caskShop.cart.loggedOut.register")}</StyledHeader>
      <StyledDiv>{format("caskShop.cart.loggedOut.register.text")}</StyledDiv>
      <StyledDiv>
        <Link to="/account/register">
          <ActionButton primary={true} label={format("register.submit")} />
        </Link>
      </StyledDiv>
    </BorderDiv>
  </Wrapper>
);

export default ShoppingCartLoggedOut;
