import { CaskStatus } from "models/cask-status";
import { useParams } from "react-router";
import { useStores } from "stores";

export const useCurrentCask = () => {
  const { id } = useParams<{ id: string }>();
  const { sessionStore, casksStore, localeStore } = useStores();
  const loading = sessionStore.isFetchingUserData || casksStore.isLoading;
  const isCaskOwner = loading
    ? undefined
    : sessionStore.isCaskOwner(Number(id));
  const cask = loading
    ? undefined
    : casksStore.casks.find((c) => c.id === Number(id));
  const caskTypeInfo = loading
    ? undefined
    : cask?.caskType?.caskTypeInformation.find(
        (c) => c.language === localeStore.languageInt
      );
  const recipeInfo = loading
    ? undefined
    : cask?.recipe?.recipeInformation.find(
        (c) => c.language === localeStore.languageInt
      ) || { name: cask?.recipeText };
  const isEmptied = cask?.status === CaskStatus.FullBottled;
  const members = isCaskOwner
    ? cask?.caskUsers.filter((u) => u.user.id !== sessionStore.user.id)
    : cask?.caskUsers;

  return {
    isCaskOwner,
    cask,
    caskTypeInfo,
    recipeInfo,
    loading,
    isEmptied,
    members,
  };
};
