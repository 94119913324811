import * as React from 'react';
import { Component } from 'react';
import { NewMemberStore } from 'stores';
import styled from 'styled-components';
import { formatToString } from 'utils/format';
import CircleSpinner from 'components/Loading/CircleSpinner';
import { inject, observer } from 'mobx-react';

const Wrapper = styled.div`
  padding: 30px 0 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  newMemberStore?: NewMemberStore;
}

@inject('newMemberStore')
@observer
export default class ResendInvitationPage extends Component<Props, {}> {
  componentDidMount() {
    const { userId } = this.props.newMemberStore!;
    this.props.newMemberStore!.resendEmail(userId);
  }
  render() {
    const { resending } = this.props.newMemberStore!;
    return (
      <Wrapper>
        <h2>{formatToString('newMember.register.resend')}</h2>
        {resending && <CircleSpinner />}
        {!resending && (
          <p>{formatToString('newMember.register.resend.success')}</p>
        )}
      </Wrapper>
    );
  }
}
