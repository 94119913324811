import Api from './api';
import Cask from 'models/cask';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';
import CaskUser from 'models/cask-user';
import { Language } from 'stores/domain/locale';

export interface CaskMemberVm {
  caskId: number;
  email: string;
  language: Language;
  firstname: string;
  lastname: string;
}

export interface VismaMember {
  caskId: number;
  LocalVismaUserId: number;
}

export default class CasksService {
  static async fetchCasks(): Promise<JsonApiResponse<Cask[]>> {
    let response = await Api.get(`user/casks`);
    return await toJsonApiResponse<Cask[]>(response);
  }

  static async addMember(
    caskMember: CaskMemberVm
  ): Promise<JsonApiResponse<CaskUser[]>> {
    let response = await Api.post(
      `user/casks/${caskMember.caskId}/add-member`,
      true,
      undefined,
      caskMember
    );
    return await toJsonApiResponse<CaskUser[]>(response);
  }

  static async setVismaMember(
    vismaMember: VismaMember
  ): Promise<JsonApiResponse<Cask>> {
    let response = await Api.post(
      `user/casks/${vismaMember.caskId}/set-visma-member`,
      true,
      undefined,
      vismaMember
    );
    return await toJsonApiResponse<Cask>(response);
  }

  static async removeMember(
    caskMember: CaskMemberVm
  ): Promise<JsonApiResponse<CaskUser[]>> {
    let response = await Api.post(
      `user/casks/${caskMember.caskId}/remove-member`,
      true,
      undefined,
      caskMember
    );
    return await toJsonApiResponse<CaskUser[]>(response);
  }
}
