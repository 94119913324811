import Countries from "i18n-iso-countries";
import _ from "lodash";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import * as React from "react";
import { Language } from "stores/domain/locale";

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
Countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));

interface Props extends __MaterialUI.SelectFieldProps {
  language: Language;
}

export interface Country {
  code: string;
  name: string;
}

export default class CountryPicker extends React.Component<Props, {}> {
  get countries(): Array<Country> {
    let countries = Countries.getNames(this.props.language);
    let countryList = new Array<Country>();

    for (let property in countries) {
      if (countries.hasOwnProperty(property)) {
        countryList.push({
          code: property,
          name: countries[property],
        });
      }
    }
    return _.sortBy(countryList, (c) => c.name);
  }

  get selectFieldProps() {
    let { language, ...rest } = this.props;
    return rest;
  }

  render() {
    return (
      <SelectField {...this.selectFieldProps}>
        {this.countries.map((country) => (
          <MenuItem
            key={country.code}
            value={country.code}
            primaryText={country.name}
          />
        ))}
      </SelectField>
    );
  }
}
