import Title from "components/Title";
import {
  Checkbox,
  Paper,
  RadioButton,
  RadioButtonGroup,
  RaisedButton,
} from "material-ui";
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import CaskType from "models/cask-type";
import Recipe from "models/recipe";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  BottlePriceListUiStore,
  BottlingPriceVariablesStore,
  RecipesStore,
} from "stores";
import styled from "styled-components";
import formatMessage, { format } from "utils/format";
import { PRESTORED_PRICE } from "utils/prices";
import PricingExampleModal from "./PricingExampleModal";

interface Props {
  recipesStore?: RecipesStore;
  caskTypeInstance: {
    caskType: CaskType;
    recipeId: string;
    preStored: Boolean;
  };
  bottlingPriceVariablesStore?: BottlingPriceVariablesStore;
  bottlePriceListUiStore?: BottlePriceListUiStore;
  toggleCaskTypePreStored: (caskType: CaskType) => void;
  setCaskTypeRecipe: (caskType: CaskType, recipeId: string) => void;
  addToCart: (caskType: CaskType, recipeId: string, preStored: Boolean) => void;
  findRecipe: (recipeId: string) => Recipe;
  recipes: Recipe[];
}

const Wrapper = styled(Paper)`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 62em) {
    height: 322px;
  }
`;

const ImageWrapper = styled.div`
  padding: 1rem;

  @media (min-width: 62em) {
    height: 100%;
    width: 483px;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 62em) {
    width: 100%;
  }
`;

const InfoWrapper = styled.div`
  padding: 15px;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: center;
  justify-self: flex-end;
  padding-bottom: 5px;
`;

const SekSpan = styled.span`
  margin-left: 2px;
  font-size: 70%;
  position: relative;
  bottom: 0.3em;
`;

const Price = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Img = styled.img`
  @media (max-width: 62em) {
    width: 100%;
    height: 100%;
  }

  max-width: 100%;
  max-height: 100%;
`;

const PriceExample = styled.a`
  position: absolute;
  font-size: 10px;
  cursor: pointer;
  bottom: 0;
  color: #42413d;
`;

const defaultCaskImageUrl: string = require("assets/images/svensk-ek.jpg");

@inject("recipesStore", "bottlePriceListUiStore", "bottlingPriceVariablesStore")
@observer
export default class CaskTypeComponent extends React.Component<Props, {}> {
  @observable modalIsOpen: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.state = {
      scrollPosition: window.scrollY,
    };
  }

  getRecipe(id: string) {
    return this.props.recipesStore!.find(parseInt(id, 10));
  }

  get simpleStyles() {
    return {
      prioritize: {
        flex: "1",
      },
      topMargin: {
        marginTop: "10px",
      },
    };
  }

  openModal = () => {
    this.modalIsOpen = true;
  };

  closeModal = () => {
    this.modalIsOpen = false;
  };

  isSmokey(recipe: Recipe): boolean {
    return recipe.id === 2;
  }

  formatPrice(price: number | string): string {
    let str = price.toString();
    if (str.length > 3) {
      return `${str.substr(0, str.length - 3)} ${str.substr(str.length - 3)}`;
    }

    return price.toString();
  }

  get recipePriceDifference() {
    let recipes = this.props.recipes;
    if (recipes.length !== 2) {
      return 0;
    }

    if (!this.isSmokey(recipes[1])) {
      return 0;
    }

    return recipes[1].price - recipes[0].price;
  }

  get smokeyRecipePrice() {
    return this.formatPrice(this.recipePriceDifference);
  }

  render() {
    let { caskType, recipeId, preStored } = this.props.caskTypeInstance;
    let { toggleCaskTypePreStored, setCaskTypeRecipe, addToCart, findRecipe } =
      this.props;
    let caskTypeInfo = format(caskType.caskTypeInformation);
    const isGin = this.props.recipes.length === 1;

    return (
      <Wrapper>
        <ImageWrapper>
          <Img src={caskType.imageUrl || defaultCaskImageUrl} />
        </ImageWrapper>
        <InfoWrapper>
          <div>
            <Title>{caskTypeInfo.name}</Title>
          </div>
          <div style={this.simpleStyles.prioritize}>
            {caskTypeInfo.description}
          </div>
          {!isGin && (
            <>
              <div style={this.simpleStyles.topMargin}>
                <Checkbox
                  label={
                    <span>
                      {formatMessage("caskShop.preStored.2.year.label")}{" "}
                      <FormattedMessage
                        id="caskShop.extraPrice"
                        values={{
                          price: this.formatPrice(
                            this.formatPrice(PRESTORED_PRICE)
                          ),
                        }}
                      />
                    </span>
                  }
                  onCheck={() => toggleCaskTypePreStored(caskType)}
                  checked={preStored ? true : false}
                />
              </div>
              <div>
                <RadioButtonGroup
                  name={"customizeRecipeToggle" + caskType.id}
                  valueSelected={recipeId}
                  onChange={(event, value) =>
                    setCaskTypeRecipe(caskType, value)
                  }
                  style={{ marginTop: 2, marginBottom: 5 }}
                >
                  {this.props.recipes.map((recipe) => (
                    <RadioButton
                      key={recipe.id}
                      value={recipe.id.toString()}
                      label={
                        <span>
                          {format(recipe.recipeInformation).name}{" "}
                          {this.isSmokey(recipe) && (
                            <FormattedMessage
                              id="caskShop.extraPrice"
                              values={{
                                price: this.formatPrice(this.smokeyRecipePrice),
                              }}
                            />
                          )}
                        </span>
                      }
                    />
                  ))}
                </RadioButtonGroup>
              </div>
            </>
          )}
          <BottomContainer>
            <Price style={this.simpleStyles.prioritize}>
              {this.formatPrice(
                caskType.price +
                  findRecipe(recipeId).price +
                  (preStored ? PRESTORED_PRICE : 0)
              )}
              <SekSpan>{`SEK`}</SekSpan>
            </Price>
            <RaisedButton
              onClick={() => addToCart(caskType, recipeId, preStored)}
              label={formatMessage("caskShop.add.cart.label")}
              primary={true}
            />
          </BottomContainer>
          <PriceExample onClick={this.openModal}>
            <FormattedMessage id={"caskShop.priceExample"} />
          </PriceExample>
        </InfoWrapper>
        <PricingExampleModal
          isOpen={this.modalIsOpen}
          onClose={this.closeModal}
          loanAmount={this.getRecipe(recipeId).price}
          bottlePriceListUiStore={this.props.bottlePriceListUiStore}
          bottlingPriceVariablesStore={this.props.bottlingPriceVariablesStore}
        />
      </Wrapper>
    );
  }
}
