export default class CaskOrderHistory {
  id!: number;
  vismaOrderId!: number;
  activityId!: number | null;
  activityName!: string;
  actionId!: number | null;
  actionName!: string;
  bottlingVolumeId!: number | null;
  bottlingVolumeName!: string;
  bottlingRoundId!: number | null;
  bottlingRoundName!: string;
  dilution!: string;
  status!: string;
  vismaCaskNumber!: string;
  preferedDeliveryTime?: Date;
  alcohol!: number;
  comment!: string;
  orderRows!: CaskHistoryOrderRows[];
  type!: CaskOrderHistoryType;
  isSettlement!: boolean;
}

export class CaskHistoryOrderRows {
  id!: number;
  description!: string;
  finishDate!: string;
  price!: number;
  amount!: number;
  productNumber!: string;
  quantity!: number;
}

export enum CaskOrderHistoryType {
  Purchase = 1,
  Production = 7,
  StockTransfer = 8,
  Unknown = 0,
}
