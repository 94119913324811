import { Box, Collapse, Text } from "@chakra-ui/react";
import { StepLine, StepNumber, StepText } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import FormInput from "components/forms/FormInput";
import { useFormikContext } from "formik";
import { observer } from "mobx-react";
import { SamplingOrderType } from "models/sampling-order";
import { FormattedHTMLMessage } from "react-intl";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import styled from "styled-components";
import { SamplingFormValues } from "./SamplingForm";

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;
  min-height: 40px;
  margin: 0 30px 30px;
  position: relative;
`;

const SamplingSystembolagetFormPart = () => {
  const {
    values: { type },
  } = useFormikContext<SamplingFormValues>();
  const isMobile = useMedia("(max-width: 48em)");
  const { cask, loading } = useCurrentCask();

  if (loading) return null;

  return (
    <Collapse in={type === SamplingOrderType.Systembolaget}>
      <Box mt={6}>
        <Box my={4}>
          <CustomAlert labelKey="orders.sampling.systembolaget.title" />
        </Box>
        <Box my={4}>
          <CustomAlert
            status="warning"
            labelKey="orders.sampling.systembolaget.reminder"
          />
        </Box>
        <Box mx={isMobile ? -6 : -2} mt={6}>
          <StepsContainer>
            <StepNumber>1</StepNumber>
            <StepLine />
            <StepText>
              <FormattedHTMLMessage id="cask.orders.sampling.systembolaget.step1" />
            </StepText>
          </StepsContainer>
          <StepsContainer>
            <StepNumber>2</StepNumber>
            <StepLine />
            <StepText>
              <FormattedHTMLMessage id="cask.orders.sampling.systembolaget.step2" />
            </StepText>
          </StepsContainer>
          <StepsContainer>
            <StepNumber>3</StepNumber>
            <StepLine />
            <StepText>
              <FormattedHTMLMessage id="cask.orders.sampling.systembolaget.step3" />
            </StepText>
          </StepsContainer>
          <StepsContainer>
            <StepNumber>4</StepNumber>
            <StepLine />
            <StepText>
              <Box>
                <FormattedHTMLMessage id="cask.orders.sampling.systembolaget.step4" />
                <Text mt={4}>Dryckens namn</Text>
                <Text fontWeight={300}>
                  Fat {cask?.id} {cask?.vismaCaskNumber || ""}
                </Text>

                <Text mt={4}>Typ av dryck</Text>
                <Text fontWeight={300}>Spritdryck</Text>

                <Text mt={4}>Förpackning</Text>
                <Text fontWeight={300}>Flaska</Text>

                <Text mt={4}>Volym ml</Text>
                <Text fontWeight={300}>
                  <FormattedHTMLMessage id="orders.sampling.systembolaget.step2.volume" />
                </Text>

                <Text mt={4}>Antal</Text>
                <Text fontWeight={300}>1</Text>
              </Box>
            </StepText>
          </StepsContainer>
          <StepsContainer>
            <StepNumber>5</StepNumber>
            <StepLine />
            <StepText>
              <Box>
                <FormattedHTMLMessage id="cask.orders.sampling.systembolaget.step5" />
                <Box mt={4}>
                  <CustomAlert labelKey="cask.orders.sampling.systembolaget.step5.notice" />
                </Box>
              </Box>
            </StepText>
          </StepsContainer>
          <StepsContainer>
            <StepNumber>6</StepNumber>
            <StepLine />
            <StepText>
              <FormattedHTMLMessage id="cask.orders.sampling.systembolaget.step6" />
            </StepText>
          </StepsContainer>
          <StepsContainer>
            <StepNumber>7</StepNumber>
            <StepText>
              <Box>
                <FormattedHTMLMessage id="cask.orders.sampling.systembolaget.step7" />
              </Box>
            </StepText>
          </StepsContainer>
        </Box>
      </Box>
      <FormInput
        mt={4}
        labelKey="cask.orders.sampling.systembolaget.input"
        name="systembolagetRequestNumber"
        placeholderKey={"cask.orders.sampling.systembolaget.input.placeholder"}
      />
      <Box mt={4}>
        <CustomAlert labelKey="cask.orders.sampling.systembolaget.step7.notice" />
      </Box>
      <Box my={4}>
        <CustomAlert
          status="warning"
          labelKey="orders.sampling.systembolaget.step4.notice"
        />
      </Box>
    </Collapse>
  );
};

export default observer(SamplingSystembolagetFormPart);
