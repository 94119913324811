import ErrorMessage from "components/ErrorMessage";
import LoadingIndicator from "components/Loading/LoadingIndicator";
import MobileStepper from "components/MobileStepper";
import { FlatButton, Paper, RaisedButton } from "material-ui";
import { Step, StepButton, Stepper } from "material-ui/Stepper";
import { inject, observer } from "mobx-react";
import CartCask from "models/cart-cask";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ShoppingCartUiStore, {
  CartStep,
  PaymentType,
} from "stores/ui/cask-shop/shopping-cart";
import styled from "styled-components";
import format from "utils/format";
import BottomSummary from "./BottomSummary";
import CartCaskComponent from "./CartCaskComponent";
import EcsterPayment from "./EcsterPayment";
import EmptyShoppingCart from "./EmptyShoppingCart";
import InvoiceForm from "./InvoiceForm";
import ShoppingCartLoggedOut from "./ShoppingCartLoggedOut";
import ShoppingCartOptions from "./ShoppingCartOptions";
import ShoppingCartPaymentOptions from "./ShoppingCartPaymentOptions";

interface Props {
  totalCartCasksCount: number;
  cartCasks: CartCask[];
  currentCartStep: number;
  stepForwards: () => void;
  stepBackwards: () => void;
  sendOrder: () => void;
  emptyCart: () => void;
  removeCask: (cartCask: CartCask) => void;
  isAuthenticated: boolean;
  isPersonalAccount: boolean;
  onInitEcsterPayment: () => void;
  selectedPaymentType?: PaymentType;
  onSelectPaymentType: (paymentType: PaymentType) => void;
  ecsterIsLoading: boolean;
  shoppingCartUiStore?: ShoppingCartUiStore;
  goToStep: (step: number) => void;
  sendingOrder: boolean;
}

const Wrapper = styled(Paper)`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 48em) {
    font-size: 16px;
  }
`;

const BodyPart = styled.div`
  padding: 0px 30px 30px 30px;

  @media (max-width: 48em) {
    padding: 0px 10px 30px 10px;
  }
`;

const Bottom = styled.div`
  padding-top: 35px;
  flex: 1 1 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  padding: 0px 16px;
`;

const EmptyCartButton = styled(FlatButton)`
  align-self: right;
  margin-top: 20px !important;
`;

const EmptyCartIcon = styled.i`
  margin-left: 15px;
`;

const Title = styled.h2`
  font-weight: 500;
  color: #8a7b52;
  @media only screen and (min-width: 768px) {
    margin: 0px 0px 15px 0px;
  }
`;

const TopPart = styled.div`
  padding: 10px 15px;
  background-color: #ebebeb;

  @media only screen and (min-width: 768px) {
    padding: 35px;
  }
`;

const Message = styled.div`
  padding: 5px 15px;
`;
const Error = styled.div`
  display: block;
`;

@inject("shoppingCartUiStore")
@observer
class ShoppingCart extends React.Component<Props, {}> {
  get isPayingWithEcster() {
    return this.props.selectedPaymentType === PaymentType.Ecster;
  }

  get cartCaskComponents() {
    return this.props.cartCasks.map((cartCask) => (
      <CartCaskComponent
        key={this.props.cartCasks.indexOf(cartCask)}
        cartCask={cartCask}
        removeCask={this.props.removeCask}
        currentCartStep={this.props.currentCartStep}
      />
    ));
  }

  get columnStyle() {
    return {
      rightColumn: {
        paddingLeft: "30px",
      },
    };
  }

  renderEmptyCartView() {
    return <EmptyShoppingCart />;
  }

  renderBody() {
    let {
      cartCasks,
      currentCartStep,
      stepForwards,
      stepBackwards,
      sendOrder,
      emptyCart,
      removeCask,
      isAuthenticated,
      sendingOrder,
      isPersonalAccount,
    } = this.props;
    let { hasFieldError, errorMessage } = this.props.shoppingCartUiStore!;

    return (
      <BodyPart>
        <EmptyCartButton onClick={emptyCart}>
          <Container>
            <span>{format("caskShop.cart.view.emptyCart.label")}</span>
            <EmptyCartIcon className="material-icons">delete</EmptyCartIcon>
          </Container>
        </EmptyCartButton>

        {(currentCartStep === CartStep.CartView ||
          currentCartStep === CartStep.CartPayment) && (
          <div>
            {this.cartCaskComponents}
            {(cartCasks.length > 1 ||
              currentCartStep === CartStep.CartPayment) && (
              <BottomSummary
                cartCasks={cartCasks}
                currentCartStep={currentCartStep}
                isPersonalAccount={isPersonalAccount}
                formStore={this.props.shoppingCartUiStore!}
              />
            )}
          </div>
        )}

        {currentCartStep === CartStep.CartOptions &&
          (isAuthenticated ? (
            <ShoppingCartOptions
              cartCasks={cartCasks}
              removeCask={removeCask}
              isPersonalAccount={isPersonalAccount}
            />
          ) : (
            <ShoppingCartLoggedOut />
          ))}

        {currentCartStep === CartStep.CartPayment &&
          (isAuthenticated ? (
            <div>
              <ShoppingCartPaymentOptions
                onInitEcsterPayment={this.props.onInitEcsterPayment}
                selectedPaymentType={this.props.selectedPaymentType}
                onSelectPaymentType={this.props.onSelectPaymentType}
              />

              {this.props.selectedPaymentType === PaymentType.Invoice && (
                <InvoiceForm />
              )}

              {this.props.selectedPaymentType === PaymentType.Ecster && (
                <EcsterPayment
                  onInitEcsterPayment={this.props.onInitEcsterPayment}
                  isLoading={this.props.ecsterIsLoading}
                />
              )}
            </div>
          ) : (
            <ShoppingCartLoggedOut />
          ))}

        <Bottom>
          {currentCartStep !== CartStep.CartView && (
            <FlatButton
              label={format("caskShop.steps.back")}
              primary={true}
              onClick={stepBackwards}
            />
          )}

          {currentCartStep === CartStep.CartView && (
            <FlatButton
              containerElement={<Link to={`/shop`} />}
              label={format("caskShop.cart.view.cancelButton")}
              primary={true}
            />
          )}

          {currentCartStep === CartStep.CartPayment ? (
            this.isPayingWithEcster ? null : (
              <RaisedButton
                label={format("caskShop.steps.confirm")}
                primary={true}
                onClick={sendOrder}
                disabled={
                  this.props.selectedPaymentType === undefined || sendingOrder
                }
              />
            )
          ) : (
            <RaisedButton
              label={format("caskShop.steps.next")}
              primary={true}
              onClick={stepForwards}
              disabled={
                currentCartStep !== CartStep.CartView &&
                (!isAuthenticated || hasFieldError)
              }
            />
          )}

          {sendingOrder && (
            <Message>
              <LoadingIndicator
                label={<FormattedMessage id="orders.sendingOrderMessage" />}
              />
            </Message>
          )}
        </Bottom>
        {currentCartStep === CartStep.CartPayment && errorMessage && (
          <Error>
            <ErrorMessage id={errorMessage} />
          </Error>
        )}
      </BodyPart>
    );
  }

  render() {
    let { totalCartCasksCount, currentCartStep, goToStep } = this.props;

    return (
      <Wrapper>
        <TopPart>
          <Title>
            <FormattedMessage id="caskShop.cart.title" />
          </Title>
          <MobileStepper
            hiddenDesktop={true}
            currentStep={currentCartStep}
            maxStep={3}
          />
          <div className="hidden-xs hidden-sm">
            <Stepper style={{ height: 35 }} activeStep={currentCartStep - 1}>
              <Step>
                <StepButton onClick={() => goToStep(1)}>
                  <FormattedMessage id="caskShop.cart.stepper.cartView" />
                </StepButton>
              </Step>
              <Step>
                <StepButton onClick={() => goToStep(2)}>
                  <FormattedMessage id="caskShop.cart.stepper.cartOptions" />
                </StepButton>
              </Step>
              <Step>
                <StepButton onClick={() => goToStep(3)}>
                  <FormattedMessage id="caskShop.cart.stepper.cartPayment" />
                </StepButton>
              </Step>
            </Stepper>
          </div>
        </TopPart>
        {totalCartCasksCount ? this.renderBody() : this.renderEmptyCartView()}
      </Wrapper>
    );
  }
}

export default ShoppingCart;
