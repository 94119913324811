import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { TextField, RaisedButton } from 'material-ui';
import LoginFormUiStore from 'stores/ui/account/login-form';
import { observer } from 'mobx-react';
import format from 'utils/format';
import { FormattedMessage } from 'react-intl';
import LoadingIndicator from 'components/Loading/LoadingIndicator';

interface ThemeProps {
  theme?: __MaterialUI.Styles.MuiTheme;
}

interface Props {
  loginFormUiStore: LoginFormUiStore;
  hasFieldError: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isSubmitting: boolean;
  isFetchingUserData: boolean;
}

const Form = styled.form`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;

const FormError = styled.div`
  color: ${(props: ThemeProps) => props.theme!.textField!.errorColor!}
`;

const Message = styled.div`
  padding: 15px 0px;
`;

@observer
export default class UserRegisterForm extends Component<Props, {}> {
  render() {
    let { loginFormUiStore, isSubmitting, isFetchingUserData } = this.props;

    return (
      <Form onSubmit={this.props.onSubmit}>
        <TextField
          floatingLabelText={format('login.email.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            loginFormUiStore.email.onChange(e.currentTarget.value.trim())}
          value={loginFormUiStore.email.value}
          errorText={format(loginFormUiStore.email.error)}
        />
        <TextField
          type="password"
          floatingLabelText={format('login.password.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            loginFormUiStore.password.onChange(e.currentTarget.value)}
          value={loginFormUiStore.password.value}
          errorText={format(loginFormUiStore.password.error)}
        />
        <hr />
        <RaisedButton
          primary={true}
          type="submit"
          label={format('login.submit.label')}
          disabled={this.props.hasFieldError || isSubmitting || isFetchingUserData}
        />

        {isSubmitting && 
          <Message>
            <LoadingIndicator label={<FormattedMessage id="login.loggingInMessage"/>}/>
          </Message>
        }

        {!isSubmitting && isFetchingUserData && 
          <Message>
            <LoadingIndicator label={<FormattedMessage id="login.fetchingUserDataMessage"/>}/>
          </Message>
        }

        {loginFormUiStore.hasAuthenticationError &&
          <Message>
            <FormError>
              <FormattedMessage id="login.error.authentication" />
            </FormError>
          </Message>
        }

      </Form>
    );
  }
}