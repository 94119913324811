import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { TextField, RaisedButton } from 'material-ui';
import { observer } from 'mobx-react';
import format from 'utils/format';
import { FormattedMessage } from 'react-intl';
import RegisterFormUiStore from 'stores/ui/account/register-form';
import { Link } from 'react-router-dom';
import { PASSWORD_MIN_LENGTH, PASSWORD_MIN_DIGITS } from 'utils/forms/constants'; 
import LoadingIndicator from 'components/Loading/LoadingIndicator';

interface Props {
  formStore: RegisterFormUiStore;
  hasFieldError: boolean;
  isSubmitting: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const Terms = () => (
  <Link to="/user-terms" target="_blank">
    <FormattedMessage id="register.terms.linkText"/>
  </Link>
);

const TermsContainer = styled.div`
  margin: 10px 0;
`;

const RegisterButton = styled(RaisedButton)`
  margin: 30px 0;
`;

const Form = styled.form`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  padding: 15px 0px;
`;

@observer
export default class RegisterForm extends Component<Props, {}> {
  render() {
    let { formStore, hasFieldError, isSubmitting, onSubmit } = this.props;

    return (
      <Form 
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <div>
          <div><FormattedMessage id="register.intro" /></div>
          <Link to="/account/register/company">
            {format('register.clickHere')}
          </Link>
        </div>

        <TextField
          type="email"
          floatingLabelText={format('register.email.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            formStore.emailUnavailable = false;
            return formStore.email.onChange(e.currentTarget.value);
          }}
          value={formStore.email.value}
          errorText={format(formStore.email.error)}
        />
        <TextField
          type="password"
          floatingLabelText={format('register.password.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            formStore.password.onChange(e.currentTarget.value);
            formStore.validateRepeatedPassword();
          }}
          value={formStore.password.value}
          errorText={format(
            formStore.password.error,
            { minLength: PASSWORD_MIN_LENGTH, minDigits: PASSWORD_MIN_DIGITS }
          )}
        />

        <TextField
          type="password"
          floatingLabelText={format('register.repeatedPassword.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            formStore.repeatedPassword.onChange(e.currentTarget.value)}
          value={formStore.repeatedPassword.value}
          errorText={format(formStore.repeatedPassword.error)}
        />

        <TextField
          floatingLabelText={format('register.firstName.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            formStore.firstName.onChange(e.currentTarget.value)}
          value={formStore.firstName.value}
          errorText={format(formStore.firstName.error)}
        />
        <TextField
          floatingLabelText={format('register.lastName.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            formStore.lastName.onChange(e.currentTarget.value)}
          value={formStore.lastName.value}
          errorText={format(formStore.lastName.error)}
        />
        <RegisterButton
          primary={true}
          type="submit"
          label={format('register.submit')}
          disabled={hasFieldError || isSubmitting}
        />

        {isSubmitting && 
          <Message>
            <LoadingIndicator label={<FormattedMessage id="login.registratingMessage"/>}/>
          </Message>
        }

        <TermsContainer>
            <FormattedMessage id="register.terms.text" values={{ terms: <Terms />}}/>
        </TermsContainer>
      </Form>
    );
  }
}