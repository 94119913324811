import { Box, Heading, Skeleton, Stack, Text } from "@chakra-ui/react";
import BrandButton from "components/BrandButton";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { FiChevronsDown, FiChevronsUp } from "react-icons/fi";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useMedia, useToggle } from "react-use";
import { useStores } from "stores";
import styled from "styled-components";

const ReadMoreText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: break-spaces;
  transition: all 1s;
  font-family: "IvarText", Times, serif;
  font-size: 15px;

  &.expanded {
    display: block;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
  }
`;

const News = () => {
  const [readMore, toggleReadMore] = useToggle(false);
  const { newsStore, localeStore } = useStores();
  const isMobile = useMedia("(max-width: 1024px)");
  const news = newsStore.news.length ? newsStore.news[0] : null;
  const content = news?.newsContent?.find(
    (n) => n.language === localeStore.languageInt
  );

  useEffect(() => {
    newsStore.fetchNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Heading size="lg" fontWeight={500} mb={4}>
        <FormattedMessage id="news.title" />
      </Heading>
      <Box
        bg="#f2f2f2"
        p={isMobile ? 6 : 4}
        py={4}
        mx={isMobile ? -6 : undefined}
      >
        <Box>
          {newsStore.isLoading ? (
            <>
              <Skeleton height="20px" mb={4} />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </>
          ) : (
            <>
              <Text fontSize="sm" color="blackAlpha.700" mb={1}>
                {news && <FormattedDate value={news.updated} />}
              </Text>
              <Text fontSize="xl" fontWeight={400} mb={4}>
                {content?.title}
              </Text>
              <ReadMoreText
                fontWeight={300}
                mt={2}
                className={(readMore || !isMobile) && "expanded"}
              >
                {content?.body}
              </ReadMoreText>
              {isMobile && (
                <Stack pt={4}>
                  <BrandButton
                    onClick={toggleReadMore}
                    variant="outline"
                    bgColor="white"
                    rightIcon={
                      !readMore ? <FiChevronsDown /> : <FiChevronsUp />
                    }
                  >
                    <FormattedMessage
                      id={!readMore ? "news.read-more" : "news.read-less"}
                    />
                  </BrandButton>
                </Stack>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default observer(News);
