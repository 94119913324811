import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  SkeletonCircle,
  SkeletonText,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CaskStatusText from "components/CaskStatusText";
import { observer } from "mobx-react";
import Cask, { getCaskImage, getCaskName } from "models/cask";
import moment from "moment";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { AiOutlineFilePdf } from "react-icons/ai";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import CaskOwnerProofService from "services/cask-owner-proof";
import OrderSettlementService from "services/order-settlement";
import { useStores } from "stores";
import { Language } from "stores/domain/locale";
import CaskImageModal from "./CaskImageModal";

const ButtonRow: React.FC<{
  isMobile: boolean;
  cask?: Cask;
  isCaskOwner?: boolean;
  language: Language;
}> = ({ isMobile, cask, isCaskOwner, language }) => {
  const [loading, setLoading] = useState<{
    proof: boolean;
    settlement: boolean;
  }>({ proof: false, settlement: false });
  if (!cask || !cask.filled || !isCaskOwner) return null;

  const caskOwnerProofClicked = async () => {
    setLoading((l) => ({ ...l, proof: true }));

    await CaskOwnerProofService.getCaskOwnerProof(
      cask.id,
      language,
      moment.tz.guess()
    );

    setLoading((l) => ({ ...l, proof: false }));
  };

  const orderSettlementClicked = async () => {
    setLoading((l) => ({ ...l, settlement: true }));

    await OrderSettlementService.getLatestOrderSettlementForCask(
      cask.id,
      language,
      moment.tz.guess()
    );

    setLoading((l) => ({ ...l, settlement: false }));
  };

  return (
    <HStack spacing={4} w="full" mt="auto" pb={2}>
      {isCaskOwner && (
        <Button
          isLoading={loading.proof}
          onClick={async () => await caskOwnerProofClicked()}
          w={isMobile ? "full" : "auto"}
          borderColor="#807F7F"
          variant="outline"
          rightIcon={<AiOutlineFilePdf size="1.25rem" />}
        >
          <FormattedMessage id="cask.info.proof" />
        </Button>
      )}
      {cask.hasSettlement && (
        <Button
          isLoading={loading.settlement}
          onClick={async () => await orderSettlementClicked()}
          w={isMobile ? "full" : "auto"}
          mt={isMobile ? 4 : 0}
          borderColor="#807F7F"
          variant="outline"
          rightIcon={<AiOutlineFilePdf size="1.25rem" />}
        >
          <FormattedMessage id="cask.info.settlement" />
        </Button>
      )}
    </HStack>
  );
};

const CaskHeader = () => {
  const { localeStore } = useStores();
  const { cask, recipeInfo, loading, isCaskOwner } = useCurrentCask();
  const isMobile = useMedia("(max-width: 500px)");
  const imageModalState = useDisclosure();

  if (loading)
    return (
      <Box w="full" maxW={800} display="flex">
        <Box
          w="185px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mr={[4, 6, 6]}
        >
          <SkeletonCircle size="100" />
        </Box>
        <Box display="flex" w="full" flexGrow={1} flexDir="column">
          <SkeletonText mt="2" noOfLines={3} spacing="4" skeletonHeight="4" />
        </Box>
      </Box>
    );

  return (
    <>
      <Helmet>
        <title>Fatägarwebben - {getCaskName(cask!)}</title>
      </Helmet>
      <Box display="flex" maxW={800}>
        <Box
          minW={isMobile ? "33%" : "256px"}
          maxW={isMobile ? "33%" : "256px"}
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          mr={[4]}
          position="relative"
        >
          <Image
            h="auto"
            w="auto"
            src={getCaskImage(cask!)}
            alt={getCaskName(cask!)}
          />
          {cask?.imageThumb && (
            <Button
              pos="absolute"
              right={2}
              bottom={2}
              //borderRadius="0"
              border="1px"
              p={2}
              borderColor="#f2f2f2"
              opacity={0.87}
              onClick={() => imageModalState.onOpen()}
            >
              <HiMagnifyingGlass />
            </Button>
          )}
        </Box>
        <Box display="flex" w="75%" flexGrow={1} flexDir="column" mt={2}>
          {cask!.vismaCaskNumber && (
            <Text
              color="gray.600"
              fontWeight="light"
              fontSize={["lg", "2xl"]}
              mb={[1, 4]}
            >
              {cask!.vismaCaskNumber}
            </Text>
          )}
          <Heading fontSize="lg" fontWeight={500} mb={0}>
            {getCaskName(cask!)}
          </Heading>
          <Heading fontSize="sm" fontWeight={500} mb={4}>
            {recipeInfo?.name}
          </Heading>
          <Box display={isMobile ? "none" : "flex"}>
            <Text mb={isMobile ? 0 : 4} mr={4} fontWeight={300}>
              <CaskStatusText cask={cask} />
            </Text>
          </Box>
          {!isMobile && (
            <ButtonRow
              isCaskOwner={isCaskOwner}
              cask={cask}
              isMobile={isMobile}
              language={localeStore.language}
            />
          )}
        </Box>
      </Box>
      {isMobile && (
        <Box w="full" mt={6}>
          <Text mb={4} mr={4} fontWeight={300}>
            <CaskStatusText cask={cask} />
          </Text>
          <ButtonRow
            cask={cask}
            isMobile={isMobile}
            isCaskOwner={isCaskOwner}
            language={localeStore.language}
          />
        </Box>
      )}
      <CaskImageModal {...imageModalState} />
    </>
  );
};

export default observer(CaskHeader);
