export const supressWarnings = () => {
  const backup = console.error;

  console.error = function filterWarnings(msg) {
    const supressedWarnings = [
      "ReactDOM.unstable_renderSubtreeIntoContainer()",
    ];

    if (!supressedWarnings.some((entry) => msg.includes(entry))) {
      backup.apply(console, arguments as any);
    }
  };
};
