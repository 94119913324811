import Content from "components/Content";
import Paper from "material-ui/Paper";
import { MuiTheme } from "material-ui/styles";
import muiThemeable from "material-ui/styles/muiThemeable";
import { Tab, Tabs } from "material-ui/Tabs";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import AccountUiStore, { Tab as AccountTab } from "stores/ui/account";
import styled from "styled-components";

const maxWidth = 900;
const Wrapper = styled.div`
  max-width: ${maxWidth}px;
  width: 100%;
  margin: 30px auto;
  height: 100%;

  .col__content {
    padding-left: 0;
    display: flex;
    flex-flow: column;
  }

  .col__bg-img {
    padding-right: 0;
  }

  .row {
    height: 100%;
  }

  .login__paper {
    height: 100%;
  }

  .login__paper,
  .row,
  .col__bg-img,
  .col__content {
    min-height: 650px;
  }

  .login__paper,
  .row {
    min-height: 650px;

    @media (min-width: 48em) {
      margin-top: 30px;
    }
  }
`;

const imgBackground: string = require("assets/images/box-lake-tower.jpg");
const BackgroundImage = styled.div`
  background: url(${imgBackground});
  width: 100%;
  height: 100%;
  background-size: cover;
`;

const RouteContent = styled.div`
  max-width: ${maxWidth / 2 - 100}px;
  box-sizing: border-box;
  width: 100%;
  padding-top: 30px;
  padding-left: 8px;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
`;

const TabContent = styled.div`
  padding-bottom: 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;
const LoginPaper = styled(Paper)<any>`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

interface Props {
  muiTheme?: MuiTheme;
  accountUiStore?: AccountUiStore;
  children?: React.ReactNode;
}

interface PropsWithRoute extends Props, RouteComponentProps<{}> {}

@inject("accountUiStore")
@observer
class AccountPage extends Component<PropsWithRoute, {}> {
  get tabStyle() {
    return {
      tab: {
        color: "#8a7b52",
      },
      tabs: {
        backgroundColor: "transparent",
      },
    };
  }

  render() {
    let { selectedTab, showTabs } = this.props.accountUiStore!;

    return (
      <Wrapper>
        <Content>
          <LoginPaper zDepth={3} className="login__paper">
            <Row>
              <Col className="col__bg-img hidden-xs" xs={12} sm={6}>
                <BackgroundImage />
              </Col>
              <Col className="col__content" xs={12} sm={6}>
                <RouteContent>
                  {showTabs ? (
                    <Tabs
                      initialSelectedIndex={selectedTab}
                      value={selectedTab}
                      className="content__tabs"
                      tabItemContainerStyle={this.tabStyle.tabs}
                    >
                      <Tab
                        value={AccountTab.Login}
                        label={<FormattedMessage id="login.tab.title" />}
                        containerElement={<Link to="/account/login" />}
                        style={this.tabStyle.tab}
                      />

                      <Tab
                        value={AccountTab.Register}
                        label={<FormattedMessage id="register.tab.title" />}
                        containerElement={<Link to="/account/register" />}
                        style={this.tabStyle.tab}
                      />
                    </Tabs>
                  ) : null}
                  <TabContent>{this.props.children}</TabContent>
                </RouteContent>
              </Col>
            </Row>
          </LoginPaper>
        </Content>
      </Wrapper>
    );
  }
}

export default muiThemeable()(withRouter(AccountPage));
