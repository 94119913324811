import * as React from 'react';
import styled from 'styled-components';
import Title from 'components/Title';
import HintText from 'components/HintText';
import { FormattedMessage } from 'react-intl';

interface Props {
    email: string;
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 80px;
    max-width: 500px;
    margin: 20px auto 0;
    text-align: center;
`;

const ContentTitle = styled(Title)`
    display: flex;
    font-size: 26px;
    line-height: 1.5;
`;

const TopTitle = styled.div`
    display: flex;
`;

const ContentText = styled.div`
    font-size: 16px;
`;

const CheckMarkIcon = styled.span`
    background: #008000;
    font-size: 50px !important;
    transform: translateY(12px);
    -ms-transform: translateY(12px);
    color: #fff;
    padding: 5px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
`;

const Margin = styled.div`
    margin-bottom: 60px;
`;

class RegisterComplete extends React.Component<Props, {}> {

    render() {
        let { email } = this.props;

        return (
            <Content>
                <TopTitle>
                  <ContentTitle primary={true}>
                      <CheckMarkIcon className="material-icons">done</CheckMarkIcon>
                  </ContentTitle>
                </TopTitle>
                <ContentTitle primary={true}>
                    <FormattedMessage id="register.complete.header" />
                </ContentTitle>
                <ContentText>
                    <HintText>
                        <FormattedMessage id="register.complete.text" />
                    </HintText>
                </ContentText>
                <ContentTitle primary={true}>
                    <Margin>
                        {email}
                    </Margin>
                </ContentTitle>
                <ContentText>
                    <FormattedMessage id="register.complete.activate" />
                </ContentText>
            </Content>
        );
    }
}

export default RegisterComplete;