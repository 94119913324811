import Title from "components/Title";
import { RaisedButton } from "material-ui";
import Paper from "material-ui/Paper";
import * as React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import formatMessage from "utils/format";

const Wrapper = styled(Paper)``;

const Content = styled.div`
  padding: 40px 20px 10px;
  max-width: 500px;
  margin: 20px auto 0;
`;

const ContentHeader = styled(Title)`
  text-transform: uppercase;
  font-size: 26px;
  line-height: 1.5;
`;

const ContentBody = styled.div`
  margin-top: 30px;
`;

const BackWrapper = styled.div`
  text-align: center;
`;

const BackLink = styled(Link)`
  display: block;
  text-align: center;
  margin: 40px 0px 60px 0px;
`;

const OrderId = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const ThumbUpIcon = styled.i`
  font-size: 50px !important;
  transform: translateY(6px);
  -ms-transform: translateY(6px);
  margin-right: 20px;
`;

const OrderNumberText = styled.div`
  margin-top: 20px;
`;

const Children = styled.div`
  margin-top: 15px;
`;

interface Props {
  backButtonPrimary: boolean;
  orderId: string;
  email: string;
  textIds: {
    title: string;
    summary: string;
    order: string;
    back: string;
  };
  backRoute: string;
  children?: React.ReactNode;
}

const OrderComplete: React.FC<Props> = ({
  orderId,
  email,
  backRoute,
  textIds,
  children,
  backButtonPrimary,
}) => {
  let { title, summary, order, back } = textIds;
  return (
    <Wrapper>
      <Content>
        <ContentHeader bold={true}>
          <ThumbUpIcon className="material-icons">thumb_up</ThumbUpIcon>
          <FormattedMessage id={title} />
        </ContentHeader>

        <ContentBody>
          <FormattedHTMLMessage id={summary} values={{ email }} />

          <OrderNumberText>
            <FormattedMessage id={order} /> <OrderId>{orderId}</OrderId>
          </OrderNumberText>
        </ContentBody>

        <Children>{children}</Children>

        <BackWrapper>
          <BackLink to={backRoute}>
            <RaisedButton
              label={formatMessage(back)}
              primary={backButtonPrimary}
            />
          </BackLink>
        </BackWrapper>
      </Content>
    </Wrapper>
  );
};

export default OrderComplete;
