import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import ChangeContactInfoService, {
  ChangePersonInfoViewModel,
} from "services/change-contact-info";
import SessionStore from "stores/domain/session";
import PersonInfoFormUiStore from "stores/ui/account/person-info-form";
import SnackBarUiStore from "stores/ui/snackBar";
import { formatToString } from "utils/format";
import PersonInfoForm from "./PersonInfoForm";

interface Props {
  personInfoFormUiStore?: PersonInfoFormUiStore;
  sessionStore?: SessionStore;
  snackBarUiStore?: SnackBarUiStore;
}

@inject("personInfoFormUiStore", "sessionStore", "snackBarUiStore")
@observer
class PersonInfoFormContainer extends React.Component<Props, {}> {
  @observable isSubmitting: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.props.personInfoFormUiStore!.resetForm();
    this.props.personInfoFormUiStore!.setForm(this.props.sessionStore!.user);
  }

  get store() {
    return this.props.personInfoFormUiStore!;
  }
  get sessionStore() {
    return this.props.sessionStore!;
  }

  get setPersonInfoViewModel(): ChangePersonInfoViewModel {
    return {
      firstName: this.store.firstName.value,
      lastName: this.store.lastName.value,
      address: this.store.address.value,
      city: this.store.city.value,
      zipCode: this.store.zipCode.value,
      phoneNumber: this.store.phoneNumber.value,
      country: this.store.country.value,
      localVismaUserId: this.sessionStore.vismaUser.id,
    };
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let validation = await this.store.form.validate();
    if (validation.hasError) {
      return;
    }

    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    let newPersonInfo = this.setPersonInfoViewModel;

    try {
      let response = await ChangeContactInfoService.changePersonInfo(
        newPersonInfo
      );

      if (!response.succeeded) {
        throw new Error("Could't change person info.");
      }
    } catch (Error) {
      throw Error;
    }
    this.props.sessionStore!.fetchUser();
    this.props.snackBarUiStore!.open(
      formatToString("settings.contactInfo.complete.message")
    );
    this.isSubmitting = false;
  };

  render() {
    let hasFieldError = this.store.form.hasFieldError;
    return (
      <div>
        <PersonInfoForm
          formStore={this.store}
          hasFieldError={hasFieldError}
          onSubmit={this.onSubmit}
          isSubmitting={this.isSubmitting}
        />
      </div>
    );
  }
}

export default PersonInfoFormContainer;
