import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { TextField, RaisedButton } from 'material-ui';
import { observer } from 'mobx-react';
import format, { formatToString } from 'utils/format';
import { FormattedMessage } from 'react-intl';
import RegisterCompanyFormUiStore from 'stores/ui/account/register-company-form';
import { Link } from 'react-router-dom';
import { PASSWORD_MIN_LENGTH, PASSWORD_MIN_DIGITS } from 'utils/forms/constants';
import LoadingIndicator from 'components/Loading/LoadingIndicator';

interface Props {
  formStore: RegisterCompanyFormUiStore;
  hasFieldError: boolean;
  isSubmitting: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const Terms = () => (
  <Link to="/account">
    <FormattedMessage id="register.terms.linkText"/>
  </Link>
);

const TermsContainer = styled.div`
  margin: 10px 0;
`;

const RegisterButton = styled(RaisedButton)`
  margin: 30px 0;
`;

const Form = styled.form`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  padding: 15px 0px;
`;

@observer
export default class RegisterCompanyForm extends Component<Props, {}> {

  render() {
    let { formStore, hasFieldError, onSubmit, isSubmitting } = this.props;

    return (
      <Form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <h2>
          {formatToString('register.company.title')}
        </h2>
        <div>
          <div><FormattedMessage id="register.intro" /></div>
          <Link to="/account/register">
            {format('register.company.clickHere')}
          </Link>
        </div>

        <TextField
          type="email"
          floatingLabelText={format('register.email.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            formStore.emailUnavailable = false;
            return formStore.email.onChange(e.currentTarget.value);
          }}
          value={formStore.email.value}
          errorText={format(formStore.email.error)}
        />

        <TextField
          type="password"
          floatingLabelText={format('register.password.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            formStore.password.onChange(e.currentTarget.value);
            formStore.validateRepeatedPassword();
          }}
          value={formStore.password.value}
          errorText={format(
            formStore.password.error, 
            { minLength: PASSWORD_MIN_LENGTH, minDigits: PASSWORD_MIN_DIGITS }
          )}
        />

        <TextField
          type="password"
          floatingLabelText={format('register.repeatedPassword.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            formStore.repeatedPassword.onChange(e.currentTarget.value)}
          value={formStore.repeatedPassword.value}
          errorText={format(formStore.repeatedPassword.error)}
        />

        <TextField
          floatingLabelText={format('register.orgNumber.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            return formStore.orgNumber.onChange(e.currentTarget.value);
          }}
          value={formStore.orgNumber.value}
          errorText={format(formStore.orgNumber.error)}
        />

        <TextField
          floatingLabelText={format('register.orgName.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            return formStore.orgName.onChange(e.currentTarget.value);
          }}
          value={formStore.orgName.value}
          errorText={format(formStore.orgName.error)}
        />

        <TextField
          floatingLabelText={format('register.contactFirstName.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            return formStore.contactFirstName.onChange(e.currentTarget.value);
          }}
          value={formStore.contactFirstName.value}
          errorText={format(formStore.contactFirstName.error)}
        />

        <TextField
          floatingLabelText={format('register.contactLastName.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            return formStore.contactLastName.onChange(e.currentTarget.value);
          }}
          value={formStore.contactLastName.value}
          errorText={format(formStore.contactLastName.error)}
        />

        <TextField
          floatingLabelText={format('register.phoneNumber.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            return formStore.phoneNumber.onChange(e.currentTarget.value);
          }}
          value={formStore.phoneNumber.value}
          errorText={format(formStore.phoneNumber.error)}
        />

        <RegisterButton
          primary={true}
          type="submit"
          label={format('register.submit')}
          disabled={hasFieldError || isSubmitting}
        />

        {isSubmitting && 
          <Message>
            <LoadingIndicator label={<FormattedMessage id="login.registratingMessage"/>}/>
          </Message>
        }

        <TermsContainer>
            <FormattedMessage id="register.terms.text" values={{ terms: <Terms />}}/>
        </TermsContainer>
      </Form>
    );
  }
}