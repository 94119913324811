import { format, formatToString } from "utils/format";
import { CaskStatus } from "./cask-status";
import CaskType from "./cask-type";
import CaskUser from "./cask-user";
import Recipe from "./recipe";

const defaultImgBackground: string = require("assets/images/svensk-ek.jpg");

class Cask {
  id!: number;
  vismaCaskNumber?: string;
  name!: string;
  caskUsers!: Array<CaskUser>;
  recipe!: Recipe | null;
  caskType!: CaskType;
  status!: CaskStatus;
  caskSign?: string;
  notRefillable!: boolean;
  // Used when the cask is unknown
  caskTypeText?: string;
  recipeText?: string;
  stockNumber?: string;
  loanClaim?: number;
  loadVolume?: number;
  alcoholPercentage?: number;
  filled?: Date;
  liquorFilled?: Date;
  timesRefilled!: number;
  refillText!: string;

  flavour?: string;
  flavourNote?: string;

  description?: string;
  dateCreated?: Date;
  bottlingDate?: Date;
  history?: Array<{ date: Date; event: string }>;
  members?: Array<{ id: number; name: string }>;
  hasSettlement!: boolean;
  warehouseArea!: number;
  imageThumb?: string;
  imageFull?: string;
  oldestOrderDate?: string;
}

export const getCaskImage = (cask: Cask) => {
  if (cask.imageThumb) return cask.imageThumb;

  if (cask.caskType) {
    return cask.caskType.imageUrl;
  }
  return defaultImgBackground;
};

export const getCaskRefillName = (cask: Cask) => {
  let itemText = "";

  return cask.caskType
    ? `${format(cask.caskType.caskTypeInformation).name}${itemText}`
    : cask.caskTypeText
    ? `${cask.caskTypeText}${itemText}`
    : formatToString("cask.unknown");
};

export const getCaskName = (cask: Cask) =>
  cask.caskType
    ? format(cask.caskType.caskTypeInformation).name
    : cask.caskTypeText
    ? cask.caskTypeText
    : formatToString("cask.unknown");

export const getDisplayId = (cask: Cask) =>
  cask.vismaCaskNumber && cask.vismaCaskNumber.length > 0
    ? cask.vismaCaskNumber
    : cask.id;

export default Cask;
