import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Skeleton,
  Stack,
  Wrap,
} from "@chakra-ui/react";
import BrandButton from "components/BrandButton";
import { observer } from "mobx-react";
import { CaskStatus } from "models/cask-status";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { useMedia } from "react-use";
import { useStores } from "stores";
import CaskWidget from "./CaskWidget";

const Casks = () => {
  const { casksStore } = useStores();
  const history = useHistory();
  const isMobile = useMedia("(max-width: 1024px)");

  useEffect(() => {
    casksStore.fetchCasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const casks = casksStore.isLoading ? [] : casksStore.casks;
  const activeCasks = casks.filter((c) => c.status !== CaskStatus.FullBottled);
  const inactiveCasks = casks.filter(
    (c) =>
      c.status === CaskStatus.FullBottled || c.status === CaskStatus.Removed
  );
  const ListComponent = isMobile ? Wrap : Stack;

  return (
    <Box>
      <Heading size="lg" fontWeight={500} mb={4}>
        <FormattedMessage id="casks.title" />
      </Heading>
      <Box maxW="100vw" overflow="hidden" mx={isMobile ? -6 : undefined}>
        {casksStore.isLoading ? (
          <Box p={4} bg="#f2f2f2">
            <Skeleton height="20px" mb={4} />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Box>
        ) : (
          <>
            {activeCasks.length === 0 && (
              <Box p={4} bg="#f2f2f2">
                <Box mb={4}>
                  <FormattedMessage id="casks.no-casks1" />
                </Box>
                <Box mb={4}>
                  <FormattedMessage id="casks.no-casks2" />
                </Box>
                <BrandButton
                  mt={4}
                  colorScheme="brand"
                  fontWeight={400}
                  fontSize="18px"
                  onClick={() => history.push("/shop")}
                >
                  <FormattedMessage id="home.hero.becomeCaskOwnerButton.label" />
                </BrandButton>
              </Box>
            )}
            <ListComponent spacing={isMobile ? 4 : 6} justify="space-between">
              {activeCasks.map((c) => (
                <CaskWidget key={c.id} cask={c} />
              ))}
            </ListComponent>
            {inactiveCasks.length !== 0 && (
              <Box mt={8} mx={isMobile ? -6 : undefined}>
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton px={isMobile ? 12 : undefined}>
                        <Box display="flex" flex={1}>
                          <FormattedMessage id="casks.inactive.title" />
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel py={4} px={isMobile ? 6 : 0}>
                      <ListComponent spacing={6} py={2} justify="space-between">
                        {inactiveCasks.map((c) => (
                          <CaskWidget key={c.id} cask={c} />
                        ))}
                      </ListComponent>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default observer(Casks);
