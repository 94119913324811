import { FieldState, FormState } from "formstate";
import { action } from "mobx";
import { PASSWORD_MIN_LENGTH } from "utils/forms/constants";
import { hasNumber, minLength, required } from "utils/forms/validators";

type SetPasswordFormState = {
  newPassword: FieldState<string>;
  repeatedPassword: FieldState<string>;
};

class SetPasswordFormUiStore {
  newPassword: FieldState<string> = new FieldState("").validators(
    required("register.password.error.required"),
    minLength(PASSWORD_MIN_LENGTH, "register.password.error.minLength"),
    hasNumber("register.password.error.hasNumber")
  );

  repeatedPassword: FieldState<string> = new FieldState("").validators(
    required("register.repeatedPassword.error.required"),
    () => this.passwordsMustMatch()
  );

  form = new FormState<SetPasswordFormState>({
    newPassword: this.newPassword,
    repeatedPassword: this.repeatedPassword,
  });

  passwordsMustMatch() {
    return this.newPassword.value === this.repeatedPassword.value
      ? false
      : "register.repeatedPassword.error.match";
  }

  @action
  validateRepeatedPassword = async () => {
    if (!this.repeatedPassword.dirty) {
      return;
    }

    this.repeatedPassword.validate();
  };

  @action
  resetForm() {
    this.newPassword.reset();
    this.repeatedPassword.reset();
  }
}

export default SetPasswordFormUiStore;
export const setPasswordFormUiStore = new SetPasswordFormUiStore();
