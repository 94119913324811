import * as React from "react";
import styled from "styled-components";
import { formatToString } from "utils/format";
interface Props {
  id: string;
}
const Message = styled.p`
  color: red;
  margin: 10px 0;
`;
const ErrorMessage: React.FC<Props> = (props) => (
  <Message>{formatToString(props.id)}</Message>
);

export default ErrorMessage;
