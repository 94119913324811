export class JsonApiResponse<T> {
  data!: T;
  errors!: Array<JsonApiError>;
  meta!: Object;
  succeeded!: boolean;
  statusCode!: number;

  get hasErrors() {
    return !!this.errors && this.errors.length > 0;
  }

  get error() {
    return this.hasErrors ? this.errors[0] : null;
  }
}

export class JsonApiError {
  id!: string;
  code!: string;
  status!: number;
  title!: string;
  detail!: string;
}

export class JsonApiRequest {
  filter!: Object;
  page!: Object;
  sort!: Object;
}

export async function toJsonApiResponse<T>(
  response: Response,
  successCode: number = 200
): Promise<JsonApiResponse<T>> {
  let json: Object;
  try {
    json = await response.json();
  } catch (Error) {
    json = {
      errors: {
        error: {
          code: response.statusText,
        },
      },
    };
  }

  let jsonApiResponse = new JsonApiResponse<T>();

  Object.assign(jsonApiResponse, json);
  jsonApiResponse.succeeded = response.status === successCode;
  jsonApiResponse.statusCode = response.status;
  return jsonApiResponse;
}
