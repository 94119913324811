import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { AccountType } from "models/user";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useStores } from "stores";

const UserPickerModal: React.FC<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isControlled: boolean;
  getButtonProps: (props?: any) => any;
  getDisclosureProps: (props?: any) => any;
}> = ({ isOpen, onClose }) => {
  const { sessionStore } = useStores();
  const { push } = useHistory();

  if (!sessionStore.user) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered useInert={false}>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader fontWeight={500}>
          <FormattedMessage id="vismaUserPicker.title.default" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormattedMessage id="vismaUserPicker.subtitle.default" />

          <Stack py={8}>
            {sessionStore.user.vismaUsers.map((vu) => (
              <Button
                key={vu.vismaUserId}
                variant="outline"
                onClick={() => {
                  sessionStore.setVismaUser(vu);
                  push("/");
                  onClose();
                }}
              >
                <Text>
                  {vu.accountType === AccountType.Person
                    ? `${vu.firstName} ${vu.lastName}`
                    : `${vu.orgName}`}
                </Text>
              </Button>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default observer(UserPickerModal);
