import { Step, StepsWrapper } from "components/CommonStyles";
import { useFormikContext } from "formik";
import { FC, useState } from "react";
import { useMedia } from "react-use";
import { formatToString } from "utils/format";
import { SamplingFormValues, samplingSchema } from "./SamplingForm";

const SamplingStepper: FC = () => {
  const [leftOffset] = useState<number>(0);
  const { values } = useFormikContext<SamplingFormValues>();
  const isMobile = useMedia("(max-width: 48em)");

  return (
    <StepsWrapper
      mt={isMobile ? 0 : 6}
      w={isMobile ? "auto" : "185px"}
      mr={isMobile ? 0 : 6}
      ml={isMobile ? -1 * leftOffset : 0}
    >
      <Step
        index={0}
        active
        hideLine
        labelKey="cask.orders.sampling.title"
        valid={samplingSchema(formatToString).isValidSync(values)}
      />
    </StepsWrapper>
  );
};

export default SamplingStepper;
