import * as React from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  primaryColor?: string;
  secondaryColor?: string;
  theme?: __MaterialUI.Styles.MuiTheme;
}

const animation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 20px;
  height: 20px;

  top: 50%;
  left: 50%;
  -webkit-animation: ${animation} 1s infinite linear;
  -moz-animation: ${animation} 1s infinite linear;
  -o-animation: ${animation} 1s infinite linear;
  animation: ${animation} 1s infinite linear;
  border: 3px solid ${(props: Props) => props.secondaryColor || "#374953"};
  border-radius: 100%;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    height: 100%;
    width: 100%;
    border-top: 6px solid
      ${(props: Props) => props.primaryColor || "rgba(255, 255 ,255 , 1)"};
    border-left: 15px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 100%;
  }
`;

class CircleSpinner extends React.Component<{}, {}> {
  render() {
    return <Spinner />;
  }
}

export default CircleSpinner;
