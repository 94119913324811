import { action, makeObservable, observable } from "mobx";
import { DeliveryOccasionLocation } from "models/delivery-occasion";

export type BottlePrice = {
  country: string;
  net: number;
  alcoholDuty: number;
  vendorCharge: number;
  vatRate: number;
};

class BottlePriceListUiStore {
  @observable openCountries: Map<DeliveryOccasionLocation, boolean> = new Map();
  @observable prices!: BottlePrice[];
  @observable loading!: boolean;

  constructor() {
    makeObservable(this);
  }

  @action
  toggleOpen(country: DeliveryOccasionLocation) {
    let isOpen = this.openCountries.get(country);
    this.openCountries.set(country, !isOpen);
  }
}
export default BottlePriceListUiStore;
export const bottlePriceListUiStore = new BottlePriceListUiStore();
