import Api from './api';
import { Language } from 'stores/domain/locale';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';

export interface RegistrationViewModel {
  firstName: string;
  lastName: string;
  password: string;
  repeatedPassword: string;
  email: string;
  language: string;
}

export interface CompanyRegistrationViewModel {
  email: string;
  password: string;
  repeatedPassword: string;
  orgNumber: string;
  orgName: string;
  contactFirstName: string;
  contactLastName: string;
  phoneNumber: string;
  language: string;
}

export default class RegisterService {
  static async registerUser(vm: RegistrationViewModel): Promise<JsonApiResponse<{}>> {
    let response = await Api.post('accounts', false, undefined, vm);
    return await toJsonApiResponse(response);
  }

  static async registerCompany(vm: CompanyRegistrationViewModel): Promise<JsonApiResponse<{}>> {
    let response = await Api.post('accounts/company', false, undefined, vm);
    return await toJsonApiResponse(response);
  }

  static async verifyEmail(userId: string, token: string): Promise<JsonApiResponse<{}>> {
    let response = await Api.get('accounts/verify', false, undefined, { userId, token });
    return await toJsonApiResponse(response);
  }

  static async sendNewVerificationEmail(email: string, language: Language): Promise<JsonApiResponse<{}>> {
    let response = await Api.post('accounts/verify', false, undefined, { email, language });
    return await toJsonApiResponse(response);
  }
}
