import { action, makeObservable, observable } from "mobx";

class DrawerUiStore {
  @observable isOpen: boolean;

  @action
  toggleDrawer() {
    let isOpen = this.isOpen;
    this.isOpen = !isOpen;
  }

  @action
  closeDrawer() {
    this.isOpen = false;
  }

  constructor() {
    this.isOpen = false;
    makeObservable(this);
  }
}

export default DrawerUiStore;
export const drawerUiStore = new DrawerUiStore();
