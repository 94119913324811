import { action, computed, makeObservable, observable } from "mobx";
import { InjectedIntl, IntlProvider } from "react-intl";
import { en, sv } from "translations";

const LS_LANGUAGE = "box_language";

export enum Language {
  English = "en",
  Swedish = "sv",
}

export enum LanguageInt {
  English,
  Swedish,
}

class LocaleStore {
  @observable language: Language;

  private defaultLanguage: Language = Language.Swedish;

  @computed get intl(): InjectedIntl {
    let locale = this.locale;
    let messages = this.messages;
    let intlProvider = new IntlProvider({ locale, messages });
    let { intl } = intlProvider.getChildContext();
    return intl;
  }

  @computed get locale(): string {
    return this.language;
  }

  @computed get messages(): Object {
    if (this.language === Language.English) {
      return en;
    } else if (this.language === Language.Swedish) {
      return sv;
    }

    throw new Error(`Couldn't find translations for current locale`);
  }

  @computed get languageInt(): LanguageInt {
    return this.language === Language.English
      ? LanguageInt.English
      : LanguageInt.Swedish;
  }

  constructor() {
    this.language = this.determineLanguage();
    makeObservable(this);
  }

  @action changeLanguage(language: Language) {
    if (this.getLanguage(language) === null) {
      throw new Error(`Couldn't change language to ${language}`);
    }

    this.language = language;
    localStorage.setItem(LS_LANGUAGE, language);
    document.documentElement.lang = language;
  }

  private determineLanguage(): Language {
    return (
      this.getLanguageFromLocalStorage() ||
      this.getLanguageFromBrowser() ||
      this.defaultLanguage
    );
  }

  private getLanguageFromLocalStorage(): Language | null {
    let language = localStorage.getItem(LS_LANGUAGE);

    return language !== null && this.getLanguage(language) !== null
      ? <Language>language
      : null;
  }

  private getLanguageFromBrowser(): Language | null {
    if (!navigator || !navigator.language) {
      return null;
    }

    let locale = navigator.language.split("-")[0];
    if (!this.isValidLocale) {
      return null;
    }

    let language = this.getLanguage(locale);
    return language !== null ? language : null;
  }

  private getLanguage(locale: string): Language | null {
    for (let lang in Language) {
      if (Language[lang as keyof typeof Language] === locale) {
        return <Language>Language[lang as keyof typeof Language];
      }
    }

    return null;
  }

  private isValidLocale(locale: string): boolean {
    if (locale.length !== 2) {
      return false;
    }

    for (let lang in Language) {
      if (Language[lang as keyof typeof Language] === locale) {
        return true;
      }
    }

    return false;
  }
}

export default LocaleStore;
export const localeStore = new LocaleStore();
