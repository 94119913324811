import React from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopSwitchProps {
  children?: React.ReactNode;
}

const ScrollToTopSwitch: React.FC<ScrollToTopSwitchProps> = ({ children }) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{children}</>;
};

export default ScrollToTopSwitch;
