import { action, makeObservable, observable } from "mobx";

class HeaderUiStore {
  @observable localeIsOpen: boolean;
  @observable moreIsOpen: boolean;

  @action
  toggleLocales(shouldClose?: boolean) {
    if (shouldClose !== undefined) {
      if (shouldClose) {
        this.localeIsOpen = false;
      } else {
        this.localeIsOpen = true;
      }
      return;
    }

    this.localeIsOpen = !this.localeIsOpen;
  }

  @action
  toggleMore(shouldClose?: boolean) {
    if (shouldClose !== undefined) {
      if (shouldClose) {
        this.moreIsOpen = false;
      } else {
        this.moreIsOpen = true;
      }
      return;
    }

    this.moreIsOpen = !this.moreIsOpen;
  }

  @action
  closeLocales() {
    this.localeIsOpen = false;
  }

  @action
  closeMore() {
    this.moreIsOpen = false;
  }

  constructor() {
    this.localeIsOpen = false;
    this.moreIsOpen = false;
    makeObservable(this);
  }
}
export default HeaderUiStore;
export const headerUiStore = new HeaderUiStore();
