import { Box } from "@chakra-ui/react";
import caskBackground from "assets/images/hcw-anchor-storehouse.jpg";
import imgBackground from "assets/images/julbord2021_dist_snow.jpg";
import { useLocation } from "react-router";
import styled from "styled-components";

const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
`;

const BackgroundSlice = styled.div`
  background: url(${imgBackground}) no-repeat bottom center;
  background-size: cover;
  max-width: 33vw;
  width: 100%;
  filter: opacity(0.65);
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);

  &.cask {
    background: url(${caskBackground}) no-repeat right center;
    background-size: auto 100%;
  }

  @media (max-width: calc(1024px + 33vw)) {
    max-width: calc(100vw - 1024px);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const getBgClassName = (location: string) => {
  if (location.startsWith("/cask")) return "cask";

  return "";
};

const HomeBackground = () => {
  const location = useLocation();

  return (
    <BackgroundWrapper>
      <Box w="full" display="flex" justifyContent="flex-end" height="full">
        <BackgroundSlice className={getBgClassName(location.pathname)} />
      </Box>
    </BackgroundWrapper>
  );
};

export default HomeBackground;
