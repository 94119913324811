import Api from './api';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';
import { Language } from 'stores/domain/locale';

export interface ChangePasswordViewModel {
  currentPassword: string;
  newPassword: string;
  repeatedPassword: string;
}

export interface ResetPasswordViewModel {
  email: string;
  language: Language;
}

export interface SetPasswordViewModel {
  userId: string;
  token: string;
  newPassword: string;
  repeatedPassword: string;
}

export default class ChangePasswordService {
  static async changePassword(vm: ChangePasswordViewModel): Promise<JsonApiResponse<{}>> {
    let response = await Api.post('accounts/change-password', true, undefined, vm);
    return await toJsonApiResponse(response);
  }
}

export class ResetPasswordService {
  static async resetPassword(vm: ResetPasswordViewModel): Promise<JsonApiResponse<{}>> {
    let response = await Api.post('accounts/reset-password', false, undefined, vm);
    return await toJsonApiResponse(response);
  }

  static async setPassword(vm: SetPasswordViewModel): Promise<JsonApiResponse<{}>> {
    let response = await Api.post('accounts/set-password', false, undefined, vm);
    return await toJsonApiResponse(response);
  }
}