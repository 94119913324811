import * as React from 'react';
import { observer, inject } from 'mobx-react';
import LocaleStore from 'stores/domain/locale';
import PersonForm from './components/PersonForm';
import {
  PersonFormUiStore,
  NewMemberStore,
  SessionStore,
  NewMemberUiStore
} from 'stores';
import { RegisterPersonMemberViewModel } from 'services/new-member';
import { RouteComponentProps } from 'react-router';

interface Props {
  personFormUiStore?: PersonFormUiStore;
  localeStore?: LocaleStore;
  newMemberStore?: NewMemberStore;
  sessionStore?: SessionStore;
  newMemberUiStore?: NewMemberUiStore;
}

interface PropsWithRoute extends Props, RouteComponentProps<{}> {}
@inject(
  'personFormUiStore',
  'localeStore',
  'newMemberStore',
  'sessionStore',
  'newMemberUiStore'
)
@observer
export default class RegisterPersonMemberPage extends React.Component<
  PropsWithRoute,
  {}
> {
  componentDidMount() {
    this.props.personFormUiStore!.resetForm();
  }

  get localeStore() {
    return this.props.localeStore!;
  }

  get store() {
    return this.props.personFormUiStore!;
  }
  get memberStore() {
    return this.props.newMemberStore!;
  }

  get memberUiStore() {
    return this.props.newMemberUiStore!;
  }

  get registrationViewModel(): RegisterPersonMemberViewModel {
    const { userId, resetToken: passwordToken } = this.memberStore;
    const { password, repeatedPassword, firstName, lastName } = this.store;
    return {
      password: password.$,
      repeatedPassword: repeatedPassword.$,
      firstName: firstName.$,
      lastName: lastName.$,
      language: this.localeStore.language,
      userId: userId,
      resetToken: passwordToken
    };
  }

  onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validation = await this.store.form.validate();
    if (validation.hasError) {
      return;
    }
    const res = await this.memberStore.registerPerson(
      this.registrationViewModel
    );
    if (res != null && !res.succeeded) {
      this.memberUiStore.handleError(res);
    } else if (res != null) {
      this.props.history.push('/account/login');
    }
  }

  render() {
    let hasFieldError = this.store.form.hasFieldError;
    return (
      <div>
        <PersonForm
          hasFieldError={hasFieldError}
          formStore={this.store}
          onSubmit={this.onSubmit}
          isSubmitting={this.memberStore.isSubmitting}
        />
      </div>
    );
  }
}
