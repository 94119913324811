import LoadingSplash from "components/Loading/LoadingSplash";
import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import CaskType from "models/cask-type";
import { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
  CaskShopUiStore,
  CaskTypesStore,
  RecipesStore,
  ShoppingCartStore,
  SnackBarUiStore,
} from "stores";
import styled from "styled-components";
import { formatToString } from "utils/format";
import CaskTypeComponent from "./CaskTypeComponent";
import InstructionsModal from "./InstructionsModal";

interface Props {
  caskTypesStore?: CaskTypesStore;
  recipesStore?: RecipesStore;
  shoppingCartStore?: ShoppingCartStore;
  caskShopUiStore?: CaskShopUiStore;
  snackBarUiStore?: SnackBarUiStore;
}

const Instructions = styled.a`
  cursor: pointer;
`;

@inject(
  "caskTypesStore",
  "recipesStore",
  "shoppingCartStore",
  "caskShopUiStore",
  "snackBarUiStore"
)
@observer
class CaskShopContainer extends Component<Props, {}> {
  @observable modalIsOpen: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.caskTypesStore.fetchCasks();
    this.recipesStore.fetchRecipes();
  }

  toggleCaskTypePreStored = (caskType: CaskType) => {
    this.caskShopUiStore.toggleCaskTypePreStored(caskType);
  };

  setCaskTypeRecipe = (caskType: CaskType, recipeId: string) => {
    this.caskShopUiStore.setCaskTypeRecipe(caskType, recipeId);
  };

  addToCart = (caskType: CaskType, recipeId: string, preStored: Boolean) => {
    this.shoppingCartStore.addToCart(
      caskType,
      this.findRecipe(recipeId),
      preStored
    );
    this.snackBarUiStore.open(formatToString("caskShop.caskAddedToCart"));
  };

  findRecipe = (recipeId: string) => {
    return this.recipesStore.find(parseInt(recipeId, 10));
  };

  get caskTypesStore() {
    return this.props.caskTypesStore!;
  }

  get recipesStore() {
    return this.props.recipesStore!;
  }

  get shoppingCartStore() {
    return this.props.shoppingCartStore!;
  }

  get snackBarUiStore() {
    return this.props.snackBarUiStore!;
  }

  get caskShopUiStore() {
    return this.props.caskShopUiStore!;
  }

  openModal = () => {
    this.modalIsOpen = true;
  };

  closeModal = () => {
    this.modalIsOpen = false;
  };

  render() {
    if (this.caskTypesStore.isLoading || this.recipesStore.isLoading) {
      return (
        <div>
          <LoadingSplash />
        </div>
      );
    }
    return (
      <div>
        <Instructions onClick={this.openModal}>
          <FormattedMessage id={"caskShop.instructions"} />
        </Instructions>
        {this.caskTypesStore.caskTypes.map((caskType) => (
          <CaskTypeComponent
            key={caskType.id}
            caskTypeInstance={this.caskShopUiStore.getCaskType(caskType)}
            toggleCaskTypePreStored={this.toggleCaskTypePreStored}
            setCaskTypeRecipe={this.setCaskTypeRecipe}
            addToCart={this.addToCart}
            findRecipe={this.findRecipe}
            recipes={
              caskType.productIdNonSmoky === "gin" ||
              caskType.productIdNonSmoky === "1400"
                ? this.recipesStore.recipes.filter((recipe) => recipe.id === 3)
                : this.recipesStore.recipes.filter((recipe) => recipe.id !== 3)
            }
          />
        ))}
        <InstructionsModal
          isOpen={this.modalIsOpen}
          onClose={this.closeModal}
        />
      </div>
    );
  }
}

export default CaskShopContainer;
