import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { Paper } from 'material-ui';
import ResetPassword from './ResetPassword';
import { withRouter, RouteComponentProps } from 'react-router';
import ResetPasswordUiStore from 'stores/ui/account/reset-password';
import LocaleStore from 'stores/domain/locale';
import {
  ResetPasswordService,
  ResetPasswordViewModel
} from 'services/change-password';

interface Props extends RouteComponentProps<{}> {
  resetPasswordUiStore?: ResetPasswordUiStore;
  localeStore?: LocaleStore;
}

const Wrapper = styled(Paper)`
  margin-top: 15px;
`;

@inject('resetPasswordUiStore', 'localeStore')
@observer
class ResetPasswordContainer extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.resetPasswordUiStore!.resetForm();
  }

  get store() {
    return this.props.resetPasswordUiStore!;
  }

  get localeStore() {
    return this.props.localeStore!;
  }

  get resetPasswordViewModel(): ResetPasswordViewModel {
    return {
      email: this.store.email.value,
      language: this.localeStore.language
    };
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let validation = await this.store.form.validate();

    if (validation.hasError) {
      return;
    }
    
    let resetPasswordVM = this.resetPasswordViewModel;

    this.store.startSubmitting();
    let response = await ResetPasswordService.resetPassword(resetPasswordVM);
    this.store.stopSubmitting();

    if (!response.succeeded) {
      if (
        response.error &&
        response.error.code.startsWith('UserNotFoundException')
      ) {
        this.store.setUserNotFound(true);
      } else {
        throw new Error('Couldn\'t request new password.');
      }
    }

    this.props.history.push('/reset-password/complete');
  }

  render() {
    let hasFieldError = this.store.form.hasFieldError;
    return (
      <Wrapper>
        <ResetPassword
          hasFieldError={hasFieldError}
          formStore={this.store}
          onSubmit={this.onSubmit}
        />
      </Wrapper>
    );
  }
}

export default withRouter(ResetPasswordContainer);
