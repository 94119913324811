import * as React from 'react';
import styled from 'styled-components';
import { Paper } from 'material-ui';
import { inject, observer } from 'mobx-react';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import { CaskHistoryStore, CaskUiStore } from 'stores';
import HistoryTimeline from './components/HistoryTimelineContainer';
import { FormattedMessage } from 'react-intl';

interface Props {
    caskHistoryStore?: CaskHistoryStore;
    caskUiStore?: CaskUiStore;
}

const Content = styled(Paper) `
    padding: 15px;
    margin-top: 10px;
    width: 100%;
`;

const Loading = styled(LoadingIndicator)`
    padding: 15px 0px;
`;

@inject('caskHistoryStore', 'caskUiStore')
@observer
class History extends React.Component<Props, {}> {
    componentDidMount() {
        this.store.fetchOrderHistory(this.caskId);
    }

    get store() {
        return this.props.caskHistoryStore!;
    }

    get caskId() {
        return this.props.caskUiStore!.caskId;
    }

    render() {
        let { orderHistory } = this.store;

        return (
            <Content>
                {this.store.isLoading 
                    ? <Loading label={<FormattedMessage id="history.loading"/>} />
                    : <HistoryTimeline caskOrderHistory={orderHistory} id={this.caskId}/>
                }
            </Content>
        );
    }
}

export default History;