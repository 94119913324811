import { Box, Button, Divider, Heading, Text } from "@chakra-ui/react";
import { OrderType } from "models/order";
import React from "react";
import { Helmet } from "react-helmet-async";
import { FiArrowLeft } from "react-icons/fi";
import { GoCheck } from "react-icons/go";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router";
import { useMedia } from "react-use";
import { formatToString } from "utils/format";

interface OrderDoneProps {
  orderNumber: number;
  email: string;
  type: OrderType;
}

const OrderDone: React.FC<OrderDoneProps> = ({ orderNumber, email, type }) => {
  const history = useHistory();
  const isMobile = useMedia("(max-width: 48em)");
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Helmet>
        <title>{`Fatägarwebben - ${formatToString(
          "caskShop.complete.title"
        )}`}</title>
      </Helmet>
      <Heading fontSize="xl" fontWeight={400} mt={6}>
        <FormattedMessage id="caskShop.complete.title" />
      </Heading>
      <Divider mt={2} />
      <Box mt={8} w="full">
        <Box
          display="flex"
          alignItems="center"
          w="full"
          bg="green.100"
          p={8}
          flexDir={isMobile ? "column" : "row"}
        >
          <Box
            mb={isMobile ? 4 : undefined}
            bgColor="#2a9d8f"
            borderRadius="50%"
            height="100px"
            width="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <GoCheck color="#FFF" size="75px" />
          </Box>
          <Box
            w={isMobile ? "auto" : "calc(100% - 100px)"}
            pl={isMobile ? 0 : 8}
          >
            <Text mt={isMobile ? 4 : 0}>
              {type === OrderType.Bottling && (
                <FormattedHTMLMessage
                  id="orders.bottlingOrder.complete.orderCompleteText"
                  values={{ email }}
                />
              )}
              {type === OrderType.Sampling && (
                <FormattedHTMLMessage
                  id="orders.sampling.complete.orderCompleteText"
                  values={{ email }}
                />
              )}
              {type === OrderType.FlavorNote && (
                <FormattedHTMLMessage
                  id="orders.flavourNote.complete.orderCompleteText"
                  values={{ email }}
                />
              )}
            </Text>
          </Box>
        </Box>
        <Button
          variant="outline"
          mt={8}
          w="full"
          size="lg"
          onClick={() => history.push("/cask/" + id)}
          leftIcon={<FiArrowLeft size="1.25rem" />}
        >
          <FormattedMessage id="cask.orders.done.back" />
        </Button>
      </Box>
    </>
  );
};

export default OrderDone;
