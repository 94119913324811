import { find } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import CaskOrderHistory from "models/cask-order-history";
import { caskHistoryStore } from "stores/domain/cask-history";

export class CaskHistoryUiStore {
  @observable currentHistoryItemId: number | undefined;

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentHistoryItem(): CaskOrderHistory | undefined {
    if (this.currentHistoryItemId === undefined) {
      return undefined;
    }

    var order = find(
      caskHistoryStore.orderHistory,
      (o) => o.id === this.currentHistoryItemId
    );
    return order || undefined;
  }

  @action
  showCaskHistoryItem(orderId: number) {
    this.currentHistoryItemId = orderId;
  }

  @action
  hideCaskHistoryItem() {
    this.currentHistoryItemId = undefined;
  }
}

export default CaskHistoryUiStore;
export const caskHistoryUiStore = new CaskHistoryUiStore();
