import TextHoverIcon from "components/TextHoverIconContainer";
import { IconButton, Paper } from "material-ui";
import ActionDelete from "material-ui/svg-icons/action/delete";
import { observer } from "mobx-react";
import CartCask from "models/cart-cask";
import * as React from "react";
import { FormattedNumber } from "react-intl";
import { CartStep } from "stores/ui/cask-shop/shopping-cart";
import styled from "styled-components";
import formatMessage, { format } from "utils/format";
import { PRESTORED_PRICE } from "utils/prices";

interface Props {
  cartCask: CartCask;
  removeCask: (cartCask: CartCask) => void;
  currentCartStep: number;
}

const Wrapper = styled(Paper)`
  margin-top: 30px;
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ebebeb !important;
  font-size: 12px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
`;

const TotalSpan = styled.span`
  padding-right: 15px;
`;

const SekSpan = styled.span`
  font-size: 70%;
  position: relative;
  bottom: 0.3em;
  margin-left: 2px;
`;

const RemoveCaskButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

const StyledHeader = styled.h2`
  flex: 1 1 0px;
`;

const IconDiv = styled.div`
  position: absolute;
  margin-left: 20px;
  margin-top: -10px;
`;

@observer
class CartCaskComponent extends React.Component<Props, {}> {
  get columnStyle() {
    return {
      rightColumn: {
        paddingLeft: "30px",
      },
    };
  }

  render() {
    let { cartCask, removeCask, currentCartStep } = this.props;
    let { caskType, recipe, preStored, caskSign } = cartCask;
    let caskTypeInfo = format(caskType.caskTypeInformation);
    let recipeInfo = format(recipe.recipeInformation);

    return (
      <Wrapper>
        <div>
          <RemoveCaskButtonContainer>
            <StyledHeader>{caskTypeInfo.name}</StyledHeader>
            <IconButton onClick={() => removeCask(cartCask)}>
              <ActionDelete />
            </IconButton>
          </RemoveCaskButtonContainer>
          <table>
            <tbody>
              <tr>
                {preStored && (
                  <td>{formatMessage("caskShop.preStored.2.year.label")}</td>
                )}
                <td>{recipeInfo.name}</td>
              </tr>
              {currentCartStep === CartStep.CartPayment && (
                <tr>
                  <td>
                    {formatMessage("caskShop.cart.payment.caskSign.column")}
                  </td>
                  <td style={this.columnStyle.rightColumn}>
                    {caskSign ? caskSign : "-"}
                  </td>
                </tr>
              )}
              <tr>
                <td>{formatMessage("caskShop.cart.view.cost.loan.label")}</td>
                <td style={this.columnStyle.rightColumn}>
                  <FormattedNumber value={recipe.price} /> SEK
                </td>
                <td>
                  <IconDiv>
                    <TextHoverIcon
                      materialIconName="info_outline"
                      text={formatMessage(
                        "caskShop.cart.view.cost.loan.tooltip"
                      )}
                    />
                  </IconDiv>
                </td>
              </tr>
              <tr>
                <td>
                  {formatMessage(
                    "caskShop.cart.view.cost.cask.vatIncluded.label"
                  )}
                </td>
                <td style={this.columnStyle.rightColumn}>
                  <FormattedNumber value={caskType.price} /> SEK
                </td>
              </tr>
              <tr>
                <td>{formatMessage("caskShop.cart.view.cost.tax.label")}</td>
                <td style={this.columnStyle.rightColumn}>
                  <FormattedNumber value={caskType.price * 0.2} /> SEK
                </td>
              </tr>
              {preStored && (
                <tr>
                  <td>
                    {formatMessage("caskShop.cart.view.cost.prestored.label")}
                  </td>
                  <td style={this.columnStyle.rightColumn}>
                    <FormattedNumber value={PRESTORED_PRICE} /> SEK
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <hr />
        <BottomContainer>
          <h2>
            <TotalSpan>
              {formatMessage("caskShop.cart.view.cost.total.label")}
            </TotalSpan>
            <FormattedNumber
              value={
                caskType.price +
                recipe.price +
                (preStored ? PRESTORED_PRICE : 0)
              }
            />
            <SekSpan>SEK</SekSpan>
          </h2>
        </BottomContainer>
      </Wrapper>
    );
  }
}

export default CartCaskComponent;
