import { Flex } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import styled from "styled-components";
import format from "utils/format";

const Wrapper = styled.nav`
  a {
    font-size: 1.125rem;
    color: #000;
    margin-right: 2rem;
    font-weight: 300;

    &:hover {
      text-decoration: none;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 0.5rem;
    }

    &.underline {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 0.5rem;
      font-weight: 500;
    }
  }

  @media (max-width: 48em) {
    text-align: center;

    a {
      margin-right: 0;
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  }
`;

const Navlinks: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const location = useLocation();
  const isMobile = useMedia("(max-width: 48em)");

  return (
    <Wrapper>
      <Flex flexDirection={isMobile ? "column" : "row"}>
        <NavLink
          onClick={onClick}
          className={() => (location.pathname === "/" ? "underline" : "")}
          to="/"
        >
          {format("navbar.home.label")}
        </NavLink>
        <NavLink
          onClick={onClick}
          className={(active) => (active ? "underline" : "")}
          to="/shop"
        >
          {format("navbar.caskShop.label")}
        </NavLink>
        <NavLink
          onClick={onClick}
          className={(active) => (active ? "underline" : "")}
          to="/information"
        >
          {format("navbar.information.label")}
        </NavLink>
      </Flex>
    </Wrapper>
  );
};

export default Navlinks;
