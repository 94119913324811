import { Box } from "@chakra-ui/react";
import HomeBackground from "components/HomeBackground";
import LayoutWrapper from "components/LayoutWrapper";
import { observer } from "mobx-react";
import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useMedia } from "react-use";
import { useStores } from "stores";
import styled from "styled-components";
import { formatToString } from "utils/format";
import CaskInstructions from "./components/CaskInstructions";
import Casks from "./components/Casks";
import HeroContent from "./components/HeroContent";
import News from "./components/News";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  width: 100%;
  position: relative;

  h1 {
    font-weight: normal;
  }
`;

const Home: React.FC = () => {
  const { sessionStore } = useStores();
  const isMobile = useMedia("(max-width: 1024px)");

  return (
    <>
      <Helmet>
        <title>Fatägarwebben - {formatToString("navbar.home.label")}</title>
      </Helmet>
      <Wrapper>
        {!sessionStore.isAuthenticated && (
          <Box pb={16}>
            <HeroContent />
            <CaskInstructions />
          </Box>
        )}

        {sessionStore.isAuthenticated && (
          <LayoutWrapper>
            <HomeBackground />
            <Box w="full">
              <Box
                w="full"
                display="flex"
                flexDir={isMobile ? "column" : "row-reverse"}
                pos="relative"
                maxW={isMobile ? "full" : "calc(67vw - 5rem)"}
                justifyContent="flex-end"
              >
                <Box
                  mt={6}
                  pl={isMobile ? 0 : 4}
                  minW={isMobile ? "full" : 464}
                  maxW={isMobile ? "full" : 620}
                >
                  <News />
                </Box>
                <Box
                  minW={isMobile ? "full" : 464}
                  maxW={isMobile ? "full" : 512}
                  mt={6}
                  pr={isMobile ? 0 : 4}
                >
                  <Casks />
                </Box>
              </Box>
            </Box>
          </LayoutWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default observer(Home);
