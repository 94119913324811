import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { DeliveryOccasionLocation } from "models/delivery-occasion";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { BottlePriceListUiStore, BottlingPriceVariablesStore } from "stores";
import styled from "styled-components";
import BottlePriceList from "./BottlePriceList";

const LoadingMessage = styled.div`
  padding: 15px;
`;

interface Props {
  bottlePriceListUiStore?: BottlePriceListUiStore;
  bottlingPriceVariablesStore?: BottlingPriceVariablesStore;
  numberOfBottles: number;
  alcoholContent: number;
  loanAmount: number;
}

@observer
class BottlePriceListContainer extends React.Component<Props, {}> {
  @observable resizing!: boolean;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  get uiStore() {
    return this.props.bottlePriceListUiStore;
  }

  get domainStore() {
    return this.props.bottlingPriceVariablesStore;
  }

  onResize = () => (this.resizing = false);

  async componentWillMount() {
    window.addEventListener("resize", this.onResize);
    this.resizing = true;
    await this.domainStore!.fetchBottlingPriceVariables();
    window.dispatchEvent(new Event("resize"));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  toggleOpenCountryPriceCalculation = (country: DeliveryOccasionLocation) => {
    this.resizing = true;
    this.uiStore!.toggleOpen(country);
    window.dispatchEvent(new Event("resize"));
  };

  renderLoading = () => {
    return (
      <LoadingMessage>
        <FormattedMessage id="orders.bottlingOrder.priceExample.loadingPrices" />
      </LoadingMessage>
    );
  };

  renderList = () => {
    let { openCountries } = this.uiStore!;
    let { bottlingPriceVariables } = this.domainStore!;
    let { numberOfBottles, alcoholContent, loanAmount } = this.props;
    return (
      <BottlePriceList
        loanAmount={loanAmount}
        pricesVariables={bottlingPriceVariables}
        numberOfBottles={numberOfBottles}
        alcoholContent={alcoholContent / 100}
        bottleSize={0.5}
        openCountries={openCountries}
        onCountryClick={this.toggleOpenCountryPriceCalculation}
      />
    );
  };

  render() {
    let { loading } = this.domainStore!;
    if (loading || this.resizing) {
      return this.renderLoading();
    } else {
      return this.renderList();
    }
  }
}

export default BottlePriceListContainer;
