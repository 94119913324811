import ErrorMessage from "components/ErrorMessage";
import LoadingIndicator from "components/Loading/LoadingIndicator";
import VismaUserPickerContainer from "components/VismaUserPicker/VismaUserPickerContainer";
import { Dialog } from "material-ui";
import { inject, observer } from "mobx-react";
import Cask from "models/cask";
import { LocalVismaUser } from "models/user";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CaskSelectUiStore, CasksStore, SessionStore } from "stores";
import styled from "styled-components";
import { formatToString } from "utils/format";
import { CaskList } from "./CaskList";

interface Props {
  sessionStore?: SessionStore;
  casksStore?: CasksStore;
  caskSelectUiStore?: CaskSelectUiStore;
}

const Subtitle = styled.p`
  font-size: 16px;
  color: rgba(95, 95, 95, 0.6);
`;

@inject("casksStore", "caskSelectUiStore", "sessionStore")
@observer
export default class CaskAccepterContainer extends React.Component<Props, {}> {
  get casksStore() {
    return this.props.casksStore!;
  }

  get selectStore() {
    return this.props.caskSelectUiStore!;
  }

  get casks() {
    return this.casksStore.casksNotAccepted || [];
  }

  get isOpen() {
    return this.casks.length > 0;
  }

  get errorMessages() {
    return this.selectStore.errors.map((e, i) => (
      <ErrorMessage id={e} key={i} />
    ));
  }

  get title() {
    return (
      <div>
        <p>{formatToString("caskSelect.title")}</p>
        <Subtitle>{formatToString("caskSelect.subtitle")}</Subtitle>
      </div>
    );
  }

  onCaskSelect = (cask: Cask) => {
    this.selectStore.showUserSelect = true;
    this.selectStore.selectedCask = cask;
  };

  onUserSelect = (vismaUser: LocalVismaUser) => {
    this.selectStore.showUserSelect = false;
    this.selectStore.selectedVismaUser = vismaUser;
    this.selectStore.setVismaMember();
  };

  render() {
    return (
      <Dialog open={this.isOpen} title={this.title}>
        <CaskList
          casks={this.casks}
          onClick={this.onCaskSelect}
          disabled={this.selectStore.loading}
        />
        {this.selectStore.loading && (
          <LoadingIndicator
            label={<FormattedMessage id="caskSelect.loading" />}
          />
        )}
        {this.errorMessages}
        <VismaUserPickerContainer
          sessionStore={this.props.sessionStore}
          title={formatToString("caskSelect.userPicker.title")}
          subtitle={formatToString("caskSelect.userPicker.subtitle")}
          isOpen={this.selectStore.showUserSelect}
          onPickUser={this.onUserSelect}
        />
      </Dialog>
    );
  }
}
