import {
  Box,
  Divider,
  Heading,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import CaskUser from "models/cask-user";
import React, { useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import CasksService from "services/casks";
import { useStores } from "stores";
import { formatToString } from "utils/format";
import { useCurrentCask } from "../cask-hooks";
import CaskMemberForm from "./CaskMemberForm";

const MembersTable: React.FC<{
  members?: CaskUser[];
  isCaskOwner?: boolean;
  loadingRemove: boolean;
  onRemove: (user: CaskUser) => Promise<void>;
}> = ({ members, isCaskOwner, loadingRemove, onRemove }) => (
  <TableContainer mt={6}>
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>
            <FormattedMessage id="cask.members.table.name" />
          </Th>
          <Th>
            <FormattedMessage id="cask.members.table.email" />
          </Th>
          {isCaskOwner && <Th isNumeric></Th>}
        </Tr>
      </Thead>
      <Tbody>
        {members?.map((u) => (
          <Tr key={u.id}>
            <Td>{`${u.user.firstName} ${u.user.lastName}`}</Td>
            <Td>{u.user.email}</Td>
            {isCaskOwner && (
              <Td isNumeric>
                <IconButton
                  disabled={loadingRemove}
                  variant="outline"
                  onClick={() => onRemove(u)}
                  aria-label={formatToString("common.remove")}
                >
                  <BsFillTrashFill />
                </IconButton>
              </Td>
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
);

const MembersMobileList: React.FC<{
  members?: CaskUser[];
  isCaskOwner?: boolean;
  loadingRemove: boolean;
  onRemove: (user: CaskUser) => Promise<void>;
}> = ({ members, isCaskOwner, loadingRemove, onRemove }) => (
  <Stack mt={2}>
    {members?.map((u) => (
      <Box key={u.id}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={4}
        >
          <Box>
            <Text>{`${u.user.firstName} ${u.user.lastName}`}</Text>
            <Text fontWeight={300} wordBreak="break-word" pr={4}>
              {u.user.email}
            </Text>
          </Box>
          {isCaskOwner && (
            <Box>
              <IconButton
                disabled={loadingRemove}
                variant="outline"
                onClick={() => onRemove(u)}
                aria-label={formatToString("common.remove")}
              >
                <BsFillTrashFill />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider />
      </Box>
    ))}
  </Stack>
);

const MembersList: React.FC<{
  caskId: number;
  members?: CaskUser[];
  isCaskOwner?: boolean;
}> = ({ caskId, members, isCaskOwner }) => {
  const [loadingRemove, setLoadingRemove] = useState(false);
  const { localeStore, casksStore } = useStores();
  const isMobile = useMedia("(max-width: 600px)");

  if (members?.length === 0) return null;

  const onRemove = async (user: CaskUser) => {
    setLoadingRemove(true);

    try {
      const response = await CasksService.removeMember({
        caskId,
        email: user.user.email,
        language: localeStore.language,
        firstname: user.user.firstName,
        lastname: user.user.lastName,
      });

      if (response.hasErrors) {
        throw Error(
          `Couldn't remove cask member with email ${user.user.email}`
        );
      }
      await casksStore.fetchCasks(false);
    } catch (error) {
      throw Error(`Couldn't remove cask member with email ${user.user.email}`);
    } finally {
      setLoadingRemove(false);
    }
  };

  return isMobile ? (
    <MembersMobileList
      isCaskOwner={isCaskOwner}
      members={members}
      onRemove={onRemove}
      loadingRemove={loadingRemove}
    />
  ) : (
    <MembersTable
      isCaskOwner={isCaskOwner}
      members={members}
      onRemove={onRemove}
      loadingRemove={loadingRemove}
    />
  );
};

const CaskMembers: React.FC = () => {
  const { cask, loading, isCaskOwner, members } = useCurrentCask();
  const isMobile = useMedia("(max-width: 500px)");

  if (!cask || loading) return null;

  return (
    <Box my={8} maxW={632} mb={6} id="members">
      <Heading size="lg" fontWeight={500} mb={6}>
        <FormattedMessage id="cask.members.title" />
      </Heading>
      {isCaskOwner && (
        <Box>
          <Text mt={2} maxW={560} fontWeight={300} mb={6}>
            <FormattedMessage id="about.members.info" />
          </Text>
          <Box mt={6} mx={isMobile ? -6 : undefined}>
            <CaskMemberForm />
          </Box>
        </Box>
      )}
      <MembersList
        caskId={cask.id}
        members={members}
        isCaskOwner={isCaskOwner}
      />
    </Box>
  );
};

export default observer(CaskMembers);
