import { FieldState, FormState } from "formstate";
import { action } from "mobx";
import User from "models/user";
import { PHONE_MAX_LENGTH, PHONE_MIN_LENGTH } from "utils/forms/constants";
import {
  countryRequired,
  isNumber,
  maxLength,
  minLengthOrEmpty,
  required,
} from "utils/forms/validators";

type PersonInfoFormState = {
  firstName: FieldState<string>;
  lastName: FieldState<string>;
  address: FieldState<string>;
  city: FieldState<string>;
  zipCode: FieldState<string>;
  phoneNumber: FieldState<string>;
  country: FieldState<string>;
};

class PersonInfoFormUiStore {
  firstName: FieldState<string> = new FieldState("").validators(
    required("register.firstName.error.required")
  );
  lastName: FieldState<string> = new FieldState("").validators(
    required("register.lastName.error.required")
  );
  address: FieldState<string> = new FieldState("");
  city: FieldState<string> = new FieldState("");
  zipCode: FieldState<string> = new FieldState("");
  phoneNumber: FieldState<string> = new FieldState("").validators(
    maxLength(
      PHONE_MAX_LENGTH,
      "settings.contactInfo.phoneNumber.error.maxLength"
    ),
    minLengthOrEmpty(
      PHONE_MIN_LENGTH,
      "settings.contactInfo.phoneNumber.error.minLength"
    ),
    isNumber("settings.contactInfo.phoneNumber.error.isNumber")
  );
  country: FieldState<string> = new FieldState("").validators(
    countryRequired("settings.contactInfo.country.error.required")
  );

  form = new FormState<PersonInfoFormState>({
    firstName: this.firstName,
    lastName: this.lastName,
    address: this.address,
    city: this.city,
    zipCode: this.zipCode,
    phoneNumber: this.phoneNumber,
    country: this.country,
  });

  @action
  resetForm() {
    this.firstName.reset();
    this.lastName.reset();
    this.address.reset();
    this.city.reset();
    this.zipCode.reset();
    this.phoneNumber.reset();
    this.country.reset();
  }

  @action
  setForm(user: User) {
    this.firstName.value = user.vismaUser.firstName || "";
    this.lastName.value = user.vismaUser.lastName || "";
    this.address.value = user.vismaUser.address || "";
    this.city.value = user.vismaUser.city || "";
    this.zipCode.value = user.vismaUser.zipCode || "";
    this.country.value = user.vismaUser.country || "";
    this.phoneNumber.value = user.vismaUser.phoneNumber || "";
  }
}

export default PersonInfoFormUiStore;
export const personInfoFormUiStore = new PersonInfoFormUiStore();
