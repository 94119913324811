import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { getCaskImage, getCaskName } from "models/cask";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";

const CaskHeader: FC<{ title: string }> = ({ title }) => {
  const { cask } = useCurrentCask();
  const isMobile = useMedia("(max-width: 500px)");

  return (
    <>
      <Helmet>
        <title>Fatägarwebben - {`${getCaskName(cask!)} - ${title}`}</title>
      </Helmet>
      <Box display="flex" maxW={800}>
        <Box
          minW={isMobile ? "33%" : "185px"}
          maxW={isMobile ? "33%" : "185px"}
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          mr={[4, 6]}
          position="relative"
        >
          <Image
            h="auto"
            w="auto"
            src={getCaskImage(cask!)}
            alt={getCaskName(cask!)}
          />
        </Box>
        <Box display="flex" w="75%" flexGrow={1} flexDir="column" mt={2}>
          {cask!.vismaCaskNumber && (
            <Text
              color="gray.600"
              fontWeight="light"
              fontSize={["lg", "2xl"]}
              mb={[1, 4]}
            >
              {cask!.vismaCaskNumber}
            </Text>
          )}
          <Heading size="lg" mb={0} fontWeight={400}>
            {title}
          </Heading>
          <Heading size="md" fontWeight={400} noOfLines={1}>
            {getCaskName(cask!)}
          </Heading>
        </Box>
      </Box>
    </>
  );
};

export default observer(CaskHeader);
