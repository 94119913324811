import User, { LocalVismaUser } from "./user";

export enum UserType {
  Owner,
  Member,
}

export default class CaskUser {
  id!: number;
  user!: User;
  vismaUser!: LocalVismaUser;
  userType!: UserType;
}
