import { Box } from "@chakra-ui/react";
import Title from "components/Title";
import { Card, CardHeader, CardText } from "material-ui/Card";
import * as React from "react";
import { FC } from "react";
import { Col, Row } from "react-flexbox-grid";
import { FormattedMessage } from "react-intl";
import { PaymentType } from "stores/ui/cask-shop/shopping-cart";
import styled from "styled-components";

const boxLogoImg = require("assets/images/logo-dark.svg");

const PaymentOptionCard = styled(Card)`
  border: 1px solid transparent;
  overflow: auto;
  &.active {
    border: 1px solid #6f5a23;
  }
  cursor: pointer;
`;

const PaymentOptionTextImg = styled.img`
  max-height: 40px;
`;

const PaymentOptionHeader = styled(CardHeader)`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
` as any;

const PaymentOptionText = styled(CardText)`
  background-color: #f2f2f2;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaymentOptionIcon = styled.i`
  &.material-icons {
    font-size: 80px;
    color: #6f5a23;
  }
`;

const PaymentHeaderText = styled.div`
  font-size: 12px;
  text-align: center;
  white-space: initial;
`;

interface PaymentOptionProps {
  isActive: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

const PaymentOption: FC<PaymentOptionProps> = ({
  isActive,
  onClick,
  children,
}) => (
  <div onClick={onClick}>
    <PaymentOptionCard className={isActive ? "active" : ""}>
      {children}
    </PaymentOptionCard>
  </div>
);

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

interface Props {
  onInitEcsterPayment: () => void;
  selectedPaymentType?: PaymentType;
  onSelectPaymentType: (paymentType: PaymentType) => void;
}

const ShoppingCartPaymentOptions: FC<Props> = (props: Props) => {
  let { selectedPaymentType, onSelectPaymentType } = props;
  return (
    <Wrapper>
      <Box mb={2}>
        <Title primary={true}>
          <FormattedMessage id="caskShop.cart.payment.title" />
        </Title>
      </Box>
      <Row>
        <Col xs={6} sm={3} md={3}>
          <PaymentOption
            isActive={selectedPaymentType === PaymentType.Invoice}
            onClick={() => {
              onSelectPaymentType(PaymentType.Invoice);
            }}
          >
            <PaymentOptionHeader>
              <PaymentOptionIcon className="material-icons">
                insert_drive_file
              </PaymentOptionIcon>
              <PaymentHeaderText>
                <FormattedMessage id="caskShop.cart.payment.option.invoice" />
              </PaymentHeaderText>
            </PaymentOptionHeader>
            <PaymentOptionText>
              <PaymentOptionTextImg src={boxLogoImg.default} />
            </PaymentOptionText>
          </PaymentOption>
        </Col>
        {/* <Col xs={6} sm={3} md={3}>
          <PaymentOption
            isActive={selectedPaymentType === PaymentType.Ecster}
            onClick={() => {
              onSelectPaymentType(PaymentType.Ecster);
            }}
          >
            <PaymentOptionHeader>
              <PaymentOptionIcon className="material-icons">
                credit_card
              </PaymentOptionIcon>
              <PaymentHeaderText>
                <FormattedMessage id="caskShop.cart.payment.option.cardInvoicePartPayment" />
              </PaymentHeaderText>
            </PaymentOptionHeader>
            <PaymentOptionText>
              <PaymentOptionTextImg src="https://www.ecster.se/content/shared/images/logos/ecster_logo/svg" />
            </PaymentOptionText>
          </PaymentOption>
        </Col> */}
      </Row>
    </Wrapper>
  );
};

export default ShoppingCartPaymentOptions;
