import { observer } from "mobx-react";
import BottlingPriceVariables from "models/bottling-price-variables";
import { DeliveryOccasionLocation } from "models/delivery-occasion";
import * as React from "react";
import styled from "styled-components";
import BottlePriceListItem from "./BottlePriceListItemContainer";

const Wrapper = styled.div`
  margin-top: 15px;
`;

interface Props {
  pricesVariables: BottlingPriceVariables[];
  loanAmount: number;
  numberOfBottles: number;
  alcoholContent: number;
  bottleSize: number;
  openCountries: Map<DeliveryOccasionLocation, boolean>;
  onCountryClick: (country: DeliveryOccasionLocation) => void;
}

const BottlePriceList: React.FC<Props> = (props) => (
  <Wrapper>
    {props.pricesVariables
      .slice()
      .sort((a, b) => a.country)
      .map((item) => (
        <BottlePriceListItem
          key={item.id}
          country={item.country}
          loanAmount={props.loanAmount}
          vatRate={item.vatRate}
          alcoholDutyRate={item.alcoholDutyRate}
          exchangeRate={item.exchangeRate}
          vendorMarginRate={item.vendorMarginRate}
          vendorFlatRate={item.vendorFlatRate}
          numberOfBottles={props.numberOfBottles}
          alcoholContent={props.alcoholContent}
          bottleSize={props.bottleSize}
          isOpen={props.openCountries.get(item.country)}
          onCountryClick={props.onCountryClick}
        />
      ))}
  </Wrapper>
);

export default observer(BottlePriceList);
