import { Box } from "@chakra-ui/react";
import CustomAlert from "components/CustomAlert";
import BottleLabel, { Customization } from "models/bottle-label";
import { FC, PropsWithChildren } from "react";
import { useMeasure } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import styled from "styled-components";

const blankLabel: string = require("assets/images/bottle-label-blank_HC.png");
const oldBoxLabel: string = require("assets/images/bottle-label-blank_BOX.jpg");
const ginLabel: string = require("assets/images/ginetikett.png");

const LabelPreviewContainer = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: calc(76vh - 100px);
`;

const LabelPreviewInner = styled.img`
  max-height: calc(100vh - 100px);
  width: fit-content;
  border: 1px solid #bdbdbd;

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const ImageFilePreviewContainerBOX = styled.div`
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 32%;
  width: 100%;
  height: 32%;
`;

const ImageFilePreviewContainerHC = styled(Box)`
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 23%;
  width: 100%;
  height: 35%;
`;

const ImageFilePreview = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const TextPreviewInner = styled(Box)`
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 23%;
  width: 100%;
  height: 35%;
  text-align: center;
  font-size: 28px;
  word-wrap: break-word;
  font-family: "Times New Roman", Georgia, Serif;

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const TextPreview: FC<PropsWithChildren<{ isGin: boolean }>> = ({
  children,
  isGin,
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const textRatio = width ? width / 624 : 1;

  return (
    <TextPreviewInner
      ref={ref}
      fontSize={Math.round(50 * textRatio)}
      top={isGin ? "10%" : "unset"}
    >
      {children}
    </TextPreviewInner>
  );
};

const LabelPreview: FC<{ label: BottleLabel; summary?: boolean }> = ({
  label,
  summary,
}) => {
  const { cask } = useCurrentCask();
  const isGin = cask?.recipe?.id === 3;

  return (
    <>
      {isGin && !summary && (
        <Box pb={6}>
          <CustomAlert
            labelKey="cask.orders.bottling.labels.gin.file-notice"
            status="info"
          />
        </Box>
      )}
      <LabelPreviewContainer>
        {label.customization === Customization.PersonalText && (
          <TextPreview isGin={isGin}>{label.text}</TextPreview>
        )}
        {label.imageFile && label.customization === Customization.File && (
          <>
            {label.oldBOXLabel ? (
              <ImageFilePreviewContainerBOX>
                <ImageFilePreview src={label.imageFile.imagePreview} />
              </ImageFilePreviewContainerBOX>
            ) : (
              <ImageFilePreviewContainerHC
                top={isGin ? "2%" : undefined}
                height={isGin ? "29%" : undefined}
              >
                <ImageFilePreview src={label.imageFile.imagePreview} />
              </ImageFilePreviewContainerHC>
            )}
          </>
        )}
        <LabelPreviewInner
          src={
            label.oldBOXLabel
              ? oldBoxLabel.toString()
              : isGin
              ? ginLabel.toString()
              : blankLabel.toString()
          }
        />
      </LabelPreviewContainer>
    </>
  );
};

export default LabelPreview;
