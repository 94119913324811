import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Stat,
  StatLabel,
  StatNumber,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomStatGroup } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import { useFormikContext } from "formik";
import { observer } from "mobx-react";
import { DeliveryOccasionLocation } from "models/delivery-occasion";
import { FC, useEffect, useState } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import { useStores } from "stores";
import {
  DEFAULT_ALCOHOL_CONTENT,
  DEFAULT_NUMBER_OF_BOTTLES,
  RECIPE_SMOKED_ID,
} from "utils/forms/constants";
import { BottlingFormValues } from "../../../components/BottlingForm";
import DeliveryInfoMap from "./DeliveryInfoMap";
import DeliveryInfoWarningComponent from "./DeliveryInfoWarningComponent";

const BOTTLE_SIZE = 0.5; // verkar vara konstant?

const getNet = (loanAmount: number, numberOfBottles: number) =>
  loanAmount / numberOfBottles;

const getAlcoholDuty = (
  alcoholDutyRate: number,
  exchangeRate: number,
  bottleSize: number,
  alcoholContent: number
) => alcoholDutyRate * exchangeRate * bottleSize * alcoholContent;

const getVendorMarginRateDecimal = (vendorMarginRate: number) =>
  vendorMarginRate / 100;

const getVendorCharge = (
  vendorFlatRate: number,
  net: number,
  vendorMarginRateDecimal: number
) => net * vendorMarginRateDecimal + vendorFlatRate;

const getVat = (
  vatRate: number,
  vendorCharge: number,
  alcoholDuty: number,
  net: number
) => Math.round((vendorCharge + alcoholDuty + net) * vatRate);

const getSum = (
  net: number,
  alcoholDuty: number,
  vendorCharge: number,
  vat: number
) => net + alcoholDuty + vendorCharge + vat;

const usePricingVariables = (deliveryLocation: DeliveryOccasionLocation) => {
  const [priceVariables, setPriceVariables] = useState<{
    net: number;
    alcoholDuty: number;
    vendorCharge: number;
    vat: number;
    sum: number;
    exchangeRate: number;
  }>({
    alcoholDuty: 0,
    net: 0,
    sum: 0,
    vat: 0,
    vendorCharge: 0,
    exchangeRate: 1,
  });
  const { bottlingPriceVariablesStore, recipesStore } = useStores();
  const { cask } = useCurrentCask();
  const {
    values: {
      bottlingInfo: { alcoholContent, numberOfBottles },
    },
  } = useFormikContext<BottlingFormValues>();

  useEffect(() => {
    const vars = bottlingPriceVariablesStore.bottlingPriceVariables.find(
      (pv) => pv.country === deliveryLocation
    );

    if (!cask || !vars) return;

    const loanClaimExists = cask.loanClaim !== undefined && cask.loanClaim > 0;
    const loanAmount = loanClaimExists
      ? cask.loanClaim!
      : cask.recipe
      ? cask.recipe.price
      : recipesStore!.find(RECIPE_SMOKED_ID).price;
    const net = getNet(loanAmount, DEFAULT_NUMBER_OF_BOTTLES);
    const alcoholDuty = getAlcoholDuty(
      vars.alcoholDutyRate,
      vars.exchangeRate,
      BOTTLE_SIZE,
      (alcoholContent || DEFAULT_ALCOHOL_CONTENT) / 100
    );
    const vendorCharge = getVendorCharge(
      vars.vendorFlatRate,
      net,
      getVendorMarginRateDecimal(vars.vendorMarginRate)
    );
    const vat = getVat(vars.vatRate, vendorCharge, alcoholDuty, net);
    const sum = getSum(net, alcoholDuty, vendorCharge, vat);

    setPriceVariables({
      sum,
      alcoholDuty,
      net,
      vat,
      vendorCharge,
      exchangeRate: vars.exchangeRate,
    });
  }, [
    alcoholContent,
    bottlingPriceVariablesStore.bottlingPriceVariables,
    cask,
    deliveryLocation,
    numberOfBottles,
    recipesStore,
  ]);

  return priceVariables;
};

const DeliveryInfo: FC<{ deliveryLocation: DeliveryOccasionLocation }> = ({
  deliveryLocation,
}) => {
  const {
    values: {
      bottlingInfo: { fullBottling, alcoholContent, numberOfBottles },
    },
  } = useFormikContext<BottlingFormValues>();
  const pricingVariables = usePricingVariables(deliveryLocation);
  const noBottles = fullBottling ? DEFAULT_NUMBER_OF_BOTTLES : numberOfBottles!;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMedia("(max-width: 500px)");

  return (
    <>
      <Box mt={4} bg="#f2f2f2">
        <CustomAlert>
          <Box>
            <Box mb={4}>
              <FormattedHTMLMessage
                id="cask.orders.bottling.delivery.price-example"
                values={{
                  price: Math.round(pricingVariables?.sum),
                  noBottles,
                  alcoholContent: alcoholContent || DEFAULT_ALCOHOL_CONTENT,
                }}
              />
            </Box>
            <Button
              variant="link"
              onClick={isOpen ? onClose : onOpen}
              rightIcon={
                isOpen ? (
                  <ChevronUpIcon boxSize={6} />
                ) : (
                  <ChevronDownIcon boxSize={6} />
                )
              }
            >
              <FormattedMessage id="cask.orders.bottling.delivery.price-example.showCalculations" />
            </Button>
          </Box>
        </CustomAlert>
        <Collapse in={isOpen} animateOpacity>
          <Box p={4} pl={isMobile ? 4 : 20} mt={-4} ml={isMobile ? 0 : -1}>
            <CustomStatGroup>
              <Stat style={{ minWidth: 145 }}>
                <StatLabel>
                  <FormattedMessage id="orders.bottlingOrder.priceExample.netPrice" />
                </StatLabel>
                <StatNumber>{`${Math.round(
                  pricingVariables.net
                )} SEK`}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>
                  <FormattedMessage id="orders.bottlingOrder.priceExample.alcoholDuty" />
                </StatLabel>
                <StatNumber>
                  <StatNumber>{`${Math.round(
                    pricingVariables.alcoholDuty
                  )} SEK`}</StatNumber>
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>
                  <FormattedMessage id="orders.bottlingOrder.priceExample.vendorCharge" />
                </StatLabel>
                <StatNumber>
                  <StatNumber>{`${Math.round(
                    pricingVariables.vendorCharge
                  )} SEK`}</StatNumber>
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>
                  <FormattedMessage id="orders.bottlingOrder.priceExample.vat" />
                </StatLabel>
                <StatNumber>{`${Math.round(
                  pricingVariables.vat
                )} SEK`}</StatNumber>
              </Stat>
              {deliveryLocation !== DeliveryOccasionLocation.Sweden && (
                <Stat>
                  <StatLabel>
                    <FormattedMessage id="orders.bottlingOrder.priceExample.exchangeRate" />
                  </StatLabel>
                  <StatNumber>{pricingVariables.exchangeRate}</StatNumber>
                </Stat>
              )}
            </CustomStatGroup>
          </Box>
        </Collapse>
      </Box>
      <Box mt={4}>
        <DeliveryInfoWarningComponent deliveryLocation={deliveryLocation} />
      </Box>
      {deliveryLocation !== DeliveryOccasionLocation.Sweden && (
        <Box mt={4}>
          <DeliveryInfoMap deliveryLocation={deliveryLocation} />
        </Box>
      )}
    </>
  );
};

export default observer(DeliveryInfo);
