import {
  Box,
  Collapse,
  FormLabel,
  HStack,
  Radio,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CustomRadioButtonGroup, CustomSelect } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import { sv } from "date-fns/locale";
import { useFormikContext } from "formik";
import { observer } from "mobx-react";
import { SamplingOrderType } from "models/sampling-order";
import { useEffect } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import { useStores } from "stores";
import { Language } from "stores/domain/locale";
import styled from "styled-components";
import { formatToString } from "utils/format";
import { SamplingFormValues } from "./SamplingForm";

const StyledDayPicker = styled(DayPicker)`
  margin: 0 !important;
  border: 1px solid #bdbdbd;
  border-radius: 0.5rem;
  padding: 1rem;

  .rdp-caption_label {
    font-weight: 400;
  }

  .rdp-head_cell {
    font-weight: 500;
  }

  .rdp-day_selected,
  .rdp-day_selected:hover {
    background-color: #3992ad;
  }

  @media (max-width: 375px) {
    padding: 0.5rem;
  }
`;

const SamplingEventsDistilleryFormPart = () => {
  const {
    values: { type, event, distilleryDateTime, amount },
    setFieldValue,
  } = useFormikContext<SamplingFormValues>();
  const isMobile = useMedia("(max-width: 48em)");
  const { eventsStore, localeStore } = useStores();
  const StackComponent = isMobile ? Stack : HStack;
  const dateInFiveDays = () => {
    const date = new Date();
    date.setDate(date.getDate() + 5);

    return date;
  };

  useEffect(() => {
    eventsStore.loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (eventsStore.isLoading) return null;

  return (
    <Collapse
      in={
        type === SamplingOrderType.Distillery ||
        type === SamplingOrderType.Event
      }
    >
      <Box mt={6}>
        <Box my={4}>
          <CustomAlert
            status="warning"
            labelKey="cask.orders.sampling.notice"
          />
        </Box>
        {type === SamplingOrderType.Event && (
          <>
            <FormLabel fontSize="lg" fontWeight={300} htmlFor="event">
              <FormattedMessage id="cask.orders.sampling.occation" />
            </FormLabel>
            <CustomSelect
              w="full"
              id="event"
              name="event"
              value={event?.id}
              onChange={(v: any) =>
                setFieldValue(
                  "event",
                  eventsStore.events.find(
                    (o) => o.id === Number(v.target.value)
                  )
                )
              }
              placeholder={formatToString("cask.orders.sampling.occation.pick")}
            >
              {(eventsStore.events || []).map((o) => (
                <option value={o.id} key={o.id}>
                  {o.name}
                </option>
              ))}
            </CustomSelect>
          </>
        )}
        {type === SamplingOrderType.Distillery && (
          <>
            <FormLabel
              fontSize="lg"
              fontWeight={300}
              htmlFor="distilleryDateTime"
            >
              <FormattedMessage id="cask.orders.sampling.date" />
            </FormLabel>
            <Box w={isMobile ? "fit-content" : "50%"}>
              <StyledDayPicker
                locale={
                  localeStore.language === Language.Swedish ? sv : undefined
                }
                onDayClick={(date) => {
                  date.setHours(11, 0);
                  setFieldValue("distilleryDateTime", date);
                }}
                disabled={
                  (date) =>
                    date.getDay() !== 6 ||
                    date < dateInFiveDays() ||
                    (date < new Date("2024-06-29") &&
                      date > new Date("2024-06-21")) // TODO: Ta bort när datumet passerat
                }
                selected={distilleryDateTime}
              />
            </Box>
          </>
        )}
        <Box mt={4}>
          <FormLabel fontSize="lg" fontWeight={300} htmlFor="amount">
            <FormattedMessage id="cask.orders.sampling.amount" />
          </FormLabel>
          <CustomRadioButtonGroup
            id="amount"
            value={amount?.toString()}
            onChange={(v: string) => {
              setFieldValue("amount", Number(v));
            }}
          >
            <StackComponent spacing={4}>
              {[3, 10].map((a) => (
                <Radio value={a.toString()} w="full" key={a}>
                  <Text>{`${a} cl`}</Text>
                  <Text fontWeight={300}>
                    <FormattedHTMLMessage
                      id="cask.orders.sampling.cost"
                      values={{ cost: a * 10 }}
                    />
                  </Text>
                </Radio>
              ))}
            </StackComponent>
          </CustomRadioButtonGroup>
        </Box>
        <Box mt={4}>
          <CustomAlert
            labelKey={
              type === SamplingOrderType.Distillery
                ? "cask.orders.sampling.amount.notice-distillery"
                : "cask.orders.sampling.amount.notice"
            }
          />
        </Box>
      </Box>
    </Collapse>
  );
};

export default observer(SamplingEventsDistilleryFormPart);
