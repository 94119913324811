import * as React from 'react';
import Cask, { getDisplayId, getCaskRefillName } from 'models/cask';
import { ListItem, List } from 'material-ui';
interface Props {
  casks: Cask[];
  onClick: (cask: Cask) => void;
  disabled: boolean;
}
export function CaskList(props: Props) {
  let { casks, onClick, disabled } = props;
  return (
    <List>
      {casks.map(cask => (
        <ListItem
          key={cask.id}
          onClick={() => {
            if (!disabled) {
              onClick(cask);
            }
          }}
          primaryText={getCaskRefillName(cask)}
          secondaryText={`id: ${getDisplayId(cask)}`}
          disabled={disabled}
        />
      ))}
    </List>
  );
}
