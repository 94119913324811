import { RaisedButton } from "material-ui";
import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import format from "utils/format";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  font-size: 16px;
  color: #929292;
`;

const Message = styled.div`
  margin-bottom: 50px;
`;

const ShoppingCart: React.FC<{}> = () => (
  <Wrapper>
    <Message>{format("caskShop.cart.empty.text")}</Message>
    <Link to="/shop">
      <RaisedButton
        label={format("caskShop.cart.shop.findWhiskybutton.label")}
        primary={true}
      />
    </Link>
  </Wrapper>
);

export default ShoppingCart;
