import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormLabel,
  Heading,
  Image,
  Input,
  Radio,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomRadioButtonGroup } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import {
  getImageMetaData,
  getImagePreview,
} from "components/FileUploader/FileUploaderPreview";
import FormInput from "components/forms/FormInput";
import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import { observer } from "mobx-react";
import BottleLabel, { Customization, UploadFile } from "models/bottle-label";
import Cask from "models/cask";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { RiUpload2Fill } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import * as Yup from "yup";
import { BottlingFormValues } from "./BottlingForm";
import { intersectingSectionAtom } from "./BottlingStepper";
import LabelAlertDialog from "./LabelAlertDialog";
import LabelPreview from "./LabelPreview";

const ginLabel: string = require("assets/images/ginfront.jpg");

export interface BottlingLabelsPartValues {
  labels: BottleLabel[];
}

export const bottlingLabelsSchema = (format: (id: string) => any) =>
  Yup.object().shape({
    labels: Yup.array().of(
      Yup.object({
        customization: Yup.mixed<Customization>().required(
          format("common.required")
        ),
        text: Yup.string().when("customization", {
          is: Customization.PersonalText,
          then: Yup.string()
            .max(60, format("common.too-long"))
            .required(format("common.required")),
        }),
        imageFile: Yup.object().when("customization", {
          is: Customization.File,
          then: Yup.object().required(format("common.required")),
        }),
      })
    ),
    percentOfLabels: Yup.object().when(["labels"], {
      is: (labels: BottleLabel[]) =>
        labels.length > 1 &&
        labels.reduce((p, c) => p + (c.percentOfLabels || 0), 0) !== 100,
      then: Yup.object().required(format("common.required")),
    }),
  });

const onFileChanged = async (
  evt: React.SyntheticEvent<HTMLInputElement>,
  index: number,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void,
  setSizeError: Dispatch<SetStateAction<boolean>>
) => {
  setSizeError(false);
  const target = evt.target as HTMLInputElement;

  if (target.files === null) {
    return;
  }

  const files = new Array<UploadFile>();

  for (let i = 0; i < target.files.length; i++) {
    if (target.files.item(i) && target.files.item(i) !== null) {
      if (
        !target.files.item(i)?.size ||
        target.files.item(i)?.size! > 5 * 1024 * 1024
      ) {
        return setSizeError(true);
      }

      files.push({
        file: target.files.item(i)!,
      });
    }
  }

  const file = files[0];

  const imagePreview = await getImagePreview(file.file);
  const imageMetaData = await getImageMetaData(imagePreview);
  file.imagePreview = imagePreview;
  file.imageMetaData = imageMetaData;

  setFieldValue(`labels.labels[${index}].imageFile`, file);
};

const FileButton: FC<{
  index: number;
  isMobile: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}> = ({ index, setFieldValue, isMobile }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [sizeError, setSizeError] = useState<boolean>(false);

  return (
    <>
      <Input
        hidden
        ref={inputRef}
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
        onChange={(e) => onFileChanged(e, index, setFieldValue, setSizeError)}
      />
      <Button
        w="full"
        colorScheme="brand"
        size={"lg"}
        rightIcon={<RiUpload2Fill size="1.25rem" />}
        onClick={() => inputRef.current?.click()}
      >
        <FormattedMessage id="cask.orders.bottling.labels.customization.upload" />
      </Button>
      {sizeError && (
        <Text color="red.500" mt={2} fontSize="sm">
          <FormattedMessage id="cask.orders.bottling.labels.customization.upload-size-error" />
        </Text>
      )}
    </>
  );
};

const isOldBOXLabelAvailable = (cask?: Cask) => {
  if (!cask) return false;
  if (cask.filled === undefined) return false;

  let filled = new Date(cask.filled).getTime();
  let boxLabelLimit = new Date("2018-06-30").getTime();
  let diff = filled - boxLabelLimit;
  return diff < 0;
};

const LabelItem: FC<{
  labels: BottleLabel[];
  label: BottleLabel;
  index: number;
  hookState: [
    (() => void | undefined) | undefined,
    Dispatch<SetStateAction<(() => void | undefined) | undefined>>
  ];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}> = ({ labels, label, index, setFieldValue, hookState }) => {
  const { cask } = useCurrentCask();
  const boxLabelAvailable = isOldBOXLabelAvailable(cask);
  const isMobile = useMedia("(max-width: 48em)");
  const percentageError =
    labels.reduce((p, c) => p + (c.percentOfLabels || 0), 0) !== 100;

  return (
    <Box mt={4}>
      <Box display="flex">
        <FormLabel
          fontSize="lg"
          fontWeight={300}
          htmlFor="bottlingInfo.fullBottling"
        >
          <FormattedMessage
            id="cask.orders.bottling.labels.titles"
            values={{ index: index + 1 }}
          />
        </FormLabel>
        {labels.length > 1 && (
          <Button
            marginBottom={2}
            color="red.500"
            variant="link"
            rightIcon={<BsFillTrashFill />}
            onClick={() =>
              setFieldValue("labels.labels", [
                ...labels.filter((l, i) => i !== index),
              ])
            }
          >
            <FormattedMessage id="common.remove" />{" "}
          </Button>
        )}
      </Box>
      <Box mt={2} mb={4}>
        <Text fontWeight={300}>
          <FormattedMessage id="cask.orders.bottling.labels.customization.pick" />
        </Text>
      </Box>
      <CustomRadioButtonGroup
        id={`label-customization-${index}`}
        value={label.customization?.toString()}
        onChange={(v: string) => {
          setFieldValue(`labels.labels[${index}].imageFile`, undefined);
          setFieldValue(`labels.labels[${index}].text`, undefined);

          if (v === "0")
            hookState[1](
              () => () =>
                setFieldValue(`labels.labels[${index}].customization`, 1)
            );
          else hookState[1](undefined);

          setFieldValue(`labels.labels[${index}].customization`, Number(v));
        }}
      >
        <Stack spacing={4} alignItems="stretch">
          <Radio value={Customization.PersonalText.toString()} w="full">
            <FormattedMessage id="cask.orders.bottling.labels.customization.personalText" />
            <Text fontWeight={300}>
              <FormattedMessage id="cask.orders.bottling.labels.customization.personalText.desc" />
            </Text>
          </Radio>
          <Radio value={Customization.File.toString()} w="full">
            <FormattedMessage id="cask.orders.bottling.labels.customization.file" />
            <Text fontWeight={300}>
              <FormattedMessage id="cask.orders.bottling.labels.customization.file.desc" />
            </Text>
          </Radio>
          <Radio value={Customization.None.toString()} w="full">
            <FormattedMessage id="cask.orders.bottling.labels.customization.none" />
            <Text fontWeight={300}>
              <FormattedMessage id="cask.orders.bottling.labels.customization.none.desc" />
            </Text>
          </Radio>
        </Stack>
      </CustomRadioButtonGroup>
      {label.customization === Customization.PersonalText && (
        <Box mt={4}>
          <FormInput
            labelKey="cask.orders.bottling.labels.customization.personalText"
            name={`labels.labels[${index}].text`}
            placeholderKey="cask.orders.bottling.labels.customization.personalText-placeholder"
          />
        </Box>
      )}
      {label.customization === Customization.File && (
        <Box mt={4}>
          <FileButton
            setFieldValue={setFieldValue}
            index={index}
            isMobile={isMobile}
          />
        </Box>
      )}
      {boxLabelAvailable && (
        <Box mt={4}>
          <Checkbox
            name={`labels.labels[${index}].oldBOXLabel`}
            onChange={(v) =>
              setFieldValue(
                `labels.labels[${index}].oldBOXLabel`,
                v.target.checked
              )
            }
          >
            <FormattedMessage id="cask.orders.bottling.labels.box" />
          </Checkbox>
        </Box>
      )}
      {label.customization !== undefined && (
        <Box my={4}>
          <Box mt={2} mb={4}>
            <Text fontWeight={300}>
              <FormattedMessage id="orders.bottlingOrder.label.preview" />
            </Text>
          </Box>
          <LabelPreview label={label} />
        </Box>
      )}
      {labels.length > 1 && (
        <Box my={4}>
          <Box mt={2} mb={4}>
            <Text fontWeight={300}>
              <FormattedMessage id="cask.orders.bottling.labels.percent" />
            </Text>
          </Box>
          <Box
            mt={4}
            pb={4}
            px={4}
            border="1px"
            borderColor={percentageError ? "red.500" : "gray.300"}
            pt={10}
          >
            <Slider
              aria-label="slider"
              onChange={(v) =>
                setFieldValue(`labels.labels[${index}].percentOfLabels`, v)
              }
            >
              <SliderMark
                value={label.percentOfLabels || 50}
                textAlign="center"
                bg="#3992AD"
                color="white"
                mt="-10"
                ml="-5"
                w="12"
                fontSize="lg"
              >
                {label.percentOfLabels}%
              </SliderMark>
              <SliderTrack mt={2} h={2} bg="#BDBDBD">
                <SliderFilledTrack bg="#3992AD" />
              </SliderTrack>
              <SliderThumb mt={2} borderColor="#BDBDBD" h={6} w={6} />
            </Slider>
          </Box>
          <Collapse in={percentageError} animateOpacity>
            <Text fontSize="sm" color="red.500" mt={4}>
              <FormattedMessage id="cask.orders.bottling.labels.sum-error" />
            </Text>
          </Collapse>
        </Box>
      )}

      <Divider my={6} />
    </Box>
  );
};

const LabelsFormPart = () => {
  const labelAlertDisclosure = useDisclosure();
  const labelAlertHookState = useState<() => void | undefined>();
  const [, setCurrentSection] = useAtom(intersectingSectionAtom);
  const {
    values: {
      labels: { labels },
    },
    setFieldValue,
  } = useFormikContext<BottlingFormValues>();
  const { cask } = useCurrentCask();
  const isGin = cask?.recipe?.id === 3;

  return (
    <fieldset id="bottling-labels" onClick={() => setCurrentSection("labels")}>
      <LabelAlertDialog
        hookState={labelAlertHookState}
        disclosure={labelAlertDisclosure}
      />
      <Box pb={8}>
        <Box mt={6}>
          <Heading size="lg" fontWeight={500} mb={4}>
            <FormattedMessage id="cask.orders.bottling.labels.title" />
          </Heading>
          <Divider mt={2} mb={6} />
          <CustomAlert
            labelKey="orders.bottlingOrder.label.managerDescription"
            status="info"
            values={{ amount: "250" }}
          />
          <Box mt={4}>
            <CustomAlert
              labelKey="cask.orders.labels.content-warning"
              status="warning"
            />
          </Box>
          {isGin && (
            <Box my={4}>
              <CustomAlert
                labelKey="cask.orders.bottling.labels.gin.info"
                status="info"
              />
              <Image src={ginLabel.toString()} />
            </Box>
          )}
          {labels.map((l, i) => (
            <LabelItem
              labels={labels}
              key={`label-${i}`}
              index={i}
              label={l}
              setFieldValue={setFieldValue}
              hookState={labelAlertHookState}
            />
          ))}
        </Box>
        <Button
          mt={2}
          w="full"
          borderColor="#807F7F"
          color="gray.900"
          size="lg"
          variant="outline"
          rightIcon={<GoPlus size="1.25rem" />}
          onClick={() => {
            const newLabels = [
              ...labels,
              { customization: Customization.None, percentOfLabels: 50 },
            ];
            setFieldValue(
              "labels.labels",
              newLabels.length === 2
                ? newLabels.map((l) => ({
                    ...l,
                    percentOfLabels: 50,
                  }))
                : newLabels
            );
          }}
        >
          <FormattedMessage id="cask.orders.bottling.labels.add" />
        </Button>
        {labels.length > 1 &&
          labels.every((l) => l.customization !== undefined) && (
            <Box mt={8}>
              <CustomAlert
                status="info"
                labelKey="cask.orders.bottling.labels.cost"
                values={{ cost: (labels.length - 1) * 250 }}
              />
            </Box>
          )}
      </Box>
    </fieldset>
  );
};

export default observer(LabelsFormPart);
