import CartCask from "models/cart-cask";
import * as React from "react";
import { FormattedNumber } from "react-intl";
import ShoppingCartUiStore, {
  CartStep,
} from "stores/ui/cask-shop/shopping-cart";
import styled from "styled-components";
import formatMessage from "utils/format";
import { PRESTORED_PRICE } from "utils/prices";

interface Props {
  cartCasks: CartCask[];
  currentCartStep: number;
  isPersonalAccount: boolean;
  formStore: ShoppingCartUiStore;
}

const Wrapper = styled.div`
  font-size: 20px;
  margin-top: 2rem;

  @media (max-width: 48em) {
    font-size: 16px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 48em) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TotalSpan = styled.span`
  font-size: 70%;
  padding-right: 15px;
`;

const SekSpan = styled.span`
  font-size: 70%;
  position: relative;
  bottom: 0.3em;
  margin-left: 2px;
`;

const RightColumn = styled.td`
  text-align: right;
`;

class BottomSummary extends React.Component<Props, {}> {
  get totalRecipePrice() {
    let price = 0;
    this.props.cartCasks.forEach((cartCask) => {
      price += cartCask.recipe.price;
    });
    return price;
  }

  get totalCaskPrice() {
    let price = 0;
    this.props.cartCasks.forEach((cartCask) => {
      price += cartCask.caskType.price;
    });
    return price;
  }

  get totalCaskPriceExcludingVat() {
    return this.totalCaskPrice * 0.8;
  }

  get totalVat() {
    return this.totalCaskPrice * 0.2;
  }

  get totalPreStorePrice() {
    let price = 0;
    this.props.cartCasks.forEach((cartCask) => {
      if (cartCask.preStored) {
        price += PRESTORED_PRICE;
      }
    });
    return price;
  }

  get isAnyCaskPreStored() {
    let preStored = false;
    this.props.cartCasks.forEach((cartCask) => {
      if (cartCask.preStored) {
        preStored = true;
      }
    });
    return preStored;
  }

  get columnStyle() {
    return {
      rightColumn: {
        paddingLeft: "30px",
      },
    };
  }

  personalRows() {
    let { socialSecurityNumber, phoneNumber, address, city, zipCode, country } =
      this.props.formStore;

    return [
      <tr key="socialSecurityNumber">
        <td>{formatMessage("settings.contactInfo.socialSecurity.label")}</td>
        <td style={this.columnStyle.rightColumn}>
          {socialSecurityNumber.value}
        </td>
      </tr>,
      <tr key="phoneNumber">
        <td>{formatMessage("settings.contactInfo.phoneNumber.label")}</td>
        <td style={this.columnStyle.rightColumn}>{phoneNumber.value}</td>
      </tr>,
      <tr key="address">
        <td>{formatMessage("settings.contactInfo.address.label")}</td>
        <td style={this.columnStyle.rightColumn}>{address.value}</td>
      </tr>,
      <tr key="city">
        <td>{formatMessage("settings.contactInfo.city.label")}</td>
        <td style={this.columnStyle.rightColumn}>{city.value}</td>
      </tr>,
      <tr key="zipCode">
        <td>{formatMessage("settings.contactInfo.zipCode.label")}</td>
        <td style={this.columnStyle.rightColumn}>{zipCode.value}</td>
      </tr>,
      <tr key="country">
        <td>{formatMessage("settings.contactInfo.country.label")}</td>
        <td style={this.columnStyle.rightColumn}>{country.value}</td>
      </tr>,
    ];
  }

  companyRows() {
    let {
      email,
      invoiceAddress,
      invoiceCity,
      invoiceZipCode,
      invoiceCountry,
      contactPhoneNumber,
      contactFirstName,
      contactLastName,
    } = this.props.formStore;

    return [
      <tr key="invoiceAddress">
        <td>{formatMessage("settings.contactInfo.address.label")}</td>
        <td style={this.columnStyle.rightColumn}>{invoiceAddress.value}</td>
      </tr>,
      <tr key="invoiceCity">
        <td>{formatMessage("settings.contactInfo.city.label")}</td>
        <td style={this.columnStyle.rightColumn}>{invoiceCity.value}</td>
      </tr>,
      <tr key="invoiceZipCode">
        <td>{formatMessage("settings.contactInfo.zipCode.label")}</td>
        <td style={this.columnStyle.rightColumn}>{invoiceZipCode.value}</td>
      </tr>,
      <tr key="invoiceCountry">
        <td>{formatMessage("settings.contactInfo.country.label")}</td>
        <td style={this.columnStyle.rightColumn}>{invoiceCountry.value}</td>
      </tr>,
      <tr key="email">
        <td>{formatMessage("settings.contactInfo.company.email.label")}</td>
        <td style={this.columnStyle.rightColumn}>{email.value}</td>
      </tr>,
      <tr key="contactPhoneNumber">
        <td>{formatMessage("settings.contactInfo.phoneNumber.label")}</td>
        <td style={this.columnStyle.rightColumn}>{contactPhoneNumber.value}</td>
      </tr>,
      <tr key="contactFirstName">
        <td>{formatMessage("settings.contactInfo.company.firstName.label")}</td>
        <td style={this.columnStyle.rightColumn}>{contactFirstName.value}</td>
      </tr>,
      <tr key="contactLastName">
        <td>{formatMessage("settings.contactInfo.company.lastName.label")}</td>
        <td style={this.columnStyle.rightColumn}>{contactLastName.value}</td>
      </tr>,
    ];
  }

  render() {
    let { currentCartStep, isPersonalAccount } = this.props;

    return (
      <Wrapper>
        {currentCartStep === CartStep.CartPayment && (
          <StyledTable>
            <tbody>
              {isPersonalAccount ? this.personalRows() : this.companyRows()}
            </tbody>
          </StyledTable>
        )}
        <StyledTable>
          <tbody>
            <tr>
              <td>{formatMessage("caskShop.cart.view.cost.loan.label")}</td>
              <RightColumn>
                <FormattedNumber value={this.totalRecipePrice} /> SEK
              </RightColumn>
            </tr>
            <tr>
              <td>
                {formatMessage(
                  "caskShop.cart.view.cost.cask.vatExcluded.label"
                )}
              </td>
              <RightColumn>
                <FormattedNumber value={this.totalCaskPriceExcludingVat} /> SEK
              </RightColumn>
            </tr>
            <tr>
              <td>{formatMessage("caskShop.cart.view.cost.tax.label")}</td>
              <RightColumn>
                <FormattedNumber value={this.totalVat} /> SEK
              </RightColumn>
            </tr>
            {this.isAnyCaskPreStored && (
              <tr>
                <td>
                  {formatMessage("caskShop.cart.view.cost.prestored.label")}
                </td>
                <RightColumn>
                  <FormattedNumber value={this.totalPreStorePrice} /> SEK
                </RightColumn>
              </tr>
            )}
          </tbody>
        </StyledTable>
        <hr />
        <StyledTable>
          <tbody>
            <tr>
              <td>
                <h1>
                  <TotalSpan>
                    {formatMessage("caskShop.cart.view.cost.total.label")}
                  </TotalSpan>
                </h1>
              </td>
              <RightColumn>
                <h1>
                  <FormattedNumber
                    value={
                      this.totalRecipePrice +
                      this.totalCaskPrice +
                      this.totalPreStorePrice
                    }
                  />
                  <SekSpan>SEK</SekSpan>
                </h1>
              </RightColumn>
            </tr>
          </tbody>
        </StyledTable>
      </Wrapper>
    );
  }
}

export default BottomSummary;
