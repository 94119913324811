import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import ChangePasswordService, {
  ChangePasswordViewModel,
} from "services/change-password";
import ChangePasswordFormUiStore from "stores/ui/account/change-password-form";
import SnackBarUiStore from "stores/ui/snackBar";
import { formatToString } from "utils/format";
import ChangePasswordForm from "./ChangePasswordForm";

interface Props {
  changePasswordFormUiStore?: ChangePasswordFormUiStore;
  snackBarUiStore?: SnackBarUiStore;
}

@inject("changePasswordFormUiStore", "snackBarUiStore")
@observer
class ChangePasswordFormContainer extends React.Component<Props, {}> {
  @observable isSubmitting: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.props.changePasswordFormUiStore!.resetForm();
  }

  get store() {
    return this.props.changePasswordFormUiStore!;
  }

  get changePasswordViewModel(): ChangePasswordViewModel {
    return {
      currentPassword: this.store.currentPassword.value,
      newPassword: this.store.newPassword.value,
      repeatedPassword: this.store.repeatedPassword.value,
    };
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let validation = await this.store.form.validate();

    if (validation.hasError) {
      return;
    }

    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    let newPassword = this.changePasswordViewModel;

    try {
      let response = await ChangePasswordService.changePassword(newPassword);

      if (!response.succeeded) {
        throw new Error("Could't change password.");
      }
    } catch (Error) {
      throw Error;
    }

    this.props.snackBarUiStore!.open(
      formatToString("settings.contactInfo.changePassword.complete.message")
    );
    this.props.changePasswordFormUiStore!.resetForm();
    this.isSubmitting = false;
  };

  render() {
    let hasFieldError = this.store.form.hasFieldError;
    return (
      <div>
        <ChangePasswordForm
          formStore={this.store}
          hasFieldError={hasFieldError}
          onSubmit={this.onSubmit}
          isSubmitting={this.isSubmitting}
        />
      </div>
    );
  }
}

export default ChangePasswordFormContainer;
