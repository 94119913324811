import { action, makeObservable, observable } from "mobx";
import CaskType from "models/cask-type";

class CaskShopUiStore {
  @observable caskTypeInstances: {
    caskType: CaskType;
    recipeId: string;
    preStored: Boolean;
  }[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  createCaskType(caskType: CaskType) {
    let caskTypeInstance = this.caskTypeInstances.find(
      (element) => element.caskType.id === caskType.id
    );

    let recipeId = caskType.productIdSmoky === "1400" ? "3" : "1";
    let preStored = false;

    if (!caskTypeInstance) {
      this.caskTypeInstances.push({ caskType, recipeId, preStored });
    }
  }

  @action
  setCaskTypeRecipe(caskType: CaskType, recipeId: string) {
    let caskTypeInstance = this.caskTypeInstances.find(
      (element) => element.caskType.id === caskType.id
    );

    if (!caskTypeInstance) {
      throw new Error(`CaskType with id ${caskType.id} couldn't be found.`);
    }
    caskTypeInstance.recipeId = recipeId;
  }

  @action
  toggleCaskTypePreStored(caskType: CaskType) {
    let caskTypeInstance = this.caskTypeInstances.find(
      (element) => element.caskType.id === caskType.id
    );

    if (!caskTypeInstance) {
      throw new Error(`CaskType with id ${caskType.id} couldn't be found.`);
    }
    caskTypeInstance.preStored = !caskTypeInstance.preStored;
  }

  @action
  getCaskType(caskType: CaskType): {
    caskType: CaskType;
    recipeId: string;
    preStored: Boolean;
  } {
    let caskTypeInstance = this.caskTypeInstances.find(
      (element) => element.caskType.id === caskType.id
    );

    if (!caskTypeInstance) {
      throw new Error(`CaskType with id ${caskType.id} couldn't be found.`);
    }
    return caskTypeInstance;
  }
}
export default CaskShopUiStore;
export const caskShopUiStore = new CaskShopUiStore();
