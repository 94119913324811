import * as React from 'react';
import { Component } from 'react';
import { UploadFile, ImageMetadata } from './FileUploader';
import styled from 'styled-components';

interface Props {
    files: Array<UploadFile>;
    imageStyle?: Object;
    // onRemoveFile: (file: UploadFile) => void;
}

export function getImagePreview(file: File): Promise<string> {
    let reader = new FileReader();
    let promise = new Promise<string>((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result as any);
        };
        reader.readAsDataURL(file);
    });

    return promise;
}

function formatBytes(bytes: number) {
    if (bytes === 0) {
        return '0 B';
    }
    let k = 1024,
        dm = 2,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getImageMetaData(imageSrc: string): Promise<ImageMetadata> {
    let image = new Image();

    image.src = imageSrc;
    image.style.position = 'absolute';
    image.style.left = '-9999px';
    image.style.visibility = 'hidden';

    let promise = new Promise<ImageMetadata>((resolve) => {
        image.onload = () => {
            let height = image.height;
            let width = image.width;

            document.body.removeChild(image);
            resolve({
                height, width
            });
        };
    });

    document.body.appendChild(image);
    return promise;
}

const Name = styled.div`
    font-weight: bold;
`;

const SecondaryInfo = styled.div``;

const BackgroundImage = styled.div`
    width: 100%;
    max-width: 400px;
    height: 100%;
    max-height: 300px;
    background-size: contain;  
    background-repeat: no-repeat;
    background-position: center center;
`;

class FileUploader extends Component<Props, {}> {
    render() {

        return (
            <div>
                {this.props.files.map(fileObj => (
                    <div key={fileObj.key}>
                        {fileObj.imagePreview &&
                            <div style={this.props.imageStyle}>
                                <BackgroundImage style={{ backgroundImage: `url(${fileObj.imagePreview})` }} />
                            </div>
                        }
                        {fileObj && fileObj.file &&
                            <div>
                                <Name>
                                    {fileObj.file.name}
                                </Name>
                                <SecondaryInfo>
                                    {formatBytes(fileObj.file.size)}<br />
                                    {fileObj.imageMetaData &&
                                        <div>
                                            {`${fileObj.imageMetaData.height}x${fileObj.imageMetaData.width} px`}
                                        </div>
                                    }
                                </SecondaryInfo>
                            </div>
                        }
                    </div>
                ))}
            </div>
        );
    }
}

export default FileUploader;