import { Badge, Box, Image, Text } from "@chakra-ui/react";
import BrandButton from "components/BrandButton";
import CaskStatusText from "components/CaskStatusText";
import { observer } from "mobx-react";
import Cask, { getCaskImage, getCaskRefillName } from "models/cask";
import { UserType } from "models/cask-user";
import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { useMedia } from "react-use";
import { useStores } from "stores";

const GoToCaskButton: React.FC<{ id: number }> = ({ id }) => {
  const history = useHistory();
  const isMobile = useMedia("(max-width: 600px)");

  return (
    <BrandButton
      w={isMobile ? "full" : "auto"}
      colorScheme="brand"
      onClick={() => history.push("/cask/" + id)}
      rightIcon={<FiArrowRight />}
    >
      <FormattedMessage id="casks.go-to-cask" />
    </BrandButton>
  );
};

const CaskWidget: React.FC<{ cask: Cask }> = ({ cask }) => {
  const { localeStore, sessionStore } = useStores();
  const isTablet = useMedia("(max-width: 1024px)");
  const isMobile = useMedia("(max-width: 600px)");
  const recipeInfo = cask.recipe?.recipeInformation.find(
    (c) => c.language === localeStore.languageInt
  );
  const isOwner = cask.caskUsers.find(
    (cu) =>
      cu.vismaUser?.vismaUserId === sessionStore.vismaUser.vismaUserId &&
      cu.userType === UserType.Owner
  );

  return (
    /*     <Box p={4}  bg="#FFF" border="1px" borderColor="gray.300"> */

    <Box
      bg="#f2f2f2"
      maxW={isTablet && !isMobile ? "calc(50% - 2rem)" : "full"}
      border={isMobile ? "none" : "1px solid #f2f2f2"}
    >
      <Box w="full" display="flex" flexGrow={1}>
        <Box
          display="flex"
          maxW="50%"
          minW="227px"
          flexGrow={1}
          flexDir="column"
          p={4}
          pl={isMobile ? 6 : 4}
          pr={isMobile ? 6 : 4}
        >
          {cask.vismaCaskNumber && (
            <Text color="gray.600" fontWeight="light" fontSize="xl" mb={2}>
              {cask.vismaCaskNumber}
            </Text>
          )}
          <Text fontSize="xl" fontWeight={400} mb={0}>
            {getCaskRefillName(cask)}
          </Text>
          <Text color="blackAlpha.700" mb={4}>
            {recipeInfo?.description || cask.recipeText}
          </Text>
          {!isTablet && (
            <Text mb={4}>
              <CaskStatusText cask={cask} skipDays />
            </Text>
          )}
          {!isOwner && (
            <Box mt={-3} mb={4}>
              <Badge fontSize="sm" bg="white" fontWeight={400}>
                <FormattedMessage id="cask.members.title" />
              </Badge>
            </Box>
          )}
          {cask.timesRefilled > 0 && (
            <Box mt={-3} mb={4}>
              <Badge
                fontSize="sm"
                bg="white"
                fontWeight={400}
              >{`Refill #${cask.timesRefilled}`}</Badge>
            </Box>
          )}
          <Box mt="auto" pr={0}>
            <GoToCaskButton id={cask.id} />
          </Box>
        </Box>
        <Box
          w="full"
          alignSelf="stretch"
          bg="white"
          display="flex"
          alignItems="center"
          pos="relative"
          overflow="hidden"
        >
          <Image
            pos="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            h="100%"
            w="100%"
            objectFit="contain"
            src={getCaskImage(cask)}
            filter="blur(50px)"
          />
          <Image h="auto" w="auto" src={getCaskImage(cask)} zIndex={1} />
        </Box>
      </Box>
      <Box></Box>
    </Box>
  );
};

export default observer(CaskWidget);
