import * as React from 'react';
import { PureComponent } from 'react';
import CaskOrderHistory, { CaskOrderHistoryType } from 'models/cask-order-history';
import { isEmpty } from 'lodash';
import { formatToString } from 'utils/format';
import HistoryTimelineItem from './HistoryTimelineItem';

interface Props {
    caskOrderHistory: CaskOrderHistory;
    onShowTimelineItem: (orderId: number) => void;
    onGetSettlement: (orderId: number) => void;
    isOwner: boolean;
}

class HistoryTimelineItemContainer extends PureComponent<Props> {
    /**
     * Checks the order history and shows the appropriate title
     */
    get itemName(): string {
        let {caskOrderHistory} = this.props;

        // Todo: i18n
        if (caskOrderHistory.type === CaskOrderHistoryType.Purchase) {
            return formatToString('history.order.type.buy.label', {
                orderText: this.firstOrderRowDescription
            });
        }

        if (caskOrderHistory.type === CaskOrderHistoryType.Production) {
            let texts = new Array<string>();

            if (!isEmpty(caskOrderHistory.actionName)) {
                texts.push(caskOrderHistory.actionName);
            }

            if (!isEmpty(caskOrderHistory.bottlingVolumeName)) {
                texts.push(caskOrderHistory.bottlingVolumeName);
            }

            if (!isEmpty(caskOrderHistory.bottlingRoundName)) {
                texts.push(caskOrderHistory.bottlingRoundName);
            }

            return formatToString('history.order.type.production.label', {
                orderText: texts.join(', '),
            });
        }

        return formatToString('history.order.type.unknown.label');
    }

    get firstOrderRowDescription(): string {
        let {orderRows} = this.props.caskOrderHistory;

        return isEmpty(orderRows) ? '' : orderRows[0].description;
    }

    render() {
        let {onShowTimelineItem} = this.props;
        let {onGetSettlement} = this.props;
        let {id} = this.props.caskOrderHistory;

        return (
            <HistoryTimelineItem
                itemName={this.itemName}
                onGetSettlement={this.props.caskOrderHistory.isSettlement && this.props.isOwner ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onGetSettlement(id);
                } : undefined}
                onClick={() => onShowTimelineItem(id)}
            />
        );
    }
}

export default HistoryTimelineItemContainer;
