import { Box, Button, Center, Divider, Stack, Text } from "@chakra-ui/react";
import CustomAlert from "components/CustomAlert";
import { observer } from "mobx-react";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { RiUserSettingsLine } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { useStores } from "stores";

const ProfileMenu: React.FC<{
  close: () => void;
  openSwitchAccountModal: () => void;
}> = ({ close, openSwitchAccountModal }) => {
  const { push } = useHistory();
  const { sessionStore } = useStores();
  const history = useHistory();
  const [name, id] = sessionStore.nameWithId as [string, string];
  const errorFetchingVismaUser = !sessionStore?.vismaUser;
  const logout = () => {
    close();
    push("/");
    sessionStore.logOut();
  };

  if (!sessionStore.isAuthenticated || sessionStore.isFetchingUserData)
    return null;

  return (
    <>
      <Stack px={4}>
        <Box py={4}>
          <Text fontSize="lg" fontWeight={500}>
            {name}
          </Text>
          <Text>
            <FormattedMessage id="profile-menu.customer-id" /> {id}
          </Text>
          {errorFetchingVismaUser && (
            <Box mt={4}>
              <CustomAlert status="error" labelKey="profile-menu.error-visma" />
            </Box>
          )}
          {sessionStore.user.vismaUsers.length > 1 && (
            <Button
              onClick={openSwitchAccountModal}
              mt={2}
              variant="outline"
              fontWeight={300}
              leftIcon={<AiOutlineUserSwitch />}
            >
              <FormattedMessage id="navbar.switchAccount.label" />
            </Button>
          )}
        </Box>
        <Divider />
        <Stack py={4} flexGrow={1}>
          <Button
            onClick={() => {
              close();
              history.push("/settings");
            }}
            variant="outline"
            leftIcon={<RiUserSettingsLine />}
          >
            <FormattedMessage id="navbar.settings.label" />
          </Button>
        </Stack>
        <Divider />
        <Center pt={4} pb={2}>
          <Button variant="outline" onClick={logout} fontWeight={300}>
            <FormattedMessage id="header.logOut.label" />
          </Button>
        </Center>
      </Stack>
    </>
  );
};

export default observer(ProfileMenu);
