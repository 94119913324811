import { action, computed, makeObservable, observable } from "mobx";
import { routerStore } from "stores/domain/router";
import { formatToString } from "utils/format";
import { JsonApiResponse } from "utils/json-api";

export enum Tab {
  Person,
  Company,
}

class NewMemberUiStore {
  @observable errorMessage = "";
  @observable errorCode = "";
  @computed get selectedTab(): Tab {
    let path = routerStore.history.location.pathname;
    return this.determineTab(path);
  }

  constructor() {
    makeObservable(this);
  }

  determineTab(location: string) {
    return location.indexOf("/company") >= 0 ? Tab.Company : Tab.Person;
  }

  @action
  handleError(error: JsonApiResponse<string>) {
    if (!error.errors) {
      this.errorMessage = formatToString(
        "newMember.register.error.unknownError"
      );
      return;
    }

    if (error.errors.length > 0) {
      switch (error.errors[0].code) {
        case "InvalidToken":
          this.errorMessage = formatToString(
            "newMember.register.error.invalidToken"
          );
          break;
        case "UserNotFoundException":
          this.errorMessage = formatToString(
            "newMember.register.error.invalidUser"
          );
          break;
        default:
          this.errorMessage = formatToString(
            "newMember.register.error.unknownError"
          );
      }
      this.errorCode = error.errors[0].code;
    } else {
      this.errorMessage = formatToString(
        "newMember.register.error.unknownError"
      );
    }
  }
}
export default NewMemberUiStore;
export const newMemberUiStore = new NewMemberUiStore();
