import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { TextField, RaisedButton } from 'material-ui';
import { observer } from 'mobx-react';
import format from 'utils/format';
import { FormattedMessage } from 'react-intl';
import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_DIGITS
} from 'utils/forms/constants';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import { PersonFormUiStore } from 'stores';

interface Props {
  formStore: PersonFormUiStore;
  hasFieldError: boolean;
  isSubmitting: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const RegisterButton = styled(RaisedButton)`
  margin: 30px 0 0 0;
`;

const Form = styled.form`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  padding: 15px 0px;
`;

@observer
export default class PersonForm extends Component<Props, {}> {
  render() {
    let { formStore, hasFieldError, isSubmitting, onSubmit } = this.props;

    return (
      <Form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <TextField
          floatingLabelText={format('register.firstName.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            formStore.firstName.onChange(e.currentTarget.value)
          }
          value={formStore.firstName.value}
          errorText={format(formStore.firstName.error)}
        />
        <TextField
          floatingLabelText={format('register.lastName.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            formStore.lastName.onChange(e.currentTarget.value)
          }
          value={formStore.lastName.value}
          errorText={format(formStore.lastName.error)}
        />

        <TextField
          type="password"
          floatingLabelText={format('register.password.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            formStore.password.onChange(e.currentTarget.value);
            formStore.validateRepeatedPassword();
          }}
          value={formStore.password.value}
          errorText={format(formStore.password.error, {
            minLength: PASSWORD_MIN_LENGTH,
            minDigits: PASSWORD_MIN_DIGITS
          })}
        />

        <TextField
          type="password"
          floatingLabelText={format('register.repeatedPassword.label')}
          fullWidth={true}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            formStore.repeatedPassword.onChange(e.currentTarget.value)
          }
          value={formStore.repeatedPassword.value}
          errorText={format(formStore.repeatedPassword.error)}
        />

        <RegisterButton
          primary={true}
          type="submit"
          label={format('register.submit')}
          disabled={hasFieldError || isSubmitting}
        />

        {isSubmitting && (
          <Message>
            <LoadingIndicator
              label={<FormattedMessage id="login.registratingMessage" />}
            />
          </Message>
        )}
      </Form>
    );
  }
}
