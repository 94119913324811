import { inject, observer } from "mobx-react";
import { Component } from "react";
import { Redirect } from "react-router";
import { Route, RouteProps } from "react-router-dom";
import SessionStore from "stores/domain/session";

interface Props extends RouteProps {
  sessionStore?: SessionStore;
}

@inject("sessionStore")
@observer
export default class ProtectedRoute extends Component<Props, {}> {
  render() {
    let { isAuthenticated } = this.props.sessionStore!;

    return (
      <div>
        {isAuthenticated ? (
          <Route {...this.props}>{this.props.children}</Route>
        ) : (
          <Redirect to={{ pathname: "/account/login", state: {} }} />
        )}
      </div>
    );
  }
}
