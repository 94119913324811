import styled from "styled-components";

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto 0px auto;

  @media only screen and (min-width: 768px) {
    padding: 15px;
  }
`;

export default Content;
