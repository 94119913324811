import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Paper } from "material-ui";
import { MenuItem } from "models/menu-item";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { LanguageInt } from "stores/domain/locale";
import styled from "styled-components";
import InformationListItems from "../../../components/InformationListItems";

interface Props {
  selectedItem: MenuItem;
  menuItems: MenuItem[];
  language: LanguageInt;
}
const StyledPaper = styled(Paper)`
  flex: 0 1 200px;
  align-self: flex-start;
  margin-right: 30px;
  font-weight: 400;
`;
const MenuComponent: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const title = props.selectedItem.menuItemContent.find(
    (content) => content.language === props.language
  )!.title;

  return (
    <Box px={6}>
      <Box display={["none", "none", "block"]}>
        <StyledPaper>
          <InformationListItems {...props} includeInformationLink={false} />
        </StyledPaper>
      </Box>
      <Box display={["block", "block", "none"]}>
        <Box mb={4}>
          <Text mb={2} fontWeight={500}>
            <FormattedMessage id="information.mobile.menu.label" />
          </Text>
          <Button
            fontWeight={400}
            leftIcon={<ChevronDownIcon boxSize={6} />}
            onClick={onOpen}
          >
            {title}
          </Button>
        </Box>
      </Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={4}>
          <ModalHeader>
            <FormattedMessage id="information.mobile.menu.label" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InformationListItems
              {...props}
              onClick={onClose}
              includeInformationLink={false}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MenuComponent;
