import { Alert, Box, Text } from "@chakra-ui/react";
import { FC, PropsWithChildren, ReactNode } from "react";
import { HiExclamationCircle, HiInformationCircle } from "react-icons/hi";
import { HiExclamationTriangle } from "react-icons/hi2";
import { FormattedHTMLMessage } from "react-intl";
import styled from "styled-components";

const StyledAlert = styled(Alert)`
  position: relative;

  strong,
  .chakra-button {
    font-weight: 500;
  }

  .chakra-button {
    text-decoration: underline;
  }

  &.warning {
    .chakra-button {
      color: #215463;
    }
  }

  @media (max-width: 500px) {
    padding-right: 3.5rem !important;
    .icon {
      position: absolute;
      top: 1rem;
      right: -0.5rem;
    }
  }
`;

type StatusType = "loading" | "success" | "info" | "warning" | "error";

const statusIcons = (status?: StatusType) => {
  switch (status) {
    case "info":
      return <HiInformationCircle size="1.75rem" color="#121212" />;

    case "warning":
      return <HiExclamationCircle size="1.75rem" color="#121212" />;

    case "error":
      return <HiExclamationTriangle size="1.75rem" color="#121212" />;

    default:
      return <HiInformationCircle size="1.75rem" color="#121212" />;
  }
};

const statusBgs = (status?: StatusType) => {
  switch (status) {
    case "info":
      return "#f2f2f2";

    case "warning":
      return "#F1B44A";

    case "error":
      return "#fed7d7";

    default:
      return "#f2f2f2";
  }
};

const CustomAlert: FC<
  {
    labelKey?: string;
    status?: StatusType;
    icon?: ReactNode;
    values?:
      | {
          [key: string]: ReactIntl.MessageValue | JSX.Element;
        }
      | undefined;
  } & PropsWithChildren
> = ({ labelKey, status = "info", values, children, icon }) => {
  return (
    <StyledAlert
      className={status}
      status={status}
      bgColor={statusBgs(status)}
      py={4}
    >
      <Box pl={2} pr={6} className="icon">
        {icon || statusIcons(status)}
      </Box>
      {children ? (
        children
      ) : (
        <Text fontWeight={400}>
          <FormattedHTMLMessage id={labelKey || ""} values={values} />
        </Text>
      )}
    </StyledAlert>
  );
};

export default CustomAlert;
