import * as React from 'react';
import styled from 'styled-components'; 
import { Paper } from 'material-ui';
import Content from 'components/Content';
import UserTerms from './components/UserTerms';

const Wrapper = styled(Paper)`
  margin-top: 15px;
  padding: 15px;
`;

export default class UserTermsPage extends React.Component<{}, {}> {
  render() {
    return (
      <Content>
        <Wrapper>
          <UserTerms />
        </Wrapper>
      </Content>
    );
  }
}
