import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "assets/fonts/material-icons/material-icons.css";
import ShoppingCartWidget from "components/ShoppingCartWidget";
import { envConfig } from "config";
import "font-awesome/css/font-awesome.css";
import { MuiThemeProvider } from "material-ui/styles";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { Provider } from "mobx-react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "reportWebVitals";
import Routes from "routes";
import App from "scenes/Application";
import stores from "stores";
import { ThemeProvider } from "styled-components";
import MobxRouter from "utils/MobxRouter";
import { supressWarnings } from "utils/supress-warnings";
import Internationalization from "./components/internationalizationProvider";
import "./index.css";

if (envConfig.REACT_APP_ENV === "production") {
  // tslint:disable-next-line:no-console
  console.log("ecster-production loaded");
  import("./vendor/ecster-pay-prod.js" as any);
} else {
  // tslint:disable-next-line:no-console
  console.log("ecster-test loaded");
  import("./vendor/ecster-pay-test.js" as any);
}

const boxTheme = getMuiTheme({
  palette: {
    primary1Color: "#374953",
    accent1Color: "#a7776c",
    accent2Color: "#ffc015",
    textColor: "#5f5f5f",
  },
  button: {
    textTransform: "none",
  },
});

const theme = extendTheme({
  fonts: {
    body: "LabGrotesque, sans-serif",
    heading: "IvarDisplay, Arial",
  },
  colors: {
    brand: {
      100: "#dbc7c3",
      200: "#cdb1ab",
      300: "#bd9991",
      400: "#b38a80",
      500: "#a7776c",
      600: "#976b61",
      700: "#865f56",
      800: "#77554d",
      900: "#654841",
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 300,
      },
      variants: {
        link: {
          color: "#a7776c",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

supressWarnings();

root.render(
  <Provider {...stores}>
    <MuiThemeProvider muiTheme={boxTheme}>
      <ThemeProvider theme={boxTheme}>
        <Internationalization>
          <HelmetProvider>
            <BrowserRouter>
              <MobxRouter>
                <ChakraProvider theme={theme}>
                  <App>
                    <Routes />
                    <ShoppingCartWidget />
                  </App>
                </ChakraProvider>
              </MobxRouter>
            </BrowserRouter>
          </HelmetProvider>
        </Internationalization>
      </ThemeProvider>
    </MuiThemeProvider>
  </Provider>
);

reportWebVitals();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}
