import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { TextField, RaisedButton } from 'material-ui';
import { observer } from 'mobx-react';
import format, { formatToString } from 'utils/format';
import ResetPasswordUiStore from 'stores/ui/account/reset-password';
import LoadingIndicator from 'components/Loading/LoadingIndicator';

interface Props {
  formStore: ResetPasswordUiStore;
  hasFieldError: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const SubmitWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width 100%;
`;

const SubmitButton = styled(RaisedButton)`
  flex: 1 1 auto;
  margin: 15px 0;
`;

const Loading = styled(LoadingIndicator)`
  margin-left: 20px;
`;

const Form = styled.form`
  padding: 15px 15px 60px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0px auto;
`;

const StyledTextField = styled(TextField)`
  width: 100% !important;
`;

@observer
export default class ResetPassword extends Component<Props, {}> {
  render() {
    let {
      formStore: { submitting, email, setUserNotFound },
      hasFieldError,
      onSubmit
    } = this.props;

    return (
      <Form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <h2 className="hidden-xs">{formatToString('reset-password.label')}</h2>
        <span>{formatToString('reset-password.description')}</span>
        <StyledTextField
          type="email"
          floatingLabelText={format('register.email.label')}
          fullWidth={true}
          onChange={(e, value) => {
            setUserNotFound(false);
            return email.onChange(value.trim());
          }}
          value={email.value}
          errorText={format(email.error)}
        />
        <SubmitWrapper>
          <SubmitButton
            primary={true}
            type="submit"
            label={format('reset-password.button')}
            disabled={hasFieldError || submitting}
          />
          {submitting && <Loading />}
        </SubmitWrapper>
      </Form>
    );
  }
}
