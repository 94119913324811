import * as React from 'react';
import { SessionStore } from 'stores';
import { inject, observer } from 'mobx-react';
import { Dialog, List } from 'material-ui';
import VismaUserItem from './VismaUserItem';
import { formatToString } from 'utils/format';
import styled from 'styled-components';
import { LocalVismaUser } from 'models/user';
interface Props {
  sessionStore?: SessionStore;
  title?: string;
  subtitle?: string;
  onPickUser: (vismaUser: LocalVismaUser) => void;
  isOpen: boolean;
}

const Subtitle = styled.p`
  font-size: 16px;
  color: rgba(95, 95, 95, 0.6);
`;

@inject('sessionStore')
@observer
export default class VismaUserPickerContainer extends React.Component<
  Props,
  {}
> {
  get title() {
    const title = this.props.title
      ? this.props.title
      : formatToString('vismaUserPicker.title.default');
    const subtitle = this.props.subtitle
      ? this.props.subtitle
      : formatToString('vismaUserPicker.subtitle.default');
    return (
      <div>
        <p>{title}</p>
        <Subtitle>{subtitle}</Subtitle>
      </div>
    );
  }
  get sessionStore() {
    return this.props.sessionStore!;
  }

  get vismaUsers() {
    if (this.sessionStore.user && this.sessionStore.user.vismaUsers) {
      return this.sessionStore.user.vismaUsers.map(vismaUser => {
        return (
          <VismaUserItem
            key={vismaUser.id}
            vismaUser={vismaUser}
            onClick={() => this.props.onPickUser(vismaUser)}
          />
        );
      });
    }
    return <p>Nu users found</p>;
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        title={this.title}
        autoScrollBodyContent={true}
      >
        <List>{this.vismaUsers}</List>
      </Dialog>
    );
  }
}
