import Api from './api';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';
import CartCask from 'models/cart-cask';
import { Language } from 'stores/domain/locale';

export interface CreateCartDto {
    language: Language;
    country: string;
    amount: number;
    items: CartCask[];
}

export default class CartService {
    static async createCart(cart: CreateCartDto): Promise<JsonApiResponse<string>> {
        let response = await Api.post('cart', true, undefined, cart);
        return await toJsonApiResponse<string>(response);
    }
} 