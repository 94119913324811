import { Box } from "@chakra-ui/react";
import BackButton from "components/BackButton";
import HomeBackground from "components/HomeBackground";
import LayoutWrapper from "components/LayoutWrapper";
import { observer } from "mobx-react";
import { useMedia } from "react-use";
import { formatToString } from "utils/format";
import OrderHeader from "../Bottling/components/OrderHeader";
import FlavourNoteForm from "./components/FlavourNoteForm";

const FlavourNotePage = () => {
  const isMobile = useMedia("(max-width: 600px)");

  return (
    <Box h="full" pos="relative" mt={isMobile ? "50px" : undefined}>
      <HomeBackground />
      <LayoutWrapper>
        <Box
          minW={["full", "full", 1024]}
          maxW={["full", "full", 1024]}
          pt={4}
          pb={8}
        >
          <BackButton />
          <OrderHeader
            title={formatToString("cask.orders.flavour-note.title")}
          />
          <Box display="flex" w="full" h="full">
            <FlavourNoteForm />
          </Box>
        </Box>
        <Box maxW={["full", "full", 512]} w="full" />
      </LayoutWrapper>
    </Box>
  );
};

export default observer(FlavourNotePage);
