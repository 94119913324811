import Title from "components/Title";
import { Card, CardMedia, CardText } from "material-ui/Card";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";

export interface OrderCardProps {
  baseRoute: string;
  linkRoute: string;
  titleId: string;
  textId: string;
  image: {};
  enabled: boolean;
}

const Wrapper = styled(Card)`
  height: 350px;
  margin-bottom: 20px;
`;

const EnabledWrapper = styled(Wrapper)`
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  }
`;

const DisabledWrapper = styled(Wrapper)`
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
  }
`;

const OrderCardTitle = styled(Title)`
  text-align: center;
`;

const CardImageWrapper = styled(CardMedia)`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const imageStyle = {
  width: "initial",
  minWidth: "initial",
};

const createCard = (image: {}, titleId: string, textId: string) => (
  <span>
    <CardImageWrapper>
      <img src={image.toString()} alt="" style={imageStyle} />
    </CardImageWrapper>
    <CardText style={{ fontSize: "12px" }}>
      <OrderCardTitle>
        <FormattedMessage id={titleId} />
      </OrderCardTitle>
      <FormattedMessage id={textId} />
    </CardText>
  </span>
);

const OrderCard: React.FC<OrderCardProps> = (props: OrderCardProps) => {
  let { baseRoute, linkRoute, titleId, textId, image, enabled } = props;
  return enabled === true ? (
    <Link to={`${baseRoute}${linkRoute}`} className="link--unstyled">
      <EnabledWrapper>{createCard(image, titleId, textId)}</EnabledWrapper>
    </Link>
  ) : (
    <DisabledWrapper>{createCard(image, titleId, textId)}</DisabledWrapper>
  );
};

export default OrderCard;
