import { Box, Slide, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { MdShoppingCart } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { useStores } from "stores";
import BrandButton from "./BrandButton";

const ShoppingCartWidget = () => {
  const { shoppingCartStore } = useStores();
  const { push } = useHistory();
  const location = useLocation();

  return (
    <>
      <Slide
        direction="bottom"
        in={
          !!shoppingCartStore.totalCartCasksCount &&
          !location.pathname.includes("cart")
        }
        style={{ zIndex: 998 }}
      >
        <Box display="flex" px={8} py={4} justifyContent="center">
          <BrandButton
            large
            pos="relative"
            leftIcon={<MdShoppingCart size="1.25rem" />}
            colorScheme="brand"
            onClick={() => push("/shop/cart")}
            size="lg"
            w={["full", "auto"]}
          >
            <Box display="flex" alignItems="center" pr={6}>
              <FormattedMessage id="shopping-cart.widget.title" />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgColor="blackAlpha.400"
                borderRadius={9999}
                pos="absolute"
                right={4}
                top={2}
                bottom={2}
                w={8}
              >
                <Text fontSize="md">
                  {shoppingCartStore.totalCartCasksCount}
                </Text>
              </Box>
            </Box>
          </BrandButton>
        </Box>
      </Slide>
    </>
  );
};

export default observer(ShoppingCartWidget);
