import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import * as React from "react";
import { BottlePriceListUiStore, BottlingPriceVariablesStore } from "stores";
import styled from "styled-components";
import format from "utils/format";
import ExamplePriceWindow from "../../Cask/scenes/Orders/scenes/Bottling/scenes/delivery/components/ExamplePriceWindow";

const dialogContentStyle = {
  width: "100%",
};

const CancelButtonWrapper = styled.div`
  margin-top: 25px;
  text-align: center;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  loanAmount: number;
  bottlingPriceVariablesStore?: BottlingPriceVariablesStore;
  bottlePriceListUiStore?: BottlePriceListUiStore;
}

const numberOfBottles = 68;
const alcoholContent = 60;

export default class PricingExampleModal extends React.Component<Props, {}> {
  render() {
    return (
      <Dialog
        contentStyle={dialogContentStyle}
        modal={true}
        open={this.props.isOpen}
        autoScrollBodyContent={true}
      >
        <ExamplePriceWindow
          numberOfBottles={numberOfBottles}
          alcoholContent={alcoholContent}
          loanClaimExists={false}
          loanAmount={this.props.loanAmount}
          bottlePriceListUiStore={this.props.bottlePriceListUiStore}
          bottlingPriceVariablesStore={this.props.bottlingPriceVariablesStore}
        />

        <CancelButtonWrapper>
          <RaisedButton
            label={format("caskShop.priceExampleCancel")}
            primary={false}
            onClick={this.props.onClose}
          />
        </CancelButtonWrapper>
      </Dialog>
    );
  }
}
