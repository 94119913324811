import { Box, Button, Flex } from "@chakra-ui/react";

import logo from "assets/images/logo.svg";
import { observer } from "mobx-react";
import { useLocation } from "react-router";
import { useWindowScroll, useWindowSize } from "react-use";
import { useStores } from "stores";
import styled from "styled-components";
import LanguagePicker from "./LanguagePicker";
import Menu from "./Menu";
import ProfileButton from "./ProfileButton";

const Wrapper = styled(Box)`
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1001;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  border-bottom: 1px solid #e2e8f0;

  .logo-wrapper {
    position: absolute;
    top: 10px;
    left: 50%;

    .logo {
      position: relative;
      left: -50%;
      height: 80px;
      width: 76px;
    }
  }

  @media (max-width: 48em) {
    height: 60px;
    padding: 0 1.5rem;
    justify-content: flex-start;
    flex-direction: row-reverse;

    .logo-wrapper {
      top: 5px;
      left: 1.5rem;

      .logo {
        left: 0;
        height: 54px;
        width: 43px;
      }
    }
  }

  &.hide-border {
    border-bottom: none;
  }
`;

const Navbar = () => {
  const { sessionStore } = useStores();
  const location = useLocation();
  const { y } = useWindowScroll();
  const { height } = useWindowSize();
  const hideNavbarBorder =
    !sessionStore.isAuthenticated &&
    location.pathname === "/" &&
    y < height / 2;

  return (
    <Wrapper className={hideNavbarBorder ? "hide-border" : undefined}>
      <Menu />
      <Button
        variant="unstyled"
        className="logo-wrapper"
        onClick={() =>
          (window.location.href = "https://www.highcoastwhisky.se/")
        }
      >
        <img className="logo" src={logo.toString()} alt="logo" />
      </Button>
      <Flex>
        <ProfileButton />
        <Box display={["none", "none", "flex"]} alignItems="center">
          <LanguagePicker />
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default observer(Navbar);
