import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import Event from "models/event";
import { SamplingOrderType } from "models/sampling-order";
import moment from "moment-timezone";
import OrdersService, { OrderSamplingVm } from "services/orders";
import { localeStore } from "stores/domain/locale";
import { sessionStore } from "stores/domain/session";
import { caskUiStore } from "stores/ui/cask";
import {
  isEnum,
  minNumber,
  required,
  requiredType,
} from "utils/forms/validators";

type DistilleryForm = {
  orderType: FieldState<SamplingOrderType | undefined>;
  distilleryDate: FieldState<Date | undefined>;
  amount: FieldState<number>;
};

type EventForm = {
  orderType: FieldState<SamplingOrderType | undefined>;
  event: FieldState<Event | undefined>;
  amount: FieldState<number>;
};

type SystembolagetForm = {
  orderType: FieldState<SamplingOrderType | undefined>;
  systembolagetRequestNumber: FieldState<string>;
};

class SamplingOrderUiStore {
  @observable
  orderId!: string;
  @observable
  orderIsComplete!: boolean;
  @observable
  sendingOrder!: boolean;
  @observable
  errorMessage!: string;

  @observable orderType = new FieldState<SamplingOrderType | undefined>(
    undefined
  ).validators(
    isEnum<SamplingOrderType | undefined>(
      "orders.sampling.orderType.error.required"
    )
  );

  constructor() {
    makeObservable(this);
  }

  get distilleryMinDate() {
    let minDate = moment(new Date());
    minDate.add(3, "days");
    return minDate.toDate();
  }

  @observable distilleryDate = new FieldState<Date | undefined>(
    undefined
  ).validators(
    requiredType<Date | undefined>(
      "orders.sampling.distillery.date.error.required"
    )
  );

  @computed get distilleryDateTime(): Date | undefined {
    if (
      this.distilleryDate === undefined ||
      this.distilleryDate.$ === undefined
    ) {
      return;
    }

    let dateTime = moment(this.distilleryDate.$);
    let time = moment("11:00", "H:mm");
    dateTime.set("hours", time.get("hours"));
    dateTime.set("minutes", time.get("minutes"));
    return dateTime.toDate();
  }

  @observable event = new FieldState<Event | undefined>(undefined).validators(
    requiredType<Event | undefined>("orders.sampling.event.name.error.required")
  );

  @observable amount = new FieldState<number>(3).validators(
    requiredType<number>("orders.sampling.event.amount.error.required"),
    minNumber(2, "orders.sampling.event.amount.error.minNumber")
  );

  @observable systembolagetRequestNumber = new FieldState<string>(
    ""
  ).validators(
    required("orders.sampling.systembolaget.step4.requestNumber.error.required")
  );

  @computed get amountPrice() {
    return this.amount.value * 10;
  }

  @observable distilleryForm = new FormState<DistilleryForm>({
    orderType: this.orderType,
    distilleryDate: this.distilleryDate,
    amount: this.amount,
  });

  @observable eventForm = new FormState<EventForm>({
    orderType: this.orderType,
    event: this.event,
    amount: this.amount,
  });

  @observable systembolagetForm = new FormState<SystembolagetForm>({
    orderType: this.orderType,
    systembolagetRequestNumber: this.systembolagetRequestNumber,
  });

  @computed get currentFormType() {
    let orderTypeField = this.orderType;
    if (orderTypeField === undefined) {
      return;
    }

    if (orderTypeField.value === SamplingOrderType.Distillery) {
      return this.distilleryForm;
    } else if (orderTypeField.value === SamplingOrderType.Event) {
      return this.eventForm;
    } else if (orderTypeField.value === SamplingOrderType.Systembolaget) {
      return this.systembolagetForm;
    }
    return;
  }

  @computed get formData(): OrderSamplingVm {
    let orderTypeField = this.orderType;
    if (orderTypeField === undefined) {
      return <OrderSamplingVm>{};
    }

    let order: OrderSamplingVm = {
      caskId: caskUiStore.caskId,
      type: this.orderType.$,
      language: localeStore.language,
      localVismaUserId: sessionStore.vismaUser.id,
    };

    if (orderTypeField.value === SamplingOrderType.Distillery) {
      order.distilleryDateTime = this.distilleryDateTime;
      order.amount = this.amount.$;
    } else if (orderTypeField.value === SamplingOrderType.Event) {
      order.amount = this.amount.$;
      order.eventId = this.event.$!.id;
    } else if (orderTypeField.value === SamplingOrderType.Systembolaget) {
      order.systembolagetRequestNumber = this.systembolagetRequestNumber.$;
    }

    return order;
  }

  @computed get formIsValid() {
    return (
      this.currentFormType !== undefined && !this.currentFormType.hasFieldError
    );
  }

  @action
  async submitForm() {
    if (this.currentFormType === undefined) {
      return;
    }

    let res = await this.currentFormType.validate();
    if (res.hasError) {
      return;
    }

    this.sendingOrder = true;
    try {
      let orderResponse = await OrdersService.orderSampling(this.formData);
      if (orderResponse.hasErrors) {
        this.sendingOrder = false;
        this.errorMessage = "orders.sampling.error.unknon";
        return false;
      } else {
        this.orderIsComplete = true;
        this.orderId = orderResponse.data.order.guid;
        this.sendingOrder = false;
        return true;
      }
    } catch (error) {
      this.sendingOrder = false;
      this.errorMessage = "orders.sampling.error.unknon";
      return false;
    }
  }

  resetForm() {
    this.orderIsComplete = false;
    this.sendingOrder = false;
    this.orderType.reset();
    this.distilleryDate.reset();
    this.event.reset();
    this.amount.reset();
    this.systembolagetRequestNumber.reset();
  }
}

export default SamplingOrderUiStore;
export const samplingOrderUiStore = new SamplingOrderUiStore();
