import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import OrdersService, { OrderFlavourNoteVm } from "services/orders";
import { localeStore } from "stores/domain/locale";
import { sessionStore } from "stores/domain/session";
import { caskUiStore } from "stores/ui/cask";

export enum FlavourNoteLanguage {
  Swedish,
  English,
}

type Form = {
  obtainedLanguage: FieldState<FlavourNoteLanguage>;
};

class FlavourNoteOrderUiStore {
  @observable
  orderId!: string;
  @observable
  orderIsComplete!: boolean;
  @observable
  sendingOrder!: boolean;
  @observable
  errorMessage!: string;

  obtainedLanguage: FieldState<FlavourNoteLanguage> =
    new FieldState<FlavourNoteLanguage>(FlavourNoteLanguage.Swedish);

  @observable form = new FormState<Form>({
    obtainedLanguage: this.obtainedLanguage,
  });

  constructor() {
    makeObservable(this);
  }

  @computed get formData(): OrderFlavourNoteVm {
    let order: OrderFlavourNoteVm = {
      caskId: caskUiStore.caskId,
      obtainedLanguage: this.obtainedLanguage.$,
      language: localeStore.language,
      localVismaUserId: sessionStore.vismaUser.id,
    };
    return order;
  }

  @action
  async submitForm() {
    if (this.form === undefined) {
      return;
    }

    let validation = await this.form.validate();
    if (validation.hasError) {
      return;
    }

    this.sendingOrder = true;
    try {
      let orderResponse = await OrdersService.orderFlavourNote(this.formData);
      if (orderResponse.hasErrors) {
        this.sendingOrder = false;
        this.errorMessage = "orders.flavourNote.error.unknon";
        return false;
      } else {
        this.orderIsComplete = true;
        this.orderId = orderResponse.data.order.guid;
        this.sendingOrder = false;
        return true;
      }
    } catch (error) {
      this.sendingOrder = false;
      this.errorMessage = "orders.sampling.error.unknon";
      return false;
    }
  }

  resetForm() {
    this.orderIsComplete = false;
    this.sendingOrder = false;
    this.obtainedLanguage.reset();
  }
}

export default FlavourNoteOrderUiStore;
export const flavourNoteOrderUiStore = new FlavourNoteOrderUiStore();
