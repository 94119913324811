import CountryPicker from "components/CountryPickerContainer";
import Title from "components/Title";
import { envConfig } from "config";
import { RaisedButton } from "material-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { HOST } from "services/api";
import LocaleStore, { Language } from "stores/domain/locale";
import SessionStore from "stores/domain/session";

interface Props {
  sessionStore?: SessionStore;
  localeStore?: LocaleStore;
}

interface ExtendedProps extends Props, InjectedIntlProps {}

interface State {
  json?: Object;
  error?: string;
  files?: File[];
}

@inject("sessionStore", "localeStore")
@observer
class DevPage extends Component<ExtendedProps, State> {
  constructor() {
    super({} as any);
    this.state = {
      json: {},
    };
  }

  login() {
    this.props.sessionStore!.logIn("elsa@boxwhisky.se", "elsa123");
  }

  async getValue() {
    let { accessToken } = this.props.sessionStore!;
    let response = await fetch(`${HOST}/api/values/auth`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok && response.status === 401) {
      // if WWW-Authenticate header is sent in,
      // then the token is probably invalid

      if (response.headers.has("WWW-Authenticate")) {
        this.props.sessionStore!.logOut();
        return;
      }

      this.setState({ error: "Authentication failed" });
      return;
    }

    let json = await response.json();
    this.setState({ json, error: undefined });
  }

  toggleLanguage(evt: React.MouseEvent<HTMLButtonElement>) {
    evt.preventDefault();
    let localeStore = this.props.localeStore!;

    if (localeStore.language === Language.English) {
      localeStore.changeLanguage(Language.Swedish);
      return;
    }

    localeStore.changeLanguage(Language.English);
  }

  render() {
    let json = JSON.stringify(this.state.json);
    let i18nMessage = this.props.intl!.formatMessage({
      id: "settings.message",
    });
    let host = envConfig.REACT_APP_API_HOST;

    return (
      <div>
        <Title className="hidden-xs">
          <FormattedMessage id="settings.title" />
        </Title>

        <CountryPicker autoWidth={true} />
        <div>{i18nMessage}</div>
        <div>
          Env: {envConfig.REACT_APP_ENV} <br />
          Host: {host}
        </div>

        <div>
          {this.props.sessionStore!.isAuthenticated ? (
            <RaisedButton
              label="Log out"
              onClick={() => this.props.sessionStore!.logOut()}
            />
          ) : (
            <RaisedButton
              primary={true}
              label="Login"
              onClick={() => this.login()}
            />
          )}
        </div>

        <br />
        <br />
        <RaisedButton
          label="Get protected values"
          onClick={() => this.getValue()}
        />

        <div>
          {this.props.sessionStore!.isAuthenticated
            ? "inloggad"
            : "ej inloggad"}
        </div>

        <div>
          Response: <br />
          {this.state.error || json}
        </div>

        <button
          onClick={(evt) => {
            this.toggleLanguage(evt);
          }}
        >
          Toggle Language
        </button>

        <br />
        <hr />
        <RaisedButton
          label="Change name"
          onClick={() => {
            this.props.sessionStore!.user.firstName += "a";
          }}
        />

        {this.props.sessionStore!.user &&
          this.props.sessionStore!.user.firstName +
            " " +
            this.props.sessionStore!.user.lastName}
        <hr />

        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default injectIntl<Props>(DevPage);
