import { action, makeObservable, observable } from "mobx";
import Cask from "models/cask";
import { LocalVismaUser } from "models/user";
import CasksService from "services/casks";
import { casksStore } from "stores/domain/casks";

class CaskSelectUiStore {
  @observable showUserSelect = false;
  @observable errors: string[] = [];
  @observable loading: boolean = false;
  @observable selectedCask: Cask | undefined;
  @observable selectedVismaUser: LocalVismaUser | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  async setVismaMember() {
    const { selectedCask, selectedVismaUser } = this;
    if (!selectedCask || !selectedVismaUser) {
      return;
    }
    this.errors = [];
    this.loading = true;
    try {
      const response = await CasksService.setVismaMember({
        LocalVismaUserId: selectedVismaUser.id,
        caskId: selectedCask.id,
      });
      if (!response.succeeded) {
        this.errors = [];
        for (let error of response.errors) {
          switch (error.code) {
            case "UserNotFoundException":
            case "VismaUserNotFoundException":
              this.errors.push("caskSelect.error.userNotFound");
              break;
            case "UserNotAddedAsCaskMemberException":
              this.errors.push("caskSelect.error.userNotAdded");
              break;
            default:
              this.errors.push("caskSelect.error.unknown");
          }
        }
      } else {
        casksStore.updateCask(response.data);
      }
    } catch (e) {
      this.errors = ["caskSelect.error.unknown"];
    } finally {
      this.selectedCask = undefined;
      this.selectedVismaUser = undefined;
      this.loading = false;
    }
  }
}
export default CaskSelectUiStore;
export const caskSelectUiStore = new CaskSelectUiStore();
