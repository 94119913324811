export default {
  "header.logIn.label": "Log in / Register",
  "header.logOut.label": "Log out",
  "header.resetPassword.label": "Återställ lösenord",
  "header.register.label": "Register",
  "header.newMember.label": "Register",
  "mobileDrawer.menu.label": "Menu",
  "navbar.home.label": "Home",
  "navbar.caskShop.label": "Buy cask",
  "navbar.information.label": "Information",
  "navbar.myCasks.label": "My Casks",
  "navbar.myCasks.noCasks": "No casks ordered yet!",
  "navbar.orderNow.label": "Order now",
  "navbar.settings.label": "Settings",
  "navbar.switchAccount.label": "Switch account",
  "navbar.login.label": "Log in",
  "home.title": "My casks",
  "home.error":
    "An error has occurred! Please contact our support if the problem remains.",

  // Login

  "login.tab.title": "Log in",
  "login.loggingInMessage": "Logging in...",
  "login.fetchingUserDataMessage": "Fetching user data...",
  "login.whatIsNeededText":
    "You need to have registered an account and verified your email to log in.",
  "login.requestVerificationEmailLink": "Request new verification email",
  "login.email.label": "Email",
  "login.email.error.required": "Enter your email",
  "login.email.error.invalidEmail": "Enter a valid email",
  "login.password.label": "Password",
  "login.password.error.required": "Enter your password",
  "login.submit.label": "Log in",
  "login.error.authentication":
    "The email or password you entered was incorrect. Ensure your email is confirmed if password reset does not work.",
  "login.resetPassword.text": "Forgot password?",

  // Home

  "home.hero.slogan": "Nordic whisky",
  "home.hero.slogan2": "with a personal flavour",
  "home.hero.becomeCaskOwnerButton.label": "Get your own cask today!",
  "home.hero.welcome": "Welcome to the Cask owner web!",
  "home.hero.welcomeText": `
        Here you can buy, store, sample and bake your own unique whisky that is produced and stored in a
        unique environment at the same time as you get help and support from world-leading whisky experts.
    `,

  "home.instructions.title": "How it works",
  "home.instructions.shopLinkText": "Buy Cask",
  "home.instructions.step1": `
        Check out our selection of casks and buy your own by
        clicking the link {link}. The cask itself costs about half the amount,
        and the other half is payed to us as a loan which we will repay when it’s time
        for you to bottle your whisky.
    `,
  "home.instructions.step2": `
        When we have recieved your payment you will become registered as a
        cask owner and will be able to view your cask through this site.
    `,
  "home.instructions.step3": `
        Your cask is filled at our destillery in Bjärtrå, Sweden.
        If you like, you can participate in the filling.
    `,
  "home.instructions.step4": `
        Your cask is stored at our destillery for at least three years and you get a free sample
        every year so you can follow the development and maturity of your whisky.
    `,
  "home.instructions.step5": `
        You decide when it’s time for us to bottle your whisky.
        Either you do it in batches, or you bottle everything all at once.
    `,
  "home.instructions.step6": `
        Your loan is repaid in full when you empty your cask, and you can use
        the money to collect your bottles from an approved retailer.
        (Note that alcohol tax, VAT and retailer markup will be added to the
        final cost when you collect your bottles.)
    `,
  "home.instructions.step7": `
        The emptied cask is yours to keep. Decide whether you want it delivered to
        your home or if you want a refill.
    `,
  "home.instructions.step8": `
        Time to enjoy your own, completely unique malt whisky.
    `,

  "home.aboutBox.title": "High Coast Distillery",
  "home.aboutBox.text": `
        Plenty of distilleries are producing amazing whisky, but some of them
        manage to outperform the competition. As always, there is no right
        or wrong when it comes to taste, but quality, precision, and surrounding
        conditions separate distilleries from each other. Some of the things
        that makes High Coast Distillery unique is the nordic climate, the
        river running close by, and the meticulousness we put in to the
        distillation process.
    `,

  // Footer
  "footer.followUs": "Follow Us",
  "footer.contactUs": "Contact Us",

  // Stepper
  "stepper.mobile.step": "Step {currentStep} of {maxStep}",

  // Cask shop
  "caskShop.title": "Buy cask",
  "caskShop.add.cart.label": "Add to shopping cart",
  "caskShop.preStored.2.year.label": "Pre stored 2-year destillate",
  "caskShop.extraPrice": "(+ {price}, incl. VAT)",
  "caskShop.caskAddedToCart": "The cask has been added to your shopping cart",
  "caskShop.priceExample": "Show pricing example",
  "caskShop.priceExampleCancel": "Close",
  "caskShop.instructions": "How it works",
  "caskShop.instructionsCancel": "Close",

  // Cask shop / Cart
  "caskShop.cart.title": "Your shopping cart",
  "caskShop.cart.empty.text":
    "Your shopping cart is empty, but that is easily fixed!",
  "caskShop.cart.shop.button.label": "Find Whisky that fits you",
  "caskShop.cart.shop.findWhiskybutton.label": "See our casks",

  "caskShop.cart.stepper.cartView": "Shopping cart",
  "caskShop.cart.stepper.cartOptions": "Options",
  "caskShop.cart.stepper.cartPayment": "Summary & payment",

  // Cask shop / Cart / View
  "caskShop.cart.view.cost.label": "Cost",
  "caskShop.cart.view.cost.loan.label": "Loan to High Coast Distillery:",
  "caskShop.cart.view.cost.loan.tooltip": `
        Because of current laws it's not allowed to sell the content of the cask directly to you. That's why
        we loan money from you which we pay back the day you choose to bottle and fetch your whisky. Bottling
        is done though a licensed retailer (Systembolaget if you choose to pick up the whisky in Sweden).
    `,
  "caskShop.cart.view.cost.cask.vatIncluded.label": "Cask cost incl. tax:",
  "caskShop.cart.view.cost.cask.vatExcluded.label": "Cask cost excl. tax:",
  "caskShop.cart.view.cost.tax.label": "Tax:",
  "caskShop.cart.view.cost.prestored.label": "Pre stored:",
  "caskShop.cart.view.cost.total.label": "Total:",
  "caskShop.cart.view.emptyCart.label": "Empty cart",
  "caskShop.cart.view.cancelButton": "Cancel",

  // Cask shop / Cart / Options
  "caskShop.cart.options.caskSign.label": "Cask sign",
  "caskShop.cart.options.partOwner.label": "Cask part owner",
  "caskShop.cart.options.partOwner.add.label": "Add part owner",
  "caskShop.cart.options.caskFilling.label": "Cask filling",
  "caskShop.cart.options.person.information.label": "Your information",
  "caskShop.cart.options.company.information.label":
    "The companies information",
  "caskShop.cart.options.takePart.description":
    "I want to take part when my cask/casks " +
    "are filled and wish to be contacted about this.",
  "caskShop.cart.options.freeTshirt.label": "Free t-shirt!",
  "caskShop.cart.options.freeTshirt.description": `As a cask owner you get a free t-shirt. Select your size.
      (If you want to buy more shirts, contact the cask owner web on: info@highcoastwhisky.se)`,

  // Cask shop / Cart / Logged out
  "caskShop.cart.loggedOut.login": "Already have an account?",
  "caskShop.cart.loggedOut.register": "Not registered?",
  "caskShop.cart.loggedOut.register.text":
    "Create an account to complete your purchase.",

  // Cask shop / Cart / Payment
  "caskShop.cart.payment.caskSign.column": "Cask sign:",
  "caskShop.cart.payment.partOwner.column": "Cask part owner:",
  "caskShop.cart.payment.caskFilling.column": "Cask filling:",
  "caskShop.cart.payment.filling.takePart": "I wish to participate",
  "caskShop.cart.payment.filling.not.takePart": "I do not want to participate",
  "caskShop.cart.payment.freeTshirt.column": "T-shirt size:",
  "caskShop.cart.payment.title": "Select payment method",
  "caskShop.cart.payment.option.invoice": "Invoice",
  "caskShop.cart.payment.option.cardInvoicePartPayment":
    "Credit card / Invoice / Part Payment",

  "caskShop.cart.payment.invoice.selected":
    "An invoice from Box will be sent to your email.",
  "caskShop.cart.payment.error.unkown":
    "An unknown error has occurred. Please try again later. Contact support if the problem persists",

  // Cask shop / Steps
  "caskShop.steps.confirm": "Place order",
  "caskShop.steps.cancel": "Cancel",
  "caskShop.steps.back": "Back",
  "caskShop.steps.next": "Next",

  // Cask shop / complete
  "caskShop.complete.orderCompleteText":
    "Your casks have now been ordered! " +
    "A confirmation has been sent to {email}.",
  "caskShop.complete.backButton": "Go to your cask",
  "caskShop.complete.title": "Thank you for your order!",
  "caskShop.complete.orderIdLabel": "Order id:",

  // Cask

  "cask.tabs.about": "About this Cask",
  "cask.tabs.orders": "Order",
  "cask.tabs.history": "History",
  "cask.info.dateCreated": "Created",
  "cask.info.dateCreated.unknown": "Unknown",
  "cask.info.flavourNote": "Flavour note",
  "cask.info.bottlingDate": "Bottling",
  "cask.info.notBottled": "Has never been bottled",
  "cask.info.notFlavoured": "Has no flavour note",
  "cask.unknown": "Unknown cask",
  "cask.isEmptied": "Emptied",
  "cask.recipe.unknown": "Unknown recipe",
  "cask.info.storageTime": "Storage time",
  "cask.info.hasBeenEmptied": "Cask has been emptied",
  "cask.info.fullbottlingOrdered": "Full bottling has been ordered",
  "cask.info.notFilled": "Has not been filled",
  "cask.info.ownerName": "Cask owner",
  "cask.info.download.caskOwnerProof": "Download cask owner proof",
  "cask.info.fileName.caskOwnerProof": "cask-owner-proof.pdf",
  "cask.info.download.orderSettlementLatest": "Download latest settlement",
  "cask.info.download.orderSettlement": "Download settlement",
  "cask.info.fileName.orderSettlement": "settlement.pdf",

  "cask.info.age.today": "filled today",
  "cask.info.age":
    "{years, plural, =0 {} one {{years} year} other {{years} years}} " +
    "{months, plural, =0 {} one {{months} month} other {{months} months}} " +
    "{days, plural, =0 {} one {{days} day} other {{days} days}}",

  // Cask / About
  "cask.info.caskNumber.label": "Cask number",
  "cask.info.caskSign.label": "Cask sign",
  "cask.info.unknown":
    "More information will be added when your cask is filled",
  "cask.info.caskSize.label": "Cask size",
  "cask.info.caskSize.liter.unit": "Litre",
  "cask.info.caskType.label": "Cask type",
  "cask.info.caskRecipe.label": "Recipe",
  "cask.info.volumeFilled.label": "Cask volume filled",
  "cask.info.stockNumber.label": "Stock number",
  "cask.info.caskLoan.label": "Claim on Box to get back at complete bottling",
  "cask.info.caskPrice.label": "Cask cost (excl. tax)",
  "cask.info.alcoholPercentage.label": "Alcohol percentage when filled",
  "cask.info.filled.label": "Filled to cask",
  "cask.info.liquorFilled.label": "Liquor date",

  // Cask / Orders

  "orders.sendingOrderMessage": "Sending order...",
  "orders.orderPage.bottling.title": "Order bottling",
  "orders.orderPage.bottling.text": `
        Choose yourself when you want to pick up your bottles.
    `,

  "orders.orderPage.sampling.title": "Order sampling",
  "orders.orderPage.sampling.text": `
        Sample your whisky on the distillery in Bjärtrå, on an event or
        by picking up your sample on Systembolaget.
    `,

  "orders.orderPage.flavourNote.title": "Order flavour note",
  "orders.orderPage.flavourNote.text": `
        Let our quality managers try your whisky and give you taste notes and an estimate of remaining maturation time.
    `,

  // Cask / Orders / Bottling Order

  "orders.bottlingOrder.title": "Order bottling",

  "orders.bottlingOrder.stepper.bottlingInformation": "Bottling Information",
  "orders.bottlingOrder.stepper.delivery": "Delivery",
  "orders.bottlingOrder.stepper.label": "Bottle Label",
  "orders.bottlingOrder.stepper.boxes": "Boxes",
  "orders.bottlingOrder.stepper.afterBottling": "After Bottling",
  "orders.bottlingOrder.stepper.contactInformation": "Contact Information",
  "orders.bottlingOrder.stepper.confirm": "Confirmation",

  "orders.bottlingOrder.backButton": "Previous",
  "orders.bottlingOrder.cancelButton": "Cancel",
  "orders.bottlingOrder.nextButton": "Next",
  "orders.bottlingOrder.confirmButton": "Confirm",

  "orders.bottlingOrder.isSwedishBankAccount.label":
    "Bank type (Loan repayment)",
  "orders.bottlingOrder.isSwedishBankAccount.infoWindowText": `When you bought your cask a part of the price
     amount was reserved as a loan to us. We are now paying back this loan so that you can use the money to
     buy the whisky from your selected delivery location.`,
  "orders.bottlingOrder.isSwedishBankAccount.true": "Swedish bank",
  "orders.bottlingOrder.isSwedishBankAccount.false": "Foreign bank",
  "orders.bottlingOrder.clearingNumber.label": "Clearing number",
  "orders.bottlingOrder.clearingNumber.error.required":
    "Clearing number is required",
  "orders.bottlingOrder.clearingNumber.error.maxLength": `
      The clearing number can't have more than {maxLength} characters`,
  "orders.bottlingOrder.swedishBankAccountNumber.label": "Bank account number",
  "orders.bottlingOrder.swedishBankAccountNumber.error.required":
    "Account number is required",
  "orders.bottlingOrder.swedishBankAccountNumber.error.minLength":
    "The account number must be at least {minLength} digits",
  "orders.bottlingOrder.swedishBankAccountNumber.error.maxLength": `
      The bank account number can't have more than {maxLength} characters`,
  "orders.bottlingOrder.foreignBankAccountNumber.label": "IBAN account number",
  "orders.bottlingOrder.foreignBankAccountNumber.error.required":
    "IBAN account number is required",
  "orders.bottlingOrder.foreignBankAccountNumber.error.minLength":
    "The account number must be at least {minLength} characters",
  "orders.bottlingOrder.foreignBankAccountNumber.error.maxLength": `
      The bank account number can't have more than {maxLength} characters`,

  "orders.bottlingOrder.bottlingAmount.label": "Bottling Amount",
  "orders.bottlingOrder.bottlingAmount.dropdown.fullBottling": "Full Bottling",
  "orders.bottlingOrder.bottlingAmount.dropdown.partBottling": "Part Bottling",
  "orders.bottlingOrder.bottlingAmount.note": `Note: An extra fee of {amount} SEK comes with part bottling.
      To ensure quality, the last partial bottling should contain at least half the cask.`,
  "orders.bottlingOrder.numberOfBottles.label": "Number of Bottles",
  "orders.bottlingOrder.numberOfBottles.bottles": "{number} Bottles",
  "orders.bottlingOrder.useDefaultAlcoholContent.label": "Alcohol Amount",
  "orders.bottlingOrder.useDefaultAlcoholContent.true": "Default",
  "orders.bottlingOrder.useDefaultAlcoholContent.false":
    "Specify alcohol content",
  "orders.bottlingOrder.alcoholContent.hint": "Choose desired alcohol content",
  "orders.bottlingOrder.diluteTo": "Dilute to",
  "orders.bottlingOrder.alcoholContent.label": "Alcohol by volume (%)",
  "orders.bottlingOrder.alcoholContent.error.required":
    "Alcohol content value is required",
  "orders.bottlingOrder.alcoholContent.error.minValue":
    "Lowest allowed value is {minValue}%",
  "orders.bottlingOrder.alcoholContent.note": `Note! An extra fee of {amount}
      SEK comes with changing the alcohol content. The lowest allowed value is 46% (50% for sherry casks).`,
  "orders.bottlingOrder.country.label": "Country to receive order",
  "orders.bottlingOrder.country.swedenSystembolaget": "Sweden (Systembolaget)",
  "orders.bottlingOrder.country.sweden": "Sweden",
  "orders.bottlingOrder.country.denmark": "Denmark",
  "orders.bottlingOrder.country.estonia": "Estonia",
  "orders.bottlingOrder.country.germany": "Germany",
  "orders.bottlingOrder.countryDescriptionLink":
    "More about the delivery location",
  "orders.bottlingOrder.date.pickup.label": "Delivery pickup from",
  "orders.bottlingOrder.date.label": "Date to receive order",
  "orders.bottlingOrder.date.error.required": "Date is required",
  "orders.bottlingOrder.customization.label": "Customize Label",
  "orders.bottlingOrder.customization.oldLabel": "BOX Label",
  "orders.bottlingOrder.customization.none": "Standard",
  "orders.bottlingOrder.customization.personalText": "Add personal text",
  "orders.bottlingOrder.customization.file": "Add personal logo",
  "orders.bottlingOrder.customization.file.error.required":
    "A file must be selected",
  "orders.bottlingOrder.customization.uploadButtonLabel": "Select file",
  "orders.bottlingOrder.labelText.label": "Bottle Label Text",
  "orders.bottlingOrder.labelText.placeholder": "Enter label text",
  "orders.bottlingOrder.labelText.error.maxLength": `The label can't have more than {maxLength} characters`,

  "orders.bottlingOrder.label.managerDescription": `Here you can customize your own personal bottle labels.
        The first label is free, thereafter every new label costs {amount} SEK. <br /> <br />
        An identical label is included for each bottle. If you choose multiple labels, you can adjust the distribution of the labels as you wish.`,
  "orders.bottlingOrder.label.rule.name.single": "{numberOfLabels} label:",
  "orders.bottlingOrder.label.rule.name.multiple": "{numberOfLabels} labels:",
  "orders.bottlingOrder.label.rule.description.one":
    "The label will be on all bottles",
  "orders.bottlingOrder.label.rule.description.two": `50% of the bottles gets the first label and
        50% gets the second`,
  "orders.bottlingOrder.label.rule.description.three": `33% of the bottles gets the first label, 33% gets the second
        and 33% gets the third`,
  "orders.bottlingOrder.label.preview": `Preview`,
  "orders.bottlingOrder.label.title.first":
    "Label {labelNumber} (First label is free)",
  "orders.bottlingOrder.label.title.subsequent":
    "Label {labelNumber} ({amount} SEK)",
  "orders.bottlingOrder.label.add": "Add label",
  "orders.bottlingOrder.label.total": "Total amount for labels:",

  "orders.bottlingOrder.label.additionalInfo.explanation": `
        If you have any special requests regarding labels, please write them below:
    `,
  "orders.bottlingOrder.label.additionalInfo.label": "Special requests",

  "orders.bottlingOrder.afterBottling.label": "After Bottling",
  "orders.bottlingOrder.afterBottling.placeHolder": "Pick one alternative",
  "orders.bottlingOrder.afterBottling.dropdown.refill":
    "Refill unsmoked distillate ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.refill.withoutAmount":
    "Refill unsmoked distillate",
  "orders.bottlingOrder.afterBottling.dropdown.refillSmokey":
    "Refill smoky distillate ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.refillSmokey.withoutAmount":
    "Refill smoky distillate",
  "orders.bottlingOrder.afterBottling.dropdown.refillTwoYear":
    "Refill with two year old unsmoked distillate ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.refillTwoYear.withoutAmount":
    "Refill with two year old unsmoked distillate",
  "orders.bottlingOrder.afterBottling.dropdown.refillTwoYearSmokey": `Refill with two
     year old smoky distillate ({amount}kr)`,
  "orders.bottlingOrder.afterBottling.dropdown.refillTwoYearSmokey.withoutAmount": `Refill with two
     year old smoky distillate`,
  "orders.bottlingOrder.afterBottling.dropdown.save":
    "Send the cask to me ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.save.withoutAmount":
    "Send the cask to me",
  "orders.bottlingOrder.afterBottling.dropdown.sell":
    "I want Box to buy my cask",
  "orders.bottlingOrder.afterBottling.error.required": "Pick one alternative",
  "orders.bottlingOrder.newCaskText.label": "Personal cask sign text",
  "orders.bottlingOrder.newCaskText.placeholder": `John's new whisky`,
  "orders.bottlingOrder.newCaskText.error.maxLength": `The personal cask sign
    can\'t have more than {maxLength} characters`,
  "orders.bottlingOrder.fullName.label": "Full name",
  "orders.bottlingOrder.fullName.error.required": "Full name is required",
  "orders.bottlingOrder.fullName.error.maxLength":
    "The name can't have more than {maxLength} characters",
  "orders.bottlingOrder.email.label": "Email",
  "orders.bottlingOrder.email.error.required": "Email is required",
  "orders.bottlingOrder.email.error.isEmail": "Must be a valid email",
  "orders.bottlingOrder.email.error.maxLength":
    "The email can't have more than {maxLength} characters",
  "orders.bottlingOrder.phoneNumber.label": "Phone",
  "orders.bottlingOrder.phoneNumber.error.required": "Phone number is required",
  "orders.bottlingOrder.phoneNumber.error.isNumber": "Must be a number",
  "orders.bottlingOrder.phoneNumber.error.minLength":
    "The phone number must be at least {minLength} digits",
  "orders.bottlingOrder.phoneNumber.error.maxLength": `The phone number can't have more than {maxLength} digits`,

  "orders.bottlingOrder.bottlingInformation.title": "Bottling Information",
  "orders.bottlingOrder.label.title": "Label",
  "orders.bottlingOrder.afterBottling.title": "After Bottling",
  "orders.bottlingOrder.contactInformation.title": "Contact information",

  "orders.bottlingOrder.confirmation.labelCustomization.label":
    "Number of unique labels",
  "orders.bottlingOrder.confirmation.boxes.boxAmount.label": "Number of boxes",
  "orders.bottlingOrder.confirmation.boxes.wantsPersonalizedLabel.label":
    "Personalized labels",
  "orders.bottlingOrder.confirmation.boxes.wantsPersonalizedLabel.yes": "Ja",
  "orders.bottlingOrder.confirmation.boxes.wantsPersonalizedLabel.no": "Nej",
  "orders.bottlingOrder.confirmation.bankAccountNumber.label":
    "Bank account number for loan repayment",
  "orders.bottlingOrder.emptyValue": "None",

  "orders.bottlingOrder.complete.orderCompleteText":
    "Your bottling has been ordered and will be available on your selected pickup location shortly! " +
    "A confirmation has been sent to {email}.",
  "orders.bottlingOrder.complete.backButton": "Back to your cask",
  "orders.bottlingOrder.complete.title": "Thank you for your order!",
  "orders.bottlingOrder.complete.orderIdLabel": "Order id:",
  "orders.bottlingOrder.complete.orderBoxesText": `Take the opportunity to buy boxes for your bottles!
      Right now we are offering boxes for {priceAmount}kr each`,
  "orders.bottlingOrder.complete.orderBoxesButton": "Order boxes",

  "orders.bottlingOrder.priceExample.loadingPrices": "Loading prices...",
  "orders.bottlingOrder.priceExample.title": "Pricing example",
  "orders.bottlingOrder.priceExample.description": `
        Estimated whisky prices for your cask and recipes with current duties on spirits,
        exchange rates and VAT-rates. Calculated with {numberOfBottles} bottles and {alcoholAmount}% alcohol.
    `,
  "orders.bottlingOrder.priceExample.descriptionNoLoanClaim": `
        Estimated whisky prices for cask and recipes with current duties on spirits,
        exchange rates and VAT-rates. Calculated with {numberOfBottles} bottles and {alcoholAmount}% alcohol.
    `,
  "orders.bottlingOrder.priceExample.descriptionNoLoanClaimWarning": `
        <div style="color:red">The example below is based on a loan of {smokedRecipePrice}kr.</div>
    `,
  "orders.bottlingOrder.priceExample.openButtonClosed": "Show calculation",
  "orders.bottlingOrder.priceExample.openButtonOpen": "Hide calculation",
  "orders.bottlingOrder.priceExample.bottleLabel": "bottle",
  "orders.bottlingOrder.priceExample.netPrice": "Box net price",
  "orders.bottlingOrder.priceExample.alcoholDuty": "Alcohol duty",
  "orders.bottlingOrder.priceExample.vendorCharge": "Vendor charge",
  "orders.bottlingOrder.priceExample.vat": "VAT",
  "orders.bottlingOrder.priceExample.exchangeRate": "Exchange rate",

  "orders.bottlingOrder.error.unknon":
    "An unknown error has occurred. Please try again later. Contact support if the problem persists",

  // Orders sampling
  "orders.sampling.title": "Order sampling",
  "orders.sampling.submitButton": "Complete Order",

  "orders.sampling.description": `
        We recommend that you sample your cask once a year, even after the first year of maturation.
        
        A lot happens during a short period of time with small casks and it's fun to follow their development.`,

  "orders.sampling.orderType": "Sampling place",
  "orders.sampling.orderType.hint":
    "OBS! The sampling must be consumed in place due to the current alcohol laws.",
  "orders.sampling.orderType.distillery": "Distillery in Bjärtrå",
  "orders.sampling.orderType.event": "Events and meetups",
  "orders.sampling.orderType.systembolaget": "Systembolaget",

  "orders.sampling.orderType.error.required":
    "You must choose a sampling place",
  "orders.sampling.distillery.date": "Proposed Date",
  "orders.sampling.distillery.date.error.required": "Date is required",
  "orders.sampling.distillery.time": "Proposed Time",
  "orders.sampling.distillery.time.error.required": "Time is required",
  "orders.sampling.distillery.price": `Total price (paid on site):
        <span class="price">{price}</span>
        <span class="currency">SEK</span>`,

  "orders.sampling.event.name": "Event",
  "orders.sampling.event.name.loading": "Loading...",
  "orders.sampling.event.name.error.required": "An event must be chosen",

  "orders.sampling.event.amount": "Amount",
  "orders.sampling.event.amount.value": "{amount} cl",
  "orders.sampling.event.amount.error.required": "Please choose an amount",
  "orders.sampling.event.amount.error.minNumber":
    "Please choose a bigger amount",

  "orders.sampling.systembolaget.title":
    "Follow the instructions below to order a sample to Systembolaget",
  "orders.sampling.systembolaget.step1.label": `
        Go to
        <a href="https://www.systembolaget.se/bestalla-och-handla/privatimport/forfragan">Systembolaget's website</a>
        and log in. (Create an account if you don't already have one)
    `,
  "orders.sampling.systembolaget.step2.label": "Create new request",
  "orders.sampling.systembolaget.step2.store":
    '<span class="normal-font">Choose the store that you want the sample to be sent to and press "Fortsätt"</span>',
  "orders.sampling.systembolaget.step2.distillery":
    '<span class="normal-font">Choose seller: High Coast, press "Spara och fortsätt"</span>',
  "orders.sampling.systembolaget.step2.new":
    'Press "+ lägg till dryck" and enter the following information for each sample you want to order:',
  "orders.sampling.systembolaget.step2.volume":
    '30, 100, 200 <span class="normal-font">or</span> 500',
  "orders.sampling.systembolaget.step2.ready":
    'Then press "Spara och fortsätt"',
  "orders.sampling.systembolaget.step2.contact":
    "Make sure that your contact information is correct and that you chosen the correct store, you still have the option to change in case something went wrong.",
  "orders.sampling.systembolaget.step3.label": `Read and approve to Systembolaget's terms and conditions and "Skicka in förfrågan"`,
  "orders.sampling.systembolaget.step4.label": `Enter the requestnumber (förfrågansnummer) you get in the order
        verification from Systembolaget into the text field below`,
  "orders.sampling.systembolaget.step4.requestNumber.label": `Request number from Systembolaget`,
  "orders.sampling.systembolaget.step4.requestNumber.error.required":
    "Please enter the request number",
  "orders.sampling.systembolaget.step4.notice":
    "Missas någon punkt kommer ditt ärende inte kunna bli hanterat på rätt sätt och ledtiden förlängs då din förfrågan kommer att bli annullerad av oss. Om förfrågan blir annullerad kommer Systembolaget meddela detta via mejl och du är då välkommen att lägga en ny förfrågan.",
  "orders.sampling.systembolaget.step4.more": `
        For a complete guide, <a href='https://my.highcoastwhisky.se/information/30/61' target='_blank'>Go here</a>
    `,
  "orders.sampling.error.unknon":
    "An unknown error has occurred. Please try again later. Contact support if the problem persists",

  "orders.sampling.complete.orderCompleteText":
    "Your sample been ordered and will soon find its way to your throat! " +
    "A Verification email has been sent to {email}.",
  "orders.sampling.complete.backButton": "Back to your cask",
  "orders.sampling.complete.title": "Thank you for your order!",
  "orders.sampling.complete.orderIdLabel": "Order id:",

  // Orders flavour note
  "orders.flavourNote.title": "Order flavour note",
  "orders.flavourNote.description": `
        Let our quality managers try your whisky and give you taste notes and an estimate of remaining maturation time.
    `,
  "orders.flavourNote.obtainedLanguage":
    "Select which language to obtain the flavour note in.",
  "orders.flavourNote.obtainedLanguage.swedish": "Svenska",
  "orders.flavourNote.obtainedLanguage.english": "English",
  "orders.flavourNote.price": `
        Total price incl. VAT (payment through invoice):
        <span class="price">{price}</span>
        <span class="currency">SEK</span>
    `,
  "orders.flavourNote.submitButton": "Order flavour note",

  "orders.flavourNote.complete.orderCompleteText":
    "Your flavour note has been ordered and our expert will get back to you with a statement shortly! " +
    "A Verification email has been sent to {email}.",
  "orders.flavourNote.complete.backButton": "Back to your cask",
  "orders.flavourNote.complete.title": "Thank you for your order!",
  "orders.flavourNote.complete.orderIdLabel": "Order number:",
  "orders.flavourNote.error.unknon":
    "An unknown error has occurred. Please try again later. Contact support if the problem persists",

  // Orders boxes
  "orders.boxes.description": `
      Take the opportunity to order boxes for your bottling.
      Buy as many boxes as your bottled bottles, or choose how many you want.
      Boxes cost {price}kr each or maximum {max}kr
    `,
  "orders.boxes.loadingBottlingOrder": "Fetching bottling order...",
  "orders.boxes.numberOfCustomBoxesLabel": "Choose {min}-{max}",
  "orders.boxes.boxAmount.placeHolder": "Pick one alternative",
  "orders.boxes.boxAmount.sameAsBottles.fullBottling":
    "Same number of boxes as ordered bottles",
  "orders.boxes.boxAmount.sameAsBottles.partBottling": `Same number of boxes as ordered bottles
    ({numberOfBottles} bottles)`,
  "orders.boxes.boxAmount.custom": "Custom number of boxes",
  "orders.boxes.boxAmount.none": "No boxes",
  "orders.boxes.boxAmount.error.required": "Pick one alternative",
  "orders.boxes.wantsPersonalizedLabelsLabel": `I want personal labels for my boxes ({priceAmount}kr/label)`,
  "orders.boxes.wantsPersonalizedLabelsLabel.info": `The boxes will get the same distribution and style as the
    bottle labels. The boxes and the box labels will be delivered with your bottling order and you will have to
    stick them on yourself`,
  "orders.boxes.boxLabelNote":
    "Note: The labels will look the same as your chosen bottle labels",
  "orders.boxes.total": "Total amount:",
  "orders.boxes.submitButton": "Order boxes",

  "orders.boxes.complete.orderCompleteText": `Your boxes has been ordered and will be sent to you shortly!
    A Verification email has been sent to {email}.`,
  "orders.boxes.complete.backButton": "Back to your cask",
  "orders.boxes.complete.title": "Thank you for your order!",
  "orders.boxes.complete.orderIdLabel": "Order number:",

  // History
  "history.order.emptyHistory": "No order history found.",
  "history.loading": "Loading history...",

  "history.order.bottling.pending": "Bottling ordered",
  "history.order.bottling.cancelled": "Bottling order cancelled",
  "history.order.bottling.declined": "Bottling order declined",
  "history.order.bottling.completed": "Bottling order completed",

  "history.order.boxes.pending": "Boxes ordered",
  "history.order.boxes.cancelled": "Box order cancelled",
  "history.order.boxes.declined": "Box order declined",
  "history.order.boxes.completed": "Box order completed",

  "history.order.flavourNote.pending": "Flavour note ordered",
  "history.order.flavourNote.cancelled": "Flavour note order cancelled",
  "history.order.flavourNote.declined": "Flavour note order declined",
  "history.order.flavourNote.completed": "Flavour note order completed",

  "history.order.sampling.pending": "Sampling ordered",
  "history.order.sampling.cancelled": "Sampling order cancelled",
  "history.order.sampling.declined": "Sampling order declined",
  "history.order.sampling.completed": "Sampling order completed",

  "history.order.cask.pending": "Cask ordered",
  "history.order.cask.cancelled": "Cask order cancelled",
  "history.order.cask.declined": "Cask order declined",
  "history.order.cask.completed": "Cask order completed",

  "history.progress.caskFilled": "The cask has been filled!",
  "history.progress.caskSignAttached": "The cask sign has been attached!",

  // History timeline

  "history.order.type.buy.label": "Purchase order registered - {orderText}",
  "history.order.type.production.label": "Order registered - {orderText}",
  "history.order.type.unknown.label": "Order registrerad",

  // History Item Modal

  "history.modal.title": "Order History",
  "history.modal.type.label": "Type",
  "history.modal.type.purchase": "Purchase order",
  "history.modal.type.production": "Production order",
  "history.modal.type.unknown": "Unknown order",
  "history.modal.vismaOrderId.label": "Order Number",
  "history.modal.activity.label": "Activity",
  "history.modal.action.label": "Action",
  "history.modal.bottlingVolume.label": "Bottling Volume",
  "history.modal.bottlingRound.label": "Bottling Round",
  "history.modal.dilution.label": "Dilution",
  "history.modal.status.label": "Status",
  "history.modal.preferedDeliveryTime.label": "Prefered Delivery Time",
  "history.modal.alcohol.label": "Alcohol",
  "history.modal.orderRow.description.label": "Description",
  "history.modal.orderRow.finishDate.label": "Finished Date",
  "history.modal.orderRow.price.label": "Price",
  "history.modal.orderRow.amount.label": "Amount",
  "history.modal.orderRow.quantity.label": "Quantity",

  "history.order.modal.okButton.label": "OK",

  // About
  "about.members.info": `
        Here you can invite cask members or other that share you interest in whisky to follow your cask.
        Cask members can see the cask, but can't make orders.
    `,
  "about.members.inviteButtonLabel": "Invite cask member",
  "about.members.modal.title": "Invite cask members",
  "about.members.modal.inviting": "Inviting cask member...",
  "about.members.modal.fullName": "Name",
  "about.members.modal.fullName.error.maxLength":
    "The name can't have more than {maxLength} characters",
  "about.members.modal.fullName.error.required": "Full name is required",
  "about.members.modal.email": "Email",
  "about.members.modal.email.error.maxLength":
    "The email can't have more than {maxLength} characters",
  "about.members.modal.email.error.required": "Email is required",
  "about.members.modal.email.error.isEmail": "Must be a valid email",
  "about.members.modal.firstname": "First name",
  "about.members.modal.firstname.required": "First name is required",
  "about.members.modal.lastname": "Last name",
  "about.members.modal.lastname.required": "Last name is required",
  "about.members.modal.submit": "Send invitation",
  "about.members.modal.cancel": "Cancel",
  "about.members.modal.error.userNotFound": `Couldn't find any user with the email {email}.`,
  "about.members.modal.error.userAlreadyMember": `The user with email {email} is already a cask member!`,
  "about.members.modal.error.unknown": `An unknown error has occurred.`,

  // Settings
  "settings.title": "Settings",
  "settings.message": "My message in english!!",
  "settings.change.password": "Change password",
  "settings.contactInfo.save": "Save",
  "settings.updatingUser": "Updating user...",

  // Reset password
  "reset-password.label": "Reset your password",
  "reset-password.button": "Send reset email",
  "reset-password.description": "Enter your e-mail to reset your password",
  "reset-password.complete.text": "A reset email has been sent to:",
  "reset-password.password.label": "New password",
  "reset-password.email.error.notFound": "No user is connected to this email",

  // Register

  "register.tab.title": "Register",
  "login.registratingMessage": "Registering...",
  "register.company.title": "Company registration",
  "register.intro": "Enter the fields below to start your whisky-journey.",
  "register.clickHere": "Register as a company",
  "register.company.clickHere": "Register as a person",

  "register.orgNumber.label": "Organisational number",
  "register.orgNumber.error.required": "Enter the organisational number",

  "register.orgName.label": "Company name",
  "register.orgName.error.required": "Enter the company name",

  "register.contactFirstName.label": "First name (contact person)",
  "register.contactFirstName.error.required":
    "Enter the first name of a contact person",

  "register.contactLastName.label": "Last name (contact person)",
  "register.contactLastName.error.required":
    "Enter the last name of a contact person",

  "register.phoneNumber.label": "Phone number",
  "register.phoneNumber.error.required": "Enter phone number",

  "register.firstName.label": "First name",
  "register.firstName.error.required": "Enter your first name",

  "register.lastName.label": "Last name",
  "register.lastName.error.required": "Enter your last name",

  "register.password.label": "Password",
  "register.password.error.required":
    "The password must be at least {minLength} character and contain at least {minDigits} number",
  "register.password.error.hasNumber":
    "Your password must contain at least {minDigits} number",
  "register.password.error.minLength":
    "You password must be at least {minLength} charactes",

  "register.repeatedPassword.label": "Confirm Password",
  "register.repeatedPassword.error.required": "Confirm your password",
  "register.repeatedPassword.error.match": "Your passwords don't match",

  "register.email.label": "Email",
  "register.email.error.required": "Enter your email",
  "register.email.error.unavailable": "That email has already been registered",
  "register.email.error.invalidEmail": "Enter a valid email",
  "register.submit": "Register",

  "register.terms.text": "By registering, you agree to Box-whisky's {terms}",
  "register.terms.linkText": "terms",

  "register.complete.header": "Registration successful!",
  "register.complete.yourEmail": "your email",
  "register.complete.text": "A verification mail has been sent to",
  "register.complete.activate":
    "Click the link in the mail to activate your account.",

  "register.verify.loading": "Verifying your email...",
  "register.verify.successHeader": "Your email has now been verified",
  "register.verify.successText": "You may now log in.",
  "register.verify.failText":
    "Couldn't verify your email. The verification link was not valid.",
  "register.verify.retrySend": "Send new verification email",

  "register.verify.new.intro":
    "Please enter your email and we can send you a new verification email.",
  "register.verify.new.emailSent":
    "A verification email has been sent to {email}",
  "register.verify.new.email.label": "Email",
  "register.verify.new.submit": "Send verification",
  "register.verify.new.email.error.invalid": "Enter a valid email",
  "register.verify.new.email.error.notFound": "The email was not found",
  "register.verify.new.email.error.alreadyVerified":
    "The email you entered has already been verified",

  // Change password
  "settings.contactInfo.changePassword.currentPassword.label":
    "Current password",
  "settings.contactInfo.changePassword.complete.message":
    "Change of password successful!",

  // Change contact info
  "settings.contactInfo.label": "Your profile",
  "settings.contactInfo.company.label": "Your organisation",

  "settings.contactInfo.orgNumber.label": "Organisational number",
  "settings.contactInfo.orgNumber.error.required":
    "Enter the organisational number",

  "settings.contactInfo.orgName.label": "Company name",
  "settings.contactInfo.orgName.error.required": "Enter the company name",

  "settings.contactInfo.socialSecurity.label": "Social security number",
  "settings.contactInfo.socialSecurity.error.required":
    "Enter your social security number",

  "settings.contactInfo.address.label": "Address",
  "settings.contactInfo.address.error.required": "Enter your address",
  "settings.contactInfo.company.address.error.required":
    "Enter the company adress",

  "settings.contactInfo.city.label": "City",
  "settings.contactInfo.city.error.required": "Enter your city",
  "settings.contactInfo.company.city.error.required": "Enter the company city",

  "settings.contactInfo.zipCode.label": "Zip-code",
  "settings.contactInfo.zipCode.error.required": "Enter your zip-code",
  "settings.contactInfo.company.zipCode.error.required":
    "Enter the company zip-code",

  "settings.contactInfo.phoneNumber.label": "Phone number",
  "settings.contactInfo.phoneNumber.error.minLength":
    "The phone number must be at least {minLength} digits",
  "settings.contactInfo.phoneNumber.error.maxLength": `The phone number can't have more than {maxLength} digits`,
  "settings.contactInfo.phoneNumber.error.isNumber": `Must be a number`,
  "settings.contactInfo.phoneNumber.error.required": "Enter phone number",
  "settings.contactInfo.company.phoneNumber.error.required":
    "Enter contacs Phone number",

  "settings.contactInfo.country.label": "Country",
  "settings.contactInfo.country.error.required": "Select your country",
  "settings.contactInfo.company.country.error.required":
    "Select country organisation is based in",

  "settings.contactInfo.region.label": "Select region",
  "settings.contactInfo.region.error.required": "Select your region",

  "settings.contactInfo.company.contact.label": "Contact",
  "settings.contactInfo.company.firstName.label": "First name",
  "settings.contactInfo.company.firstName.error.required":
    "Enter the contact person's first name",
  "settings.contactInfo.company.lastName.label": "Last name",
  "settings.contactInfo.company.lastName.error.required":
    "Enter contacs last name",

  "settings.contactInfo.company.email.label": "Email",
  "settings.contactInfo.company.email.error.required": "Enter contacs email",

  "settings.contactInfo.complete.message":
    "Change of contact information successful!",
  "user.terms": `
        <h2>Generally terms and conditions</h2>
        <p>1. The terms in points 1-21 ("Terms of Use") apply between you and High Coast Distillery AB ("High Coast")
            when you visit, or interact with, the High Coast website highcoastwhisky.se ("Website"). Through
            your use of, or interacting with, the Site you agree to the Terms of Service.</p>
        <p>2. When ordering a product or service on the Website, in addition to the Terms of Service,
            the terms and conditions of paragraphs 22-24 (Order) apply.</p>
        <p>3. High Coast is not responsible for any typing and / or printing errors on the Site and is entitled
            at any time to remove or make changes to information and functionality on the Site. High Coast
            also has the right to change the General Terms at any time. The General Terms and Conditions
            are published on the website. You should therefore read the General Terms and Conditions of
            each visit to the Website (and at each order of goods or services).</p>
        <p>4. You are responsible for the technical equipment and software required for the use of the
            Website, such as Internet access, browser and email.</p>
        <p>5. Maintenance shutdown, etc. may occur on the site. High Coast is not responsible for interruptions
            or other interferences that may occur on the Site.</p>
        <p>6. All intellectual property rights to the content on the Website are added to High Coast (or third party).
            High Coast intellectual property rights include the company High Coast, the brand BHigh Coastox,
            designs, for example High Coast
            symbols and rights under copyright law, such as photographs and databases. You may download,
            print, copy and view materials on the Site for your own private use, but you may not handle such
            material for commercial or commercial purposes. If you are unsure of what material you may use and
            how, please contact High Coast Customer Service (see section 16 for contact details).</p>
        <p>7. It is not permitted to use technical solutions or automated tools such as, for example, agents,
            robots, crawlers or spiders associated with the Site to register or reproduce information, post orders,
            utilize services or to collect information from or on the Site in order to provide features or services
            related to marketing. You may also not (i) interfere with or affect the High Coast-designated functionality
            of the Site, or (ii) circumvent any technical safeguards that High Coast has taken to restrict access or
            access to the Site.</p>
        <p>8. Should a dispute arise between you and the High Coast,
            it should be resolved in the first place in agreement.
            If this is not possible, the case shall be settled in the general court.
            Disputes are settled according to Swedish law.</p>
        <h2>Linking</h2>
        <p>9. You may refer to the Site by linking.</p>
        <h2>User</h2>
        <p>10. You can make an order for a product or service when you have created a user account on the site,
            including Must enter your name and social security number. If you do not have a Swedish Social Security
            number or are not written in Sweden, you can contact High Coast Partner
            Services for assistance with creating an account.</p>
        <p>If you are in Norway, enter it, then you will leave the following information: first name, last name,
            date of birth (YYYYMMDD), street address and postal code.</p>
        <p>Exit data checked by High Coast using the company Ratsit AB. High Coast confirms
            via e-mail that you have created your user account. Both legal and natural persons may
            create user accounts and only one user account per legal or physical person is allowed.</p>
        <p>11. In order to reach your user account on the Site, choose a personal and unique password that,
            together with your chosen username (your email address), gives you access to the user account.
            You are responsible for keeping your password confidential. By registering your user account,
            you confirm that it is personal that the registered e-mail address is personal and that the
            user account has not been registered for someone else's account. User account may not be used
            by or transferred to another. The user account is automatically deleted if you have not been
            logged in for a 12-month period or if you specifically request that your user account be deleted.</p>
        <p>12. User account may not be registered on behalf of another person or by commercial actor eg.
            Traders who convey or market alcoholic beverages.</p>
        <p>13. High Coast may, at your option, cancel your order and / or suspend your user account
            (temporary or permanent) if High Coast has reasonable reason to suspect (i) that your user account
            contains incorrect, misleading or inaccurate data; or (ii) to Your user account or Website
            is otherwise used in ways that are not permitted under the General Terms and Conditions.
            If cancellation of your order and / or suspension of your user account occurs, you will
            receive a message from High Coast.</p>
        <p>14. All personal data you provide to the High Coast on the Site are processed in accordance
            with the Personal Information Act ("PUL"). User information may only be registered by the
            person to whom the information relates. High Coast processes your information to manage your use
            of the Site, Customer Relationship, History, and to inform you about conditions affecting
            your use of the Site and to make agreements with you, follow up on deliveries or otherwise
            take the actions you have requested as well as to Ensure that the person making an order
            or making a request is over 20 years. My Orders history is deleted by High Coast when you request
            it. History is saved for the purpose of providing a well-functioning service based on your
            role as a wearer. Your information will not be transferred or used for any form of external
            marketing. By submitting your information you agree to the use of your information by High Coast,
            including your social security number, as stated in the General Terms and Conditions.</p>
        <p>15. High Coast Distillery AB, 556729-2593, Sörviken 140, 872 96 Bjärtrå is the person responsible
            for the personal data being processed. If you provide the High Coast with a written and signed
            application, you will be notified once a year of how Personal Information about you is handled
            by High Coast. You are entitled to request that your information be corrected, blocked or deleted.
            In that case, please contact High Coast Cask Owner Service (Fatägarservice) at the above address or
            by telephone at 0612-530 60. By submitting your information you agree that High Coast may contact
            you for the purpose of informing and / or sending such surveys.</p>
        <h2>Delivery and prices etc.</h2>
        <p>17. If you have reached the age of 20 and created a user account (see points 11-13), you
            can make an order for items from the High Coast range on the Website either for pickup in Denmark,
            Estonia, Germany at High Coast appointed partner and at Systembolaget's entire stores in Sweden.</p>
        <p>18. Under the terms of the Alcohol Act, High Coast does not sell or dispatch alcoholic beverages
            to anyone other than the authorized warehousekeeper, holder of a serving license or otherwise
            authorized to handle alcoholic beverages. Client not belonging to the above category is referred
            to as the withdrawal of bottled products for delivery under the conditions described in paragraph 17.</p>
        <p>19. High Coast is not responsible for any final sale of ordered goods or goods for other reasons and is
            entitled to cancel your order regarding the goods sold out or for any reason not being delivered.
            If High Coast can not deliver all or part of your order, you will be notified as soon as possible.</p>
        <p>20. The price quoted for each item in High Coast range includes normal value added tax.
            The product in question is handled according to special conditions and VAT is included in the
            cost of the cask, but for the part relating to loans, no value is included. When a cask is bottled,
            the store's handling costs, alcohol tax and value added tax will be
            subject to the terms and conditions of our partners. For shipping outside
            Sweden, the price of the item may be affected by the current exchange rate.</p>
        <p>21. If you fail to retrieve ordered goods on time, refuse to receive goods at home delivery or do not
            pick up items at the agreed time of our partners, additional handling costs may be charged to you when
            picking up the item.</p>
        <h2>Ordering</h2>
        <p>In addition to the following conditions for ordering with delivery to the customer's chosen store or
            partner, the Terms of Use are also applicable in paragraphs 1-21.</p>
        <p>22. You are bound by your order when it has been registered on the Site.</p>
        <p>23. When ordered is available, you will be notified by email confirming what you ordered. When ordering a
            dish, your order will appear on the invoice sent to you. When pick-up samples are collected at trade fairs,
            the current order number must always be reported and, in connection with this, the current sample will be
            paid with the payment method applicable to the relevant fair or festival. For sample samples via
            Systembolaget, the customer always has to make a "private import" according to the procedures according
            to Systembolaget's website.</p>
        <p>24. Defective goods can always be advertised at High Coast.
        In case of complaint please contact High Coast Fatägarservice, 0612-530 60.</p>
        <h2>Order for delivery</h2>
        <p>In addition to the following terms for ordering for home delivery, the Terms of Use in paragraphs 1-21
            above also apply.</p>
        <p>25. You are bound by your order when it has been registered on the Site. However, High Coast is not bound
            by your order until Systembolaget has found that the sale and delivery of alcoholic beverages
            may take place in accordance with the Alcohol Act (see paragraph 18). If your order is in
            accordance with the Alcohol Act, a purchase order will be sent to the High Coast, after which
            High Coast will ship the ordered item to the current store.</p>
        <p>26. Delivery of goods may take place on weekdays to any Systembolagsbutik in Sweden. Delivery
            will only occur when High Coast has received an approved purchase order from Systembolaget. An
            approved purchase order assumes that the customer has registered a "private import" and approved
            quotation. For our partners in Denmark and Germany, the given time window applies where the
            current date is shown on our website. However, the product will be collected within 2 months
            from the first day when the product is available for pickup as per order in the form of a
            bottleneck notification. When picking up in Estonia, the current date of departure is valid. Upon
            pickup at a later date, our partners may charge a handling fee.</p>
        <p>27. Delivery of ordered goods will be either either your National Account Address or other address
            you specify. Upon receipt of the goods, you must provide valid ID processing to verify your identity
            and to comply with the Alcohol Laws sales rules (see paragraph 18). The item you have ordered may
            also be received by another provided you have ordered the item to your national registration address.
            This, however, provided that this recipient may provide valid ID document for the purpose of
            strengthening his identity and complies with the Alcohol Act's sales rules (see paragraph 18).</p>
    `,

  // Information
  "information.title": `Information`,

  // New Member registration
  "newMember.register.person.title": "Person",
  "newMember.register.company.title": "Company",
  "newMember.register.intro":
    "You can register as either a person or a company with the menu above.",
  "newMember.register.error.invalidUser": "User not found",
  "newMember.register.error.invalidToken":
    "The registartion link has expired, click here to get a new link",
  "newMember.register.error.unknownError": "An unknown error has occured",
  "newMember.register.resend": "Resend link",
  "newMember.register.resend.success":
    "A message has been sent to your email with a new link that you can use too complete your registration.",

  // Visma user picker
  "vismaUserPicker.title.default": "Your connected accounts",
  "vismaUserPicker.subtitle.default": "Pick the accout you want to use",

  // Cask select
  "caskSelect.title": "Casks you have been invited to",
  "caskSelect.subtitle":
    "Click on a cask to pick what account that cask should be bound to",
  "caskSelect.userPicker.title": "Your connected accounts",
  "caskSelect.userPicker.subtitle":
    "Pick the account that you want to bind the cask to",
  "caskSelect.error.userNotFound": "The user you selected could not be found",
  "caskSelect.error.userNotAdded":
    "The cask could not be bound to that account",
  "caskSelect.error.unknown":
    "An unknown error has occured. Please contact support if the problem persists.",
  "caskSelect.loading": "Binding cask to account...",
  "footer.col1.title": "Visit us",
  "footer.col2.title": "About us",
  "footer.col3.title": "Social",
  "footer.col2.investors": "Financial information",
  "footer.col2.press": "Press",
  "footer.col2.locations": "Where to buy High Coast Whisky",
  "footer.col2.contact": "Contact",
  "footer.col2.work": "Work here",
  "footer.col2.start": "Home",
  "footer.col2.webshop": "Webshop",
  "footer.col2.cookies": "Cookie policy",
  "footer.all-rights-reseved": "All rights reserved",
  "information.mobile.menu.label": "Select section",
  "profile-menu.customer-id": "Customer number:",
  "news.title": "News",
  "news.read-more": "Read more",
  "news.read-less": "Read less",
  "casks.title": "My casks",
  "casks.go-to-cask": "Show cask",
  "casks.no-casks1": "You don't seem to own any casks at the moment.",
  "casks.no-casks2":
    "Become a cask owner today by buying your own cask or ask a cask owner to invite you as a part owner.",
  "cask.stored": "Storage time ",
  "cask.info.proof": "Owner proof",
  "cask.info.settlement": "Settlement",
  "cask.info.history": "Show cask history",
  "cask.members.title": "Cask members",
  "cask.members.table.name": "Name",
  "cask.members.table.email": "Email",
  "common.back": "Back",
  "common.too-short": "Too short",
  "common.too-long": "Too long",
  "common.required": "Required",
  "common.invalid-email": "Invalid email",
  "cask.members.invite.language": "Language of invitation email",
  "cask.members.invite.pick-language": "Pick a language",
  "common.language.sv": "Swedish",
  "common.language.en": "English",
  "common.reset": "Reset",
  "cask.members.invite.success": "Invitation sent!",
  "common.remove": "Remove",
  "common.error.unknown":
    "An unknown error has occurred. Please try again later. Contact support if the problem persists",
  "cask.orders-info.title": "Order",
  "cask.history.title": "History",
  "cask.orders.bottling.title": "Order bottling",
  "casks.inactive.title": "Emptied casks",
  "cask.orders.bottling.loan.title": "Loan repayment",
  "cask.orders.bottling.info.title": "Bottling information",
  "cask.orders.bottling.labels.title": "Bottle label",
  "cask.orders.bottling.delivery.title": "Delivery",
  "cask.orders.bottling.contact.title": "Contact information",
  "cask.orders.bottling.summary.title": "Summary",
  "cask.orders.bottling.loan.bank-type": "Bank",
  "cask.orders.bottling.loan.clearingNumber-placeholder":
    "Enter your bank clearing number",
  "cask.orders.bottling.loan.swedishBankAccountNumber-placeholder":
    "Enter your account number",
  "cask.orders.bottling.loan.foreignBankAccountNumber-placeholder":
    "Enter your IBAN number",
  "cask.members.invite.firstname-placeholder": "Enter first name",
  "cask.members.invite.lastname-placeholder": "Enter last name",
  "cask.members.invite.email-placeholder": "Enter email",
  "cask.orders.bottling.info.amount.whole": "Whole cask",
  "cask.orders.bottling.info.amount.whole.desc":
    "Your whole cask will be bottled at once",
  "cask.orders.bottling.info.amount.part": "Part bottling",
  "cask.orders.bottling.info.amount.part.desc": "Bottle part of your cask",
  "common.invalid-number": "Enter only numbers",
  "common.too-much": "Too high",
  "common.too-little": "Too low",
  "cask.orders.bottling.info.amount.part.no-bottles-label": "Number of bottles",
  "cask.orders.bottling.info.amount.part.pick-number-of":
    "Select number of bottles",
  "cask.orders.bottling.info.amount.part.bottles": "{amount} bottles",
  "cask.orders.bottling.info.dialute.info":
    "Lowest allowed alchol content is 46% (50% for sherry casks).",
  "cask.orders.bottling.info.dialute.label": "Alchohol content (%)",
  "cask.orders.bottling.info.dialute.placeholder":
    "Enter desired alcohol content",
  "cask.orders.bottling.info.after.label": "After bottling",
  "cask.orders.bottling.info.after.refill": "Refill",
  "cask.orders.bottling.info.after.refill.desc": "Refill your cask",
  "cask.orders.bottling.info.after.send-cask": "Deliver the cask",
  "cask.orders.bottling.info.after.send-cask.desc":
    "Your cask is delivered to you for a fee of 500 SEK",
  "cask.orders.bottling.info.refill.select.label": "Refill type",
  "cask.orders.bottling.info.refill.select.placeholder": "Select refill type",
  "cask.orders.bottling.info.refill.select.refill": "Unsmoked distillate",
  "cask.orders.bottling.info.refill.select.refill-smokey": "Smoky distillate",
  "cask.orders.bottling.info.refill.select.refill-two-year":
    "Ywo year old unsmoked distillate",
  "cask.orders.bottling.info.refill.select.refill-two-year-smokey":
    "Ywo year old smoky distillate",
  "cask.orders.bottling.info.after.cask-sign.placeholder":
    "Enter new cask sign text",
  "cask.orders.bottling.labels.titles": "Label {index}",
  "cask.orders.bottling.labels.customization.none": "Default",
  "cask.orders.bottling.labels.customization.personalText": "Custom text",
  "cask.orders.bottling.labels.customization.file": "Custom logo",
  "cask.orders.bottling.labels.add": "Add another label",
  "cask.orders.bottling.labels.customization.personalText-placeholder":
    "Enter personal label text",
  "cask.orders.bottling.labels.customization.pick": "Select type",
  "cask.orders.bottling.labels.customization.upload": "Upload a logo image",
  "cask.orders.bottling.labels.box": "Use BOX label",
  "cask.orders.bottling.labels.percent":
    "Percentage of bottles with this label",
  "cask.orders.bottling.labels.cost":
    "Total cost of labels: <strong>{cost} SEK</strong>",
  "cask.orders.bottling.delivery.occation.pick": "Select a delivery occation",
  "cask.orders.bottling.delivery.location": "Delivery location",
  "cask.orders.bottling.delivery.location.sweden": "Sweden",
  "cask.orders.bottling.delivery.location.germany": "Germany",
  "cask.orders.bottling.delivery.location.denmark": "Denmark",
  "cask.orders.bottling.delivery.location.sweden.desc":
    "Pick up your whisky at any systembolag.",
  "cask.orders.bottling.delivery.location.germany.desc":
    "Pick up your whisky at our partner Nielsen’s Discount in Germany.",
  "cask.orders.bottling.delivery.location.denmark.desc":
    "Pick up your whisky at our partner Bottleshop Kastrup in Denmark.",
  "cask.orders.bottling.delivery.price-example":
    "<strong>{price} SEK</strong> per bottle is the estimated price for your cask and recipe using todays taxes and exchange rates. Calculated on {noBottles} bottles and {alcoholContent}% alcohol content.",
  "cask.orders.bottling.delivery.price-example.showCalculations":
    "Show how we've calculated",
  "cask.orders.bottling.delivery.price-example.showCalculations.title":
    "Calculation examples",
  "common.close": "Close",
  "cask.orders.bottling.delivery.warning.read-more": "Read more about it here.",
  "cask.orders.bottling.delivery.warning.sweden":
    "You need to do a private import at systembolaget after your cask has been bottled. <strong>We'll send you an email when it's time.</strong> ",
  "cask.orders.bottling.delivery.warning.denmark":
    "You'll receive information about how to pay for your whisky about 1-2 weeks before the first available delivery day in the bottling round. <strong>It's important that you pick up your whisky within a month of delivery.</strong>",
  "cask.orders.bottling.delivery.warning.germany":
    "Your bottles will be kept at Nielsen's Discount two month free of charge. After that you will be charged 300 SEK per additional month. <strong>You must announce your pick up at least 7 days prior. State your cask number when doing so.</strong>",
  "cask.orders.bottling.delivery.address.denmark":
    "Bottleshop Kastrup. Amager Standvej 418, 2770 Kastrup, DEnmark.",
  "cask.orders.bottling.delivery.address.germany":
    "Nielsen's Discount. Ehlers Kamp 9, Fehmarn, Germany.",
  "cask.orders.bottling.delivery.bus":
    "We arrange bus trips to the delivery location.",
  "cask.orders.bottling.delivery.bus.read-more": "Read more here.",
  "common.invalid-phoneno": "Invalid phone number",
  "cask.orders.bottling.contact.fullname": "Full name",
  "cask.orders.bottling.contact.fullname.placeholder":
    "Enter the delivery receivers full name",
  "common.email": "Email",
  "cask.orders.bottling.contact.email.placeholder":
    "Enter the delivery receivers email",
  "common.phone-number": "Phone number",
  "cask.orders.bottling.contact.phone-number.placeholder":
    "Enter the delivery receivers phone number",
  "cask.orders.bottling.show-summary": "Show summary",
  "cask.orders.bottling.show-summary.invalid":
    "Enter all the required fields to complete your order.",
  "cask.orders.bottling.summary.labels.plural": "Bottle labels",
  "cask.orders.bottling.summary.labels.percent": "Percentage:",
  "cask.orders.bottling.summary.edit": "Edit order",
  "cask.orders.bottling.summary.confirm": "Confirm your order",
  "cask.orders.done.back": "Back to cask page",
  "cask.orders.sampling.title": "Order samling",
  "cask.orders.sampling.orderType.event.desc":
    "Sample your whisky at any of our cask owner events or select conventions.",
  "cask.orders.sampling.orderType.systembolaget.desc":
    "Pick up your sample at any systembolag.",
  "cask.orders.sampling.orderType.distillery.desc":
    "Pick a date where you sample your whisky at the distillery.",
  "cask.orders.sampling.notice":
    "Your sample must be consumed on site due to the current alcohol legislation.",
  "cask.orders.sampling.occation": "Occation",
  "cask.orders.sampling.occation.pick": "Select an occation for your sampling",
  "cask.orders.sampling.amount": "Size of sample",
  "cask.orders.sampling.cost": "<strong>{cost} SEK</strong>",
  "cask.orders.sampling.date": "Desired sampling date",
  "cask.orders.sampling.date.placeholder": "Pick a date",
  "cask.orders.sampling.systembolaget.step1": `
        Go to
        <a target="_blank" href="https://www.systembolaget.se/bestalla-och-handla/privatimport/forfragan">Systembolagets hemsida</a>
        and sign in. <br />(Create an account if you haven't already)
    `,
  "cask.orders.sampling.systembolaget.step2":
    'Create a new "förfrågan" and select the store where you want to pick up your sample. Click <strong>Fortsätt</strong>.',
  "cask.orders.sampling.systembolaget.step3":
    "Select <strong>Svensk säljare</strong> and enter <strong>High Coast Distillery AB</strong>.",
  "cask.orders.sampling.systembolaget.step4":
    "Click <strong>+ Lägg till dryck</strong> and enter the following for each sample you want to order:",
  "cask.orders.sampling.systembolaget.step5":
    "Click <strong>Spara och fortsätt</strong>",
  "cask.orders.sampling.systembolaget.step5.notice":
    "Check that your entered contact information is correct ant that you've picked the right store.",
  "cask.orders.sampling.systembolaget.step6":
    "Check <strong>Allmänna villkor</strong> and then click <strong>Skicka in förfrågan</strong>. ",
  "cask.orders.sampling.systembolaget.step7":
    'You\'ll receive an email confirming your private import. Enter the "förfrågansnummer" from that email below.',
  "cask.orders.sampling.systembolaget.step7.notice":
    "This email may take up to an hour to receive. Check your spam folder as well.",
  "cask.orders.sampling.systembolaget.input":
    "Förfrågansnummer from Systembolaget",
  "cask.orders.sampling.systembolaget.input.placeholder":
    "Enter your förfrågansnummer from systembolaget",
  "cask.orders.sampling.amount.notice": "You'll pay for your order on site.",
  "cask.info.warehouse.label": "Warehouse",
  "cask.info.stock-number.label": "Stock numer",
  "cask.info.recipe.label": "Recipe",
  "cask.orders.flavour-note.title": "Order flavour note",
  "profile-menu.error-visma":
    "We couldn't fetch your user profile at the moment. Try again later.",
  "shopping-cart.widget.title": "Show cart",
  "cask.orders.bottling.delivery.location.estonia": "Estonia",
  "cask.orders.bottling.delivery.location.estonia.desc":
    "Join us on a trip to Estonia where you pick up your whisky.",
  "cask.orders.bottling.delivery.address.estonia": "Tallinn, Estland.",
  "cask.orders.bottling.delivery.warning.estonia":
    "We arrange a ferry trip to Estonia with Viking Cinderella where you pick up your bottles. <strong>You cannot pick up your whisky on your own.</strong>",
  "cookie-banner.preamble":
    "Please confirm that you are 20 years of age or older and that you that you accept our cookies.",
  "cookie-banner.no": "No",
  "cookie-banner.accept": "I accept",
  "cookie-banner.enjoy": "Enjoy High Coast Whisky responsibly.",
  "cookie-banner.footer":
    'By visiting this website you accept that we store cookies. <a href="https://highcoastwhisky.se/integritetspolicy/" target="_blank">Read more.</a>',
  "cask.orders.bottling.labels.sum-error": "The sum of the labels must be 100%",
  "footer.col4.title": "Follow us",
  "cask.orders.bottling.info.after.sell-cask": "Sell cask",
  "cask.orders.bottling.info.after.sell-cask.desc":
    "I want High Coast to buy my cask",
  "cask.orders.bottling.labels.customization.upload-size-error":
    "Max file size is 5 MB.",
  "orders.sampling.systembolaget.reminder":
    "Remember to confirm your order here on the retailer's website after you have entered the Systembolaget inquiry number.",
  "common.unsaved-changes":
    "You have unsaved changes. Are you sure you want to leave?",
  "common.out-of-stock": "Temporarily out of stock",
  "cask.orders.labels.content-warning":
    "We do not approve text or images that allude to violence, sex, or incitement to hatred.",
  "cask.orders.bottling.info.after.alert.title": "Are you sure?",
  "cask.orders.bottling.info.after.alert.desc":
    "You've chose to not refill your cask. This means that your cask will be emptied and you will not be able to refill it again.",
  "cask.orders.bottling.info.after.alert.confirm": "Yes, I'm sure",
  "cask.orders.bottling.info.after.alert.refill": "Refill my cask",
  "cask.orders.bottling.info.refill.select.refill-gin":
    "Gin (We'll contact you)",
  "cask.orders.labels.alert.title": "Label Selection",
  "cask.orders.labels.alert.desc":
    "You have chosen not to have your own text or logo on your label. Are you sure you don't want it?",
  "cask.orders.labels.alert.desc-gin":
    "You have chosen not to have your own text on your label. Are you sure you don't want it?",
  "cask.orders.labels.alert.customize": "Enter Personal Text",
  "cask.orders.labels.alert.confirm": "Yes, I'm Sure",
  "orders.bottlingOrder.date.desc":
    "Your bottles will be bottled after the final registration date and will be available from the first pickup date.",
  "cask.orders.bottling.labels.gin.info":
    "You can choose a text for the label on the back of the bottle.<br/>The front label will look like this:",
  "cask.orders.bottling.labels.customization.personalText.desc":
    "Choose the text you want on your label",
  "cask.orders.bottling.labels.customization.file.desc":
    "Upload a logo to be printed on your label",
  "cask.orders.bottling.labels.customization.none.desc":
    "Empty space on the label",
  "cask.orders.bottling.labels.gin.file-notice":
    "The max size of the logo is 50mm x 23mm. Please consider if this fits your logo.",
  "cask.orders.bottling.info.after.scrap": "Scrap cask",
  "cask.orders.bottling.info.after.scrap.desc":
    "I want High Coast to scrap my cask",
  "cask.orders.bottling.contact.comment-title": "Comments",
  "cask.orders.bottling.contact.comment-placeholder":
    "Enter any comments or inqueries here",
  "cask.orders.sampling.amount.notice-distillery":
    "You'll get an invoice for the cost of the sample.",
};
