import { FC } from "react";
import { FormattedMessage } from "react-intl";

interface Props {}

const InvoiceForm: FC<Props> = (props: Props) => {
  return <FormattedMessage id="caskShop.cart.payment.invoice.selected" />;
};

export default InvoiceForm;
