import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import bottlingImage from "assets/images/bottle.png";
import samplingImage from "assets/images/glass.png";
import flavourNoteImage from "assets/images/nose.png";
import BrandButton from "components/BrandButton";
import { observer } from "mobx-react-lite";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { useMedia } from "react-use";
import styled from "styled-components";
import { formatToString } from "utils/format";
import { useCurrentCask } from "../cask-hooks";

const CardContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  width: 33.3%;
  max-width: 256px;
  position: relative;

  img {
    margin-top: 0.5rem;
    width: auto;
    height: 120px;
    filter: grayscale(1); //fixa
  }

  button {
    position: absolute;
    bottom: 0.5rem;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;

    img {
      margin-top: 0;
      width: auto;
      height: 80px;
      filter: grayscale(1); //fixa
    }

    button {
      position: unset;
    }
  }

  @media (max-width: 360px) {
    svg {
      display: none;
    }
  }
`;

const OrderInfoCard: React.FC<{
  image: string;
  titleKey: string;
  descKey: string;
  href: string;
}> = ({ image, titleKey, descKey, href }) => {
  const isMobile = useMedia("(max-width: 600px)");
  const { push } = useHistory();

  return (
    <CardContainer
      p={isMobile ? 0 : 4}
      py={isMobile ? 4 : undefined}
      bg="#f2f2f2"
    >
      <Box
        display={isMobile ? "flex" : "block"}
        pb={isMobile ? 0 : 12}
        w="full"
        h="full"
        pos="relative"
        alignItems="stretch"
      >
        <Center w={isMobile ? "30%" : "full"}>
          <Image alt={formatToString(titleKey)} src={image} />
        </Center>
        <Box
          w={isMobile ? "70%" : "full"}
          display="flex"
          flexDir="column"
          pr={isMobile ? 4 : 0}
        >
          <Box>
            <Text
              textAlign={isMobile ? "left" : "center"}
              my={isMobile ? 0 : 4}
              fontWeight={300}
              flexGrow={1}
            >
              <FormattedMessage id={descKey} />
            </Text>
          </Box>
          <BrandButton
            w="full"
            mt={isMobile ? 4 : "auto"}
            colorScheme="brand"
            onClick={() => push(href)}
          >
            <FormattedMessage id={titleKey} />
          </BrandButton>
        </Box>
      </Box>
    </CardContainer>
  );
};

const CaskOrdersInfo: React.FC = () => {
  const { cask, loading, isCaskOwner } = useCurrentCask();
  const isMobile = useMedia("(max-width: 600px)");
  const StackElement = isMobile ? Stack : HStack;

  if (!cask || loading || !isCaskOwner) return null;

  return (
    <Box my={8} maxW={800} mb={6} id="orders-info">
      <Heading size="lg" fontWeight={500}>
        <FormattedMessage id="cask.orders-info.title" />
      </Heading>
      <StackElement mt={4} spacing={4} alignItems="stretch">
        <OrderInfoCard
          image={bottlingImage}
          titleKey="orders.orderPage.bottling.title"
          descKey="orders.orderPage.bottling.text"
          href={`/cask/${cask.id}/orders/bottling`}
        />
        <OrderInfoCard
          image={samplingImage}
          titleKey="orders.orderPage.sampling.title"
          descKey="orders.orderPage.sampling.text"
          href={`/cask/${cask.id}/orders/sampling`}
        />
        <OrderInfoCard
          image={flavourNoteImage}
          titleKey="orders.orderPage.flavourNote.title"
          descKey="orders.orderPage.flavourNote.text"
          href={`/cask/${cask.id}/orders/flavour-note`}
        />
      </StackElement>
    </Box>
  );
};

export default observer(CaskOrdersInfo);
