import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components'; 
import { Paper } from 'material-ui';
import { withRouter, RouteComponentProps  } from 'react-router';
import { formatToString } from 'utils/format';
import SetPasswordPage from './SetPasswordPage';
import SnackBarUiStore from 'stores/ui/snackBar';
import SetPasswordFormUiStore from 'stores/ui/account/set-password-form';
import { ResetPasswordService, SetPasswordViewModel } from 'services/change-password';

interface Props extends RouteComponentProps<{}> {
    setPasswordFormUiStore?: SetPasswordFormUiStore;
    snackBarUiStore?: SnackBarUiStore;
}

interface SearchParams {
    userId: string;
    token: string;
}

const Wrapper = styled(Paper)`
    margin-top: 15px;
`;

@inject('setPasswordFormUiStore', 'snackBarUiStore')
@observer
class ChangePasswordFormContainer extends React.Component<Props, {}> {

    componentDidMount() {
        this.props.setPasswordFormUiStore!.resetForm();
    }

    get queryParams(): SearchParams {
        let urlSearchParams = new URLSearchParams(this.props.location.search);
        if (!urlSearchParams.has('userId') || !urlSearchParams.has('token')) {
            throw new Error('Couldn\'t get query parameters from Url');
        }
        
        return {
            userId: urlSearchParams.get('userId')!,
            token: urlSearchParams.get('token')!
        };
    }

    get store() {
        return this.props.setPasswordFormUiStore!;
    }

    setPasswordViewModel(userId: string, token: string): SetPasswordViewModel {
        return {
            userId: userId,
            token: token,
            newPassword: this.store.newPassword.value,
            repeatedPassword: this.store.repeatedPassword.value,
        };
      }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        let validation = await this.store.form.validate();
        
        if (validation.hasError) {
            return;
        }

        let { userId, token } = this.queryParams;
        let setPasswordVM = this.setPasswordViewModel(userId, token);
        
        try {
          let response = await ResetPasswordService.setPassword(setPasswordVM);
          
          if (!response.succeeded) {
            throw new Error('Could\'t set password.');
          }
        } catch (Error) {
          throw Error;
        }

        this.props.snackBarUiStore!.open(formatToString('settings.contactInfo.changePassword.complete.message'));
        this.props.setPasswordFormUiStore!.resetForm();
        this.props.history.push(`/account/login`);
    }

    render() {
        let hasFieldError = this.store.form.hasFieldError;        
        return (
            <Wrapper>
                <SetPasswordPage
                    formStore={this.store}
                    hasFieldError={hasFieldError}
                    onSubmit={this.onSubmit}
                />
            </Wrapper>
        );
    }

}

export default withRouter(ChangePasswordFormContainer);