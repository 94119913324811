import Cask from "models/cask";
import Event from "models/event";
import Order from "models/order";
import User from "models/user";

export default class SamplingOrder {
  id!: number;
  cask!: Cask;
  user!: User;
  type!: SamplingOrderType;
  status!: SamplingOrderStatus;
  event!: Event;
  distilleryDateTime?: Date;
  amount?: number;
  systembolagetRequestNumber!: string;
  created!: Date;
  updated!: Date;
  order!: Order;
}

export enum SamplingOrderStatus {
  // Pending order, awaiting verification from Box
  Pending,

  // Order Cancelled by orderer
  Cancelled,

  // Order declined by Box
  Declined,

  // Bottling Completed
  Completed,
}

export enum SamplingOrderType {
  Distillery,
  Event,
  Systembolaget,
}
