import { Box, Flex, Link } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { Language } from "stores/domain/locale";

const LanguagePicker = observer(() => {
  const { localeStore } = useStores();
  const { language } = localeStore;

  return (
    <Flex alignItems="center" mx={4}>
      <Link
        onClick={() => localeStore.changeLanguage(Language.Swedish)}
        fontWeight={language === Language.Swedish ? 600 : 400}
      >
        SV
      </Link>
      <Box mx={1}>/</Box>
      <Link
        onClick={() => localeStore.changeLanguage(Language.English)}
        fontWeight={language === Language.English ? 600 : 400}
      >
        EN
      </Link>
    </Flex>
  );
});

export default LanguagePicker;
