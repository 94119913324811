export enum OrderStatus {
  Pending,
  Cancelled,
  Declined,
  Completed,
}

export enum OrderType {
  Sampling = 1,
  Bottling = 2,
  FlavorNote = 3,
  Cask = 4,
  Boxes = 5,
}

export default class Order {
  id!: number;
  guid!: string;
  orderType!: OrderType;
}
