export default {
  "header.logIn.label": "Logga in / Registrera",
  "header.logOut.label": "Logga ut",
  "header.resetPassword.label": "Reset password",
  "header.register.label": "Registrera",
  "header.newMember.label": "Registrera",
  "mobileDrawer.menu.label": "Meny",
  "navbar.home.label": "Hem",
  "navbar.caskShop.label": "Köp fat",
  "navbar.information.label": "Information",
  "navbar.myCasks.label": "Mina fat",
  "navbar.myCasks.noCasks": "Inga fat beställda ännu!",
  "navbar.orderNow.label": "Beställ nu",
  "navbar.settings.label": "Kontoinställningar",
  "navbar.switchAccount.label": "Växla konto",
  "navbar.login.label": "Logga in",
  "home.title": "Mina fat",
  "home.error":
    "Ett fel har uppstått! Var god kontakta vår support om felet skulle kvarstå.",

  // Login

  "login.tab.title": "Logga in",
  "login.loggingInMessage": "Loggar in...",
  "login.fetchingUserDataMessage": "Hämtar användare...",
  "login.whatIsNeededText": `
        Registrerad men kan inte logga in? Verifiera din e-postadress via bekräftelsemeddelandet
        vi skickade när du registrerade dig.
    `,
  "login.requestVerificationEmailLink": "Skicka nytt bekräftelsemeddelande",
  "login.email.label": "E-post",
  "login.email.error.required": "Ange din e-postadress",
  "login.email.error.invalidEmail": "Ange en giltig e-postadress",
  "login.password.label": "Lösenord",
  "login.password.error.required": "Ange ditt lösenord",
  "login.submit.label": "Logga in",
  "login.error.authentication":
    "E-postadressen eller lösenordet som du har angett är fel. Kontrollera att din e-postadess är verifierad om återställning av lösenord inte fungerar.",
  "login.resetPassword.text": "Glömt ditt lösenord?",

  // Home

  "home.hero.slogan": "Norrländsk whisky",
  "home.hero.slogan2": "i personlig tappning",
  "home.hero.becomeCaskOwnerButton.label": "Bli fatägare idag!",
  "home.hero.welcome": "Välkommen till Fatägarwebben!",
  "home.hero.welcomeText": `
        Köp, provsmaka och buteljera din egen whisky som tas fram och lagras i en unik miljö samtidigt
        som du får hjälp och stöttning av världsledande whiskyexperter.
    `,

  "home.instructions.title": "Hur går det till?",
  "home.instructions.shopLinkText": "köp fat",
  "home.instructions.step1": `
        Kolla in vårt utbud av fat och köp ditt eget genom
        att klicka på länken Köp fat. Ungefär halva beloppet betalas
        för själva fatet, och andra halvan betalas som ett lån till oss
        som vi återbetalar när det är dags för dig att buteljera din whisky.
    `,
  "home.instructions.step2": `
        När vi mottagit din betalning registreras du som fatägare och ditt
        fat blir synligt här på fatägarwebben.
    `,
  "home.instructions.step3": `
        Ditt fat fylls på i vårt destilleri i Bjärtrå. Om du vill kan du delta
        i fatfyllningen (gäller ej Sherryfat).
    `,
  "home.instructions.step4": `
        Fatet lagras i vårt lagerhus i minst tre år. Du får möjlighet till årlig provsmakning
        för att följa fatinnehållets utveckling och mognad.
    `,
  "home.instructions.step5": `
        Du bestämmer när det är dags att låta oss buteljera whiskyn. Du kan välja att
        buteljera i omgångar eller ta ut alltihop på en gång.
    `,
  "home.instructions.step6": `
        När du tömmer ditt fat betalas ditt lån tillbaka, och du kan
        använda pengarna till att hämta ut whiskyn hos en godkänd återförsäljare.
        (Notera att alkoholskatt, moms och försäljningsställets påslag tillkommer
        när du hämtar ut den buteljerade whiskyn.)
    `,
  "home.instructions.step7": `
        Det tomma fatet är ditt. Du väljer om du vill ha det skickat hem till dig
        eller om du vill att vi fyller det på nytt.
    `,
  "home.instructions.step8": `
        Dags att njuta av din egen, helt unika maltwhisky.
    `,

  "home.aboutBox.title": "High Coast Distillery",
  "home.aboutBox.text": `
        Många destillerier tillverkar fantastisk whisky, men några få lyckas bättre än
        andra. Som alltid finns det inga rätt eller fel när det gäller smak, men
        kvalitet, noggrannhet och förutsättningar skiljer destillerier från varandra.
        Det finns mycket som gör High Coast Distillery unikt, till exempel klimatet, älven,
        noggrannheten, faten och råvarorna.
    `,

  // Footer
  "footer.followUs": "Följ oss",
  "footer.contactUs": "Kontakta oss",

  // Stepper
  "stepper.mobile.step": "Steg {currentStep} av {maxStep}",

  // Cask shop
  "caskShop.title": "Köp fat",
  "caskShop.add.cart.label": "Lägg till i varukorgen",
  "caskShop.preStored.2.year.label": "Förlagrat 2-årigt destillat",
  "caskShop.extraPrice": "(+ {price}, inkl. moms)",
  "caskShop.caskAddedToCart": "Fatet har lagts till i varukorgen",
  "caskShop.priceExample": "Visa exempel på prisuträkning",
  "caskShop.priceExampleCancel": "Stäng",
  "caskShop.instructions": "Hur det går till",
  "caskShop.instructionsCancel": "Stäng",

  // Cask shop / Cart
  "caskShop.cart.title": "Din varukorg",
  "caskShop.cart.empty.text":
    "Din varukorg är för närvarande tom, men det är lätt åtgärdat!",
  "caskShop.cart.shop.button.label": "Hitta Whisky som passar dig",
  "caskShop.cart.shop.findWhiskybutton.label": "Se vårt fatutbud",

  "caskShop.cart.stepper.cartView": "Varukorg",
  "caskShop.cart.stepper.cartOptions": "Uppgifter",
  "caskShop.cart.stepper.cartPayment": "Summering & betalning",

  // Cask shop / Cart / View
  "caskShop.cart.view.cost.label": "Kostnad",
  "caskShop.cart.view.cost.loan.label": "Lån till High Coast:",
  "caskShop.cart.view.cost.loan.tooltip": `
        På grund av rådande lagstiftning är det inte tillåtet att vi säljer fatets innehåll direkt till dig.
        Därför lånar vi istället pengar av dig som vi betalar tillbaka den dag du väljer att buteljera och
        ta hem din whisky. Buteljering sker då via licensierad återförsäljare (Systembolaget om du hämtar
        ut whiskyn i Sverige).
    `,
  "caskShop.cart.view.cost.cask.vatIncluded.label": "Fatkostnad inkl. moms:",
  "caskShop.cart.view.cost.cask.vatExcluded.label": "Fatkostnad exkl. moms:",
  "caskShop.cart.view.cost.tax.label": "Moms:",
  "caskShop.cart.view.cost.prestored.label": "Förlagring:",
  "caskShop.cart.view.cost.total.label": "Totalt:",
  "caskShop.cart.view.emptyCart.label": "Töm varukorg",
  "caskShop.cart.view.cancelButton": "Avbryt",

  // Cask shop / Cart / Options
  "caskShop.cart.options.caskSign.label": "Fatskylt",
  "caskShop.cart.options.partOwner.label": "Fatdelägare",
  "caskShop.cart.options.partOwner.add.label": "Lägg till delägare",
  "caskShop.cart.options.caskFilling.label": "Fatfyllning",
  "caskShop.cart.options.person.information.label": "Dina uppgifter",
  "caskShop.cart.options.company.information.label": "Företagets uppgifter",
  "caskShop.cart.options.takePart.description":
    "Jag vill delta när mitt/mina fat " +
    "fylls och önskar bli kontaktad om detta.",
  "caskShop.cart.options.freeTshirt.label": "T-shirt på köpet!",
  "caskShop.cart.options.freeTshirt.description": `Som fatägare får du en gratis t-shirt. Ange din storlek.
      (Vill du köpa fler tröjor, kontakta fatägarservice på: info@highcoastwhisky.se)`,

  // Cask shop / Cart / Logged out
  "caskShop.cart.loggedOut.login": "Redan kund hos High Coast?",
  "caskShop.cart.loggedOut.register": "Inte registrerad?",
  "caskShop.cart.loggedOut.register.text":
    "Skapa ett konto för att fullfölja ditt köp.",

  // Cask shop / Cart / Payment
  "caskShop.cart.payment.caskSign.column": "Fatskylt:",
  "caskShop.cart.payment.partOwner.column": "Fatdelägare:",
  "caskShop.cart.payment.caskFilling.column": "Fatfyllning:",
  "caskShop.cart.payment.filling.takePart": "Jag önskar delta",
  "caskShop.cart.payment.filling.not.takePart": "Jag vill ej delta",
  "caskShop.cart.payment.freeTshirt.column": "T-shirt storlek:",
  "caskShop.cart.payment.title": "Välj betalsätt",
  "caskShop.cart.payment.option.invoice": "Faktura",
  "caskShop.cart.payment.option.cardInvoicePartPayment":
    "Kort / Faktura / Delbetalning",

  "caskShop.cart.payment.invoice.selected":
    "En faktura från High Coast kommer att skickas till din e-post",
  "caskShop.cart.payment.error.unkown":
    "Ett okänt fel har uppstått. Vänligen försök igen senare. Kontakta kundtjänst om problemet kvarstår.",

  // Cask shop / Steps
  "caskShop.steps.confirm": "Genomför köp",
  "caskShop.steps.cancel": "Avbryt",
  "caskShop.steps.back": "Tillbaka",
  "caskShop.steps.next": "Nästa",

  // Cask shop / complete
  "caskShop.complete.orderCompleteText":
    "Välkommen till fatägarlivet! Ditt fat är beställt och en bekräftelse är skickad till {email}. " +
    "Nu kan du luta dig tillbaka, trygg i vetskapen om att din sprit är i gott förvar hos oss i " +
    "Bjärtrå Destilleri.",
  "caskShop.complete.backButton": "Gå till fatet",
  "caskShop.complete.title": "Tack för din beställning!",
  "caskShop.complete.orderIdLabel": "Order-id:",

  // Cask

  "cask.tabs.about": "Om fatet",
  "cask.tabs.orders": "Beställ",
  "cask.tabs.history": "Historik",
  "cask.info.dateCreated": "Skapad",
  "cask.info.dateCreated.unknown": "Okänt",
  "cask.info.flavourNote": "Smaknot",
  "cask.info.bottlingDate": "Buteljering",
  "cask.info.notBottled": "Har aldrig buteljerats",
  "cask.info.notFlavoured": "Har ingen smaknot",
  "cask.unknown": "Okänt fat",
  "cask.isEmptied": "Tömd",
  "cask.recipe.unknown": "Okänt recept",
  "cask.info.storageTime": "Lagringstid",
  "cask.info.hasBeenEmptied": "Fatet har tömts",
  "cask.info.fullbottlingOrdered": "Helbuteljering beställd",
  "cask.info.notFilled": "Har ej tappats",
  "cask.info.ownerName": "Fatägare",
  "cask.info.download.caskOwnerProof": "Ladda ner fatägarbevis",
  "cask.info.fileName.caskOwnerProof": "fatägarbevis.pdf",
  "cask.info.download.orderSettlementLatest":
    "Ladda ner senaste avräkningsnota",
  "cask.info.download.orderSettlement": "Ladda ner avräkningsnota",
  "cask.info.fileName.orderSettlement": "avräkningsnota.pdf",

  "cask.info.age.today": "fylldes idag",
  "cask.info.age":
    "{years, plural, =0 {} one {{years} år} other {{years} år}} " +
    "{months, plural, =0 {} one {{months} månad} other {{months} månader}} " +
    "{days, plural, =0 {} one {{days} dag} other {{days} dagar}}",

  // Cask / About
  "cask.info.caskNumber.label": "Fatnummer",
  "cask.info.caskSign.label": "Fatskylt",
  "cask.info.unknown": "Mer information kommer när ditt fat är fyllt",
  "cask.info.caskSize.label": "Fatvolym",
  "cask.info.caskSize.liter.unit": "Liter",
  "cask.info.caskType.label": "Fat-typ",
  "cask.info.caskRecipe.label": "Recept",
  "cask.info.volumeFilled.label": "Fyllningsvolym",
  "cask.info.stockNumber.label": "Lagerplats",
  "cask.info.caskLoan.label":
    "Fordran på High Coast att få tillbaka vid helbuteljering",
  "cask.info.caskPrice.label": "Fatkostnad (exkl. moms)",
  "cask.info.alcoholPercentage.label": "Alkoholhalt vid tappning",
  "cask.info.filled.label": "Tappat på fat",
  "cask.info.liquorFilled.label": "Spritdatum",

  // Cask / Orders

  "orders.sendingOrderMessage": "Skickar order...",
  "orders.orderPage.bottling.title": "Beställ buteljering",
  "orders.orderPage.bottling.text": `
        Välj själv hur och när du vill hämta ut dina flaskor.
    `,

  "orders.orderPage.sampling.title": "Beställ fatprov",
  "orders.orderPage.sampling.text": `
        Provsmaka din whisky på destilleriet i Bjärtrå,
        på mässor eller genom att hämta ut ditt fatprov på Systembolaget.
    `,

  "orders.orderPage.flavourNote.title": "Beställ smaknot",
  "orders.orderPage.flavourNote.text": `
        Låt vår kvalitetsansvarige bedöma din whisky och ge dig smaknoter och en uppskattning av återstående lagringstid
    `,

  // Cask / Orders / Bottling Order

  "orders.bottlingOrder.title": "Beställ buteljering",

  "orders.bottlingOrder.stepper.bottlingInformation": "Buteljeringsinformation",
  "orders.bottlingOrder.stepper.delivery": "Utlämning",
  "orders.bottlingOrder.stepper.label": "Etikett",
  "orders.bottlingOrder.stepper.boxes": "Kartonger",
  "orders.bottlingOrder.stepper.afterBottling": "Efter butleljering",
  "orders.bottlingOrder.stepper.contactInformation": "Kontaktinfo",
  "orders.bottlingOrder.stepper.confirm": "Sammanfattning",

  "orders.bottlingOrder.backButton": "Föregående",
  "orders.bottlingOrder.cancelButton": "Avbryt",
  "orders.bottlingOrder.nextButton": "Nästa",
  "orders.bottlingOrder.confirmButton": "Bekräfta",

  "orders.bottlingOrder.isSwedishBankAccount.label":
    "Banktyp (Återbetalning av lån)",
  "orders.bottlingOrder.isSwedishBankAccount.infoWindowText": `När du köpte ditt fat reserverades
    en del av beloppet som ett lån till oss. Detta lån betalar vi nu tillbaka så du kan använd
    pengarna till att köpa ut whiskyn på ditt valda utlämningsställe`,
  "orders.bottlingOrder.isSwedishBankAccount.true": "Svensk bank",
  "orders.bottlingOrder.isSwedishBankAccount.false": "Utländsk bank",
  "orders.bottlingOrder.clearingNumber.label": "Clearingnummer",
  "orders.bottlingOrder.clearingNumber.error.required":
    "Clearingnummer måste fyllas i",
  "orders.bottlingOrder.clearingNumber.error.maxLength":
    "Clearingnummret kan max innehålla {maxLength} tecken",
  "orders.bottlingOrder.swedishBankAccountNumber.label": "Kontonummer",
  "orders.bottlingOrder.swedishBankAccountNumber.error.required":
    "Kontonummer måste fyllas i",
  "orders.bottlingOrder.swedishBankAccountNumber.error.minLength":
    "Kontonumret måste minst innehålla {minLength} tecken",
  "orders.bottlingOrder.swedishBankAccountNumber.error.maxLength":
    "Kontonumret kan max innehålla {maxLength} tecken",
  "orders.bottlingOrder.foreignBankAccountNumber.label": "IBAN-kontonummer",
  "orders.bottlingOrder.foreignBankAccountNumber.error.required":
    "IBAN-nummer måste fyllas i",
  "orders.bottlingOrder.foreignBankAccountNumber.error.minLength":
    "Kontonumret måste minst innehålla {minLength} tecken",
  "orders.bottlingOrder.foreignBankAccountNumber.error.maxLength":
    "Kontonumret kan max innehålla {maxLength} tecken",

  "orders.bottlingOrder.bottlingAmount.label": "Buteljeringsmängd",
  "orders.bottlingOrder.bottlingAmount.dropdown.fullBottling": "Hela fatet",
  "orders.bottlingOrder.bottlingAmount.dropdown.partBottling": "Delbuteljering",
  "orders.bottlingOrder.bottlingAmount.note": `Vid delbuteljering tillkommer en avgift på <strong>{amount} SEK</strong>.
      Av kvalitetsskäl bör den sista delbuteljeringen vara minst halva fatet.`,
  "orders.bottlingOrder.numberOfBottles.label": "Antal flaskor",
  "orders.bottlingOrder.numberOfBottles.bottles": "{number}st flaskor",
  "orders.bottlingOrder.useDefaultAlcoholContent.label": "Spädning",
  "orders.bottlingOrder.useDefaultAlcoholContent.true": "Ingen spädning",
  "orders.bottlingOrder.useDefaultAlcoholContent.false": "Bestäm alkoholhalt",
  "orders.bottlingOrder.alcoholContent.hint": "Ange önskad alkoholhalt",
  "orders.bottlingOrder.diluteTo": "Späd till",
  "orders.bottlingOrder.alcoholContent.label": "Alkoholvolym (%)",
  "orders.bottlingOrder.alcoholContent.error.required":
    "Alkoholhalt måste anges",
  "orders.bottlingOrder.alcoholContent.error.minValue":
    "Lägsta tillåtna alkoholhalt är {minValue}%",
  "orders.bottlingOrder.alcoholContent.note": `Vid spädning tillkommer en avgift på <strong>{amount} SEK</strong>.`,
  "orders.bottlingOrder.country.label": "Utlämningsland",
  "orders.bottlingOrder.country.swedenSystembolaget": "Sverige (Systembolaget)",
  "orders.bottlingOrder.country.sweden": "Sverige",
  "orders.bottlingOrder.country.germany": "Tyskland",
  "orders.bottlingOrder.country.estonia": "Estland",
  "orders.bottlingOrder.country.denmark": "Danmark",
  "orders.bottlingOrder.countryDescriptionLink": "Mer om utlämningsplatsen",
  "orders.bottlingOrder.date.pickup.label": "Uthämtning från och med",
  "orders.bottlingOrder.date.label": "Utlämningstillfälle",
  "orders.bottlingOrder.date.error.required": "Utlämningstillfälle krävs",
  "orders.bottlingOrder.customization.label": "Anpassa etiketten",
  "orders.bottlingOrder.customization.oldLabel": "BOX etikett",
  "orders.bottlingOrder.customization.none":
    "Standard (som etikett till höger, vitt fält i mitten)",
  "orders.bottlingOrder.customization.personalText": "Lägg till personlig text",
  "orders.bottlingOrder.customization.file": "Lägg till personlig logotyp",
  "orders.bottlingOrder.customization.file.error.required":
    "En fil måste väljas",
  "orders.bottlingOrder.customization.uploadButtonLabel": "Välj fil",
  "orders.bottlingOrder.customization.bottlingHint": "jpeg, png eller gif",

  "orders.bottlingOrder.labelText.label": "Text på flasketikett",
  "orders.bottlingOrder.labelText.placeholder": "Ange text på etiketten",
  "orders.bottlingOrder.labelText.error.maxLength":
    "Etiketten kan max innehålla {maxLength} bokstäver",

  "orders.bottlingOrder.label.managerDescription": `Här utformar du dina egna personliga flasketiketter.
        Första etiketten är gratis,
        och därefter kostar varje ny etikett <strong>{amount} SEK</strong>. <br /> <br /> 
        En likadan etikett ingår för varje flaska. Ifall du väljer flera etiketter så får du justera fördelningen av etiketterna som du önskar.`,
  "orders.bottlingOrder.label.rule.name.single": "{numberOfLabels} etikett:",
  "orders.bottlingOrder.label.rule.name.multiple":
    "{numberOfLabels} etiketter:",
  "orders.bottlingOrder.label.rule.description.one":
    "Etiketten sätts på alla flaskor",
  "orders.bottlingOrder.label.rule.description.two":
    "50% av flaskorna får ena etiketten och 50% får den andra",
  "orders.bottlingOrder.label.rule.description.three": `33% av flaskorna får ena etiketten,
        33% får den andra, och 33% får den sista`,
  "orders.bottlingOrder.label.preview": `Förhandsgranskning`,
  "orders.bottlingOrder.label.title.first":
    "Etikett {labelNumber} (första etiketten är gratis)",
  "orders.bottlingOrder.label.title.subsequent":
    "Etikett {labelNumber} ({amount} SEK)",
  "orders.bottlingOrder.label.add": "Lägg till etikett",
  "orders.bottlingOrder.label.total": "Total kostnad för etiketter:",

  "orders.bottlingOrder.label.additionalInfo.explanation": `
        Om du har några särskilda önskemål när det gäller etiketter, kan du skriva det nedan:
    `,
  "orders.bottlingOrder.label.additionalInfo.label": "Särskilda önskemål",

  "orders.bottlingOrder.afterBottling.label": "Efter Buteljering",
  "orders.bottlingOrder.afterBottling.placeHolder": "Välj ett alternativ",
  "orders.bottlingOrder.afterBottling.dropdown.refill":
    "Refill orökt destillat ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.refill.withoutAmount":
    "Refill orökt destillat",
  "orders.bottlingOrder.afterBottling.dropdown.refillSmokey":
    "Refill rökigt destillat ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.refillSmokey.withoutAmount":
    "Refill rökigt destillat",
  "orders.bottlingOrder.afterBottling.dropdown.refillTwoYear":
    "Refill med 2-årigt orökt destillat ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.refillTwoYear.withoutAmount":
    "Refill med 2-årigt orökt destillat",
  "orders.bottlingOrder.afterBottling.dropdown.refillTwoYearSmokey":
    "Refill med 2-årigt rökigt destillat ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.refillTwoYearSmokey.withoutAmount":
    "Refill med 2-årigt rökigt destillat",
  "orders.bottlingOrder.afterBottling.dropdown.save":
    "Skicka fatet till mig ({amount}kr)",
  "orders.bottlingOrder.afterBottling.dropdown.withoutAmount":
    "Skicka fatet till mig",
  "orders.bottlingOrder.afterBottling.dropdown.sell":
    "Jag vill att High Coast köper fatet av mig",
  "orders.bottlingOrder.afterBottling.error.required": "Välj ett alternativ",
  "orders.bottlingOrder.newCaskText.label": "Personlig text på ny fatskylt",
  "orders.bottlingOrder.newCaskText.placeholder": "Johns nya whisky",
  "orders.bottlingOrder.newCaskText.error.maxLength":
    "Den personliga texten får högst innehålla {maxLength} tecken",
  "orders.bottlingOrder.fullName.label": "Fullständigt namn",
  "orders.bottlingOrder.fullName.error.required": "Fullständigt namn krävs",
  "orders.bottlingOrder.fullName.error.maxLength":
    "Namnet kan max innehålla {maxLength} tecken",
  "orders.bottlingOrder.email.label": "Email",
  "orders.bottlingOrder.email.error.required": "Email krävs",
  "orders.bottlingOrder.email.error.isEmail": "Måste vara en giltig email",
  "orders.bottlingOrder.email.error.maxLength":
    "Emailadressen kan max innehålla {maxLength} tecken",
  "orders.bottlingOrder.phoneNumber.label": "Telefonnummer",
  "orders.bottlingOrder.phoneNumber.error.required": "Telefonnummer krävs",
  "orders.bottlingOrder.phoneNumber.error.isNumber": "Måste vara ett nummer",
  "orders.bottlingOrder.phoneNumber.error.minLength":
    "Telefonnumret måste minst innehålla {minLength} siffror",
  "orders.bottlingOrder.phoneNumber.error.maxLength":
    "Telefonnumret kan max innehålla {maxLength} siffror",

  "orders.bottlingOrder.bottlingInformation.title": "Buteljeringsinformation",
  "orders.bottlingOrder.label.title": "Etikett {index}",
  "orders.bottlingOrder.afterBottling.title": "Efter buteljering",
  "orders.bottlingOrder.contactInformation.title": "Kontaktinformation",

  "orders.bottlingOrder.confirmation.labelCustomization.label":
    "Antal unika etiketter",
  "orders.bottlingOrder.confirmation.boxes.boxAmount.label": "Antal kartonger",
  "orders.bottlingOrder.confirmation.boxes.wantsPersonalizedLabel.label":
    "Personlig etikett",
  "orders.bottlingOrder.confirmation.boxes.wantsPersonalizedLabel.yes": "Ja",
  "orders.bottlingOrder.confirmation.boxes.wantsPersonalizedLabel.no": "Nej",
  "orders.bottlingOrder.confirmation.bankAccountNumber.label":
    "Återbetalning av lån till kontonummer",
  "orders.bottlingOrder.emptyValue": "Ingen",

  "orders.bottlingOrder.complete.orderCompleteText":
    "Dina flaskor är på väg och kommer finnas tillgängliga på ditt valda uthämtningsställe inom kort! " +
    "En bekräftelse är skickad till <strong>{email}</strong>.",
  "orders.bottlingOrder.complete.backButton": "Tillbaka till fatet",
  "orders.bottlingOrder.complete.title": "Tack för din beställning!",
  "orders.bottlingOrder.complete.orderIdLabel": "Order-id:",
  "orders.bottlingOrder.complete.orderBoxesText": `Passa på att köpa kartonger till dina flaskor!
      Just nu erbjuder vi flask-kartonger för {priceAmount}kr/st.`,
  "orders.bottlingOrder.complete.orderBoxesButton": "Beställ kartonger",

  "orders.bottlingOrder.priceExample.loadingPrices": "Laddar priser...",
  "orders.bottlingOrder.priceExample.title": "Räkneexempel",
  "orders.bottlingOrder.priceExample.description": `
        Uppskattade whiskypriser för ditt fat och recept med dagens gällande alkoholskatter,
     valutakurser och momssatser. Räknat på {numberOfBottles} flaskor och {alcoholAmount}% alkohol.
     `,
  "orders.bottlingOrder.priceExample.descriptionNoLoanClaim": `
        Uppskattade whiskypriser för fat och recept med dagens gällande alkoholskatter,
     valutakurser och momssatser. Räknat på {numberOfBottles} flaskor och {alcoholAmount}% alkohol.
     `,
  "orders.bottlingOrder.priceExample.descriptionNoLoanClaimWarning": `
        <div style="color:red">Räkneexemplet nedan baseras på ett lån som är {smokedRecipePrice}kr.</div>
    `,
  "orders.bottlingOrder.priceExample.openButtonClosed": "Visa uträkning",
  "orders.bottlingOrder.priceExample.openButtonOpen": "Dölj uträkning",
  "orders.bottlingOrder.priceExample.bottleLabel": "flaska",
  "orders.bottlingOrder.priceExample.netPrice": "High Coast nettopris",
  "orders.bottlingOrder.priceExample.alcoholDuty": "Alkoholskatt",
  "orders.bottlingOrder.priceExample.vendorCharge": "Butikspåslag",
  "orders.bottlingOrder.priceExample.vat": "Moms",
  "orders.bottlingOrder.priceExample.exchangeRate": "Växelkurs",
  "orders.bottlingOrder.error.unknon":
    "Ett okänt fel har uppstått. Vänligen försök igen senare. Kontakta kundtjänst om problemet kvarstår.",

  // Orders sampling
  "orders.sampling.title": "Beställ fatprov",
  "orders.sampling.submitButton": "Slutför beställning",

  "orders.sampling.description": `
        Vi rekommenderar att du provar ditt fat en gång om året,
        även redan efter det första årets lagringstid.
        Det händer så mycket på kort tid i små fat och det är kul att följa utvecklingen i det.`,

  "orders.sampling.orderType": "Provställe",
  "orders.sampling.orderType.hint":
    "OBS! Fatprovet måste förtäras på plats på grund av rådande alkohol-lagstiftning",
  "orders.sampling.orderType.distillery": "Destilleriet i Bjärtrå",
  "orders.sampling.orderType.event": "Mässor och fatägarträffar",
  "orders.sampling.orderType.systembolaget": "Systembolaget",

  "orders.sampling.orderType.error.required": "Provställe måste väljas",
  "orders.sampling.distillery.date": "Önskat datum",
  "orders.sampling.distillery.date.error.required": "Önskat datum måste anges",
  "orders.sampling.distillery.time": "Önskad tid",
  "orders.sampling.distillery.time.error.required": "Önskad tid måste anges",
  "orders.sampling.distillery.price": `Total kostnad (betalas på plats):
        <span class="price">{price}</span>
        <span class="currency">SEK</span>`,

  "orders.sampling.event.name": "Tillfälle",
  "orders.sampling.event.name.loading": "Laddar...",
  "orders.sampling.event.name.error.required": "Tillfälle måste anges",

  "orders.sampling.event.amount": "Mängd",
  "orders.sampling.event.amount.value": "{amount} cl",
  "orders.sampling.event.amount.error.required": "Mängd måste anges",
  "orders.sampling.event.amount.error.minNumber": "En större mängd måste anges",

  "orders.sampling.systembolaget.title":
    "Följ instruktionerna nedan för att genomföra en beställning till Systembolaget",
  "orders.sampling.systembolaget.step1.label": `
        Gå till
        <a target="_blank" href="https://www.systembolaget.se/bestalla-och-handla/privatimport/forfragan">Systembolagets hemsida</a>
        och logga in. (Skapa en användare om du saknar sådan)
    `,
  "orders.sampling.systembolaget.step2.label":
    'Skapa en ny förfrågan och välj den butik där du vill hämta ut fatprovet och tryck "Fortsätt"',
  "orders.sampling.systembolaget.step2.store":
    '<span class="normal-font">Välj den butik där du vill hämta ut fatprovet och tryck "Fortsätt"</span>',
  "orders.sampling.systembolaget.step2.distillery":
    '<span class="normal-font">Välj säljare: High Coast, tryck "Spara och fortsätt"</span>',
  "orders.sampling.systembolaget.step2.new":
    'Tryck på rutan "+ lägg till dryck" och fyll i följande uppgifter för varje fatprov du vill beställa:',
  "orders.sampling.systembolaget.step2.volume":
    '30, 100, 200 <span class="normal-font">eller</span> 500',
  "orders.sampling.systembolaget.step2.ready":
    'Välj sedan "Spara och fortsätt"',
  "orders.sampling.systembolaget.step2.contact":
    "Kontrollera att dina kontaktuppgifter stämmer och att du valt rätt butik, du har fortfarande möjlighet att ändra om något blivit fel.",
  "orders.sampling.systembolaget.step3.label":
    'Läs och godkänn Systembolagets allmänna villkor och privatimportsvillkor och "Skicka in förfrågan"',
  "orders.sampling.systembolaget.step4.label":
    "Ange förfrågansnumret du får i bokningsbekräftelsen från Systembolaget i fältet nedan",
  "orders.sampling.systembolaget.step4.requestNumber.label": `Förfrågansnummer från Systembolaget`,
  "orders.sampling.systembolaget.step4.requestNumber.error.required":
    "Förfrågansnumret måste anges",
  "orders.sampling.systembolaget.step4.notice":
    "Missas någon punkt kommer ditt ärende inte kunna bli hanterat på rätt sätt och ledtiden förlängs då din förfrågan kommer att bli annullerad av oss. Om förfrågan blir annullerad kommer Systembolaget meddela detta via mejl och du är då välkommen att lägga en ny förfrågan.",
  "orders.sampling.systembolaget.step4.more": `
        För fullständiga instruktioner <a href='https://my.highcoastwhisky.se/information/30/61' target='_blank'>Klicka här</a>
    `,
  "orders.sampling.error.unknon":
    "Ett okänt fel har uppstått. Vänligen försök igen senare. Kontakta kundtjänst om problemet kvarstår.",

  "orders.sampling.complete.orderCompleteText":
    "Ditt fatprov är nu beställt och kommer att leta sig till din strupe inom kort! " +
    "En bekräftelse är skickad till <strong>{email}</strong>.",
  "orders.sampling.complete.backButton": "Tillbaka till fatet",
  "orders.sampling.complete.title": "Tack för din beställning!",
  "orders.sampling.complete.orderIdLabel": "Order-id:",

  // Orders flavour note
  "orders.flavourNote.title": "Beställ smaknot",
  "orders.flavourNote.description": `
        Låt vår kvalitetsansvarige bedöma din whisky och ge dig smaknoter och
        en uppskattning av återstående lagringstid.
    `,
  "orders.flavourNote.obtainedLanguage":
    "Välj språk som du vill erhålla ditt smaknot i",
  "orders.flavourNote.obtainedLanguage.swedish": "Svenska",
  "orders.flavourNote.obtainedLanguage.english": "English",
  "orders.flavourNote.price": `
        Total kostnad inkl. moms (betalning via faktura):
        <strong>{price}
        SEK</strong>
    `,
  "orders.flavourNote.submitButton": "Beställ smaknot",

  "orders.flavourNote.complete.orderCompleteText":
    "Din smaknot är beställd och vår expert återkommer med ett utlåtande inom kort! " +
    "En bekräftelse är skickad till <strong>{email}</strong>.",
  "orders.flavourNote.complete.backButton": "Tillbaka till fatet",
  "orders.flavourNote.complete.title": "Tack för din beställning!",
  "orders.flavourNote.complete.orderIdLabel": "Order-id:",
  "orders.flavourNote.error.unknon":
    "Ett okänt fel har uppstått. Vänligen försök igen senare. Kontakta kundtjänst om problemet kvarstår.",

  // Orders boxes
  "orders.boxes.description": `
      Passa på att köpa kartonger till din buteljering.
      Köp lika många kartonger som antal buteljerade flaskor,
      eller välj fritt antal. Kartonger kostar {price}kr/styck eller max {max}kr
    `,
  "orders.boxes.loadingBottlingOrder": "Hämtar butlejeringsorder...",
  "orders.boxes.numberOfCustomBoxesLabel": "Välj {min}-{max}",
  "orders.boxes.boxAmount.placeHolder": "Välj ett alternativ",
  "orders.boxes.boxAmount.sameAsBottles.fullBottling":
    "Samma antal kartonger som beställda flaskor",
  "orders.boxes.boxAmount.sameAsBottles.partBottling": `Samma antal kartonger som beställda flaskor
    ({numberOfBottles}st)`,
  "orders.boxes.boxAmount.custom": "Fritt antal",
  "orders.boxes.boxAmount.none": "Inga kartonger",
  "orders.boxes.boxAmount.error.required": "Välj ett alternativ",
  "orders.boxes.wantsPersonalizedLabelsLabel": `Jag önskar personliga etiketter
     till mina kartonger ({priceAmount}kr/etikett)`,
  "orders.boxes.wantsPersonalizedLabelsLabel.info": `Kartongetiketterna får samma fördelning och utseende
    som flasketiketterna. Kartongetiketter och kartonger levereras med din buteljeringsbeställning och du får
    klistra fast dem själv`,
  "orders.boxes.boxLabelNote":
    "OBS! Kartongetiketterna kommer att ha samma utseende som dina flasketiketter",
  "orders.boxes.total": "Totalkostnad:",
  "orders.boxes.submitButton": "Beställ kartonger",

  "orders.boxes.complete.orderCompleteText": `Dina kartonger är beställda och kommer skickas hem till dig inom kort!
      En bekräftelse är skickad till {email}.`,
  "orders.boxes.complete.backButton": "Tillbaka till fatet",
  "orders.boxes.complete.title": "Tack för din beställning!",
  "orders.boxes.complete.orderIdLabel": "Ordernummer:",

  // History
  "history.order.emptyHistory": "Inga beställningar hittades.",
  "history.loading": "Laddar historik...",

  "history.order.bottling.pending": "Buteljering beställd",
  "history.order.bottling.cancelled": "Buteljering avbruten",
  "history.order.bottling.declined": "Buteljering nekad",
  "history.order.bottling.completed": "Buteljering slutförd",

  "history.order.boxes.pending": "Kartonger beställda",
  "history.order.boxes.cancelled": "Kartongbeställning avbruten",
  "history.order.boxes.declined": "Kartongbeställning nekad",
  "history.order.boxes.completed": "Kartongbeställning slutförd",

  "history.order.flavourNote.pending": "Smaknot beställd",
  "history.order.flavourNote.cancelled": "Smaknotbeställning avbruten",
  "history.order.flavourNote.declined": "Smaknotbeställning nekad",
  "history.order.flavourNote.completed": "Smaknotbeställning slutförd",

  "history.order.sampling.pending": "Fatprov beställt",
  "history.order.sampling.cancelled": "Fatprovbeställning avbruten",
  "history.order.sampling.declined": "Fatprovbeställning nekad",
  "history.order.sampling.completed": "Fatprovbeställning slutförd",

  "history.order.cask.pending": "Fat beställt",
  "history.order.cask.cancelled": "Fatbeställning avbruten",
  "history.order.cask.declined": "Fatbeställning nekad",
  "history.order.cask.completed": "Fatbeställning slutförd",

  "history.progress.caskFilled": "Fatet fyllt",
  "history.progress.caskSignAttached": "Fatskylt fastsatt",

  // History timeline

  "history.order.type.buy.label": "Köporder registrerad - {orderText}",
  "history.order.type.production.label":
    "Beställning registrerad - {orderText}",
  "history.order.type.unknown.label": "Order registrerad",

  // History Item Modal

  "history.modal.title": "Orderhistorik",
  "history.modal.type.label": "Typ",
  "history.modal.type.purchase": "Köporder",
  "history.modal.type.production": "Produktionsorder",
  "history.modal.type.unknown": "Okänd order",
  "history.modal.vismaOrderId.label": "Ordernummer",
  "history.modal.activity.label": "Aktivitet",
  "history.modal.action.label": "Handling",
  "history.modal.bottlingVolume.label": "Buteljeringsvolym",
  "history.modal.bottlingRound.label": "Buteljeringsrunda",
  "history.modal.dilution.label": "Utspädning",
  "history.modal.status.label": "Status",
  "history.modal.preferedDeliveryTime.label": "Önskat leveransdatum",
  "history.modal.alcohol.label": "Alkoholhalt",
  "history.modal.orderRow.description.label": "Beskrivning",
  "history.modal.orderRow.finishDate.label": "Klardatum",
  "history.modal.orderRow.price.label": "Pris",
  "history.modal.orderRow.amount.label": "Antal",
  "history.modal.orderRow.quantity.label": "Mängd",

  "history.order.modal.okButton.label": "OK",

  // About

  "about.members.info": `
        Här kan du bjuda in fatdelägare eller andra som delar ditt whiskyintresse för att följa ditt fat.
        Fatdelägare kan se fatet, men kan inte lägga beställningar.
    `,
  "about.members.inviteButtonLabel": "Bjud in fatdelägare",
  "about.members.modal.title": "Bjud in fatdelägare",
  "about.members.modal.inviting": "Bjuder in fatdelägare...",
  "about.members.modal.fullName": "Namn",
  "about.members.modal.fullName.error.maxLength":
    "Namnet kan max innehålla {maxLength} tecken",
  "about.members.modal.fullName.error.required": "Fullständigt namn krävs",
  "about.members.modal.email": "E-postadress",
  "about.members.modal.email.error.maxLength":
    "Emailadressen kan max innehålla {maxLength} tecken",
  "about.members.modal.email.error.required": "Email krävs",
  "about.members.modal.email.error.isEmail": "Måste vara en giltig email",
  "about.members.modal.firstname": "Förnamn",
  "about.members.modal.firstname.required": "Förnamn krävs",
  "about.members.modal.lastname": "Efternamn",
  "about.members.modal.lastname.required": "Efternamn krävs",
  "about.members.modal.submit": "Skicka inbjudan",
  "about.members.modal.cancel": "Avbryt",
  "about.members.modal.error.userNotFound":
    "Kunde inte hitta någon användare med e-postadressen {email}.",
  "about.members.modal.error.userAlreadyMember": `Användaren med e-postadressen {email} är redan en fatdelägare!`,
  "about.members.modal.error.unknown": `Ett okänt fel har uppstått.`,

  // Settings

  "settings.title": "Inställningar",
  "settings.message": "My message in english!!",
  "settings.change.password": "Byt lösenord",
  "settings.contactInfo.save": "Spara",
  "settings.updatingUser": "Uppdaterar användaren...",

  // Reset password
  "reset-password.label": "Återställ ditt lösenord",
  "reset-password.button": "Skicka återställningsmeddelande",
  "reset-password.description":
    "Ange din e-postadress för att återställa ditt lösenord",
  "reset-password.complete.text":
    "Ett återställningsmeddelande har skickats till:",
  "reset-password.password.label": "Nytt lösenord",
  "reset-password.email.error.notFound":
    "Det finns ingen användare kopplad till denna mail",

  // Register

  "register.tab.title": "Registrera",
  "login.registratingMessage": "Registrerar...",
  "register.company.title": "Företagsregistrering",
  "register.intro": "Fyll i uppgifterna för att påbörja din whisky-resa.",
  "register.clickHere": "Registrera som företag",
  "register.company.clickHere": "Registrera som privatperson",

  "register.orgNumber.label": "Organisationsnummer",
  "register.orgNumber.error.required": "Ange organisationsnummer",

  "register.orgName.label": "Företagets namn",
  "register.orgName.error.required": "Ange företagets namn",

  "register.contactFirstName.label": "Förnamn (kontaktperson)",
  "register.contactFirstName.error.required":
    "Förnamn för en kontaktperson måste anges",

  "register.contactLastName.label": "Efternamn (kontaktperson)",
  "register.contactLastName.error.required":
    "Förnamn för en kontaktperson måste anges",

  "register.phoneNumber.label": "Telefonnummer",
  "register.phoneNumber.error.required": "Ange telefonnummer",

  "register.firstName.label": "Förnamn",
  "register.firstName.error.required": "Ange ditt förnamn",

  "register.lastName.label": "Efternamn",
  "register.lastName.error.required": "Ange ditt efternamn",

  "register.password.label": "Lösenord",
  "register.password.error.required":
    "Lösenordet måste vara minst {minLength} tecken och innehålla minst {minDigits} siffra",
  "register.password.error.hasNumber":
    "Ditt lösenord måste innehålla minst {minDigits} siffra",
  "register.password.error.minLength":
    "Ditt lösenord måste vara minst {minLength} tecken",

  "register.repeatedPassword.label": "Upprepa lösenordet",
  "register.repeatedPassword.error.required": "Upprepa ditt lösenord",
  "register.repeatedPassword.error.match": "Lösenorden matchar inte",

  "register.email.label": "E-post",
  "register.email.error.required": "Ange din e-postadress",
  "register.email.error.unavailable": "E-postadressen är redan registrerad",
  "register.email.error.invalidEmail": "Ange en giltig e-postadress",
  "register.submit": "Registrera",

  "register.terms.text":
    "Genom att registrera dig godkänner du till High Coast-whiskys {terms}",
  "register.terms.linkText": "användarvillkor",

  "register.complete.header": "Registreringen lyckades!",
  "register.complete.yourEmail": "din e-postadress",
  "register.complete.text": "Vi har mailat en bekräftelse till",
  "register.complete.activate":
    "Klicka på länken i mailet för att aktivera ditt konto.",

  "register.verify.loading": "Bekräftar din e-postadress...",
  "register.verify.successHeader": "Din e-postadress är nu bekräftad",
  "register.verify.successText": "Du kan nu logga in.",
  "register.verify.failText":
    "Kunde inte bekräfta din e-postadress. Bekräftelselänken var inte giltig.",
  "register.verify.retrySend": "Skicka ett nytt bekräftelsemeddelande",

  "register.verify.new.intro":
    "Vänligen ange din e-post så kan vi skicka ett nytt bekräftelsemeddelande.",
  "register.verify.new.emailSent":
    "Ett bekräftelsemeddelande har skickats till {email}",
  "register.verify.new.email.label": "E-postadress",
  "register.verify.new.submit": "Skicka bekräftelse",
  "register.verify.new.email.error.invalid": "Ange en giltig e-postadress",
  "register.verify.new.email.error.notFound": "E-postadressen hittades inte",
  "register.verify.new.email.error.alreadyVerified":
    "E-postadressen som du har angivit är redan bekräftad",

  // Change password
  "settings.contactInfo.changePassword.currentPassword.label":
    "Nuvarande lösenord",
  "settings.contactInfo.changePassword.complete.message":
    "Byte av lösenord lyckades!",

  // Change contact info
  "settings.contactInfo.label": "Din profil",
  "settings.contactInfo.company.label": "Din organisation",

  "settings.contactInfo.orgNumber.label": "Organisationsnummer",
  "settings.contactInfo.orgNumber.error.required":
    "Ange företagets organisationsnummer",

  "settings.contactInfo.orgName.label": "Företagets namn",
  "settings.contactInfo.orgName.error.required": "Ange företagets namn",

  "settings.contactInfo.socialSecurity.label": "Personnummer",
  "settings.contactInfo.socialSecurity.error.required":
    "Ange ditt personnummer",

  "settings.contactInfo.address.label": "Adress",
  "settings.contactInfo.address.error.required": "Ange din adress",
  "settings.contactInfo.company.address.error.required":
    "Ange företagets adress",

  "settings.contactInfo.city.label": "Stad",
  "settings.contactInfo.city.error.required": "Ange din stad",
  "settings.contactInfo.company.city.error.required": "Ange företagets stad",

  "settings.contactInfo.zipCode.label": "Postnummer",
  "settings.contactInfo.zipCode.error.required": "Ange ditt postnummer",
  "settings.contactInfo.company.zipCode.error.required":
    "Ange företagets postnummer",

  "settings.contactInfo.phoneNumber.label": "Telefonnummer",
  "settings.contactInfo.phoneNumber.error.minLength":
    "Telefonnumret måste minst innehålla {minLength} siffror",
  "settings.contactInfo.phoneNumber.error.maxLength":
    "Telefonnumret kan max innehålla {maxLength} siffror",
  "settings.contactInfo.phoneNumber.error.isNumber": "Måste vara ett nummer",
  "settings.contactInfo.phoneNumber.error.required": "Ange telefonnummer",
  "settings.contactInfo.company.phoneNumber.error.required":
    "Ange kontaktpersonens telefonnummer",

  "settings.contactInfo.country.label": "Land",
  "settings.contactInfo.country.error.required": "Välj ditt land",
  "settings.contactInfo.company.country.error.required":
    "Välj landet organisationen är baserad i",

  "settings.contactInfo.company.contact.label": "Kontaktperson",
  "settings.contactInfo.company.firstName.label": "Förnamn",
  "settings.contactInfo.company.firstName.error.required":
    "Ange kontaktpersonens förnamn",
  "settings.contactInfo.company.lastName.label": "Efternamn",
  "settings.contactInfo.company.lastName.error.required":
    "Ange kontaktpersonens efternamn",

  "settings.contactInfo.company.email.label": "E-post",
  "settings.contactInfo.company.email.error.required":
    "Ange kontaktpersonens e-postadress",

  "settings.contactInfo.complete.message": "Byte av kontaktuppgifter lyckades!",
  "user.terms": `
        <h1>Användarvillkoren</h1>
        <h2>Allmänt</h2>
        <p>1. Villkoren i punkt 1-21 (”Användarvillkoren”) gäller mellan dig och
            High Coast Distillery AB (”High Coast”) vid ditt besök på, eller interagerande med,
            High Coast webbplats highcoastwhisky.se (”Webbplatsen”). Genom din användning
            av, eller interagerande med, Webbplatsen accepterar du Användarvillkoren.</p>
        <p>2. Vid beställning av vara eller tjänst på Webbplatsen gäller, förutom Användarvillkoren, även villkoren
            i punkt 22-24 (Beställning).</p>
        <p>3. High Coast ansvarar inte för eventuella skriv- och/eller tryckfel på Webbplatsen och har rätt att när som
            helst ta bort eller göra ändringar i information och funktionalitet på Webbplatsen.
            High Coast äger också rätt
            att när som helst ändra de Allmänna Villkoren. De vid var tid gällande Allmänna Villkoren finns publicerade
            på Webbplatsen. Du ska därför läsa igenom de Allmänna Villkoren vid varje besök på Webbplatsen
            (och vid varje beställning av vara eller tjänst).</p>
        <p>4. Du ansvarar själv för den tekniska utrustning och mjukvara som krävs för nyttjande av Webbplatsen, t.ex.
            åtkomst till Internet, webbläsare och e-post.</p>
        <p>5. Driftsstopp för underhåll m.m. kan förekomma på Webbplatsen. High Coast ansvarar inte för
            avbrott eller andra
            störningar som kan förekomma på Webbplatsen.</p>
        <p>6. Samtliga immateriella rättigheter till innehållet på Webbplatsen tillkommer High Coast (eller tredje man).
            High Coast immateriella rättigheter omfattar bl.a. firman High Coast, varumärket High Coast,
            formgivningar t.ex. High Coast symboler
            och rättigheter enligt upphovsrättslagen, till exempel fotografier och databaser. Du får ladda ner,
            skriva ut, kopiera och titta på material på Webbplatsen för ditt eget privata bruk men du får inte hantera
            sådant material i kommersiellt syfte eller i näringsverksamhet. Om du är osäker på vilket material som du
            får använda och hur, är du välkommen att kontakta High Coast kundtjänst (se punkt 16
            för kontaktuppgifter).</p>
        <p>7. Det är inte tillåtet att använda tekniska lösningar eller automatiska verktyg som exempelvis
            s.k. agenter, robotar, crawlers eller spindlar i anslutning till Webbplatsen för att registrera
            eller reproducera information, lämna beställningar, nyttja tjänster eller för att samla in
            information från eller om Webbplatsen i syfte att tillhandhålla funktioner eller tjänster relaterat
            till marknadsföring. Du får inte heller (i) störa eller påverka den av High Coast avsedda funktionaliteten
            på Webbplatsen, eller (ii) kringgå eventuella tekniska skyddsåtgärder som High Coast har vidtagit för att
            begränsa tillgång eller åtkomst till Webbplatsen.</p>
        <p>8. Om tvist skulle uppstå mellan dig och High Coast ska den i första hand lösas i samförstånd.
            Om detta inte är möjligt ska saken avgöras i allmän domstol. Tvist avgörs enligt svensk rätt.</p>
        <h2>Länkning</h2>
        <p>9. Du får hänvisa till Webbplatsen genom länkning.</p>
        <h2>Användarkonto</h2>
        <p>10. Du kan göra en beställning av vara eller tjänst när du har skapat ett användarkonto på Webbplatsen,
            varvid du bl.a. måste ange ditt namn och personnummer. Om du saknar svenskt personnummer eller inte är
            skriven i Sverige kan du kontakta High Coast fatägarservice för assistans med att skapa ett konto.</p>
        <p>Om du är skriven i Norge anger du det, varpå du istället lämnar följande uppgifter; förnamn, efternamn,
            födelsedatum (ÅÅÅÅMMDD), gatuadress samt postnummer.</p>
        <p>Lämnade uppgifter kontrolleras av High Coast med hjälp av företaget Ratsit AB. High Coast bekräftar via
            e-post att du
            har skapat ditt användarkonto. Både juridiska och fysiska personer får skapa användarkonton och endast
            ett användarkonto per juridisk eller fysisk person är tillåtet.</p>
        <p>11. För att kunna nå ditt användarkonto på Webbplatsen ska du välja ett personligt och unikt lösenord som,
            tillsammans med ditt valda användarnamn (din e-postadress), ger dig tillgång till
            användarkontot. Du ansvarar själv för att hålla ditt lösenord konfidentiellt. Genom
            din registrering av användarkontot bekräftar du att det är personligt, att den registrerade
            e-postadressen är personlig och att användarkontot inte registrerats
            för annans räkning. Användarkonto får inte användas av eller överlåtas till annan. Användarkontot raderas
            automatiskt om du inte har varit inloggad under en tolvmånadersperiod eller om du särskilt begär att ditt
            användarkonto ska raderas.</p>
        <p>12. Användarkonto får inte registreras för annans räkning eller av kommersiell aktör t.ex. näringsidkare som
            förmedlar eller marknadsför alkoholhaltiga drycker.</p>
        <p>13. High Coast kan, efter eget val, annullera din beställning och/eller stänga av ditt användarkonto
            (tillfälligt eller permanent) om High Coast har skälig anledning att misstänka (i) att ditt användarkonto
            innehåller felaktiga, missvisande eller inaktuella uppgifter, eller (ii) att ditt användarkonto eller
            Webbplatsen i övrigt används på sätt som inte är tillåtet enligt de Allmänna Villkoren. Om annullering
            av din beställning och/eller avstängning av ditt användarkonto sker, kommer du att få ett
            meddelande från High Coast.</p>
        <p>14. Alla personuppgifter som du lämnar till High Coast på Webbplatsen behandlas i enlighet med
            personuppgiftslagen (”PUL”). Användaruppgifter får endast registreras av den person som uppgifterna
            avser. High Coast behandlar dina uppgifter för att administrera din användning av Webbplatsen,
            kundförhållandet, historik, och för att kunna informera dig om förhållanden som påverkar din
            användning av Webbplatsen och fullgöra avtal med dig, följa upp leveranser eller annars vidta åtgärder
            som du har begärt samt för att säkerställa att den som gör en beställning eller ställer en förfrågan
            är över 20 år. Historik för Mina beställningar raderas av High Coast när du begär det. Historik sparas för
            ändamålet att tillhandahålla en välfungerande tjänst utifrån din roll som fatägare. Dina uppgifter
            kommer inte att överlåtas eller användas för någon form av externt marknadsföringsyfte. Genom att
            lämna dina uppgifter samtycker du till High Coast användning av dina uppgifter, inklusive ditt personnummer,
            enligt vad som framgår av de Allmänna villkoren.</p>
        <p>15. High Coast Distillery AB, 556729-2593, Sörviken 140, 872 96 Bjärtrå är personuppgiftsansvarigt för de
            personuppgifter som behandlas. Om du tillhandahåller High Coast en skriftlig och av dig undertecknad
            ansökan kan du en gång per år få besked om hur personuppgifter om dig behandlas av High Coast. Du har
            rätt att begära att få dina uppgifter rättade, blockerade eller raderade. Kontakta i så fall
            High Coast fatägarservice på postadressen enligt ovan eller på telefon 0612-530 60. Genom att lämna
            dina uppgifter samtycker du till att High Coast kan komma att kontakta dig i syfte att informera om
            och/eller översända sådana enkäter.</p>
        <h2>Leverans och priser m.m.</h2>
        <p>17. Om du har fyllt 20 år och skapat ett användarkonto (se punkt 11-13) kan du göra en beställning
            av varor ur High Coast sortiment på Webbplatsen antingen för avhämtning i Danmark, Estland, Tyskland
            hos av High Coast utsedd samarbetspartner samt hos Systembolagets samtliga butiker i Sverige.</p>
        <p>18. I enlighet med alkohollagen säljer eller lämnar High Coast inte ut alkoholhaltiga drycker till
            annan än den som är godkänd upplagshavare, innehavare av serveringstillstånd eller i övrigt
            är behörig att hantera alkoholhaltiga drycker. Kund som inte tillhör ovanstående kategori
            hänvisas vid uthämtning av buteljerade produkter till leverans enligt de villkor som beskrivs i
            stycke 17.</p>
        <p>19. High Coast ansvarar inte för eventuell slutförsäljning av beställd vara eller varubrist av andra
            skäl och har rätt att avboka din beställning avseende de varor som är slutsålda eller av
            annan anledning inte kan levereras. Om High Coast inte kan leverera hela eller delar av din
            beställning informeras du om det så snart det kan ske.</p>
        <p>20. Det pris som anges för respektive vara i High Coast sortiment inkluderar normalt mervärdesskatt.
            Gällande produkten fat hanteras dessa enligt särskilda villkor och moms inkluderas i kostnaden
            för fatet men för den del som avser lån ingår ej mervärdesskatt. När ett fat buteljeras
            tillkommer butikens hanteringskostnad, alkoholskatt och mervärdesskatt enligt de villkor som
            gäller för våra samarbetspartners. Vid leverans utanför Sverige kan priset på varan påverkas
            av aktuell valutakurs.</p>
        <p>21. Om du vid flera tillfällen underlåter att hämta ut beställda varor i tid, vägrar ta
            emot varor vid hemleverans eller ej hämtar varor på avtalad tid hos våra samarbetspartners
            kan extra hanteringskostnader debiteras dig vid hämtning av varan.</p>
        <h2>Beställning</h2>
        <p>Förutom nedanstående villkor om beställning med leverans till av kunden vald butik eller
            samarbetspartner gäller även Användarvillkoren i punkt 1-21.</p>
        <p>22. Du är bunden av din beställning när den har registrerats på Webbplatsen.</p>
        <p>23. När beställd vara finns tillgänglig meddelas du via mail med bekräftelse om vad du beställt.
            Vid beställning av fat framgår din beställning av den faktura som skickas till dig. Vid hämtning
            av fatprover vid mässor ska alltid aktuellt ordernummer redovisas och i samband därmed betalas
            också aktuellt fatprov med det betalningsmedel som gäller för aktuell mässa eller festival.
            För fatprov via Systembolaget gäller att kunden alltid måste göra en ”privatimport”
            enligt de rutiner som gäller enligt Systembolagets hemsida.</p>
        <p>24. Defekta varor kan alltid reklameras hos High Coast. Vid reklamation kontaktas High Coast fatägarservice,
            0612-530 60.</p>
        <h2>Beställning för leverans</h2>
        <p>Förutom nedanstående villkor om beställning för hemleverans gäller även Användarvillkoren i
            punkt 1-21 ovan.</p>
        <p>25. Du är bunden av din beställning när den har registrerats på Webbplatsen. High Coast är emellertid
            inte bundet av din beställning förrän Systembolaget har konstaterat att försäljning och
            utlämning av alkoholdrycker kan ske i enlighet med alkohollagen (se punkt 18). Är din
            beställning i enlighet med alkohollagen skickas en inköpsorder till High Coast varefter High Coast levererar
            beställd vara till aktuell butik.</p>
        <p>26. Leverans av varor kan ske alla veckans vardagar till valfri Systembolagsbutik i Sverige.
            Leverans sker dock först när High Coast erhållit en godkänd inköpsorder från Systembolaget. En
            godkänd inköpsorder förutsätter att kunden registrerat en ”privatimport” och godkänt
            erhållen offert. För våra samarbetspartners i Danmark och Tyskland gäller givna tidsfönster
            där aktuella datum framgår i vår webbplats dock gäller att varan ska hämtas inom 2 månader från
            första dag när varan är tillgänglig för uthämtning enligt vad som gäller vid beställning i form av
            buteljeringsanmälan. Vid hämtning i Estland gäller aktuellt datum för utlämningsresan. Vid
            avhämtning vid senare tillfälle kan våra samarbetsparters komma att debitera en hanteringskostnad.</p>
        <p>27. Leverans av beställda varor sker antingen till din folkbokföringsadress eller annan adress som
            du anger. När du tar emot varan måste du uppvisa giltig ID-handling för att styrka din identitet
            och för att uppfylla alkohollagens försäljningsregler (se punkt 18). Varan du har beställt
            kan även tas emot av annan förutsatt att du beställt varan till din folkbokföringsadress.
            Detta dock under förutsättning att denna mottagare kan uppvisa giltig ID-handling för styrkande
            av sin identitet samt uppfyller alkohollagens försäljningsregler (se punkt 18).</p>
    `,

  // Information
  "information.title": `Information`,

  // New Member registration
  "newMember.register.person.title": "Person",
  "newMember.register.company.title": "Företag",
  "newMember.register.intro":
    "Du kan registrera dig som en person eller ett företag genom menyn ovan",
  "newMember.register.error.invalidUser": "Kunde inte hitta användaren",
  "newMember.register.error.invalidToken":
    "Giltigheten för länken har gått ut, klicka här för att få en ny länk.",
  "newMember.register.error.unknownError": "Ett okänt fel har uppståt",
  "newMember.register.resend": "Skicka ny länk",
  "newMember.register.resend.success":
    "Ett mejl har skickats till din e-postadress med en ny länk som du kan använda för att färdigställa din registrering.",

  // Visma user picker
  "vismaUserPicker.title.default": "Dina konton",
  "vismaUserPicker.subtitle.default": "Välj det konto du vill använda",

  // Cask select
  "caskSelect.title": "Fat du har blivit inbjuden till",
  "caskSelect.subtitle":
    "Välj det fat som du vill binda till ett av dina kopplade konton.",
  "caskSelect.userPicker.title": "Dina kopplade konton",
  "caskSelect.userPicker.subtitle":
    "Välj det konto som du vill att fatet ska bindas till.",
  "caskSelect.error.userNotFound": "Kontot du valde kunde inte hittas",
  "caskSelect.error.userNotAdded": "Fatet kunde inte bindas till det kontot",
  "caskSelect.error.unknown":
    "Ett okäänt problem har uppstått. Vänligen kontakta support om problemet kvartstår.",
  "caskSelect.loading": "Binder fatet till kontot...",
  "footer.col1.title": "Besök oss",
  "footer.col2.title": "Om oss",
  "footer.col3.title": "Social",
  "footer.col2.investors": "Investerare",
  "footer.col2.press": "Press",
  "footer.col2.locations": "Köpställen",
  "footer.col2.contact": "Kontakt",
  "footer.col2.work": "Jobba hos oss",
  "footer.col2.start": "Startsida",
  "footer.col2.webshop": "Webshop",
  "footer.col2.cookies": "Integritetspolicy & Cookies",
  "footer.all-rights-reseved": "Alla rättigheter är reserverade.",
  "information.mobile.menu.label": "Välj avsnitt",
  "profile-menu.customer-id": "Kundnummer:",
  "news.title": "Nyheter",
  "news.read-more": "Läs mer",
  "news.read-less": "Läs mindre",
  "casks.title": "Mina fat",
  "casks.go-to-cask": "Visa fat-sida",
  "casks.no-casks1": "Du verkar för närvarande inte ha några fat tillgängliga.",
  "casks.no-casks2":
    "Bli fatägare idag genom att köpa ett fat eller be en fatägare bjuda in dig som delägare.",
  "cask.stored": "Lagrat ",
  "cask.info.proof": "Fatägarbevis",
  "cask.info.settlement": "Avräkningsnota",
  "cask.info.history": "Visa fathistorik",
  "cask.members.title": "Delägare",
  "cask.members.table.name": "Namn",
  "cask.members.table.email": "E-post",
  "common.back": "Tillbaka",
  "common.too-short": "För kort",
  "common.too-long": "För långt",
  "common.required": "Obligatorisk",
  "common.invalid-email": "Ogiltig e-post",
  "cask.members.invite.language": "Språk på inbjudansmail",
  "cask.members.invite.pick-language": "Välj språk",
  "common.language.sv": "Svenska",
  "common.language.en": "Engelska",
  "common.reset": "Återställ",
  "cask.members.invite.success": "Inbjudan skickad!",
  "common.remove": "Ta bort",
  "common.error.unknown":
    "Ett okänt fel har uppstått. Vänligen försök igen senare. Kontakta kundtjänst om problemet kvarstår.",
  "cask.orders-info.title": "Beställ",
  "cask.history.title": "Historik",
  "cask.orders.bottling.title": "Beställ buteljering",
  "casks.inactive.title": "Tömda fat",
  "cask.orders.bottling.loan.title": "Återbetalning lån",
  "cask.orders.bottling.info.title": "Buteljerings-information",
  "cask.orders.bottling.labels.title": "Flasketikett",
  "cask.orders.bottling.delivery.title": "Utlämning",
  "cask.orders.bottling.contact.title": "Kontaktuppgifter",
  "cask.orders.bottling.summary.title": "Sammanfattning",
  "cask.orders.bottling.loan.bank-type": "Banktyp",
  "cask.orders.bottling.loan.clearingNumber-placeholder":
    "Ange bankens clearingnummer",
  "cask.orders.bottling.loan.swedishBankAccountNumber-placeholder":
    "Ange ditt kontonummer",
  "cask.orders.bottling.loan.foreignBankAccountNumber-placeholder":
    "Ange ditt IBAN-kontonummer",
  "cask.members.invite.firstname-placeholder": "Ange förnamn",
  "cask.members.invite.lastname-placeholder": "Ange efternamn",
  "cask.members.invite.email-placeholder": "Ange e-post",
  "cask.orders.bottling.info.amount.whole": "Hela fatet",
  "cask.orders.bottling.info.amount.whole.desc":
    "Hela ditt fat buteljeras på en gång",
  "cask.orders.bottling.info.amount.part": "Delbuteljering",
  "cask.orders.bottling.info.amount.part.desc": "Buteljera en del av ditt fat",
  "common.invalid-number": "Ange endast siffor",
  "common.too-much": "För högt",
  "common.too-little": "För lågt",
  "cask.orders.bottling.info.amount.part.no-bottles-label": "Antal flaskor",
  "cask.orders.bottling.info.amount.part.pick-number-of": "Välj antal flaskor",
  "cask.orders.bottling.info.amount.part.bottles": "{amount} st flaskor",
  "cask.orders.bottling.info.dialute.info":
    "Lägsta alkoholhalt är 46% (50% för sherryfat).",
  "cask.orders.bottling.info.dialute.label": "Alkoholhalt i %",
  "cask.orders.bottling.info.dialute.placeholder": "Ange önskad alkoholhalt",
  "cask.orders.bottling.info.after.label": "Efter buteljering",
  "cask.orders.bottling.info.after.refill": "Refill",
  "cask.orders.bottling.info.after.refill.desc":
    "Fyll på mitt fat med ny sprit",
  "cask.orders.bottling.info.after.send-cask": "Skicka fatet till mig",
  "cask.orders.bottling.info.after.send-cask.desc":
    "Fatet skickas till dig mot en kostnad på 500 SEK",
  "cask.orders.bottling.info.refill.select.label": "Spritsort",
  "cask.orders.bottling.info.refill.select.placeholder":
    "Välj spritsort för refill",
  "cask.orders.bottling.info.refill.select.refill": "Orökt destillat",
  "cask.orders.bottling.info.refill.select.refill-smokey": "Rökigt destillat",
  "cask.orders.bottling.info.refill.select.refill-two-year":
    "Tvåårigt orökt destillat",
  "cask.orders.bottling.info.refill.select.refill-two-year-smokey":
    "Tvåårigt rökigt destillat",
  "cask.orders.bottling.info.after.cask-sign.placeholder":
    "Ange text för ny fatskylt",
  "cask.orders.bottling.labels.titles": "Etikett {index}",
  "cask.orders.bottling.labels.customization.none": "Original",
  "cask.orders.bottling.labels.customization.personalText": "Personlig text",
  "cask.orders.bottling.labels.customization.file": "Egen logotyp",
  "cask.orders.bottling.labels.add": "Lägg till en flasketikett",
  "cask.orders.bottling.labels.customization.personalText-placeholder":
    "Ange personlig text för etikett",
  "cask.orders.bottling.labels.customization.pick": "Välj variant",
  "cask.orders.bottling.labels.customization.upload":
    "Välj bild till din flasketikett",
  "cask.orders.bottling.labels.box": "Använd BOX-etikett",
  "cask.orders.bottling.labels.percent": "Andel flaskor med denna etikett",
  "cask.orders.bottling.labels.cost":
    "Total kostnad för etiketter: <strong>{cost} SEK</strong>",
  "cask.orders.bottling.delivery.occation.pick": "Välj ett leveranstillfälle",
  "cask.orders.bottling.delivery.location": "Utlämningsplats",
  "cask.orders.bottling.delivery.location.sweden": "Sverige",
  "cask.orders.bottling.delivery.location.germany": "Tyskland",
  "cask.orders.bottling.delivery.location.denmark": "Danmark",
  "cask.orders.bottling.delivery.location.sweden.desc":
    "Hämta ut din whisky på valfritt systembolag.",
  "cask.orders.bottling.delivery.location.germany.desc":
    "Hämta ut din whisky hos vår samarbetspartner Nielsen’s Discount i Tyskland.",
  "cask.orders.bottling.delivery.location.denmark.desc":
    "Hämta ut din whisky hos vår samarbetspartner Bottleshop Kastrup i Danmark.",
  "cask.orders.bottling.delivery.price-example":
    "<strong>{price} SEK</strong> per flaska är det uppskattade whiskypriset för ditt fat och recept med dagens gällande alkoholskatter, valutakurser och momssatser. Räknat på {noBottles} flaskor och {alcoholContent}% alkohol.",
  "cask.orders.bottling.delivery.price-example.showCalculations":
    "Visa hur vi räknat",
  "cask.orders.bottling.delivery.price-example.showCalculations.title":
    "Räkneexempel",
  "common.close": "Stäng",
  "cask.orders.bottling.delivery.warning.read-more":
    "Läs här hur du går tillväga.",
  "cask.orders.bottling.delivery.warning.sweden":
    "Efter att ditt fat buteljerats så måste du göra en privatimport hos systembolaget. <strong>Vi skickar ett mail när det är dags.</strong> ",
  "cask.orders.bottling.delivery.warning.denmark":
    "Du kommer få information om hur du betalar din whisky ca. 1-2 veckor innan första uthämtningsdag för buteljeringsomgången. <strong>Det är viktigt att ni hämtar er whisky inom en månad efter att den levererats.</strong>",
  "cask.orders.bottling.delivery.warning.germany":
    "Flaskorna ligger kostnadsfritt hos Nielsen's Discount i två månader. Därefter tillkommer en kostnad på 300 SEK/månad. <strong>Ni måste anmäla till butiken att ni kommer och vilket fatnummer det gäller minst 7 dagar innan.</strong>",
  "cask.orders.bottling.delivery.address.denmark":
    "Bottleshop Kastrup. Amager Standvej 418, 2770 Kastrup, Danmark.",
  "cask.orders.bottling.delivery.address.germany":
    "Nielsen's Discount. Ehlers Kamp 9, Fehmarn, Tyskland.",
  "cask.orders.bottling.delivery.bus":
    "Vi anordnar bussresor till utlämningsstället. ",
  "cask.orders.bottling.delivery.bus.read-more": "Läs mer här.",
  "common.invalid-phoneno": "Ogiltigt telefonnummer",
  "cask.orders.bottling.contact.fullname": "Fullständigt namn",
  "cask.orders.bottling.contact.fullname.placeholder":
    "Ange mottagarens fullständiga namn",
  "common.email": "E-post",
  "cask.orders.bottling.contact.email.placeholder": "Ange mottagarens e-post",
  "common.phone-number": "Telefonnummer",
  "cask.orders.bottling.contact.phone-number.placeholder":
    "Ange mottagarens telefonnummer",
  "cask.orders.bottling.show-summary": "Visa sammanfattning",
  "cask.orders.bottling.show-summary.invalid":
    "Fyll i alla nödvändiga fält för att kunna slutföra din order.",
  "cask.orders.bottling.summary.labels.plural": "Flasketiketter",
  "cask.orders.bottling.summary.labels.percent": "Andel:",
  "cask.orders.bottling.summary.edit": "Ändra beställning",
  "cask.orders.bottling.summary.confirm": "Bekräfta beställning",
  "cask.orders.done.back": "Tillbaka till fat-sidan",
  "cask.orders.sampling.title": "Beställ fatprov",
  "cask.orders.sampling.orderType.event.desc":
    "Provsmaka ditt fatprov på någon av våra fatägarträffar eller utvalda mässor.",
  "cask.orders.sampling.orderType.systembolaget.desc":
    "Hämta ut ditt fatprov på valfritt systembolag.",
  "cask.orders.sampling.orderType.distillery.desc":
    "Välj ett datum då provet skall finnas på plats. (prover plockas ut 1ggr/vecka).",
  "cask.orders.sampling.notice":
    "Fatprovet måste förtäras på plats på grund av rådande alkohol-lagstiftning.",
  "cask.orders.sampling.occation": "Tillfälle",
  "cask.orders.sampling.occation.pick": "Välj ett tillfälle för fatprovning",
  "cask.orders.sampling.amount": "Storlek på fatprov",
  "cask.orders.sampling.cost": "<strong>{cost} SEK</strong>",
  "cask.orders.sampling.date":
    "Provet finns i besökscenter från valt datum och framåt",
  "cask.orders.sampling.date.placeholder": "Välj ett datum",
  "cask.orders.sampling.systembolaget.step1": `
        Gå till
        <a target="_blank" href="https://www.systembolaget.se/bestalla-och-handla/privatimport/forfragan">Systembolagets hemsida</a>
        och logga in. <br />(Skapa en användare om du saknar sådan)
    `,
  "cask.orders.sampling.systembolaget.step2":
    "Skapa en ny förfrågan och välj den butik där du vill hämta ut fatprovet. Tryck sedan <strong>Fortsätt</strong>.",
  "cask.orders.sampling.systembolaget.step3":
    "Välj <strong>Svensk säljare</strong> och ange <strong>High Coast Distillery AB</strong>.",
  "cask.orders.sampling.systembolaget.step4":
    "Klicka på rutan <strong>+ Lägg till dryck</strong> och fyll i följande uppgifter för varje fatprov du vill beställa:",
  "cask.orders.sampling.systembolaget.step5":
    "Välj sedan <strong>Spara och fortsätt</strong>",
  "cask.orders.sampling.systembolaget.step5.notice":
    "Kontrollera här att dina kontaktuppgifter stämmer och att du valt rätt butik, du har fortfarande möjlighet att ändra om något blivit fel.",
  "cask.orders.sampling.systembolaget.step6":
    "Bocka i och godkänn Systembolagets <strong>Allmänna villkor</strong> och klicka sedan på <strong>Skicka in förfrågan</strong>. ",
  "cask.orders.sampling.systembolaget.step7":
    "Du kommer nu får ett e-post som bekräftar din privatimport. Ange förfrågansnumret från det mailet nedan.",
  "cask.orders.sampling.systembolaget.step7.notice":
    "Det kan ta upp till en timme innan du får e-post om din förfrågan. Kontrollera även att mailet inte hamnat i din skräppost.",
  "cask.orders.sampling.systembolaget.input":
    "Förfrågansnummer från Systembolaget",
  "cask.orders.sampling.systembolaget.input.placeholder":
    "Ange nummer för din förfrågan från systembolaget",
  "cask.orders.sampling.amount.notice":
    "Kostnaden för din order betalas på plats.",
  "cask.info.warehouse.label": "Lagerhus",
  "cask.info.stock-number.label": "Lagerplats",
  "cask.info.recipe.label": "Recept",
  "cask.orders.flavour-note.title": "Beställ smaknot",
  "profile-menu.error-visma":
    "Vi kunde inte hämta din användarprofil. Försök igen om en stund.",
  "shopping-cart.widget.title": "Visa varukorg",
  "cask.orders.bottling.delivery.location.estonia": "Estland",
  "cask.orders.bottling.delivery.location.estonia.desc":
    "Följ med oss på en fatutlämningsresa till Estland.",
  "cask.orders.bottling.delivery.address.estonia": "Tallinn, Estland.",
  "cask.orders.bottling.delivery.warning.estonia":
    "Vi arrangerar en fatutlämningsresa till Estland med Viking Cinderella för utlämnig av era flaskor. <strong>Du kan inte hämta dina flaskor på egen hand.</strong>",
  "cookie-banner.preamble":
    "Vänligen bekräfta att du är äldre än 20 år och att du godkänner att vi lagrar cookies.",
  "cookie-banner.no": "Nej",
  "cookie-banner.accept": "Jag accepterar",
  "cookie-banner.enjoy": "Avnjut High Coast Whisky ansvarsfullt.",
  "cookie-banner.footer":
    'Genom att besöka denna hemsida accepterar du att vi lagrar cookies. <a class="hover-underline-animation" href="https://highcoastwhisky.se/integritetspolicy/" target="_blank">Läs mer.</a>',
  "cask.orders.bottling.labels.sum-error":
    "Summan av etiketterna måste vara 100%",
  "footer.col4.title": "Följ oss",
  "cask.orders.bottling.info.after.sell-cask": "Sälj tillbaka fat",
  "cask.orders.bottling.info.after.sell-cask.desc":
    "Jag vill att High Coast köper fatet av mig",
  "cask.orders.bottling.labels.customization.upload-size-error":
    "Filen får max vara 5 MB stor.",
  "orders.sampling.systembolaget.reminder":
    "Kom ihåg att bekräfta din beställning här i fatägarwebben efter att du fyllt i systembolagets förfrågansnummer.",
  "common.unsaved-changes":
    "Du har osparade ändringar. Vill du verkligen lämna sidan?",
  "common.out-of-stock": "Tillfälligt slut",
  "cask.orders.labels.content-warning":
    "Vi godkänner ej text eller bilder som anspelar på våld, sex eller hets mot folkgrupp.",
  "cask.orders.bottling.info.after.alert.title": "Är du säker?",
  "cask.orders.bottling.info.after.alert.desc":
    "Du har valt att inte fylla på ditt fat. Detta innebär att ditt fat töms och du tappar möjligheten att fylla på ditt fat med ny sprit.",
  "cask.orders.bottling.info.after.alert.confirm": "Ja, jag är säker",
  "cask.orders.bottling.info.after.alert.refill": "Fyll på mitt fat",
  "cask.orders.bottling.info.refill.select.refill-gin":
    "Gin (vi kontaktar dig)",
  "cask.orders.labels.alert.title": "Val av etikett",
  "cask.orders.labels.alert.desc":
    "Du har valt att inte ha en egen text eller egen logotype på din etikett. Är du säker på att du inte vill ha det?",
  "cask.orders.labels.alert.desc-gin":
    "Du har valt att inte ha en egen text på din etikett. Är du säker på att du inte vill ha det?",
  "cask.orders.labels.alert.customize": "Ange personlig text",
  "cask.orders.labels.alert.confirm": "Ja, jag är säker",
  "orders.bottlingOrder.date.desc":
    "Dina flaskor kommer buteljeras efter sista anmälningsdag och finnas tillgängliga från första utlämningsdag.",
  "cask.orders.bottling.labels.gin.info":
    "För gin så syns din egna text på flaskans bakre etikett. <br />Framsidan ser ut som nedan:",
  "cask.orders.bottling.labels.customization.personalText.desc":
    "Välj en egen text till din etikett",
  "cask.orders.bottling.labels.customization.file.desc":
    "Ladda upp en bild till din etikett",
  "cask.orders.bottling.labels.customization.none.desc":
    "Tomt utrymme på etiketten",
  "cask.orders.bottling.labels.gin.file-notice":
    "Bildens största möjliga mått är 50mm x 23mm. Vi ber er tänka på om bilden passar på det utrymmet.",
  "cask.orders.bottling.info.after.scrap": "Fatet skrotas",
  "cask.orders.bottling.info.after.scrap.desc": "Fatet skrotas hos High Coast",
  "cask.orders.bottling.contact.comment-title": "Övriga kommentarer",
  "cask.orders.bottling.contact.comment-placeholder":
    "Ange eventuella övriga kommentarer eller frågor du har om din order",
  "cask.orders.sampling.amount.notice-distillery":
    "Kostnaden för fatprovet faktureras.",
};
