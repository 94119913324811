import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import LoginForm from './components/LoginFormContainer';

const RegisterText = styled.div`
  text-align: center;
  flex-grow: 1;
`;

const EmailRequestContainer = styled.div`
  text-align: left;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;

class RegisterPage extends React.Component<{}, {}> {
  render() {
    return (
      <Wrapper>          
          <LoginForm />
          <RegisterText>
            <p>
              <Link to="/reset-password"><FormattedMessage id="login.resetPassword.text" /></Link>
            </p>

          </RegisterText>

          <EmailRequestContainer>
            <p>
              <FormattedMessage id="login.whatIsNeededText" />
            </p>
            <Link to="/account/register/verify/new">
              <FormattedMessage id="login.requestVerificationEmailLink" />
            </Link>
          </EmailRequestContainer>
      </Wrapper>
    );
  }
}
export default RegisterPage;