import { formatToString } from "utils/format";
import Validator from "validator";

export const minLength = (minValue: number, message: string) => (val: string) =>
  val.length >= minValue ? false : message;

export const maxLength = (maxValue: number, message: string) => (val: string) =>
  val.length <= maxValue ? false : message;

export const minLengthOrEmpty =
  (minValue: number, message: string) => (val: string) =>
    val.length >= minValue || Validator.isEmpty(val) ? false : message;

export const isEmail = (message: string) => (val: string) =>
  Validator.isEmail(val) ? false : message;

export const isNumber = (message: string) => (val: string) =>
  Validator.isNumeric(val) ? false : message;

export const hasNumber = (message: string) => (val: string) =>
  /\d/.test(val) ? false : message;

export const hasLetter = (message: string) => (val: string) =>
  /\w*[a-zA-ZåäöÅÄÖ]\w*/.test(val) ? false : message;

export const required = (message: string) => (val: string) =>
  !Validator.isEmpty(val) ? false : message;

export const countryRequired = (message: string) => (val: string) =>
  !Validator.isEmpty(val) &&
  !Validator.equals(val, formatToString("settings.contactInfo.country.label"))
    ? false
    : message;

export const requiredType =
  <T>(message: string) =>
  (val: T) =>
    val !== undefined ? false : message;

export const isEnum =
  <T>(message: string) =>
  (val: T) =>
    val !== undefined && val !== null ? false : message;

export const minNumber = (min: number, message: string) => (val: number) =>
  val > min ? false : message;
