import { computed, makeObservable, observable } from "mobx";
import Cask from "models/cask";
import DomainStores from "stores/domain";

const { routerStore, casksStore } = DomainStores;

export enum Tab {
  Information,
  Orders,
  History,
}

export enum OrderForm {
  Bottling,
  FlavorNote,
  Sampling,
  None,
}

class CaskUiStore {
  @observable showOrderForm: boolean;

  @computed get selectedTab(): Tab {
    return this.determineTab();
  }

  @computed get cask(): Cask {
    return casksStore.find(this.caskId);
  }

  @computed get caskId(): number {
    let path = routerStore.history.location.pathname;
    return parseInt(path.split("/")[2], 10);
  }

  @computed get selectedOrderForm(): OrderForm {
    let path = routerStore.history.location.pathname;
    let caskId = path.split("/")[2];
    return this.determineForm(path, caskId);
  }

  constructor() {
    this.showOrderForm = false;
    makeObservable(this);
  }

  private determineTab(): Tab {
    let path = routerStore.history.location.pathname;
    let caskId = this.caskId;

    if (path.indexOf(`/cask/${caskId}/order`) >= 0) {
      return Tab.Orders;
    }

    if (path.indexOf(`/cask/${caskId}/history`) === 0) {
      return Tab.History;
    }

    return Tab.Information;
  }

  private determineForm(locationPath: string, caskId: string): OrderForm {
    if (locationPath.indexOf(`/cask/${caskId}/orders/bottling`) === 0) {
      return OrderForm.Bottling;
    }

    if (locationPath.indexOf(`/cask/${caskId}/orders/flavournote`) === 0) {
      return OrderForm.FlavorNote;
    }

    if (locationPath.indexOf(`/cask/${caskId}/orders/sampling`) === 0) {
      return OrderForm.Sampling;
    }

    return OrderForm.None;
  }
}

export default CaskUiStore;
export const caskUiStore = new CaskUiStore();
