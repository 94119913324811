import {
  Box,
  Collapse,
  FormLabel,
  HStack,
  Radio,
  Stack,
} from "@chakra-ui/react";
import BrandButton from "components/BrandButton";
import { CustomRadioButtonGroup } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import { Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react";
import { FlavourNoteLanguage } from "models/flavour-note-order";
import { OrderType } from "models/order";
import { FormattedMessage } from "react-intl";
import { Prompt } from "react-router-dom";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import OrdersService from "services/orders";
import { useStores } from "stores";
import { formatToString } from "utils/format";
import * as Yup from "yup";
import OrderDone from "../../Index/OrderDone";
import FlavourNoteStepper from "./FlavourNoteStepper";

export interface FlavourNoteFormValues {
  obtainedLanguage?: FlavourNoteLanguage;
  submitError?: boolean;
  newOrderNumber?: number;
}

export const flavourNoteSchema = (format: (id: string) => any) =>
  Yup.object().shape({
    obtainedLanguage: Yup.number().required(format("common.required")),
  });

const initialValues = { obtainedLanguage: undefined } as FlavourNoteFormValues;

const FlavourNoteForm = () => {
  const isMobile = useMedia("(max-width: 600px)");
  const StackComponent = isMobile ? Stack : HStack;
  const { localeStore, sessionStore, casksStore } = useStores();
  const { cask } = useCurrentCask();
  if (!cask) return null;

  const onSubmit = async (
    values: FlavourNoteFormValues,
    formikHelpers: FormikHelpers<FlavourNoteFormValues>
  ) => {
    try {
      const orderResponse = await OrdersService.orderFlavourNote({
        caskId: cask.id,
        language: localeStore.language,
        localVismaUserId: sessionStore.vismaUser.id,
        obtainedLanguage: values.obtainedLanguage!,
      });

      if (orderResponse.hasErrors) {
        formikHelpers.setFieldValue("submitError", true);
      } else {
        await casksStore.fetchCasks(false);
        window.scrollTo(0, 0);
        formikHelpers.setFieldValue(
          "newOrderNumber",
          orderResponse.data.order.id
        );
      }
    } catch (error) {
      formikHelpers.setFieldValue("submitError", true);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={flavourNoteSchema(formatToString)}
    >
      {(props) => (
        <>
          <Prompt
            when={props.dirty && !props.values.newOrderNumber}
            message={formatToString("common.unsaved-changes")}
          />
          <FlavourNoteStepper />
          <Box maxW={isMobile ? undefined : 1024 - 400} w="full">
            {!!props.values.newOrderNumber && (
              <OrderDone
                type={OrderType.FlavorNote}
                orderNumber={props.values.newOrderNumber}
                email={sessionStore.user.email}
              />
            )}
            {!props.values.newOrderNumber && (
              <Box mt={6}>
                <Box mt={8}>
                  <FormLabel
                    fontSize="md"
                    fontWeight={300}
                    htmlFor="obtainedLanguage"
                  >
                    <FormattedMessage id="orders.flavourNote.obtainedLanguage" />
                  </FormLabel>
                  <CustomRadioButtonGroup
                    id="obtainedLanguage"
                    value={props.values.obtainedLanguage?.toString()}
                    onChange={(v: string) => {
                      props.setFieldValue("obtainedLanguage", Number(v));
                    }}
                  >
                    <StackComponent spacing={4} alignItems="stretch">
                      <Radio
                        value={FlavourNoteLanguage.Swedish.toString()}
                        w="full"
                      >
                        <FormattedMessage id="orders.flavourNote.obtainedLanguage.swedish" />
                      </Radio>
                      <Radio
                        value={FlavourNoteLanguage.English.toString()}
                        w="full"
                      >
                        <FormattedMessage id="orders.flavourNote.obtainedLanguage.english" />
                      </Radio>
                    </StackComponent>
                  </CustomRadioButtonGroup>
                </Box>
                <Collapse in={props.values.submitError}>
                  <Box mt={8} mb={-4}>
                    <Box mb={4}>
                      <CustomAlert
                        status="error"
                        labelKey="orders.bottlingOrder.error.unknon"
                      />
                    </Box>
                  </Box>
                </Collapse>
                <>
                  <Box mt={4}>
                    <CustomAlert
                      labelKey="orders.flavourNote.price"
                      values={{ price: 1250 }}
                    />
                  </Box>
                  <Box>
                    <BrandButton
                      large
                      mt={8}
                      colorScheme="brand"
                      w="full"
                      size="lg"
                      isDisabled={props.values.obtainedLanguage === undefined}
                      isLoading={props.isSubmitting}
                      onClick={() => props.submitForm()}
                    >
                      <FormattedMessage id="cask.orders.bottling.summary.confirm" />
                    </BrandButton>
                  </Box>
                </>
              </Box>
            )}
          </Box>
        </>
      )}
    </Formik>
  );
};

export default observer(FlavourNoteForm);
