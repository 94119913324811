import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import logo from "assets/images/logo.svg";
import BrandButton from "components/BrandButton";
import { useRef } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { useCookie } from "react-use";
import { consent } from "tracking";

const CookieBanner = () => {
  const [value, updateCookie] = useCookie("cookie_consent");
  const btnRef = useRef(null);

  if (value) return null;

  const onNo = () =>
    window.open("https://highcoastwhisky.se/integritetspolicy/");

  const onYes = () => {
    updateCookie("true");
    consent();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      onClose={onNo}
      isOpen
      isCentered
      size="xl"
      initialFocusRef={btnRef}
    >
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalBody
          display="flex"
          alignItems="center"
          flexDir="column"
          p={8}
          textAlign="center"
        >
          <Box w="33%" mb={8}>
            <img className="logo" src={logo.toString()} alt="logo" />
          </Box>
          <Text mb={8} fontSize="lg">
            <FormattedMessage id="cookie-banner.preamble" />
          </Text>
          <Text mb={8}>
            <FormattedMessage id="cookie-banner.enjoy" />
          </Text>

          <HStack
            spacing={4}
            w="full"
            display="flex"
            justifyContent="center"
            mb={8}
          >
            <BrandButton size="lg" variant="outline" onClick={onNo}>
              <FormattedMessage id="cookie-banner.no" />
            </BrandButton>
            <BrandButton
              ref={btnRef}
              size="lg"
              colorScheme="brand"
              onClick={onYes}
            >
              <FormattedMessage id="cookie-banner.accept" />
            </BrandButton>
          </HStack>
          <Text fontSize="sm">
            <FormattedHTMLMessage id="cookie-banner.footer" />
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CookieBanner;
