import {
  Box,
  Input,
  RadioGroup,
  Select,
  StatGroup,
  Textarea,
} from "@chakra-ui/react";
import classnames from "classnames";
import { FC } from "react";
import { GoCheck } from "react-icons/go";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

export const StepLine = styled.div`
  width: 1px;
  position: absolute;
  top: 35px;
  bottom: -25px;
  left: 12px;
  background-color: #d3d3d3;
`;

export const StepNumber = styled.div`
  margin-top: 0.25rem;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  line-height: 25px;
  background-color: #dbdbdb;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const StepText = styled.div`
  padding: 0 15px;
  width: 100%;
  font-size: 16px;
  align-items: start;
  display: flex;
`;

export const CustomSelect = styled(Select)`
  border: 1px solid #bdbdbd !important;
  border-radius: 0.5rem;
  font-weight: 300;

  &:active,
  &:focus,
  &:hover {
    border-color: #3992ad !important;
    box-shadow: 0 0 0 1px #3992ad !important;
  }
`;

export const CustomRadioButtonGroup = styled(RadioGroup)`
  .chakra-radio {
    border: 1px solid #bdbdbd;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    transition: all 0.25s;
    font-size: 18px;

    &[data-checked],
    &:hover {
      border-color: #3992ad;
    }

    .chakra-radio__control {
      border: 1px solid #8c8c8c;
      width: 22px;
      height: 22px;
      background-color: #f2f2f2;
      margin-right: 0.5rem;
      color: #3992ad;

      &[data-hover] {
        background-color: #f2f2f2;
      }
    }
  }
`;

export const CustomInput = styled(Input)`
  border: 1px solid #bdbdbd !important;
  border-radius: 0.5rem;

  &:active,
  &:focus,
  &:hover {
    border-color: #3992ad !important;
    box-shadow: 0 0 0 1px #3992ad !important;
  }
`;

export const CustomTextArea = styled(Textarea)`
  border: 1px solid #bdbdbd !important;
  border-radius: 0.5rem;

  &:active,
  &:focus,
  &:hover {
    border-color: #3992ad !important;
    box-shadow: 0 0 0 1px #3992ad !important;
  }
`;

export const CustomStatGroup = styled(StatGroup)`
  justify-content: flex-start !important;

  .chakra-stat {
    width: 100px;
    min-width: 100px;
    padding-bottom: 1rem;
    flex-grow: 0;
  }

  .chakra-stat__label {
    font-weight: 300;
  }

  .chakra-stat__number {
    font-size: medium;
    font-weight: 500;
  }
`;

export const StepsWrapper = styled(Box)`
  position: sticky;
  top: 116px;
  height: calc(100vh - 348px);
  display: flex;
  flex-direction: column;
  transition: all 1s;

  @media (max-width: 48em) {
    position: fixed;
    top: 59px;
    z-index: 1000;
    left: 0;
    right: 0;
    flex-direction: row;
    padding: 0 1.25rem;
    border-bottom: 1px solid #e2e8f0;
    height: 50px;
    align-items: center;
    background: #fff;
  }
`;

export const StepsContainer = styled.div<{ active?: boolean; valid?: boolean }>`
  display: flex;
  flex-direction: row;
  max-width: 200px;
  margin-bottom: 30px;
  position: relative;
  padding-left: 1.25rem;

  .badge {
    div {
      transition: all 1s;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .valid {
      background-color: #2a9d8f;
      transform: rotateY(-180deg);
    }
  }

  .text {
    padding-top: 4px;
  }

  &.valid {
    .badge {
      .number {
        transform: rotateY(-180deg);
      }

      .valid {
        transform: rotateY(0);
      }
    }
  }

  &.active {
    .number {
      background-color: #3992ad;
      color: #fff;
    }

    .text {
      font-weight: 500;
    }
  }

  @media (max-width: 48em) {
    margin: 0;
    min-width: 70vw;
    align-items: center;

    .number,
    .valid {
      margin: 0;
    }

    .text {
      display: flex;
      align-items: center;
      height: 100%;
      padding-top: 0;
      height: 25px;
      width: auto;
    }

    .line {
      position: relative;
      height: 1px;
      top: unset;
      bottom: unset;
      left: unset;
      flex-grow: 1;
      margin-right: 15px;
    }
  }
`;

export const Step: FC<{
  index: number;
  active: boolean;
  labelKey: string;
  hideLine?: boolean;
  valid?: boolean;
}> = ({ active, index, labelKey, hideLine, valid }) => {
  return (
    <StepsContainer
      id={`stepper-${index}`}
      className={classnames(active && "active", valid && "valid")}
    >
      <Box className="badge">
        <StepNumber className="number">{index + 1}</StepNumber>
        <StepNumber className="valid">
          <GoCheck color="#FFF" />
        </StepNumber>
      </Box>
      <StepText className="text">
        <FormattedMessage id={labelKey} />
      </StepText>
      {!hideLine && <StepLine className="line" />}
    </StepsContainer>
  );
};
