import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import CustomAlert from "components/CustomAlert";
import { EditorState } from "draft-js";
import L, { LayerGroup, Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import { observer } from "mobx-react-lite";
import { DeliveryOccasionLocation } from "models/delivery-occasion";
import { MenuItemContent } from "models/menu-item";
import { FC, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { IoMdBus, IoMdPin } from "react-icons/io";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { useStores } from "stores";

interface MapState {
  viewport: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
}

const MAP_STATES: { [key: number]: MapState } = {
  1: {
    // tyskland
    viewport: {
      latitude: 54.445597377116584,
      longitude: 11.181679684657462,
      zoom: 7,
    },
  },
  2: {
    // danmark
    viewport: {
      latitude: 55.63402764836093,
      longitude: 12.651754534734648,
      zoom: 7,
    },
  },
  3: {
    // estland
    viewport: {
      latitude: 59.436970449966296,
      longitude: 24.75334765041305,
      zoom: 5,
    },
  },
};

const MiniMap: FC<{ deliveryLocation: DeliveryOccasionLocation }> = ({
  deliveryLocation,
}) => {
  const mapRef = useRef<Map>();
  const { viewport } = MAP_STATES[deliveryLocation];
  const layerRef = useRef<LayerGroup<any>>();

  useEffect(() => {
    // create map
    if (mapRef.current) return;

    mapRef.current = L.map("map", {
      center: [viewport.latitude, viewport.longitude],
      attributionControl: false,
      zoomControl: false,
      dragging: false,
      zoom: viewport.zoom,
      scrollWheelZoom: false,
      layers: [
        L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png",
          {
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: "abcd",
            maxZoom: 19,
          }
        ),
      ],
    });
  }, [viewport.latitude, viewport.longitude, viewport.zoom]);

  useEffect(() => {
    if (!mapRef.current) return;

    if (layerRef.current) mapRef.current.removeLayer(layerRef.current);

    layerRef.current = L.layerGroup().addTo(mapRef.current);

    L.marker([viewport.latitude, viewport.longitude], {
      icon: new L.Icon({
        iconUrl: `/map-pin.svg`,
        iconRetinaUrl: `/map-pin.svg`,
        iconSize: new L.Point(30, 35),
        iconAnchor: [15, 25],
        className: "leaflet-div-icon",
      }),
    }).addTo(layerRef.current!);

    mapRef.current.flyTo(
      [viewport.latitude, viewport.longitude],
      viewport.zoom
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryLocation]);

  return (
    <Box
      id="map"
      backgroundColor="#FFF"
      width="100%"
      height="100%"
      borderRadius="4"
    />
  );
};

const ModalInner: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [localizedContent, setLocalizedContent] = useState<
    MenuItemContent | undefined
  >();
  const { informationStore, localeStore } = useStores();

  useEffect(() => {
    if (!informationStore.isLoaded) return;

    const contents = informationStore.find(59); // bussresor info

    if (!contents) return;

    let localizedContent = contents.menuItemContent.find(
      (content) => content.language === localeStore.languageInt
    );
    if (!localizedContent) {
      localizedContent = contents.menuItemContent[0];
    }
    setLocalizedContent(localizedContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [informationStore.isLoaded]);

  useEffect(() => {
    if (!informationStore.isLoaded) informationStore.fetchInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContent mx={4}>
      {!localizedContent ? (
        <Center p="8">
          <Spinner />
        </Center>
      ) : (
        <>
          <ModalHeader fontWeight={500}>{localizedContent.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {localizedContent.state && (
              <Box mt={-6}>
                <Editor
                  toolbarHidden
                  readOnly
                  editorState={EditorState.createWithContent(
                    localizedContent.state
                  )}
                  onChange={() => {}}
                />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose}>
              <FormattedMessage id="common.close" />
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  );
};

const ModalInnerObserver = observer(ModalInner);

const DeliveryInfoMap: FC<{
  deliveryLocation: DeliveryOccasionLocation;
}> = ({ deliveryLocation }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box h="400px">
        <MiniMap deliveryLocation={deliveryLocation} />
      </Box>
      <Box mt={4}>
        <CustomAlert status="info" icon={<IoMdPin size="1.75rem" />}>
          {deliveryLocation === DeliveryOccasionLocation.Denmark && (
            <Box>
              <FormattedHTMLMessage id="cask.orders.bottling.delivery.address.denmark" />
            </Box>
          )}
          {deliveryLocation === DeliveryOccasionLocation.Germany && (
            <Box>
              <FormattedHTMLMessage id="cask.orders.bottling.delivery.address.germany" />
            </Box>
          )}
          {deliveryLocation === DeliveryOccasionLocation.Estonia && (
            <Box>
              <FormattedHTMLMessage id="cask.orders.bottling.delivery.address.estonia" />
            </Box>
          )}
        </CustomAlert>
      </Box>
      {deliveryLocation === DeliveryOccasionLocation.Germany && (
        <Box mt={4}>
          <CustomAlert status="info" icon={<IoMdBus size="1.75rem" />}>
            <Box display="inline-block">
              <FormattedHTMLMessage id="cask.orders.bottling.delivery.bus" />
              <Button variant="link" onClick={onOpen}>
                <FormattedMessage id="cask.orders.bottling.delivery.bus.read-more" />
              </Button>
            </Box>
          </CustomAlert>
        </Box>
      )}
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        useInert
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalInnerObserver onClose={onClose} />
      </Modal>
    </>
  );
};

export default DeliveryInfoMap;
