import { Box, Flex, HStack, Image, Stack, Text } from "@chakra-ui/react";
import fb from "assets/images/facebook-icon.svg";
import footerBackground from "assets/images/footer.svg";
import ig from "assets/images/instagram-icon.svg";
import styled from "styled-components";
import format, { formatToString } from "utils/format";

const Wrapper = styled.div`
  margin: 0;
  background-color: #faf5e8;
  background-image: url("${footerBackground}");
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
  min-height: 300px;
  padding: 2.5rem 0;
  font-weight: 300;
  z-index: 100;
  position: relative;
  font-weight: 400;

  a {
    display: inline-block;
    position: relative;
    color: #000;
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #000;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &.copper {
      color: #a7776c;

      &::after {
        background-color: #a7776c;
      }
    }
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Box mx={[8, 16]} py={[0, "4.5rem"]}>
        <Flex
          margin="auto"
          maxW={960}
          justifyContent="space-between"
          flexDirection={["column", "row"]}
        >
          <Box mr={12}>
            <Text
              fontSize="xs"
              textTransform="uppercase"
              fontWeight={600}
              letterSpacing={1}
            >
              {format("footer.col1.title")}
            </Text>
            <Box display={["flex", "block"]} justifyContent="space-between">
              <Box>
                <Text mb={2} fontFamily="IvarDisplay" mt={4}>
                  High Coast Whisky
                </Text>
                <Text mb={2} fontFamily="IvarDisplay">
                  <a
                    className="copper"
                    href="https://goo.gl/maps/sSpgAhZF6vZ3J7e59"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sörviken 140 →
                  </a>
                </Text>
                <Text fontFamily="IvarDisplay">872 96 Bjärtrå</Text>
                <Box display={["none", "block"]}>
                  <Text mt={4} fontSize="sm">
                    <a className="copper" href="tel:+46 (0) 612 530 60">
                      +46 (0) 612 530 60
                    </a>
                  </Text>
                  <Text mb={6} fontSize="sm">
                    <a
                      className="copper"
                      href="mailto:besok@highcoastwhisky.se"
                    >
                      besok@highcoastwhisky.se
                    </a>
                  </Text>
                </Box>
              </Box>
              <Box>
                <Text mb={2} fontFamily="IvarDisplay" mt={4}>
                  Marknadskontor
                </Text>
                <Text mb={2} fontFamily="IvarDisplay">
                  <a
                    className="copper"
                    href="https://goo.gl/maps/LprmHkQMRhkexNbF6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Östgötagatan 23A →
                  </a>
                </Text>
                <Text fontFamily="IvarDisplay">116 25 Stockholm</Text>
              </Box>
            </Box>
            <Box display={["block", "none"]}>
              <Text mt={4} fontSize="sm">
                <a className="copper" href="tel:+46 (0) 612 530 60">
                  +46 (0) 612 530 60
                </a>
              </Text>
              <Text mb={6} fontSize="sm">
                <a className="copper" href="mailto:besok@highcoastwhisky.se">
                  besok@highcoastwhisky.se
                </a>
              </Text>
            </Box>
          </Box>
          <Stack mr={12} mt={[8, 0]} spacing={3}>
            <Text fontSize="14px">
              <a href="https://highcoastwhisky.se/finansiell-information/">
                {format("footer.col2.investors")}
              </a>
            </Text>
            <Text fontSize="14px">
              <a href="https://highcoastwhisky.se/press/">
                {format("footer.col2.press")}
              </a>
            </Text>
            <Text fontSize="14px">
              <a href="https://highcoastwhisky.se/kopstallen/">
                {format("footer.col2.locations")}
              </a>
            </Text>
            <Text fontSize="14px">
              <a href="https://highcoastwhisky.se/jobba-har/">
                {format("footer.col2.work")}
              </a>
            </Text>
            <Text fontSize="14px">
              <a href="https://highcoastwhisky.se/">
                {format("footer.col2.start")}
              </a>
            </Text>
            <Text fontSize="14px">
              <a href="https://www.hcwshop.se/">
                {format("footer.col2.webshop")}
              </a>
            </Text>
          </Stack>
          <Stack mr={12} mt={[8, 0]}>
            <Text fontSize="14px">
              <a href="https://highcoastwhisky.se/kontakta-oss/">
                {format("footer.col2.contact")}
              </a>
            </Text>
            <Text fontSize="14px">
              <a href="https://highcoastwhisky.se/integritetspolicy/">
                {format("footer.col2.cookies")}
              </a>
            </Text>
          </Stack>
          <Box mr={12} mt={[6, 0]}>
            <Text
              fontSize="xs"
              textTransform="uppercase"
              fontWeight={600}
              letterSpacing={1}
            >
              {format("footer.col4.title")}
            </Text>
            <HStack mt={4} spacing={4}>
              <Image
                cursor="pointer"
                h="24px"
                src={ig}
                alt="instagram"
                onClick={() =>
                  window.open("https://www.instagram.com/highcoastwhisky/")
                }
              />
              <Image
                cursor="pointer"
                h="24px"
                src={fb}
                alt="facebook"
                onClick={() =>
                  window.open("https://www.facebook.com/highcoastdistillery")
                }
              />
            </HStack>
          </Box>
        </Flex>
        <Flex margin="auto" maxW={960} fontWeight="normal" mt={16}>
          <Box fontSize="sm">{`Copyright High Coast Distillery ${new Date().getUTCFullYear()}. ${formatToString(
            "footer.all-rights-reseved"
          )}`}</Box>
        </Flex>
      </Box>
    </Wrapper>
  );
};

export default Footer;
