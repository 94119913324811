import { sumBy } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import CartCask from "models/cart-cask";
import CaskType from "models/cask-type";
import Recipe from "models/recipe";
import CartService, { CreateCartDto } from "services/cart";
import EcsterService, { OnPaymentSuccessData } from "services/ecster";
import { localeStore } from "stores/domain/locale";
import { PaymentType } from "stores/ui/cask-shop/shopping-cart";
import { PRESTORED_PRICE } from "utils/prices";

const LS_CART_CASKS = "box_cart_casks";
const LS_CART_OPTIONS_STEP = "box_cart_options_step";

class ShoppingCartStore {
  @observable cartCasks: CartCask[] = [];
  @observable paymentStep: boolean = false;
  @observable optionsStep: boolean = false;
  @observable ecsterCartKey!: string;
  @observable ecsterIsLoading: boolean = false;
  @observable paymentType?: PaymentType;

  @action
  setOptionsStep(optionsStep: boolean) {
    this.optionsStep = optionsStep;

    localStorage.setItem(
      LS_CART_OPTIONS_STEP,
      JSON.stringify(this.optionsStep)
    );
  }

  @action
  setPaymentStep(paymentStep: boolean) {
    this.paymentStep = paymentStep;
  }

  @computed
  get totalCartCasksCount() {
    return this.cartCasks.length;
  }

  @computed get totalPrice(): number {
    return sumBy(this.cartCasks, (c: CartCask) => {
      let preStored = c.preStored ? PRESTORED_PRICE : 0;
      return c.caskType.price + c.recipe.price + preStored;
    });
  }

  @action
  async initEcsterPayment(
    onPaymentSuccess: (success: OnPaymentSuccessData) => void
  ) {
    let cart = await this.getCreateCartDto();
    this.ecsterIsLoading = true;
    let response = await CartService.createCart(cart);
    this.ecsterIsLoading = false;
    let cartKey = response.data;

    EcsterService.startPayment(cartKey, onPaymentSuccess);

    this.ecsterCartKey = cartKey;
  }

  @action
  addToCart(caskType: CaskType, recipe: Recipe, preStored: Boolean) {
    let cartCask = new CartCask();
    cartCask.caskType = caskType;
    cartCask.recipe = recipe;
    cartCask.preStored = preStored;
    cartCask.partOwners = [];

    this.cartCasks.push(cartCask);
    localStorage.setItem(LS_CART_CASKS, JSON.stringify(this.cartCasks));

    this.setPaymentStep(false);
    this.setOptionsStep(false);
  }

  @action
  updateCart(cartCask: CartCask, partOwners: string[], caskSign: string) {
    cartCask.caskSign = caskSign;
    cartCask.partOwners = partOwners;
    localStorage.setItem(LS_CART_CASKS, JSON.stringify(this.cartCasks));
  }

  @action
  removeCask(cartCask: CartCask) {
    let index = this.cartCasks.indexOf(cartCask);

    this.cartCasks.splice(index, 1);
    localStorage.setItem(LS_CART_CASKS, JSON.stringify(this.cartCasks));
  }

  @action
  clear() {
    this.cartCasks = [];
    localStorage.setItem(LS_CART_CASKS, JSON.stringify(this.cartCasks));

    this.setPaymentStep(false);
    this.ecsterCartKey = "";
    this.paymentType = undefined;
  }

  @action
  setPaymentType(paymentType: PaymentType) {
    this.paymentType = paymentType;
    this.setOptionsStep(false);
  }

  constructor() {
    this.cartCasks = this.getCartsFromLocalStorage();
    this.paymentStep = false;
    this.optionsStep = this.getOptionsStepFromLocalStorage();
    makeObservable(this);
  }

  private getCreateCartDto(): CreateCartDto {
    return {
      language: localeStore.language,
      country: "SE",
      amount: this.totalPrice,
      items: this.cartCasks,
    };
  }

  private getOptionsStepFromLocalStorage(): boolean {
    return JSON.parse(localStorage.getItem(LS_CART_OPTIONS_STEP) || "false");
  }

  private getCartsFromLocalStorage(): CartCask[] {
    return JSON.parse(localStorage.getItem(LS_CART_CASKS) || "[]");
  }
}

export default ShoppingCartStore;
export const shoppingCartStore = new ShoppingCartStore();
