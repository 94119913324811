import InformationStore, { informationStore } from './information';
import SessionStore, { sessionStore } from './session';
import CasksStore, { casksStore } from './casks';
import LocaleStore, { localeStore } from './locale';
import RouterStore, { routerStore } from './router';
import EventsStore, { eventsStore } from './events';
import CaskTypesStore, { caskTypesStore } from './cask-types';
import ShoppingCartStore, { shoppingCartStore } from './shopping-cart';
import RecipesStore, { recipesStore } from './recipes';
import DeliveryOccasionsStore, {
  deliveryOccasionsStore
} from './delivery-occasions';
import NewsStore, { newsStore } from './news';
import CaskHistoryStore, { caskHistoryStore } from './cask-history';
import BottlingPriceVariablesStore, {
  bottlingPriceVariablesStore
} from './bottling-price-variables';
import NewMemberStore, { newMemberStore } from './new-member';

export default {
  casksStore,
  localeStore,
  routerStore,
  sessionStore,
  eventsStore,
  caskTypesStore,
  shoppingCartStore,
  recipesStore,
  deliveryOccasionsStore,
  newsStore,
  caskHistoryStore,
  bottlingPriceVariablesStore,
  informationStore,
  newMemberStore
};

export {
  SessionStore,
  CasksStore,
  LocaleStore,
  RouterStore,
  EventsStore,
  CaskTypesStore,
  ShoppingCartStore,
  RecipesStore,
  DeliveryOccasionsStore,
  NewsStore,
  CaskHistoryStore,
  BottlingPriceVariablesStore,
  InformationStore,
  NewMemberStore
};
