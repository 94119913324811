import Api from './api';
import { UploadFile } from 'components/FileUploader/FileUploader';
import { JsonApiResponse } from 'utils/json-api';

interface FileUploadResponse {
    data: {
        uploadedUrl: string;
    };
}

interface FileUploadResponseData {
    uploadedUrl: string;    
}

export default class UploadFileService {
    static async uploadFiles(files: UploadFile[], fileNamePrefix: string | undefined = undefined)
        : Promise<JsonApiResponse<FileUploadResponseData>> {

        let formData = new FormData();
        formData.append('file', files[0].file, files[0].file.name);

        if (fileNamePrefix !== undefined) {
            formData.append('prefix', fileNamePrefix);
        }

        let response = await Api.post(
            'file-upload/label', 
            true, 
            { 'Accept': 'application/json' }, 
            formData, 
            undefined,
            true
        );
        
        let jsonApiResponse = new JsonApiResponse<FileUploadResponseData>();
        
        if (response.status !== 200) {
            jsonApiResponse.statusCode = response.status;
            jsonApiResponse.succeeded = false;
            return jsonApiResponse;
        }

        let fileUploadResponse = <FileUploadResponse> await response.json();
        jsonApiResponse.statusCode = response.status;
        jsonApiResponse.succeeded = true;

        jsonApiResponse.data = { 
            uploadedUrl: fileUploadResponse.data.uploadedUrl
        };

        return jsonApiResponse;
    }

}