import { action, computed, makeObservable, observable } from "mobx";
import { routerStore } from "stores/domain/router";

export enum Tab {
  Home,
  Information,
  Order,
  Cask,
  None,
}

class NavbarUiStore {
  @observable myCasksIsOpen: boolean;
  @observable transparent!: boolean;

  @computed get selectedTab() {
    let path = routerStore.history?.location?.pathname;
    return this.determineTab(path);
  }

  @action
  toggleMyCasks(shouldClose?: boolean) {
    if (shouldClose !== undefined) {
      if (shouldClose) {
        this.myCasksIsOpen = false;
      } else {
        this.myCasksIsOpen = true;
      }
      return;
    }

    this.myCasksIsOpen = !this.myCasksIsOpen;
  }

  @action
  setTransparent(isTransparent: boolean) {
    this.transparent = isTransparent;
  }

  @action
  closeMyCasks() {
    this.myCasksIsOpen = false;
  }

  constructor() {
    this.myCasksIsOpen = false;
    makeObservable(this);
  }

  private determineTab(locationPath: string): Tab {
    if (this.isRoute(locationPath, "")) {
      return Tab.Home;
    }

    if (this.isRoute(locationPath, "shop")) {
      return Tab.Order;
    }

    if (this.isRoute(locationPath, "information")) {
      return Tab.Information;
    }

    if (this.isRoute(locationPath, "cask")) {
      return Tab.Cask;
    }

    return Tab.None;
  }

  private isRoute(locationPath: string, route: string) {
    if (route === "") {
      return locationPath === "/";
    }

    return locationPath?.indexOf(`/${route}`) === 0;
  }
}
export default NavbarUiStore;
export const navbarUiStore = new NavbarUiStore();
