import { action, makeObservable, observable } from "mobx";

export enum FormStep {
  None,
  BottlingInformation,
  Delivery,
  Label,
  ContactInformation,
  Confirm,
}

class BottlingOrder {
  @observable currentFormStep!: FormStep;
  @observable complete!: boolean;

  constructor() {
    makeObservable(this);
  }

  @action
  setComplete() {
    this.complete = true;
  }
}

export default BottlingOrder;
export const bottlingOrderUiStore = new BottlingOrder();
