import { action, makeObservable, observable } from "mobx";
import DeliveryOccasion from "models/delivery-occasion";
import DeliveryService from "services/delivery-occasion";

class DeliveryOccasionsStore {
  @observable isLoading!: boolean;
  @observable deliveryOccasions!: Array<DeliveryOccasion>;

  id!: number;
  country!: string;
  date!: Date;
  active!: boolean;

  constructor() {
    makeObservable(this);
  }

  @action
  async fetchDeliveryOccasions() {
    this.isLoading = true;

    let response = await DeliveryService.fetchDeliveryOccasions();
    if (response.hasErrors) {
      throw new Error(`Couldn't fetch events`);
    }

    this.deliveryOccasions = response.data;
    this.isLoading = false;
  }
}

export default DeliveryOccasionsStore;
export const deliveryOccasionsStore = new DeliveryOccasionsStore();
