import CaskAccepterContainer from "components/CaskAccepter/CaskAccepterContainer";
import LoadingSplash from "components/Loading/LoadingSplash";
import Navbar from "components/Navbar/Navbar";
import Snackbar from "components/SnackBar";
import { inject } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import CasksStore from "stores/domain/casks";
import SessionStore from "stores/domain/session";
import SnackBarUiStore from "stores/ui/snackBar";
import styled from "styled-components";
import { formatToString } from "utils/format";
import CookieBanner from "./components/CookieBanner";
import Footer from "./components/Footer";

interface Props {
  sessionStore?: SessionStore;
  casksStore?: CasksStore;
  snackBarUiStore?: SnackBarUiStore;
  children?: React.ReactNode;
}

interface State {
  isLoading: boolean;
}

const Content = styled.div`
  min-height: 75vh;
`;

@inject("sessionStore", "casksStore", "snackBarUiStore")
class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isLoading: true };
  }

  get sessionStore() {
    return this.props.sessionStore!;
  }

  get casksStore() {
    return this.props.casksStore!;
  }

  get snackBarStore() {
    return this.props.snackBarUiStore!;
  }

  async componentWillMount() {
    await this.sessionStore.logInCurrentUser();
    this.setState({ isLoading: false });
    window.onerror = () => {
      // Set general error message on snackbar,
      // this is picked up by all uncaught exceptions
      this.snackBarStore.error(formatToString("home.error"));
    };
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingSplash />;
    }

    return (
      <div>
        <CookieBanner />
        <Content>
          <Navbar />
          {this.props.children}
        </Content>
        <Snackbar />
        <Footer />
        <CaskAccepterContainer />
      </div>
    );
  }
}

export default App;
