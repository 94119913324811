import Cask from "models/cask";
import Order from "models/order";
import User from "models/user";

export default class FlavourNoteOrder {
  id!: number;
  cask!: Cask;
  user!: User;
  obtainedLanguage!: FlavourNoteLanguage;
  status!: FlavourNoteOrderStatus;
  created!: Date;
  updated!: Date;
  order!: Order;
}

export enum FlavourNoteOrderStatus {
  // Pending order, awaiting verification from Box
  Pending,

  // Order Cancelled by orderer
  Cancelled,

  // Order declined by Box
  Declined,

  // Bottling Completed
  Completed,
}

export enum FlavourNoteLanguage {
  Swedish,
  English,
}
