import { Heading } from "@chakra-ui/react";
import { StepLine, StepNumber, StepText } from "components/CommonStyles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #fff;
  margin: 0;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;
  min-height: 40px;
  margin: 0 30px 30px;
  position: relative;
`;

const StepsWrapper = styled.div``;

const CaskInstructions = () => {
  return (
    <>
      <Wrapper>
        <Heading size="lg" fontWeight={500} my={12}>
          <FormattedMessage id="home.instructions.title" />
        </Heading>

        <StepsWrapper>
          <StepsContainer>
            <StepNumber>1</StepNumber>
            <StepLine />
            <StepText>
              <FormattedMessage
                id="home.instructions.step1"
                values={{
                  link: (
                    <Link to="/shop">
                      <FormattedMessage id="home.instructions.shopLinkText" />
                    </Link>
                  ),
                }}
              />
            </StepText>
          </StepsContainer>

          <StepsContainer>
            <StepNumber>2</StepNumber>
            <StepLine />
            <StepText>
              <FormattedMessage id="home.instructions.step2" />
            </StepText>
          </StepsContainer>

          <StepsContainer>
            <StepNumber>3</StepNumber>
            <StepLine />
            <StepText>
              <FormattedMessage id="home.instructions.step3" />
            </StepText>
          </StepsContainer>

          <StepsContainer>
            <StepNumber>4</StepNumber>
            <StepLine />
            <StepText>
              <FormattedMessage id="home.instructions.step4" />
            </StepText>
          </StepsContainer>

          <StepsContainer>
            <StepNumber>5</StepNumber>
            <StepLine />
            <StepText>
              <FormattedMessage id="home.instructions.step5" />
            </StepText>
          </StepsContainer>

          <StepsContainer>
            <StepNumber>6</StepNumber>
            <StepLine />
            <StepText>
              <FormattedMessage id="home.instructions.step6" />
            </StepText>
          </StepsContainer>

          <StepsContainer>
            <StepNumber>7</StepNumber>
            <StepLine />
            <StepText>
              <FormattedMessage id="home.instructions.step7" />
            </StepText>
          </StepsContainer>

          <StepsContainer>
            <StepNumber>8</StepNumber>
            <StepText>
              <FormattedMessage id="home.instructions.step8" />
            </StepText>
          </StepsContainer>
        </StepsWrapper>
      </Wrapper>
    </>
  );
};

export default CaskInstructions;
