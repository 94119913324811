import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { isEmail, required } from "utils/forms/validators";

type ResetPasswordState = {
  email: FieldState<string>;
};

class ResetPasswordUiStore {
  @observable userNotFound!: boolean;
  @observable submitting!: boolean;

  constructor() {
    makeObservable(this);
  }

  email: FieldState<string> = new FieldState("").validators(
    required("register.email.error.required"),
    isEmail("register.email.error.invalidEmail"),
    this.isUserNotFound("reset-password.email.error.notFound")
  );

  form = new FormState<ResetPasswordState>({
    email: this.email,
  });

  isUserNotFound(message: string) {
    return () => this.userNotFound && message;
  }

  @action
  setUserNotFound = (value: boolean) => {
    this.userNotFound = value;

    this.email.validate();
  };

  @action
  resetForm() {
    this.email.reset();
  }

  @action
  startSubmitting() {
    this.submitting = true;
  }

  @action
  stopSubmitting() {
    this.submitting = false;
  }
}

export default ResetPasswordUiStore;
export const resetPasswordUiStore = new ResetPasswordUiStore();
