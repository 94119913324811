import { Box, Divider, Heading, Radio, Stack, Text } from "@chakra-ui/react";
import { CustomRadioButtonGroup } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import { useFormikContext } from "formik";
import {} from "framer-motion";
import {} from "material-ui";
import { SamplingOrderType } from "models/sampling-order";
import { FormattedMessage } from "react-intl";
import { SamplingFormValues } from "./SamplingForm";

const SamplingTypePicker = () => {
  const {
    values: { type },
    setFieldValue,
  } = useFormikContext<SamplingFormValues>();

  return (
    <Box mt={6}>
      <CustomAlert labelKey="orders.sampling.description" />
      <Box mt={6}>
        <Heading size="lg" fontWeight={500} mb={4}>
          <FormattedMessage id="orders.sampling.orderType" />
        </Heading>
        <Divider mt={2} />
      </Box>
      <Box mt={4}>
        <CustomRadioButtonGroup
          id="sampling.type"
          value={type?.toString()}
          onChange={(v: string) => {
            setFieldValue("type", Number(v));
          }}
        >
          <Stack spacing={4} alignItems="stretch">
            <Radio value={SamplingOrderType.Event.toString()} w="full">
              <FormattedMessage id="orders.sampling.orderType.event" />
              <Text fontWeight={300}>
                <FormattedMessage id="cask.orders.sampling.orderType.event.desc" />
              </Text>
            </Radio>
            <Radio value={SamplingOrderType.Systembolaget.toString()} w="full">
              <FormattedMessage id="orders.sampling.orderType.systembolaget" />
              <Text fontWeight={300}>
                <FormattedMessage id="cask.orders.sampling.orderType.systembolaget.desc" />
              </Text>
            </Radio>
            <Radio value={SamplingOrderType.Distillery.toString()} w="full">
              <FormattedMessage id="orders.sampling.orderType.distillery" />
              <Text fontWeight={300}>
                <FormattedMessage id="cask.orders.sampling.orderType.distillery.desc" />
              </Text>
            </Radio>
          </Stack>
        </CustomRadioButtonGroup>
      </Box>
    </Box>
  );
};

export default SamplingTypePicker;
