export enum CaskStatus {
    NotFilled,
    Filled,
    FullBottled,
    Removed,
    FullBottlingOrdered
}

export const ordersEnabled = (status: CaskStatus) => {
    return status === CaskStatus.Filled;
};