import { isEmpty } from "lodash";
import { RaisedButton } from "material-ui";
import Dialog from "material-ui/Dialog";
import CaskOrderHistory, {
  CaskOrderHistoryType,
} from "models/cask-order-history";
import { FC } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";
import { formatToString } from "utils/format";

interface DlProps {
  theme?: __MaterialUI.Styles.MuiTheme;
}

const Dl = styled.dl`
  margin: 20px;

  dt {
    float: left;
    width: 180px;
    clear: left;
    white-space: nowrap;
    text-align: left;
  }

  dd {
    margin-left: 120px;
    margin-bottom: 10px;
    color: #5f5f5f;
  }
`;

interface HistoryItemModalDataProps {
  label: string | JSX.Element;
  value: number | string | JSX.Element;
}

const HistoryItemModalData: FC<HistoryItemModalDataProps> = ({
  label,
  value,
}) => (
  <Dl>
    <dt>{label}</dt>
    <dd>{value}</dd>
  </Dl>
);

interface Props {
  isOpen: boolean;
  onHideModal: () => void;
  historyItem: CaskOrderHistory;
}

const HistoryItemModal: FC<Props> = ({ isOpen, onHideModal, historyItem }) => (
  <Dialog
    title={formatToString("history.modal.title")}
    modal={false}
    actions={[
      <RaisedButton
        key="ok"
        primary={true}
        label={<FormattedMessage id="history.order.modal.okButton.label" />}
        onClick={onHideModal}
      />,
    ]}
    open={isOpen}
    onRequestClose={onHideModal}
    autoScrollBodyContent={true}
  >
    {historyItem.vismaOrderId !== 0 && (
      <HistoryItemModalData
        label={<FormattedMessage id="history.modal.vismaOrderId.label" />}
        value={historyItem.vismaOrderId}
      />
    )}

    {!isEmpty(historyItem.status) && (
      <HistoryItemModalData
        label={<FormattedMessage id="history.modal.status.label" />}
        value={historyItem.status}
      />
    )}

    {historyItem.type && (historyItem.type as number) !== 0 && (
      <HistoryItemModalData
        label={<FormattedMessage id="history.modal.type.label" />}
        value={
          historyItem.type === CaskOrderHistoryType.Purchase ? (
            <FormattedMessage id="history.modal.type.purchase" />
          ) : historyItem.type === CaskOrderHistoryType.Production ? (
            <FormattedMessage id="history.modal.type.production" />
          ) : (
            <FormattedMessage id="history.modal.type.unknown" />
          )
        }
      />
    )}

    {!isEmpty(historyItem.activityName) && (
      <HistoryItemModalData
        label={<FormattedMessage id="history.modal.activity.label" />}
        value={historyItem.activityName}
      />
    )}

    {!isEmpty(historyItem.actionName) && (
      <HistoryItemModalData
        label={<FormattedMessage id="history.modal.action.label" />}
        value={historyItem.actionName}
      />
    )}

    {!isEmpty(historyItem.bottlingVolumeName) && (
      <HistoryItemModalData
        label={<FormattedMessage id="history.modal.bottlingVolume.label" />}
        value={historyItem.bottlingVolumeName}
      />
    )}

    {!isEmpty(historyItem.bottlingRoundName) && (
      <HistoryItemModalData
        label={<FormattedMessage id="history.modal.bottlingRound.label" />}
        value={historyItem.bottlingRoundName}
      />
    )}

    {/* Removed dilution because it is usually the same as alcohol
            !isEmpty(historyItem.dilution) && (
            <HistoryItemModalData
                label={<FormattedMessage id="history.modal.dilution.label" />}
                value={historyItem.dilution}
            />
        )*/}

    {!!historyItem.alcohol && historyItem.alcohol !== 0 && (
      <HistoryItemModalData
        label={<FormattedMessage id="history.modal.alcohol.label" />}
        value={historyItem.alcohol}
      />
    )}

    {historyItem.preferedDeliveryTime && (
      <HistoryItemModalData
        label={
          <FormattedMessage id="history.modal.preferedDeliveryTime.label" />
        }
        value={<FormattedDate value={historyItem.preferedDeliveryTime} />}
      />
    )}

    {historyItem.orderRows &&
      historyItem.orderRows.map((orderRow) => (
        <div key={orderRow.id}>
          <hr />
          {!isEmpty(orderRow.description) && (
            <HistoryItemModalData
              label={
                <FormattedMessage id="history.modal.orderRow.description.label" />
              }
              value={orderRow.description}
            />
          )}

          {!isEmpty(orderRow.finishDate) && (
            <HistoryItemModalData
              label={
                <FormattedMessage id="history.modal.orderRow.finishDate.label" />
              }
              value={<FormattedDate value={orderRow.finishDate} />}
            />
          )}

          {historyItem.type !== CaskOrderHistoryType.Production &&
            !!orderRow.price &&
            orderRow.price !== 0 && (
              <HistoryItemModalData
                label={
                  <FormattedMessage id="history.modal.orderRow.price.label" />
                }
                value={<FormattedNumber value={orderRow.price} />}
              />
            )}

          {/* !!orderRow.amount && orderRow.amount !== 0 && (
                    <HistoryItemModalData
                        label={<FormattedMessage id="history.modal.orderRow.amount.label" />}
                        value={<FormattedNumber value={orderRow.amount} />}
                    />
                )*/}

          {!!orderRow.quantity && orderRow.quantity !== 0 && (
            <HistoryItemModalData
              label={
                <FormattedMessage id="history.modal.orderRow.quantity.label" />
              }
              value={<FormattedNumber value={orderRow.quantity} />}
            />
          )}
        </div>
      ))}
  </Dialog>
);

export default HistoryItemModal;
