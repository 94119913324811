import * as React from 'react';
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { BottlingOrderFormUiStore, CaskUiStore, SessionStore } from 'stores';
import OrderComplete from 'components/OrderComplete';
import LoadingSplash from 'components/Loading/LoadingSplash';
import { Redirect } from 'react-router';

interface Props {
  bottlingOrderFormUiStore?: BottlingOrderFormUiStore;
  caskUiStore?: CaskUiStore;
  sessionStore?: SessionStore;
}

@inject('bottlingOrderFormUiStore', 'caskUiStore', 'sessionStore')
@observer
class BottlingOrderCompleteContainer extends Component<Props, {}> {
  get store() {
    return this.props.bottlingOrderFormUiStore!;
  }

  get cask() {
    return this.props.caskUiStore!.cask;
  }

  get orderId() {
    return this.store.orderId;
  }

  get email() {
    return this.props.sessionStore!.user.email;
  }

  render() {
    if (!this.orderId) {
      return <Redirect to={`/cask/${this.cask.id}/orders/bottling`} />;
    }
    return (
      <div>
        {this.store.orderIsComplete ? (
          <OrderComplete
            backButtonPrimary={false}
            orderId={this.orderId}
            email={this.email}
            textIds={{
              title: 'orders.bottlingOrder.complete.title',
              summary: 'orders.bottlingOrder.complete.orderCompleteText',
              order: 'orders.bottlingOrder.complete.orderIdLabel',
              back: 'orders.bottlingOrder.complete.backButton'
            }}
            backRoute={`/cask/${this.cask.id}`}
          />
        ) : (
          <LoadingSplash />
        )}
      </div>
    );
  }
}

export default BottlingOrderCompleteContainer;
