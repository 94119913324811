import { inject, observer } from "mobx-react";
import CartCask from "models/cart-cask";
import * as React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { OnPaymentSuccessData } from "services/ecster";
import SessionStore from "stores/domain/session";
import ShoppingCartStore from "stores/domain/shopping-cart";
import ShoppingCartUiStore, {
  CartStep,
  PaymentType,
} from "stores/ui/cask-shop/shopping-cart";
import ShoppingCart from "./components/ShoppingCart";

interface Props {
  shoppingCartStore?: ShoppingCartStore;
  shoppingCartUiStore?: ShoppingCartUiStore;
  sessionStore?: SessionStore;
}

interface PropsWithRoute extends Props, RouteComponentProps<{}> {}

@inject("shoppingCartStore", "shoppingCartUiStore", "sessionStore")
@observer
class ShoppingCartPage extends React.Component<PropsWithRoute, {}> {
  get shoppingCartStore() {
    return this.props.shoppingCartStore!;
  }

  getStep(search: string) {
    let urlSearchParams = new URLSearchParams(search);
    let step = urlSearchParams.get("step");

    if (!step) {
      return null;
    }

    return parseInt(step!, 10);
  }

  get currentStep() {
    return this.getStep(this.props.location.search);
  }

  stepForwards = async () => {
    let step = this.currentStep!;

    if (this.currentStep === CartStep.CartOptions) {
      let hasError = await this.props.shoppingCartUiStore!.validate();
      if (hasError) {
        return;
      }
      this.props.shoppingCartUiStore!.caskOptions.forEach((caskOption) =>
        this.props.shoppingCartStore!.updateCart(
          caskOption.cartCask,
          caskOption.partOwners
            .filter(
              (partOwner) =>
                !partOwner.mail.hasError && partOwner.mail.value !== ""
            )
            .map((partOwner) => partOwner.mail.value),
          caskOption.caskSign
        )
      );
    }

    this.props.history.push({
      search: `step=${step + 1}`,
    });

    this.scrollToTop();
  };

  validateStep = async () => {
    let hasError = await this.props.shoppingCartUiStore!.validate();
    if (hasError) {
      this.stepBackwards();
    }
  };

  stepBackwards = () => {
    let step = this.currentStep!;

    this.props.history.push({
      search: `step=${step - 1}`,
    });
    this.scrollToTop();
  };

  goToStep = (step: number) => {
    this.props.history.push({
      search: `step=${step}`,
    });
    this.scrollToTop();
  };

  cancelOrder = () => {
    this.shoppingCartStore.clear();
  };

  sendOrder = async () => {
    const success = await this.props.shoppingCartUiStore!.submit();
    if (success) {
      this.props.history.push(`/shop/cart/complete`);
      this.scrollToTop();
    }
  };

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  removeCask = (cartCask: CartCask) => {
    if (this.shoppingCartStore.cartCasks.length === 1) {
      this.shoppingCartStore.clear();
    } else {
      this.shoppingCartStore.removeCask(cartCask);
    }
  };

  initEcsterPayment = () => {
    this.shoppingCartStore.initEcsterPayment(this.onPaymentSuccess);
  };

  onPaymentSuccess = (success: OnPaymentSuccessData) => {
    this.sendOrder();
  };

  get ecsterCartKey() {
    return this.shoppingCartStore.ecsterCartKey;
  }

  selectPaymentType = (paymentType: PaymentType) => {
    this.props.shoppingCartStore!.setPaymentType(paymentType);
  };

  render() {
    let { pathname } = this.props.location;

    if (this.currentStep === null) {
      let search = `?step=${CartStep.CartView}`;
      if (
        this.shoppingCartStore.paymentStep &&
        this.props.sessionStore!.isAuthenticated
      ) {
        search = `?step=${CartStep.CartPayment}`;
      } else if (this.shoppingCartStore.optionsStep) {
        search = `?step=${CartStep.CartOptions}`;
      }
      return <Redirect to={{ pathname, search }} />;
    }

    let currentStep = this.currentStep!;
    this.shoppingCartStore.setPaymentStep(currentStep === CartStep.CartPayment);
    this.shoppingCartStore.setOptionsStep(
      currentStep === CartStep.CartOptions ||
        currentStep === CartStep.CartPayment
    );

    if (this.currentStep === CartStep.CartPayment) {
      this.validateStep();
    }

    return (
      <div>
        <ShoppingCart
          totalCartCasksCount={this.shoppingCartStore.totalCartCasksCount}
          cartCasks={this.props.shoppingCartStore!.cartCasks}
          currentCartStep={currentStep}
          stepForwards={this.stepForwards}
          stepBackwards={this.stepBackwards}
          sendOrder={this.sendOrder}
          emptyCart={this.cancelOrder}
          removeCask={this.removeCask}
          isAuthenticated={this.props.sessionStore!.isAuthenticated}
          isPersonalAccount={this.props.sessionStore!.isPersonalAccount}
          onInitEcsterPayment={this.initEcsterPayment}
          onSelectPaymentType={this.selectPaymentType}
          selectedPaymentType={this.props.shoppingCartStore!.paymentType}
          ecsterIsLoading={this.props.shoppingCartStore!.ecsterIsLoading}
          goToStep={this.goToStep}
          sendingOrder={this.props.shoppingCartUiStore!.sendingOrder}
        />
      </div>
    );
  }
}

export default withRouter(ShoppingCartPage);
