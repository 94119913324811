export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MIN_DIGITS = 1;
export const PHONE_MIN_LENGTH = 4;
export const PHONE_MAX_LENGTH = 20;
export const EMAIL_MAX_LENGTH = 40;
export const FULL_NAME_MAX_LENGTH = 40;
export const CASK_SIGN_TEXT_MAX_LENGTH = 20;
export const CASK_LABEL_TEXT_MAX_LENGTH = 20;
export const BOTTLE_LABEL_TEXT_MAX_LENGTH = 60;
export const CLEARING_NUMBER__MAX_LENGTH = 6;
export const SWEDISH_BANK_ACCOUNT_NUMBER_MIN_LENGTH = 7;
export const SWEDISH_BANK_ACCOUNT_NUMBER_MAX_LENGTH = 10;
export const FOREIGN_BANK_ACCOUNT_NUMBER_MIN_LENGTH = 15;
export const FOREIGN_BANK_ACCOUNT_NUMBER_MAX_LENGTH = 34;
export const DEFAULT_NUMBER_OF_BOTTLES = 68;
export const DEFAULT_ALCOHOL_CONTENT = 60;
export const ALCOHOL_CONTENT_MIN_VALUE = 46;
export const AFTER_BOTTLING_SAVE_PRICE = 500;
export const RECIPE_SMOKED_ID = 2;
