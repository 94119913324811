import { FieldState, FormState } from "formstate";
import { action } from "mobx";
import { PASSWORD_MIN_LENGTH } from "utils/forms/constants";
import { hasNumber, minLength, required } from "utils/forms/validators";

type CompanyFormState = {
  password: FieldState<string>;
  repeatedPassword: FieldState<string>;
  orgNumber: FieldState<string>;
  orgName: FieldState<string>;
  contactFirstName: FieldState<string>;
  contactLastName: FieldState<string>;
  phoneNumber: FieldState<string>;
};

class CompanyFormUiStore {
  orgNumber: FieldState<string> = new FieldState("").validators(
    required("register.orgNumber.error.required")
  );
  orgName: FieldState<string> = new FieldState("").validators(
    required("register.orgName.error.required")
  );
  contactFirstName: FieldState<string> = new FieldState("").validators(
    required("register.contactFirstName.label")
  );
  contactLastName: FieldState<string> = new FieldState("").validators(
    required("register.contactLastName.label")
  );
  password: FieldState<string> = new FieldState("").validators(
    required("register.password.error.required"),
    minLength(PASSWORD_MIN_LENGTH, "register.password.error.minLength"),
    hasNumber("register.password.error.hasNumber")
  );

  repeatedPassword: FieldState<string> = new FieldState("").validators(
    required("register.repeatedPassword.error.required"),
    () => this.passwordsMustMatch()
  );
  phoneNumber: FieldState<string> = new FieldState("").validators(
    required("register.phoneNumber.error.required")
  );

  form = new FormState<CompanyFormState>({
    password: this.password,
    repeatedPassword: this.repeatedPassword,
    orgNumber: this.orgNumber,
    orgName: this.orgName,
    contactFirstName: this.contactFirstName,
    contactLastName: this.contactLastName,
    phoneNumber: this.phoneNumber,
  });

  passwordsMustMatch() {
    return this.password.value === this.repeatedPassword.value
      ? false
      : "register.repeatedPassword.error.match";
  }

  @action
  validateRepeatedPassword = async () => {
    if (!this.repeatedPassword.dirty) {
      return;
    }

    this.repeatedPassword.validate();
  };

  @action
  resetForm() {
    this.orgNumber.reset();
    this.orgName.reset();
    this.password.reset();
    this.repeatedPassword.reset();
    this.phoneNumber.reset();
    this.contactFirstName.reset();
    this.contactLastName.reset();
  }
}

export default CompanyFormUiStore;
export const companyFormUiStore = new CompanyFormUiStore();
