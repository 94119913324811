import { action, makeObservable, observable } from "mobx";

class SnackBarUiStore {
  @observable isOpen: boolean;
  @observable message: string;
  @observable backgroundColor!: string;

  @action
  open(message: string) {
    this.isOpen = true;
    this.message = message;
    this.backgroundColor = "#517760";
  }

  @action
  error(message: string) {
    this.isOpen = true;
    this.message = message;
    this.backgroundColor = "#842E2E";
  }

  @action
  close() {
    this.isOpen = false;
  }

  constructor() {
    this.isOpen = false;
    this.message = "";
    makeObservable(this);
  }
}
export default SnackBarUiStore;
export const snackBarUiStore = new SnackBarUiStore();
