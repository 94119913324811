import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import CaskUser from "models/cask-user";
import CasksService, { CaskMemberVm } from "services/casks";
import { localeStore } from "stores/domain/locale";
import { caskUiStore } from "stores/ui/cask";
import { isEmail, maxLength, required } from "utils/forms/validators";
import { JsonApiResponse } from "utils/json-api";

type Form = {
  email: FieldState<string>;
  firstname: FieldState<string>;
  lastname: FieldState<string>;
};

class InviteMemberUiStore {
  @observable submitting: boolean = false;

  @observable email: FieldState<string> = new FieldState<string>("").validators(
    required("about.members.modal.email.error.required"),
    isEmail("about.members.modal.email.error.isEmail"),
    maxLength(40, "about.members.modal.email.error.maxLength")
  );

  @observable firstName: FieldState<string> = new FieldState<string>(
    ""
  ).validators(required("about.members.modal.firstname.required"));

  @observable lastName: FieldState<string> = new FieldState<string>(
    ""
  ).validators(required("about.members.modal.lastname.required"));

  @observable form = new FormState<Form>({
    email: this.email,
    firstname: this.firstName,
    lastname: this.lastName,
  });

  constructor() {
    makeObservable(this);
  }

  @computed get formData(): CaskMemberVm {
    let order: CaskMemberVm = {
      caskId: caskUiStore.caskId,
      email: this.email.$,
      language: localeStore.language,
      firstname: this.firstName.$,
      lastname: this.lastName.$,
    };
    return order;
  }

  @action
  async submitForm() {
    if (this.form === undefined) {
      return;
    }

    let validation = await this.form.validate();
    if (validation.hasError) {
      return;
    }

    this.submitting = true;
    let response: JsonApiResponse<CaskUser[]> | undefined = undefined;
    try {
      response = await CasksService.addMember(this.formData);
    } finally {
      this.submitting = false;
      return response;
    }
  }

  resetForm() {
    this.email.reset();
    this.firstName.reset();
    this.lastName.reset();
  }
}

export default InviteMemberUiStore;
export const inviteMemberUiStore = new InviteMemberUiStore();
