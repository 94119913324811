import { Box, Divider, Heading } from "@chakra-ui/react";
import FormInput from "components/forms/FormInput";
import TextAreaInput from "components/forms/TextAreaInput";
import { useAtom } from "jotai";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { intersectingSectionAtom } from "./BottlingStepper";

export interface BottlingContactPartValues {
  fullName: string;
  email: string;
  phoneNumber: string;
  comment: string;
}

export const bottlingContactSchema = (format: (id: string) => any) =>
  Yup.object().shape({
    fullName: Yup.string().required(format("common.required")),
    email: Yup.string()
      .email(format("common.invalid-email"))
      .required(format("common.required")),
    phoneNumber: Yup.string()
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        format("common.invalid-phoneno")
      )
      .required(format("common.required")),
    comment: Yup.string(),
  });

const ContactFormPart = () => {
  const [, setCurrentSection] = useAtom(intersectingSectionAtom);

  return (
    <fieldset
      id="bottling-contact"
      onClick={() => setCurrentSection("contact")}
    >
      <Box mt={6} pb={8}>
        <Heading size="lg" fontWeight={500} mb={4}>
          <FormattedMessage id="cask.orders.bottling.contact.title" />
        </Heading>
        <Divider mt={2} />
        <Box mt={4}>
          <Box mt={4}>
            <FormInput
              labelKey="cask.orders.bottling.contact.fullname"
              name="contact.fullName"
              placeholderKey={
                "cask.orders.bottling.contact.fullname.placeholder"
              }
            />
          </Box>
          <Box mt={4}>
            <FormInput
              labelKey="common.email"
              name="contact.email"
              placeholderKey={"cask.orders.bottling.contact.email.placeholder"}
            />
          </Box>
          <Box mt={4}>
            <FormInput
              labelKey="common.phone-number"
              name="contact.phoneNumber"
              placeholderKey={
                "cask.orders.bottling.contact.phone-number.placeholder"
              }
            />
          </Box>
          <Box mt={8}>
            <TextAreaInput
              labelKey="cask.orders.bottling.contact.comment-title"
              name="contact.comment"
              placeholderKey={
                "cask.orders.bottling.contact.comment-placeholder"
              }
            />
          </Box>
        </Box>
      </Box>
    </fieldset>
  );
};

export default ContactFormPart;
