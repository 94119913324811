import Content from "components/Content";
import { Paper } from "material-ui";
import { inject } from "mobx-react";
import * as React from "react";
import RegisterCompanyFormUiStore from "stores/ui/account/register-company-form";
import RegisterFormUiStore from "stores/ui/account/register-form";
import styled from "styled-components";
import RegisterComplete from "./RegisterComplete";

interface Props {
  registerFormUiStore?: RegisterFormUiStore;
  registerCompanyFormUiStore?: RegisterCompanyFormUiStore;
}

const Wrapper = styled(Paper)`
  margin-top: 15px;
`;

@inject("registerFormUiStore", "registerCompanyFormUiStore")
class RegisterCompleteContainer extends React.Component<Props, {}> {
  get store() {
    return this.props.registerFormUiStore!;
  }

  get companyStore() {
    return this.props.registerCompanyFormUiStore!;
  }

  render() {
    return (
      <Content>
        <Wrapper>
          <RegisterComplete
            email={
              this.store.registeredEmail !== ""
                ? this.store.registeredEmail
                : this.companyStore.registeredEmail
            }
          />
        </Wrapper>
      </Content>
    );
  }
}

export default RegisterCompleteContainer;
