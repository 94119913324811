import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Wrapper = styled.div`
  background: #ebebeb;
`;

export interface AppProps {
  hiddenDesktop?: boolean; 
  currentStep: number;
  maxStep: number;
}

export default class MobileStepper extends React.Component<AppProps, {}> {
  render() {
    let { hiddenDesktop, currentStep, maxStep } = this.props;
    return (
      <Wrapper className={hiddenDesktop ? 'visible-xs visible-sm' : ''}>
          <FormattedMessage id="stepper.mobile.step" values={{ currentStep, maxStep }}/>
      </Wrapper>
    );
  }
}
