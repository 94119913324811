import * as React from 'react';
import { inject, observer } from 'mobx-react';
import LocaleStore from 'stores/domain/locale';
import CountryPicker from './CountryPicker';

interface Props extends __MaterialUI.SelectFieldProps {
  localeStore?: LocaleStore;
}

@inject('localeStore')
@observer
export default class CountryPickerContainer extends React.Component<Props, {}> {
    get selectFieldProps() {
        let { localeStore, ...rest } = this.props;
        return rest;
    }
    
    render() {
        return (
            <CountryPicker language={this.props.localeStore!.language} {...this.selectFieldProps} />
        );
    }
}