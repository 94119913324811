import BottleLabel from "./bottle-label";
import DeliveryOccasion, {
  DeliveryOccasionLocation,
} from "./delivery-occasion";
import Order from "./order";

export enum BankAccountType {
  Swedish,
  International,
}

export enum AfterBottlingAction {
  NotSet = -1,
  Refill,
  RefillSmokey,
  RefillTwoYear,
  RefillTwoYearSmokey,
  Save,
  Sell,
  RefillGin,
  Scrap,
}

export enum BottlingAmount {
  FullBottling,
  PartBottling,
}

export default class BottlingOrder {
  id!: number;

  bottlingAmount!: BottlingAmount;
  numberOfBottles?: number;
  alcoholContent?: number;
  bottlingDeliveryOccasion!: DeliveryOccasion;
  deliveryLocation!: DeliveryOccasionLocation;
  bottleLabels!: BottleLabel[];
  afterBottlingAction!: AfterBottlingAction;
  caskSignText!: string;

  // Bank information
  bankAccountType!: BankAccountType;
  bankAccountNumber!: string;

  // Order contact information
  fullName!: string;
  email!: string;
  phoneNumber!: string;

  // Inherited from Orders
  order!: Order;
  orderId!: string;
}

export enum BottlingOrderStatus {
  // Pending order, awaiting verification from Box
  Pending,

  // Order Cancelled by orderer
  Cancelled,

  // Order declined by Box
  Declined,

  // Bottling Completed
  Completed,
}
