import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { TextField, RaisedButton } from 'material-ui';
import SetPasswordFormUiStore from 'stores/ui/account/set-password-form';
import format, { formatToString } from 'utils/format';
import { PASSWORD_MIN_LENGTH, PASSWORD_MIN_DIGITS } from 'utils/forms/constants';

interface Props {
    formStore: SetPasswordFormUiStore;
    hasFieldError: boolean;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const Form = styled.form`
    padding: 15px 15px 60px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledTextField = styled(TextField)`
    width: 300px !important;
`;

const SubmitButton = styled(RaisedButton)`
    margin: 10px 0;
    min-width: 300px !important;
`;

@observer
class SetPasswordPage extends React.Component<Props, {}> {

    render() {
        let { formStore, hasFieldError, onSubmit } = this.props;

        return (
            <Form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    onSubmit(e);
                }}
            >
                <h2 className="hidden-xs">
                    {formatToString('reset-password.label')}
                </h2>
                <StyledTextField
                    type="password"
                    floatingLabelText={format('reset-password.password.label')}
                    fullWidth={true}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        
                        formStore.newPassword.onChange(e.currentTarget.value);
                        formStore.validateRepeatedPassword();
                    }}
                    value={formStore.newPassword.value}
                    errorText={format(
                        formStore.newPassword.error, 
                        { minLength: PASSWORD_MIN_LENGTH, minDigits: PASSWORD_MIN_DIGITS }
                    )}
                />
                <StyledTextField
                    type="password"
                    floatingLabelText={format('register.repeatedPassword.label')}
                    fullWidth={true}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        formStore.repeatedPassword.onChange(e.currentTarget.value)}
                    value={formStore.repeatedPassword.value}
                    errorText={format(formStore.repeatedPassword.error)}
                />
                <SubmitButton
                    primary={true}
                    type="submit"
                    label={format('settings.change.password')}
                    disabled={hasFieldError}
                />
            </Form>
        );
    }
}

export default SetPasswordPage;