import { Center } from "@chakra-ui/react";
import ellipse from "assets/images/ellipse.svg";
import imgBackground from "assets/images/julbord2021_dist_snow.jpg";
import BrandButton from "components/BrandButton";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import format from "utils/format";

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .ellipse {
    position: absolute;
    bottom: -5%;
    width: 100%;
    min-width: 1000px;
    z-index: 10;
  }
`;

const Background = styled.div`
  background: url(${imgBackground}) no-repeat bottom center;
  background-size: cover;
  height: 80vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:before {
    content: "";
    height: 90vh;
    width: 100%;
    content: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  height: calc(50vh);
`;

const BoxSlogan = styled.div`
  margin-bottom: calc(90vh * 0.06);
  color: #000000;
  font-size: 28px;
  text-align: center;
  line-height: 42px;
  font-family: IvarDisplay, sans-serif;

  .row1 {
    font-size: 50px;
  }

  .row2 {
    font-size: 42px;
  }

  @media (max-width: 1024px) {
    line-height: 32px;

    .row1 {
      font-size: 40px;
    }

    .row2 {
      font-size: 32px;
    }
  }
`;

const HeroContent = () => {
  const history = useHistory();

  return (
    <>
      <Wrapper className="hero-content">
        <Content>
          <BoxSlogan>
            <span className="row1">{format("home.hero.slogan")}</span>
            <br />
            <span className="row2">{format("home.hero.slogan2")}</span>
          </BoxSlogan>
          <Center>
            <BrandButton
              colorScheme="brand"
              fontWeight={400}
              fontSize="18px"
              onClick={() => history.push("/shop")}
            >
              {format("home.hero.becomeCaskOwnerButton.label")}
            </BrandButton>
          </Center>
        </Content>
        <Background />
        <img className="ellipse" src={ellipse.toString()} alt="ellipse" />
      </Wrapper>
    </>
  );
};

export default HeroContent;
