import Content from "components/Content";
import Paper from "material-ui/Paper";
import { MuiTheme } from "material-ui/styles";
import muiThemeable from "material-ui/styles/muiThemeable";
import { Tab, Tabs } from "material-ui/Tabs";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { NewMemberStore } from "stores";
import NewMemberUiStore, { Tab as MemberTab } from "stores/ui/new-member";
import styled from "styled-components";

const maxWidth = 900;
const Wrapper = styled.div`
  max-width: ${maxWidth}px;
  width: 100%;
  margin: 30px auto;
  height: 100%;

  .col__content {
    padding-left: 0;
    display: flex;
    flex-flow: column;
  }

  .col__bg-img {
    padding-right: 0;
  }

  .row {
    height: 100%;
  }

  .login__paper {
    height: 100%;
  }

  .login__paper,
  .row,
  .col__bg-img,
  .col__content {
    min-height: 650px;
  }

  .login__paper,
  .row {
    min-height: 650px;

    @media (min-width: 48em) {
      margin-top: 30px;
    }
  }
`;

const imgBackground: string = require("assets/images/box-lake-tower.jpg");
const BackgroundImage = styled.div`
  background: url(${imgBackground});
  width: 100%;
  height: 100%;
  background-size: cover;
`;

const RouteContent = styled.div`
  max-width: ${maxWidth / 2 - 100}px;
  box-sizing: border-box;
  width: 100%;
  padding-top: 30px;
  padding-left: 8px;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
`;

const TabContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
`;
const LoginPaper = styled(Paper)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const Instructions = styled.div`
  margin-top: 20px;
`;
const Error = styled.p`
  font-size: 14px;
  color: red;
  margin-top: 20px;
  text-align: center;
`;

const Terms = () => (
  <Link to="/user-terms" target="_blank">
    <FormattedMessage id="register.terms.linkText" />
  </Link>
);

const TermsContainer = styled.div`
  margin: 10px 0;
`;
interface Props {
  muiTheme?: MuiTheme;
  newMemberUiStore?: NewMemberUiStore;
  newMemberStore?: NewMemberStore;
  children?: React.ReactNode;
}

interface PropsWithRoute extends Props, RouteComponentProps<{}> {}

@inject("newMemberUiStore", "newMemberStore")
@observer
class NewMemberpage extends Component<PropsWithRoute, {}> {
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    this.memberStore.setTokenData(
      query.get("userId") || "",
      query.get("resetToken") || ""
    );
  }

  get memberStore() {
    return this.props.newMemberStore!;
  }
  get memberUiStore() {
    return this.props.newMemberUiStore!;
  }

  get tabStyle() {
    return {
      tab: {
        color: "#8a7b52",
      },
      tabs: {
        backgroundColor: "transparent",
      },
    };
  }

  GetErrorMessage() {
    const { errorMessage, errorCode } = this.memberUiStore;
    if (errorMessage.length > 0) {
      if (errorCode === "InvalidToken") {
        return <Link to={`/new-member/resend`}>{errorMessage}</Link>;
      }

      return <Error>{errorMessage}</Error>;
    }
    return;
  }

  render() {
    let { selectedTab } = this.props.newMemberUiStore!;

    return (
      <Wrapper>
        <Content>
          <LoginPaper zDepth={3} className="login__paper">
            <Row>
              <Col className="col__bg-img hidden-xs" xs={12} sm={6}>
                <BackgroundImage />
              </Col>
              <Col className="col__content" xs={12} sm={6}>
                <RouteContent>
                  <Tabs
                    initialSelectedIndex={selectedTab}
                    value={selectedTab}
                    className="content__tabs"
                    tabItemContainerStyle={this.tabStyle.tabs}
                  >
                    <Tab
                      value={MemberTab.Person}
                      label={
                        <FormattedMessage id="newMember.register.person.title" />
                      }
                      containerElement={<Link to="/new-member/person" />}
                      style={this.tabStyle.tab}
                    />

                    <Tab
                      value={MemberTab.Company}
                      label={
                        <FormattedMessage id="newMember.register.company.title" />
                      }
                      containerElement={<Link to="/new-member/company" />}
                      style={this.tabStyle.tab}
                    />
                  </Tabs>
                  <Instructions>
                    <FormattedMessage id="newMember.register.intro" />
                  </Instructions>
                  <TabContent>{this.props.children}</TabContent>
                  {this.GetErrorMessage()}
                  <TermsContainer>
                    <FormattedMessage
                      id="register.terms.text"
                      values={{ terms: <Terms /> }}
                    />
                  </TermsContainer>
                </RouteContent>
              </Col>
            </Row>
          </LoginPaper>
        </Content>
      </Wrapper>
    );
  }
}

export default muiThemeable()(withRouter(NewMemberpage));
