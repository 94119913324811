import Api from './api';
import { JsonApiResponse, toJsonApiResponse } from 'utils/json-api';
interface IsVisma {
  localVismaUserId: number;
}
export interface ChangePersonInfoViewModel extends IsVisma {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  zipCode: string;
  phoneNumber: string;
  country: string;
}

export interface ChangeCompanyInfoViewModel extends IsVisma {
  orgNumber: string;
  orgName: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
}

export default class ChangeContactInfoService {
  static async changePersonInfo(
    vm: ChangePersonInfoViewModel
  ): Promise<JsonApiResponse<{}>> {
    let response = await Api.post(
      'accounts/change-person-info',
      true,
      undefined,
      vm
    );
    return await toJsonApiResponse(response);
  }

  static async changeCompanyInfo(
    vm: ChangeCompanyInfoViewModel
  ): Promise<JsonApiResponse<{}>> {
    let response = await Api.post(
      'accounts/change-company-info',
      true,
      undefined,
      vm
    );
    return await toJsonApiResponse(response);
  }
}
