import Api from './api';
import { Language } from 'stores/domain/locale';
import { formatToString } from 'utils/format';
import { saveAs } from 'file-saver';

export default class OrderSettlementService {
    static async getLatestOrderSettlementForCask(
        caskId: number,
        language: Language,
        timeZone: string
    ): Promise<void> {
        let response = await Api.get(
            `latestSettlementForCask/${caskId}`,
            true,
            {},
            {
                timeZone: timeZone,
                language: language
            }
        );

        if (response.status !== 200) {
            return;
        }

        let buffer = await response.arrayBuffer();
        var file = new Blob([buffer], { type: 'application/pdf' });

        saveAs(file, formatToString('cask.info.fileName.orderSettlement'));
    }
    static async getOrderSettlement(
        orderId: number,
        language: Language,
        timeZone: string
    ): Promise<void> {
        let response = await Api.get(
            `settlement/${orderId}`,
            true,
            {},
            {
                timeZone: timeZone,
                language: language
            }
        );

        if (response.status !== 200) {
            return;
        }

        let buffer = await response.arrayBuffer();
        var file = new Blob([buffer], { type: 'application/pdf' });

        saveAs(file, formatToString('cask.info.fileName.orderSettlement'));
    }
}
