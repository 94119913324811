import { action, computed, makeObservable, observable } from "mobx";
import NewsItem from "models/news-item";
import NewsService from "services/news";

class NewsStore {
  @observable news: NewsItem[] = [];
  @observable isLoading!: boolean;

  constructor() {
    makeObservable(this);
  }

  @computed
  get totalNewsCount() {
    return this.news.length;
  }

  @action
  async fetchNews() {
    this.isLoading = true;
    let response = await NewsService.fetchNews();

    if (!response.succeeded) {
      throw new Error(`Couldn't fetch news`);
    }

    this.news = response.data;
    this.isLoading = false;
  }

  find(id: number): NewsItem {
    let newsItem = this.news.find((c) => c.id === id);
    if (!newsItem) {
      throw new Error(`News item with id ${id} couldn't be found`);
    }

    return newsItem;
  }
}

export default NewsStore;
export const newsStore = new NewsStore();
