import * as React from 'react';
import { Component } from 'react';
import { withRouter, RouteComponentProps  } from 'react-router';
import { Link } from 'react-router-dom';
import RegisterService from 'services/register';
import { FormattedMessage } from 'react-intl';

interface Props extends RouteComponentProps<{}> { }
interface SearchParams {
    userId: string;
    token: string;
}

interface State {
    hasValidToken: boolean;
    isLoading: boolean;
}

class VerifyPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasValidToken: false,
            isLoading: false
        };
    }

    get queryParams(): SearchParams {
        let urlSearchParams = new URLSearchParams(this.props.location.search);
        if (!urlSearchParams.has('userId') || !urlSearchParams.has('token')) {
            throw new Error('Couldn\'t get query parameters from Url');
        }
        
        return {
            userId: urlSearchParams.get('userId')!,
            token: urlSearchParams.get('token')!
        };
    }
    async componentDidMount() {
        let { userId, token } = this.queryParams;
        this.setState({ isLoading: true });

        let response = await RegisterService.verifyEmail(userId, token);
        this.setState({ isLoading: false });

        if (!response.succeeded) {
            this.setState({ hasValidToken: false });
            return;
        }
        
        this.setState({ hasValidToken: true });
    }

    render() {
        let { isLoading, hasValidToken } = this.state;
        return (
            <div>
                {isLoading 
                    ? (<h2><FormattedMessage id="register.verify.loading"/></h2>)
                    : (hasValidToken 
                        ? <div>
                            <h2>
                                <FormattedMessage id="register.verify.successHeader"/>
                            </h2>
                            <p>
                                <FormattedMessage id="register.verify.successText"/>
                            </p>
                        </div>
                        : <div>
                            <p>
                                <FormattedMessage id="register.verify.failText"/>
                            </p>
                            <Link to="/account/register/verify/new">
                                <FormattedMessage id="register.verify.retrySend"/>
                            </Link>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default withRouter(VerifyPage);