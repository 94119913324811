const config = {
  REACT_APP_ENV: "%#{{REACT_APP_ENV}}#%",
  REACT_APP_API_HOST: "%#{{REACT_APP_API_HOST}}#%",
  REACT_APP_CLIENT_ID: "%#{{REACT_APP_CLIENT_ID}}#%",
  NODE_PATH: "%#{{NODE_PATH}}#%",
};

export const envConfig = {
  REACT_APP_ENV:
    config.REACT_APP_ENV.charAt(0) !== "%"
      ? config.REACT_APP_ENV
      : "development",
  REACT_APP_API_HOST:
    config.REACT_APP_API_HOST.charAt(0) !== "%"
      ? config.REACT_APP_API_HOST
      : "http://localhost:2315",
  REACT_APP_CLIENT_ID:
    config.REACT_APP_CLIENT_ID.charAt(0) !== "%"
      ? config.REACT_APP_CLIENT_ID
      : "boxweb",
  NODE_PATH: config.NODE_PATH.charAt(0) !== "%" ? config.NODE_PATH : "src/",
};
