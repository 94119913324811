import { Box } from "@chakra-ui/react";
import styled from "styled-components";

const LayoutWrapper = styled(Box)`
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  padding-bottom: 2rem;

  @media (max-width: 48em) {
    padding: 0 1.5rem;
    padding-bottom: 2rem;
  }
`;

export default LayoutWrapper;
