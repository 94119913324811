import {
  Box,
  Center,
  Divider,
  FormLabel,
  Heading,
  Radio,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CustomRadioButtonGroup, CustomSelect } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import { observer } from "mobx-react";
import DeliveryOccasion, {
  DeliveryOccasionLocation,
} from "models/delivery-occasion";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useStores } from "stores";
import { formatToString } from "utils/format";
import * as Yup from "yup";
import DeliveryInfo from "../scenes/delivery/components/DeliveryInfo";
import { BottlingFormValues } from "./BottlingForm";
import { intersectingSectionAtom } from "./BottlingStepper";

export interface BottlingDeliveryPartValues {
  bottlingDeliveryOccasion?: DeliveryOccasion;
  deliveryLocation?: DeliveryOccasionLocation;
}

export const bottlingDeliverySchema = (format: (id: string) => any) =>
  Yup.object().shape({
    bottlingDeliveryOccasion: Yup.object().required(format("common.required")),
    deliveryLocation: Yup.number().required(format("common.required")),
  });

const DeliveryFormPart = () => {
  const [, setCurrentSection] = useAtom(intersectingSectionAtom);
  const { deliveryOccasionsStore, bottlingPriceVariablesStore, recipesStore } =
    useStores();
  const {
    values: {
      delivery: { bottlingDeliveryOccasion, deliveryLocation },
    },
    setFieldValue,
  } = useFormikContext<BottlingFormValues>();

  useEffect(() => {
    deliveryOccasionsStore.fetchDeliveryOccasions();
    bottlingPriceVariablesStore.fetchBottlingPriceVariables();
    recipesStore.fetchRecipes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    deliveryOccasionsStore.isLoading ||
    bottlingPriceVariablesStore.loading ||
    recipesStore.isLoading
  )
    return (
      <Center mt={8}>
        <Spinner />
      </Center>
    );

  return (
    <fieldset
      id="bottling-delivery"
      onClick={() => setCurrentSection("delivery")}
    >
      <Box mt={6} pb={8}>
        <Heading size="lg" fontWeight={500} mb={4}>
          <FormattedMessage id="cask.orders.bottling.delivery.title" />
        </Heading>
        <Divider mt={2} />
        <Box mt={4}>
          <CustomAlert
            labelKey="orders.bottlingOrder.date.desc"
            status="info"
          />
        </Box>
        <Box mt={4}>
          <FormLabel
            fontSize="lg"
            fontWeight={300}
            htmlFor="delivery.bottlingDeliveryOccasion"
          >
            <FormattedMessage id="orders.bottlingOrder.date.label" />
          </FormLabel>
          <CustomSelect
            w="full"
            id="delivery.bottlingDeliveryOccasion"
            name="delivery.bottlingDeliveryOccasion"
            value={bottlingDeliveryOccasion?.id}
            onChange={(v: any) =>
              setFieldValue(
                "delivery.bottlingDeliveryOccasion",
                deliveryOccasionsStore.deliveryOccasions.find(
                  (o) => o.id === Number(v.target.value)
                )
              )
            }
            placeholder={formatToString(
              "cask.orders.bottling.delivery.occation.pick"
            )}
          >
            {(deliveryOccasionsStore.deliveryOccasions || []).map((o) => (
              <option value={o.id} key={o.id}>
                {o.name}
              </option>
            ))}
          </CustomSelect>
          {bottlingDeliveryOccasion && (
            <Box mt={4}>
              <FormLabel
                fontSize="lg"
                fontWeight={300}
                htmlFor="delivery.deliveryLocation"
              >
                <FormattedMessage id="cask.orders.bottling.delivery.location" />
              </FormLabel>
              <CustomRadioButtonGroup
                id="delivery.deliveryLocation"
                value={deliveryLocation?.toString()}
                onChange={(v: string) => {
                  setFieldValue("delivery.deliveryLocation", Number(v));
                }}
              >
                <Stack spacing={4} alignItems="stretch">
                  {bottlingDeliveryOccasion.bottlingCountries.includes(
                    DeliveryOccasionLocation.Sweden
                  ) && (
                    <Radio
                      value={DeliveryOccasionLocation.Sweden.toString()}
                      w="full"
                    >
                      <FormattedMessage id="cask.orders.bottling.delivery.location.sweden" />
                      <Text fontWeight={300}>
                        <FormattedMessage id="cask.orders.bottling.delivery.location.sweden.desc" />
                      </Text>
                    </Radio>
                  )}
                  {bottlingDeliveryOccasion.bottlingCountries.includes(
                    DeliveryOccasionLocation.Denmark
                  ) && (
                    <Radio
                      value={DeliveryOccasionLocation.Denmark.toString()}
                      w="full"
                    >
                      <FormattedMessage id="cask.orders.bottling.delivery.location.denmark" />
                      <Text fontWeight={300}>
                        <FormattedMessage id="cask.orders.bottling.delivery.location.denmark.desc" />
                      </Text>
                    </Radio>
                  )}
                  {bottlingDeliveryOccasion.bottlingCountries.includes(
                    DeliveryOccasionLocation.Germany
                  ) && (
                    <Radio
                      value={DeliveryOccasionLocation.Germany.toString()}
                      w="full"
                    >
                      <FormattedMessage id="cask.orders.bottling.delivery.location.germany" />
                      <Text fontWeight={300}>
                        <FormattedMessage id="cask.orders.bottling.delivery.location.germany.desc" />
                      </Text>
                    </Radio>
                  )}
                  {bottlingDeliveryOccasion.bottlingCountries.includes(
                    DeliveryOccasionLocation.Estonia
                  ) && (
                    <Radio
                      value={DeliveryOccasionLocation.Estonia.toString()}
                      w="full"
                    >
                      <FormattedMessage id="cask.orders.bottling.delivery.location.estonia" />
                      <Text fontWeight={300}>
                        <FormattedMessage id="cask.orders.bottling.delivery.location.estonia.desc" />
                      </Text>
                    </Radio>
                  )}
                </Stack>
              </CustomRadioButtonGroup>
              {deliveryLocation !== undefined && (
                <DeliveryInfo deliveryLocation={deliveryLocation} />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </fieldset>
  );
};

export default observer(DeliveryFormPart);
