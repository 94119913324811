import * as H from "history";
import { action, makeObservable, observable } from "mobx";

class RouterStore {
  @observable history!: H.History;
  @observable location!: H.Location;

  constructor() {
    makeObservable(this);
  }

  @action
  changeRouterProps(history: H.History, location: H.Location) {
    this.history = history;
    this.location = location;
  }
}
export default RouterStore;
export const routerStore = new RouterStore();
