import { Box, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { UserType } from "models/cask-user";
import { ReactNode } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { formatToString } from "utils/format";
import { useCurrentCask } from "../cask-hooks";

const DetailsItem: React.FC<{ label: string; value?: ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <WrapItem display="block" w={["full", 256, 256]}>
      <Box mb={[2, 0, 0]}>
        <Text
          h={["auto", "auto", "40px"]}
          mb={2}
          display="flex"
          alignItems="flex-end"
          fontWeight={300}
        >
          <FormattedMessage id={label} />
        </Text>
        <Box p={4} bg="#f2f2f2">
          <Text fontWeight={500}>{value || "-"}</Text>
        </Box>
      </Box>
    </WrapItem>
  );
};

const CaskDetails = () => {
  const { cask, loading, isCaskOwner, recipeInfo } = useCurrentCask();

  if (loading) return null;
  const caskOwner = cask?.caskUsers.find(
    (user) => user.userType === UserType.Owner
  );
  const caskOwnerName =
    caskOwner && caskOwner.vismaUser
      ? caskOwner.vismaUser.firstName + " " + caskOwner.vismaUser.lastName
      : "";

  return (
    <>
      {!isCaskOwner && (
        <Box maxW={800} mt={4} mb={-4}>
          <Text
            h={["auto", "auto", "40px"]}
            mb={2}
            display="flex"
            alignItems="flex-end"
            fontWeight={300}
          >
            <FormattedMessage id="cask.info.ownerName" />
          </Text>
          <Box p={4} borderRadius={6} bg="#f2f2f2">
            <Text fontWeight={500}>{caskOwnerName}</Text>
          </Box>
        </Box>
      )}
      <Wrap mt={4} maxW={800} mb={6} spacing={4}>
        <DetailsItem
          label="cask.info.caskNumber.label"
          value={cask?.vismaCaskNumber}
        />
        <DetailsItem label="cask.info.caskSign.label" value={cask?.caskSign} />
        <DetailsItem
          label="cask.info.caskSize.label"
          value={
            cask?.loadVolume
              ? `${cask.loadVolume} ${formatToString(
                  "cask.info.caskSize.liter.unit"
                )}`
              : ""
          }
        />
        <DetailsItem
          label="cask.info.alcoholPercentage.label"
          value={cask?.alcoholPercentage}
        />
        <DetailsItem
          label="cask.info.liquorFilled.label"
          value={
            cask?.liquorFilled && <FormattedDate value={cask?.liquorFilled} />
          }
        />
        <DetailsItem
          label="cask.info.filled.label"
          value={cask?.filled && <FormattedDate value={cask?.filled} />}
        />
        <DetailsItem
          label="cask.info.warehouse.label"
          value={cask?.warehouseArea}
        />
        <DetailsItem
          label="cask.info.stock-number.label"
          value={cask?.stockNumber}
        />
        <DetailsItem label="cask.info.recipe.label" value={recipeInfo?.name} />

        <DetailsItem
          label="cask.info.caskLoan.label"
          value={cask?.loanClaim ? `${cask.loanClaim} SEK` : ""}
        />
      </Wrap>
    </>
  );
};

export default observer(CaskDetails);
