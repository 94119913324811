import { inject, observer } from "mobx-react";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import RouterStore from "stores/domain/router";

interface Props {
  routerStore?: RouterStore;
}

interface PropsWithRouter extends Props, RouteComponentProps<{}> {}

@inject("routerStore")
@observer
class MobxRouter extends Component<PropsWithRouter, {}> {
  get routerStore() {
    return this.props.routerStore!;
  }

  componentDidMount() {
    this.routerStore.changeRouterProps(this.props.history, this.props.location);
  }

  componentWillReceiveProps(nextProps: RouteComponentProps<{}>) {
    this.routerStore.changeRouterProps(nextProps.history, nextProps.location);
  }

  render() {
    return <div>{(this.props as any).children}</div>;
  }
}

export default withRouter(MobxRouter) as any;
