import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Snackbar from 'material-ui/Snackbar';
import SnackBarUiStore from 'stores/ui/snackBar';

interface Props {
    snackBarUiStore?: SnackBarUiStore;
}

@inject('snackBarUiStore')
@observer
class SnackBar extends React.Component<Props, {}> {

    handleRequestClose = () => {
        this.props.snackBarUiStore!.close();
    }

    render() {
        return (
            <Snackbar 
                open={this.props.snackBarUiStore!.isOpen}
                message={this.props.snackBarUiStore!.message}
                autoHideDuration={4000}
                onRequestClose={this.handleRequestClose}
                style={{ top: '64px', transform: 'none', right: 0, left: 'auto', opacity: 0.8, height: 48 }}
                bodyStyle={{ backgroundColor: this.props.snackBarUiStore!.backgroundColor }}
            />
        );
    }
}

export default SnackBar;