import { Step, StepsWrapper } from "components/CommonStyles";
import { useFormikContext } from "formik";
import { atom, useAtom } from "jotai";
import { FC, useEffect, useState } from "react";
import { useMedia } from "react-use";
import { formatToString } from "utils/format";
import { BottlingFormValues } from "./BottlingForm";
import { bottlingInfoSchema } from "./BottlingInfoFormPart";
import { bottlingContactSchema } from "./ContactFormPart";
import { bottlingDeliverySchema } from "./DeliveryFormPart";
import { bottlingLabelsSchema } from "./LabelsFormPart";
import { bottlingLoanSchema } from "./LoanFormPart";

type Section =
  | "loan"
  | "summary"
  | "contact"
  | "delivery"
  | "labels"
  | "information";

export const intersectingSectionAtom = atom<Section>("loan");

const getElementOffset = (id: string, windowHeight: number) => {
  const element = document.getElementById(id);
  if (!element) return [0, Number.MAX_SAFE_INTEGER];

  return [
    element.getBoundingClientRect().left,
    element.getBoundingClientRect().top + windowHeight / 2,
  ];
};

const useActiveStep = (showSummary: boolean) => {
  const [active, setActive] = useState<number>(0);
  const [currentSection] = useAtom(intersectingSectionAtom);

  useEffect(() => {
    if (showSummary) return setActive(5);

    if (currentSection === "contact") return setActive(4);
    if (currentSection === "delivery") return setActive(3);
    if (currentSection === "labels") return setActive(2);
    if (currentSection === "information") return setActive(1);

    return setActive(0);
  }, [showSummary, currentSection]);

  return active;
};

const BottlingStepper: FC = () => {
  const [leftOffset, setLeftOffset] = useState<number>(0);
  const {
    values: {
      loan,
      bottlingInfo,
      labels,
      delivery,
      contact,
      showSummary,
      newOrderNumber,
    },
  } = useFormikContext<BottlingFormValues>();
  const isMobile = useMedia("(max-width: 48em)");
  const index = useActiveStep(showSummary);

  useEffect(() => {
    if (!isMobile) return;

    if (index === 0) return setLeftOffset(0);

    return setLeftOffset(
      (o) => getElementOffset(`stepper-${index}`, 0)[0] + o - 20
    );
  }, [isMobile, index]);

  return (
    <StepsWrapper
      mt={isMobile ? 0 : 6}
      w={isMobile ? "auto" : "185px"}
      mr={isMobile ? 0 : 6}
      ml={isMobile ? -1 * leftOffset : 0}
    >
      <Step
        index={0}
        active={index === 0}
        labelKey="cask.orders.bottling.loan.title"
        valid={bottlingLoanSchema(formatToString).isValidSync(loan)}
      />
      <Step
        index={1}
        active={index === 1}
        labelKey="cask.orders.bottling.info.title"
        valid={bottlingInfoSchema(formatToString).isValidSync(bottlingInfo)}
      />
      <Step
        index={2}
        active={index === 2}
        labelKey="cask.orders.bottling.labels.title"
        valid={bottlingLabelsSchema(formatToString).isValidSync(labels)}
      />
      <Step
        index={3}
        active={index === 3}
        labelKey="cask.orders.bottling.delivery.title"
        valid={bottlingDeliverySchema(formatToString).isValidSync(delivery)}
      />
      <Step
        index={4}
        active={index === 4}
        labelKey="cask.orders.bottling.contact.title"
        valid={bottlingContactSchema(formatToString).isValidSync(contact)}
      />
      <Step
        index={5}
        active={index === 5}
        hideLine
        labelKey="cask.orders.bottling.summary.title"
        valid={!!newOrderNumber}
      />
    </StepsWrapper>
  );
};

export default BottlingStepper;
