import * as React from 'react';
import { inject } from 'mobx-react';
import styled from 'styled-components';
import { Paper } from 'material-ui';
import ResetPasswordUiStore from 'stores/ui/account/reset-password';
import format, { formatToString } from 'utils/format';

interface Props {
    resetPasswordUiStore?: ResetPasswordUiStore;
}

const Wrapper = styled(Paper)`
    padding: 15px 15px 60px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

@inject('resetPasswordUiStore')
export default class ResetPasswordCompletePage extends React.Component<Props, {}> {

    render() {
        return (
            <Wrapper>
                <h2 className="hidden-xs">
                    {formatToString('reset-password.label')}
                </h2>

               {format('reset-password.complete.text')}
               <h3>
                   {this.props.resetPasswordUiStore!.email.value ? this.props.resetPasswordUiStore!.email.value : ''}
               </h3>
            </Wrapper>
        );
    }
}