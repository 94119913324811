import Cask from "models/cask";
import { CaskStatus } from "models/cask-status";
import React from "react";
import { FormattedMessage } from "react-intl";
import { formatCaskAge } from "utils/format";

interface CaskStatusTextProps {
  cask?: Cask;
  skipDays?: boolean;
}

const CaskStatusText: React.FC<CaskStatusTextProps> = ({ cask, skipDays }) => {
  if (!cask) return null;

  if (cask.status === CaskStatus.NotFilled)
    return <FormattedMessage id="cask.info.notFilled" />;

  if (cask.status === CaskStatus.FullBottled)
    return <FormattedMessage id="cask.isEmptied" />;

  if (cask.status === CaskStatus.FullBottlingOrdered)
    return <FormattedMessage id="cask.info.fullbottlingOrdered" />;

  return (
    <>
      <FormattedMessage id="cask.stored" />
      {cask.liquorFilled ? (
        formatCaskAge(cask.liquorFilled, skipDays)
      ) : cask.filled ? (
        formatCaskAge(cask.filled, skipDays)
      ) : (
        <FormattedMessage id="cask.info.notFilled" />
      )}
    </>
  );
};

export default CaskStatusText;
