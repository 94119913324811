import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const tracking = "_paq" in window ? (window as any)._paq : [];
interface TrackingEvent {
  category: string;
  action: string;
  name: string;
}

export const consent = () => tracking.push(["rememberCookieConsentGiven"]);

export const trackEvent = (event: TrackingEvent) => {
  tracking.push(["trackEvent", event.action, event.category, event.name]);
};

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    tracking.push(["setCustomUrl", location.pathname + location.search]);
    tracking.push(["setDocumentTitle", document.title]);
    tracking.push(["trackPageView"]);
  }, [location]);
};
