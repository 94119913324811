import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { PASSWORD_MIN_LENGTH } from "utils/forms/constants";
import {
  hasNumber,
  isEmail,
  minLength,
  required,
} from "utils/forms/validators";

type RegisterCompanyFormState = {
  email: FieldState<string>;
  password: FieldState<string>;
  repeatedPassword: FieldState<string>;
  orgNumber: FieldState<string>;
  orgName: FieldState<string>;
  contactFirstName: FieldState<string>;
  contactLastName: FieldState<string>;
  phoneNumber: FieldState<string>;
};

class RegisterCompanyFormUiStore {
  @observable
  registeredEmail!: string;
  @observable
  emailUnavailable!: boolean;

  constructor() {
    makeObservable(this);
  }

  email: FieldState<string> = new FieldState("").validators(
    required("register.email.error.required"),
    isEmail("register.email.error.invalidEmail"),
    this.isAvailableEmail("register.email.error.unavailable")
  );
  orgNumber: FieldState<string> = new FieldState("").validators(
    required("register.orgNumber.error.required")
  );
  orgName: FieldState<string> = new FieldState("").validators(
    required("register.orgName.error.required")
  );
  contactFirstName: FieldState<string> = new FieldState("").validators(
    required("register.contactFirstName.label")
  );
  contactLastName: FieldState<string> = new FieldState("").validators(
    required("register.contactLastName.label")
  );
  password: FieldState<string> = new FieldState("").validators(
    required("register.password.error.required"),
    minLength(PASSWORD_MIN_LENGTH, "register.password.error.minLength"),
    hasNumber("register.password.error.hasNumber")
  );
  repeatedPassword: FieldState<string> = new FieldState("").validators(
    required("register.repeatedPassword.error.required"),
    () => this.passwordsMustMatch()
  );
  phoneNumber: FieldState<string> = new FieldState("").validators(
    required("register.phoneNumber.error.required")
  );

  form = new FormState<RegisterCompanyFormState>({
    email: this.email,
    password: this.password,
    repeatedPassword: this.repeatedPassword,
    orgNumber: this.orgNumber,
    orgName: this.orgName,
    contactFirstName: this.contactFirstName,
    contactLastName: this.contactLastName,
    phoneNumber: this.phoneNumber,
  });

  passwordsMustMatch() {
    return this.password.value === this.repeatedPassword.value
      ? false
      : "register.repeatedPassword.error.match";
  }

  isAvailableEmail(message: string) {
    return () => this.emailUnavailable && message;
  }

  @action
  setEmailUnavailable(value: boolean) {
    this.emailUnavailable = value;

    this.email.validate();
  }

  @action
  validateRepeatedPassword = async () => {
    if (!this.repeatedPassword.dirty) {
      return;
    }

    this.repeatedPassword.validate();
  };

  @action
  resetForm() {
    this.email.reset();
    this.orgNumber.reset();
    this.orgName.reset();
    this.password.reset();
    this.repeatedPassword.reset();
    this.phoneNumber.reset();
    this.contactFirstName.reset();
    this.contactLastName.reset();
    this.emailUnavailable = false;
    this.registeredEmail = "";
  }
}

export default RegisterCompanyFormUiStore;
export const registerCompanyFormUiStore = new RegisterCompanyFormUiStore();
