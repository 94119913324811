import LoadingSpash from "components/Loading/LoadingSplash";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import InformationStore from "stores/domain/information";
import LocaleStore from "stores/domain/locale";
import DrawerUiStore from "stores/ui/drawer";
import styled from "styled-components";
import BodyComponent from "./BodyComponent";
import MenuComponent from "./MenuComponent";

interface Props {
  informationStore?: InformationStore;
  localeStore?: LocaleStore;
  drawerUiStore?: DrawerUiStore;
}

interface PropsWithRoute
  extends Props,
    RouteComponentProps<{ id: string; child: string }> {}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-flow: row wrap;
  min-height: 70vh;

  @media (max-width: 48em) {
    flex-direction: column;
  }
`;

@inject("informationStore", "localeStore", "drawerUiStore")
@observer
export default class InformationContainer extends React.Component<
  PropsWithRoute,
  {}
> {
  async componentDidMount() {
    await this.informationStore.fetchInformation();
  }
  componentDidUpdate() {
    this.informationStore.setSelectedItem(this.id, this.parentId);
    this.drawerUiStore.closeDrawer();
  }

  get id() {
    const id = this.props.match.params.child
      ? this.props.match.params.child
      : this.props.match.params.id;
    return parseInt(id, 10);
  }

  get parentId() {
    return this.props.match.params.child
      ? parseInt(this.props.match.params.id, 10)
      : NaN;
  }

  get informationStore() {
    return this.props.informationStore!;
  }

  get localeStore() {
    return this.props.localeStore!;
  }
  get drawerUiStore() {
    return this.props.drawerUiStore!;
  }

  get currentItemContent() {
    const contents = this.informationStore.selectedItem.menuItemContent;
    let localizedContent = contents.find(
      (content) => content.language === this.localeStore.languageInt
    );
    if (!localizedContent) {
      localizedContent = contents[0];
    }
    return localizedContent;
  }
  render() {
    if (
      this.informationStore.isLoading ||
      this.informationStore.menuItems.length === 0
    ) {
      return (
        <div>
          <LoadingSpash />
        </div>
      );
    } else {
      return (
        <Container>
          <MenuComponent
            selectedItem={this.informationStore.selectedItem}
            menuItems={this.informationStore.menuItems}
            language={this.localeStore.languageInt}
          />
          <BodyComponent
            content={this.currentItemContent.state}
            title={this.currentItemContent.title}
          />
        </Container>
      );
    }
  }
}
