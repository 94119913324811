import { UploadFile } from "components/FileUploader/FileUploader";
import {
  getImageMetaData,
  getImagePreview,
} from "components/FileUploader/FileUploaderPreview";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { BottleLabel, Customization } from "models/bottle-label";
import {
  AfterBottlingAction,
  BankAccountType,
  BottlingAmount,
} from "models/bottling-order";
import { DeliveryOccasionLocation } from "models/delivery-occasion";
import OrdersService, { OrderBottlingVm } from "services/orders";
import UploadFileService from "services/upload-file-service";
import { deliveryOccasionsStore } from "stores/domain/delivery-occasions";
import { localeStore } from "stores/domain/locale";
import { sessionStore } from "stores/domain/session";
import { caskUiStore } from "stores/ui/cask";
import * as Constants from "utils/forms/constants";
import {
  isEmail,
  isNumber,
  maxLength,
  minLength,
  required,
} from "utils/forms/validators";
import { FormStep } from "../bottling-order";

export type BottleLabelForm = {
  customization: FieldState<any>;
  oldBOXLabel: FieldState<boolean>;
  text: FieldState<string>;
  file: FieldState<any>;
};

type BottlingInformationFormState = {
  bottlingAmount: FieldState<BottlingAmount>;
  numberOfBottles: FieldState<number>;
  useDefaultAlcoholContent: FieldState<boolean>;
  alcoholContent: FieldState<number>;
  afterBottlingAction: FieldState<AfterBottlingAction>;
  caskSignText: FieldState<string>;
  isSwedishBankAccount: FieldState<boolean>;
  clearingNumber: FieldState<string>;
  swedishBankAccountNumber: FieldState<string>;
  foreignBankAccountNumber: FieldState<string>;
};

type DeliveryFormState = {
  location: FieldState<DeliveryOccasionLocation>;
  bottlingRoundName: FieldState<string>;
};

type LabelFormState = {
  customization: FieldState<Customization>;
  oldBOXLabel: FieldState<boolean>;
  labelText: FieldState<string>;
  labelFile: FieldState<UploadFile | undefined>;
};

type ContactInformationFormState = {
  fullName: FieldState<string>;
  email: FieldState<string>;
  phoneNumber: FieldState<string>;
};

class BottlingOrderFormUiStore {
  @observable loading: boolean = false;
  @observable
  orderId!: string;
  @observable
  orderIsComplete!: boolean;
  @observable
  sendingOrder!: boolean;
  @observable id: number | undefined;
  @observable
  errorMessage!: string;
  @observable allowedNumberOfBoxes: number[] = Array(50)
    .fill(0)
    .map((e, i) => i + 1);

  constructor() {
    makeObservable(this);
  }

  bottlingAmount: FieldState<any> = new FieldState(BottlingAmount.FullBottling);
  numberOfBottles: FieldState<number> = new FieldState(6);
  useDefaultAlcoholContent: FieldState<boolean> = new FieldState(true);
  alcoholContent: FieldState<number> = new FieldState(NaN).validators(
    (value: number) => {
      if (!this.isDefaultAlcoholContent() && !value) {
        return "orders.bottlingOrder.alcoholContent.error.required";
      }
      return false;
    },
    (value: number) => {
      if (
        !this.isDefaultAlcoholContent() &&
        value < Constants.ALCOHOL_CONTENT_MIN_VALUE
      ) {
        return "orders.bottlingOrder.alcoholContent.error.minValue";
      }
      return false;
    }
  );
  isSwedishBankAccount: FieldState<boolean> = new FieldState(true);

  clearingNumber: FieldState<string> = new FieldState("").validators(
    (value: string) => {
      if (
        this.swedishBankAccountisSelected() &&
        this.isFullBottling() &&
        !value
      ) {
        return "orders.bottlingOrder.clearingNumber.error.required";
      }
      return false;
    },
    (value: string) => {
      if (
        this.swedishBankAccountisSelected() &&
        this.isFullBottling() &&
        value.length > Constants.CLEARING_NUMBER__MAX_LENGTH
      ) {
        return "orders.bottlingOrder.clearingNumber.error.maxLength";
      }
      return false;
    }
  );

  swedishBankAccountNumber: FieldState<string> = new FieldState("").validators(
    (value: string) => {
      if (
        this.swedishBankAccountisSelected() &&
        this.isFullBottling() &&
        !value
      ) {
        return "orders.bottlingOrder.swedishBankAccountNumber.error.required";
      }
      return false;
    },
    (value: string) => {
      if (
        this.swedishBankAccountisSelected() &&
        this.isFullBottling() &&
        value.length < Constants.SWEDISH_BANK_ACCOUNT_NUMBER_MIN_LENGTH
      ) {
        return "orders.bottlingOrder.swedishBankAccountNumber.error.minLength";
      }
      return false;
    },
    (value: string) => {
      if (
        this.swedishBankAccountisSelected() &&
        this.isFullBottling() &&
        value.length > Constants.SWEDISH_BANK_ACCOUNT_NUMBER_MAX_LENGTH
      ) {
        return "orders.bottlingOrder.swedishBankAccountNumber.error.maxLength";
      }
      return false;
    }
  );

  foreignBankAccountNumber: FieldState<string> = new FieldState("").validators(
    (value: string) => {
      if (
        !this.swedishBankAccountisSelected() &&
        this.isFullBottling() &&
        !value
      ) {
        return "orders.bottlingOrder.foreignBankAccountNumber.error.required";
      }
      return false;
    },
    (value: string) => {
      if (
        !this.swedishBankAccountisSelected() &&
        this.isFullBottling() &&
        value.length < Constants.FOREIGN_BANK_ACCOUNT_NUMBER_MIN_LENGTH
      ) {
        return "orders.bottlingOrder.foreignBankAccountNumber.error.minLength";
      }
      return false;
    },
    (value: string) => {
      if (
        !this.swedishBankAccountisSelected() &&
        this.isFullBottling() &&
        value.length > Constants.FOREIGN_BANK_ACCOUNT_NUMBER_MAX_LENGTH
      ) {
        return "orders.bottlingOrder.foreignBankAccountNumber.error.maxLength";
      }
      return false;
    }
  );

  location: FieldState<DeliveryOccasionLocation> = new FieldState(
    DeliveryOccasionLocation.Sweden
  ).validators();
  bottlingRoundName: FieldState<string> = new FieldState("").validators(
    (name: string) => {
      if (!name) {
        return "orders.bottlingOrder.date.error.required";
      }
      return false;
    }
  );

  @observable bottleCustomization: FieldState<any> = new FieldState(
    Customization.None
  );
  @observable bottleOldBOXLabel: FieldState<boolean> = new FieldState(false);
  @observable bottleLabelText: FieldState<string> = new FieldState(
    ""
  ).validators(
    maxLength(
      Constants.BOTTLE_LABEL_TEXT_MAX_LENGTH,
      "orders.bottlingOrder.labelText.error.maxLength"
    )
  );
  @observable bottleLabelFile: FieldState<any> = new FieldState(
    undefined
  ).validators((file: UploadFile | undefined) => {
    if (!file && this.selectedLabelType() === Customization.File) {
      return "orders.bottlingOrder.customization.file.error.required";
    }
    return false;
  });

  @observable additionalLabelInfo: FieldState<string> = new FieldState("");

  // Label
  @observable bottleLabels: BottleLabelForm[] = [
    {
      customization: this.bottleCustomization,
      oldBOXLabel: this.bottleOldBOXLabel,
      text: this.bottleLabelText,
      file: this.bottleLabelFile,
    },
  ];

  @observable
  afterBottlingAction: FieldState<AfterBottlingAction> = new FieldState(
    AfterBottlingAction.NotSet
  ).validators((bottlingAction: AfterBottlingAction) => {
    return !this.isFullBottling() ||
      bottlingAction !== AfterBottlingAction.NotSet
      ? false
      : "orders.bottlingOrder.afterBottling.error.required";
  });

  @observable
  caskSignText: FieldState<string> = new FieldState("").validators(
    maxLength(
      Constants.CASK_SIGN_TEXT_MAX_LENGTH,
      "orders.bottlingOrder.newCaskText.error.maxLength"
    )
  );

  @observable
  fullName: FieldState<string> = new FieldState("").validators(
    required("orders.bottlingOrder.fullName.error.required"),
    maxLength(
      Constants.FULL_NAME_MAX_LENGTH,
      "orders.bottlingOrder.fullName.error.maxLength"
    )
  );
  @observable
  email: FieldState<string> = new FieldState("").validators(
    required("orders.bottlingOrder.email.error.required"),
    isEmail("orders.bottlingOrder.email.error.isEmail"),
    maxLength(
      Constants.EMAIL_MAX_LENGTH,
      "orders.bottlingOrder.email.error.maxLength"
    )
  );
  @observable
  phoneNumber: FieldState<string> = new FieldState("").validators(
    required("orders.bottlingOrder.phoneNumber.error.required"),
    isNumber("orders.bottlingOrder.phoneNumber.error.isNumber"),
    minLength(
      Constants.PHONE_MIN_LENGTH,
      "orders.bottlingOrder.phoneNumber.error.minLength"
    ),
    maxLength(
      Constants.PHONE_MAX_LENGTH,
      "orders.bottlingOrder.phoneNumber.error.maxLength"
    )
  );

  @observable
  wantsPersonalizedLabels: FieldState<boolean> = new FieldState<boolean>(false);

  bottlingInformationFields = new FormState<BottlingInformationFormState>({
    bottlingAmount: this.bottlingAmount,
    numberOfBottles: this.numberOfBottles,
    useDefaultAlcoholContent: this.useDefaultAlcoholContent,
    alcoholContent: this.alcoholContent,
    afterBottlingAction: this.afterBottlingAction,
    caskSignText: this.caskSignText,
    isSwedishBankAccount: this.isSwedishBankAccount,
    clearingNumber: this.clearingNumber,
    swedishBankAccountNumber: this.swedishBankAccountNumber,
    foreignBankAccountNumber: this.foreignBankAccountNumber,
  });

  deliveryFields = new FormState<DeliveryFormState>({
    location: this.location,
    bottlingRoundName: this.bottlingRoundName,
  });

  labelFields = new FormState<LabelFormState>({
    customization: this.bottleCustomization,
    oldBOXLabel: this.bottleOldBOXLabel,
    labelText: this.bottleLabelText,
    labelFile: this.bottleLabelFile,
  });

  contactInformationFields = new FormState<ContactInformationFormState>({
    fullName: this.fullName,
    email: this.email,
    phoneNumber: this.phoneNumber,
  });

  selectedLabelType(): Customization {
    return this.bottleCustomization.value;
  }

  swedishBankAccountisSelected() {
    return this.isSwedishBankAccount.value;
  }

  isDefaultAlcoholContent() {
    return this.useDefaultAlcoholContent.value;
  }

  isFullBottling() {
    return this.bottlingAmount.value === BottlingAmount.FullBottling;
  }

  @action
  validateFormStep = async (formStep: FormStep) => {
    switch (formStep) {
      case FormStep.BottlingInformation:
        return await this.bottlingInformationFields.validate();
      case FormStep.Delivery:
        return await this.deliveryFields.validate();
      case FormStep.Label:
        return await this.labelFields.validate();
      case FormStep.ContactInformation:
        return await this.contactInformationFields.validate();
      default:
        throw new Error("Trying to validate undefined formstep");
    }
  };

  @computed
  get deliveryOccasionId(): number | undefined {
    let location = this.location.$;
    let bottlingRoundName = this.bottlingRoundName.$;

    if (
      location === undefined ||
      bottlingRoundName === undefined ||
      deliveryOccasionsStore.deliveryOccasions === undefined
    ) {
      return;
    }

    let occasion = deliveryOccasionsStore.deliveryOccasions.filter(
      (o) => o.name === bottlingRoundName
    );

    // If there should be duplicates of occation dates, select the first of them.
    if (occasion.length > 1) {
      occasion = [occasion[0]];
    }

    if (!occasion || occasion.length !== 1) {
      throw new Error(`Couldn't find the delivery occasion`);
    }

    return occasion[0].id;
  }

  @computed
  get deliveryOccasionPickup(): Date | undefined {
    let deliveryOccasionId = this.deliveryOccasionId;

    if (deliveryOccasionsStore.deliveryOccasions === undefined) {
      return;
    }

    let deliveryOccasion = deliveryOccasionsStore.deliveryOccasions.find(
      (o) => o.id === deliveryOccasionId
    );

    if (!deliveryOccasion) {
      return;
    }

    return deliveryOccasion!.pickup;
  }

  /**
   * Create a new bottling order from the form input.
   */
  @action
  async submitForm() {
    this.sendingOrder = true;

    // Process labels and send vismaCaskNumber as prefix
    let vismaCaskNumber = caskUiStore.cask.vismaCaskNumber || "";
    let labels = await this.processLabels(this.bottleLabels, vismaCaskNumber);

    let order: OrderBottlingVm = {
      caskId: caskUiStore.caskId,
      bottlingAmount: this.bottlingAmount.value,
      numberOfBottles: undefined,
      alcoholContent: undefined,
      additionalLabelInfo: this.additionalLabelInfo.value,
      deliveryOccasionId: this.deliveryOccasionId,
      deliveryLocation: this.location.value,
      bottleLabels: labels,
      afterBottlingAction: this.afterBottlingAction.value,
      caskSignText: "",
      fullName: this.fullName.value,
      email: this.email.value,
      phoneNumber: this.phoneNumber.value,
      bankAccountType: this.isSwedishBankAccount.value
        ? BankAccountType.Swedish
        : BankAccountType.International,
      clearingNumber: this.isSwedishBankAccount.value
        ? this.clearingNumber.value
        : undefined,
      bankAccountNumber: this.isSwedishBankAccount.value
        ? this.swedishBankAccountNumber.value
        : this.foreignBankAccountNumber.value,
      language: localeStore.language,
      localVismaUserId: sessionStore.vismaUser.id,
    };

    if (this.bottlingAmount.value === BottlingAmount.PartBottling) {
      order.numberOfBottles = this.numberOfBottles.value;
    }

    if (
      this.afterBottlingAction.value === AfterBottlingAction.Refill ||
      this.afterBottlingAction.value === AfterBottlingAction.RefillSmokey ||
      this.afterBottlingAction.value === AfterBottlingAction.RefillTwoYear ||
      this.afterBottlingAction.value === AfterBottlingAction.RefillTwoYearSmokey
    ) {
      order.caskSignText = this.caskSignText.value;
    }

    if (!this.useDefaultAlcoholContent.value) {
      order.alcoholContent = this.alcoholContent.value;
    }
    let success = true;
    try {
      let orderResponse = await OrdersService.orderBottling(order);
      if (orderResponse.hasErrors) {
        this.errorMessage = "orders.bottlingOrder.error.unknon";
        success = false;
      } else {
        this.orderIsComplete = true;
        this.orderId = orderResponse.data.order.guid;
        this.id = orderResponse.data.id;
      }
    } catch (e) {
      this.errorMessage = "orders.bottlingOrder.error.unknon";
      success = false;
    }
    this.sendingOrder = false;
    return success;
  }

  async firstInvalidStep(validateToStep: FormStep): Promise<FormStep> {
    for (let i = FormStep.BottlingInformation; i < validateToStep; i++) {
      let { hasError } = await this.validateFormStep(i);
      if (hasError) {
        return i;
      }
    }

    return validateToStep;
  }

  @action
  resetForm(email: string) {
    this.afterBottlingAction.reset();
    this.alcoholContent.reset();
    this.bottlingAmount.reset();
    this.caskSignText.reset();
    this.bottleCustomization.reset();
    this.bottlingRoundName.reset();
    this.fullName.reset();
    this.email.reset(email);
    this.foreignBankAccountNumber.reset();
    this.isSwedishBankAccount.reset();
    this.bottleLabelFile.reset();
    this.bottleLabelText.reset();
    this.location.reset();
    this.numberOfBottles.reset();
    this.phoneNumber.reset();
    this.swedishBankAccountNumber.reset();
    this.clearingNumber.reset();
    this.useDefaultAlcoholContent.reset();
    this.wantsPersonalizedLabels.reset();
    this.wantsPersonalizedLabels.reset();

    this.orderIsComplete = false;
    this.sendingOrder = false;
    this.id = undefined;
    this.errorMessage = "";
  }

  @action
  addLabel() {
    this.bottleLabels.push({
      customization: new FieldState(Customization.None),
      oldBOXLabel: new FieldState(false),
      text: new FieldState("").validators(
        maxLength(75, "orders.bottlingOrder.labelText.error.maxLength")
      ),
      file: new FieldState(undefined).validators(
        (file: UploadFile | undefined) => {
          if (!file && this.selectedLabelType() === Customization.File) {
            return "orders.bottlingOrder.customization.file.error.required";
          }
          return false;
        }
      ),
    });
  }

  @action
  removeLabel(index: number) {
    this.bottleLabels.splice(index, 1);
  }

  @action
  async addLabelFile(labelIndex: number, file: UploadFile) {
    let imagePreview = await getImagePreview(file.file);
    let imageMetaData = await getImageMetaData(imagePreview);
    file.imagePreview = imagePreview;
    file.imageMetaData = imageMetaData;
    this.bottleLabels[labelIndex].file.onChange(file);
  }

  private async processLabels(
    labelForm: BottleLabelForm[],
    labelPrefix: string
  ) {
    // Upload labels that contain files
    let labelsWithFiles = labelForm.filter(
      (label) => label.customization.value === Customization.File
    );
    let labelFiles: UploadFile[] = labelsWithFiles.map(
      (label) => label.file.value!
    );

    let fileResponses = await Promise.all(
      labelFiles.map((file) => {
        return UploadFileService.uploadFiles([file], labelPrefix);
      })
    );

    let fileUrls = fileResponses.map((file) => file.data.uploadedUrl);

    let labels: BottleLabel[] = labelForm.map((label) => {
      let changedLabel = {
        customization: label.customization.value,
        text: "",
        imageUrl: "",
        oldBOXLabel: label.oldBOXLabel.value,
      };

      if (label.customization.value === Customization.PersonalText) {
        changedLabel.text = label.text.value;
      }

      return changedLabel;
    });

    labels = labels.filter(
      (label) => label.customization !== Customization.File
    );

    /* fileUrls.forEach((imageUrl) => {
      labels.push({
        customization: Customization.File,
        text: "",
        imageUrl: imageUrl,
      });
    }); */

    return labels;
  }
}

export default BottlingOrderFormUiStore;
export const bottlingOrderFormUiStore = new BottlingOrderFormUiStore();
