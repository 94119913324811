import { inject, observer } from "mobx-react";
import * as React from "react";
import { Component } from "react";
import { addLocaleData, IntlProvider } from "react-intl";
import * as en from "react-intl/locale-data/en";
import * as sv from "react-intl/locale-data/sv";

import LocaleStore from "stores/domain/locale";

interface Props {
  localeStore?: LocaleStore;
  children?: React.ReactNode;
}

@inject("localeStore")
@observer
export default class InternationalizationProvider extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    addLocaleData([...en, ...sv]);
  }
  get localeStore() {
    return this.props.localeStore!;
  }
  render() {
    let { locale, messages } = this.localeStore;

    let settings = { key: locale, locale, messages };

    return <IntlProvider {...settings}>{this.props.children}</IntlProvider>;
  }
}
