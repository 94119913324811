import styled from "styled-components";

interface WrapperProps {
  theme?: __MaterialUI.Styles.MuiTheme;
  bold?: boolean;
  primary?: boolean;
}

const Title = styled.h2<any>`
  color: ${(props: WrapperProps) => {
    return props.primary ? "#374953" : "#8a7b52";
  }};
  font-size: 20px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;

export default Title;
