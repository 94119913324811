import * as React from 'react';
import { Component } from 'react';
import ThreeDotSpinner from 'components/ThreeDotSpinner';

interface Props {
    onInitEcsterPayment: () => void;
    isLoading: boolean;
}

class EcsterPayment extends Component<Props, {}> {
    componentDidMount() {
        this.props.onInitEcsterPayment();
    }

    render() {
        if (this.props.isLoading) {
            return (<ThreeDotSpinner />);
        }

        return (
            <div id="ecster-pay-ctr" />
        );
    }
}

export default EcsterPayment;