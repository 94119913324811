import { action, makeObservable, observable } from "mobx";
import RegisterMemeberService, {
  RegisterCompanyMemberViewModel,
  RegisterPersonMemberViewModel,
} from "services/new-member";
import { JsonApiResponse } from "utils/json-api";
import { localeStore } from "./locale";

export enum Tab {
  Person,
  Company,
}

class NewMemberStore {
  @observable isSubmitting = false;
  @observable
  resetToken!: string;
  @observable
  verificationToken!: string;
  @observable
  userId!: string;
  @observable resending = false;

  constructor() {
    makeObservable(this);
  }

  @action
  registerPerson(
    person: RegisterPersonMemberViewModel
  ): Promise<JsonApiResponse<string> | null> {
    return this.register(person);
  }
  @action
  registerCompany(
    company: RegisterCompanyMemberViewModel
  ): Promise<JsonApiResponse<string> | null> {
    return this.register(company);
  }

  @action
  setTokenData(userId: string, resetToken: string) {
    this.resetToken = resetToken;
    this.userId = userId;
  }

  @action
  async resendEmail(userId: string) {
    this.resending = true;
    try {
      await RegisterMemeberService.resendEmail(userId, localeStore.locale);
    } finally {
      this.resending = false;
    }
  }

  private async register(
    vm: RegisterCompanyMemberViewModel | RegisterPersonMemberViewModel
  ): Promise<JsonApiResponse<string> | null> {
    if (this.isSubmitting) {
      return null;
    }
    this.isSubmitting = true;
    try {
      let response: JsonApiResponse<string>;
      if (vm instanceof RegisterCompanyMemberViewModel) {
        response = await RegisterMemeberService.registerCompany(vm);
      } else {
        response = await RegisterMemeberService.registerPerson(vm);
      }
      this.isSubmitting = false;
      return response;
    } catch (e) {
      this.isSubmitting = false;
      let res = new JsonApiResponse<string>();
      res.succeeded = false;
      res.statusCode = 500;
      return res;
    }
  }
}
export default NewMemberStore;
export const newMemberStore = new NewMemberStore();
