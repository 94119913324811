import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import ChangeContactInfoService, {
  ChangeCompanyInfoViewModel,
} from "services/change-contact-info";
import SessionStore from "stores/domain/session";
import CompanyInfoFormUiStore from "stores/ui/account/company-info-form";
import SnackBarUiStore from "stores/ui/snackBar";
import { formatToString } from "utils/format";
import CompanyInfoForm from "./CompanyInfoForm";

interface Props {
  companyInfoFormUiStore?: CompanyInfoFormUiStore;
  sessionStore?: SessionStore;
  snackBarUiStore?: SnackBarUiStore;
}

@inject("companyInfoFormUiStore", "sessionStore", "snackBarUiStore")
@observer
class CompanyInfoFormContainer extends React.Component<Props, {}> {
  @observable isSubmitting: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.props.companyInfoFormUiStore!.resetForm();
    this.props.companyInfoFormUiStore!.setForm(this.props.sessionStore!.user);
  }

  get store() {
    return this.props.companyInfoFormUiStore!;
  }

  get sessionStore() {
    return this.props.sessionStore!;
  }

  get companyInfoViewModel(): ChangeCompanyInfoViewModel {
    return {
      orgNumber: this.store.orgNumber.value,
      orgName: this.store.orgName.value,
      address: this.store.address.value,
      city: this.store.city.value,
      zipCode: this.store.zipCode.value,
      country: this.store.country.value,
      phoneNumber: this.store.phoneNumber.value,
      firstName: this.store.firstName.value,
      lastName: this.store.lastName.value,
      localVismaUserId: this.sessionStore.vismaUser.id,
    };
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let validation = await this.store.form.validate();

    if (validation.hasError) {
      return;
    }

    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    let newCompanyInfo = this.companyInfoViewModel;

    try {
      let response = await ChangeContactInfoService.changeCompanyInfo(
        newCompanyInfo
      );

      if (!response.succeeded) {
        throw new Error("Could't change person info.");
      }
    } catch (Error) {
      throw Error;
    }
    this.props.sessionStore!.fetchUser();
    this.props.snackBarUiStore!.open(
      formatToString("settings.contactInfo.complete.message")
    );
    this.isSubmitting = false;
  };

  render() {
    let hasFieldError = this.store.form.hasFieldError;
    return (
      <div>
        <CompanyInfoForm
          formStore={this.store}
          hasFieldError={hasFieldError}
          onSubmit={this.onSubmit}
          isSubmitting={this.isSubmitting}
        />
      </div>
    );
  }
}

export default CompanyInfoFormContainer;
