import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
// import { FormStateLazy } from 'formstate';
import { RouteComponentProps, withRouter } from "react-router";
import RegisterService, {
  CompanyRegistrationViewModel,
} from "services/register";
import LocaleStore from "stores/domain/locale";
import RegisterCompanyFormUiStore from "stores/ui/account/register-company-form";
import RegisterCompany from "./RegisterCompanyForm";

interface Props extends RouteComponentProps<{}> {
  registerCompanyFormUiStore?: RegisterCompanyFormUiStore;
  localeStore?: LocaleStore;
}

@inject("registerCompanyFormUiStore", "localeStore")
@observer
class RegisterCompanyFormContainer extends React.Component<Props, {}> {
  @observable isSubmitting: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.props.registerCompanyFormUiStore!.resetForm();
  }

  get localeStore() {
    return this.props.localeStore!;
  }

  get store() {
    return this.props.registerCompanyFormUiStore!;
  }

  get companyRegistrationViewModel(): CompanyRegistrationViewModel {
    return {
      email: this.store.email.value,
      password: this.store.password.value,
      repeatedPassword: this.store.repeatedPassword.value,
      orgNumber: this.store.orgNumber.value,
      orgName: this.store.orgName.value,
      contactFirstName: this.store.contactFirstName.value,
      contactLastName: this.store.contactLastName.value,
      phoneNumber: this.store.phoneNumber.value,
      language: this.localeStore.language,
    };
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let validation = await this.store.form.validate();

    if (validation.hasError) {
      return;
    }

    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    let companyRegistration = this.companyRegistrationViewModel;

    let response = await RegisterService.registerCompany(companyRegistration);

    if (!response.succeeded) {
      if (
        response.error &&
        response.error.detail.startsWith("DuplicateUserName")
      ) {
        this.store.setEmailUnavailable(true);
        return;
      }

      throw new Error("Could't register user.");
    }

    this.store.registeredEmail = companyRegistration.email;
    this.props.history.push(`/register-complete`);
    this.isSubmitting = false;
  };

  render() {
    let hasFieldError = this.store.form.hasFieldError;
    return (
      <RegisterCompany
        hasFieldError={hasFieldError}
        formStore={this.store}
        onSubmit={this.onSubmit}
        isSubmitting={this.isSubmitting}
      />
    );
  }
}

export default withRouter(RegisterCompanyFormContainer);
