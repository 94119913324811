import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { TextField } from 'material-ui';
import CountryPicker from 'components/CountryPickerContainer';
import format, { formatToString } from 'utils/format';
import { Row, Col } from 'react-flexbox-grid';

interface Props {
    invoiceAddress: FieldState<string>;
    invoiceCity: FieldState<string>;
    invoiceZipCode: FieldState<string>;
    invoiceCountry: FieldState<string>;
    email: FieldState<string>;
    contactPhoneNumber: FieldState<string>;
    contactFirstName: FieldState<string>;
    contactLastName: FieldState<string>;
}

@observer
export default class CompanyInfoComponent extends React.Component<Props, {}> {

  render() {
    let { invoiceAddress, invoiceCity, invoiceZipCode, invoiceCountry, email, 
        contactPhoneNumber, contactFirstName, contactLastName } = this.props;

    return (
        <div>
            <h4>
                {format('caskShop.cart.options.company.information.label')}
            </h4>
            <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.address.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            invoiceAddress.onChange(e.currentTarget.value)}
                        value={invoiceAddress.value}
                        errorText={format(invoiceAddress.error)}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.city.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            invoiceCity.onChange(e.currentTarget.value)}
                        value={invoiceCity.value}
                        errorText={format(invoiceCity.error)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.zipCode.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            invoiceZipCode.onChange(e.currentTarget.value)}
                        value={invoiceZipCode.value}
                        errorText={format(invoiceZipCode.error)}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <CountryPicker
                        fullWidth={true}
                        floatingLabelText={formatToString('settings.contactInfo.country.label')}
                        onChange={(e: {}, i: {}, country: string) => invoiceCountry.onChange(country)}
                        value={invoiceCountry.value}
                        errorText={format(invoiceCountry.error)}
                    />
                </Col>
            </Row>
            <h4>
                {format('settings.contactInfo.company.contact.label')}
            </h4>
            <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        type="email"
                        floatingLabelText={format('settings.contactInfo.company.email.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            email.onChange(e.currentTarget.value);
                        }}
                        value={email.value}
                        errorText={format(email.error)}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.phoneNumber.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            contactPhoneNumber.onChange(e.currentTarget.value)}
                        value={contactPhoneNumber.value}
                        errorText={format(contactPhoneNumber.error)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.company.firstName.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            contactFirstName.onChange(e.currentTarget.value)}
                        value={contactFirstName.value}
                        errorText={format(contactFirstName.error)}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.company.lastName.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            contactLastName.onChange(e.currentTarget.value)}
                        value={contactLastName.value}
                        errorText={format(contactLastName.error)}
                    />
                </Col>
            </Row>
        </div>
    );
  }
}