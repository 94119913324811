import Content from "components/Content";
import { Paper } from "material-ui";
import { inject, observer } from "mobx-react";
import * as React from "react";
import SessionStore from "stores/domain/session";
import styled from "styled-components";
import CompanyInfoFormContainer from "./ChangeContactInfo/components/company/CompanyInfoFormContainer";
import PersonInfoFormContainer from "./ChangeContactInfo/components/person/PersonInfoFormContainer";
import ChangePasswordFormContainer from "./ChangePassword/components/ChangePasswordFormContainer";

interface Props {
  sessionStore?: SessionStore;
}

const FormContainer = styled(Paper)`
  margin-top: 15px;
  padding: 20px 35px;
`;

@inject("sessionStore")
@observer
class SettingsPage extends React.Component<Props, {}> {
  render() {
    let { sessionStore } = this.props;

    return (
      <Content>
        <FormContainer>
          {sessionStore!.isPersonalAccount ? (
            <PersonInfoFormContainer />
          ) : (
            <CompanyInfoFormContainer />
          )}
        </FormContainer>
        <FormContainer>
          <ChangePasswordFormContainer />
        </FormContainer>
      </Content>
    );
  }
}

export default SettingsPage;
