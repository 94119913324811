import { convertFromRaw } from "draft-js";
import { action, makeObservable, observable } from "mobx";
import { MenuItem } from "models/menu-item";
import InformationService from "services/information";

export default class InformationStore {
  @observable menuItems: MenuItem[] = [];
  @observable isLoading!: boolean;
  @observable isLoaded!: boolean;
  @observable selectedItem!: MenuItem;

  constructor() {
    makeObservable(this);
  }

  @action
  async fetchInformation() {
    this.isLoading = true;
    const response = await InformationService.fetchInformation();
    if (!response.succeeded) {
      throw new Error(`Couldn't fetch menu items`);
    }

    this.menuItems = response.data.map((menuItem) => {
      return this.setupContentBodyState(menuItem);
    });

    this.sortItems();
    this.setSelectedItem();

    this.isLoading = false;
    this.isLoaded = true;
  }

  @action
  setSelectedItem(id?: number, parentId?: number) {
    if (id && this.menuItems.length > 0) {
      this.selectedItem = this.find(id, parentId);
    } else if (this.menuItems.length > 0) {
      this.selectedItem = this.menuItems[0];
    }
  }
  @action
  sortItems() {
    this.menuItems = this.sort(this.menuItems);
  }

  find(id: number, parentId?: number): MenuItem {
    let item: MenuItem | undefined = undefined;
    if (parentId) {
      let parentItem = this.menuItems.find((c) => c.id === parentId);
      if (parentItem && parentItem.children) {
        item = parentItem.children.find((c) => c.id === id);
      }
    } else {
      item = this.menuItems.find((c) => c.id === id);
    }

    if (!item) {
      throw new Error(`Menu item with id ${id} couldn't be found`);
    }

    return item;
  }

  /**
   * Sort all items in the menu items array based on the position value.
   * Will also sort the child array of each menu item recurivly until there are no more children.
   * @param menuItems The Items to sort.
   */
  private sort(menuItems: MenuItem[]) {
    const sorted = menuItems.sort((a, b) => a.position - b.position);
    return sorted.map((item) => {
      if (item.children) {
        item.children = this.sort(item.children);
      }
      return item;
    });
  }

  private setupContentBodyState(menuItem: MenuItem): MenuItem {
    const newContent = menuItem.menuItemContent.map((content) => {
      if (content.body) {
        content.state = convertFromRaw(JSON.parse(content.body));
      }
      return content;
    });

    menuItem.menuItemContent = newContent;

    if (menuItem.children) {
      menuItem.children = menuItem.children.map((child) =>
        this.setupContentBodyState(child)
      );
    }
    return menuItem;
  }
}

export const informationStore = new InformationStore();
