export default class EcsterService {
    static startPayment(cartKey: string, onPaymentSuccess: (payload: OnPaymentSuccessData) => void) {

        // @ts-ignore
        let w = <EcsterWindow> window;
        
        w.EcsterPay.start({
            cartKey,
            shopTermsUrl: 'https://my.highcoastwhisky.se/user-terms',
            onPaymentSuccess: onPaymentSuccess
        });
    }
}

export interface EcsterWindow extends Window {
    EcsterPay: EcsterPay;
}
  
export interface EcsterPay {
    start: (payload: Payload) => void;
}

export interface Payload {
    cartKey: string;
    shopTermsUrl: string;
    onPaymentSuccess: (payload: OnPaymentSuccessData) => void;
}

export interface OnPaymentSuccessData {
    customer: {
        address: string;
        cellular: string;
        city: string;
        email: string;
        firstName: string;
        lastName: string;
        name: string;
        zip: string;
        id: number;
    };
    internalReference: string;
    order: {
        amount: number;
        currency: string;
        rows: Array<OrderRow>;
    };
    paymentMethod: string;
    totalAmount: number;
}

export interface OrderRow {
    name: string;
    description: string;
    quantity: number;
    total: number;
    unitPrice: number;
    vatCode: string;
}