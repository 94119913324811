import { action, makeObservable, observable } from "mobx";
class VismaUserPickerUiStore {
  @observable isOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action
  show() {
    this.isOpen = true;
  }
  @action
  hide() {
    this.isOpen = false;
  }
}
export default VismaUserPickerUiStore;
export const vismaUserPickerUiStore = new VismaUserPickerUiStore();
