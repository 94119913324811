import * as React from 'react';
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SamplingOrderUiStore, CaskUiStore, SessionStore } from 'stores';
import OrderComplete from 'components/OrderComplete';
import LoadingSplash from 'components/Loading/LoadingSplash';
import { Redirect } from 'react-router';

interface Props {
  samplingOrderUiStore?: SamplingOrderUiStore;
  caskUiStore?: CaskUiStore;
  sessionStore?: SessionStore;
}

@inject('samplingOrderUiStore', 'caskUiStore', 'sessionStore')
@observer
class SamplingOrderCompleteContainer extends Component<Props, {}> {
  get store() {
    return this.props.samplingOrderUiStore!;
  }
  
  get cask() {
    return this.props.caskUiStore!.cask;
  }

  get orderId() {
    return this.store.orderId;
  }

  get email() {
    return this.props.sessionStore!.user.email;
  }

  render() {
    if (!this.orderId) {
      return <Redirect to={`/cask/${this.cask.id}/orders/sampling`} />;
    }
    return (
      <div>
        {this.store.orderIsComplete ?
          <OrderComplete
            backButtonPrimary={true}
            orderId={this.orderId} 
            email={this.email}
            textIds={{
              title: 'orders.sampling.complete.title',
              summary: 'orders.sampling.complete.orderCompleteText',
              order: 'orders.sampling.complete.orderIdLabel',
              back: 'orders.sampling.complete.backButton',
            }}
            backRoute={`/cask/${this.cask.id}`}
          />
        :
          <LoadingSplash />
        }
      </div>
    );
  }
}

export default SamplingOrderCompleteContainer;