import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  50% {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    transform: scale(1.1);
  }

  100% {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
`;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
`;

const BounceOne = styled.div`
  margin: 0px 15px;
  background: #47525a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${animation} 1.5s ease-in-out infinite;
  -webkit-animation-delay: -0.64s;
  animation-delay: -0.64s;
`;

const BounceTwo = styled.div`
  margin: 0px 15px;
  background: #47525a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${animation} 1.5s ease-in-out infinite;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
`;

const BounceThree = styled.div`
  margin: 0px 15px;
  background: #47525a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${animation} 1.5s ease-in-out infinite;
  -webkit-animation-delay: 0s;
  animation-delay: -0s;
`;

class ThreeDotSpinner extends React.Component<{}, {}> {
  render() {
    return(
      <Spinner>
        <BounceOne />
        <BounceTwo />
        <BounceThree />
      </Spinner>
    );
  }
}

export default ThreeDotSpinner;