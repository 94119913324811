export enum Customization {
  None,
  PersonalText,
  File,
}

export interface UploadFile {
  imagePreview?: string;
  file: File;
  imageMetaData?: ImageMetadata;
}

export interface ImageMetadata {
  height: number;
  width: number;
}

export class BottleLabel {
  customization: Customization = Customization.None;
  text!: string;
  imageFile?: UploadFile;
  oldBOXLabel?: boolean;
  percentOfLabels?: number;
  imageUrl?: string;
}

export default BottleLabel;
