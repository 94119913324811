import Content from "components/Content";
import * as React from "react";

export default class ResetPasswordPage extends React.Component<
  { children?: React.ReactNode },
  {}
> {
  render() {
    return <Content>{this.props.children}</Content>;
  }
}
