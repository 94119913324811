import { ListItem } from "material-ui";
import { ActionAccountCircle, SocialLocationCity } from "material-ui/svg-icons";
import { AccountType, LocalVismaUser } from "models/user";
interface Props {
  vismaUser: LocalVismaUser;
  onClick: (vismaUser: LocalVismaUser) => void;
}
const VismaUserItem = (props: Props) => {
  const { vismaUser, onClick } = props;
  const isPerson = vismaUser.accountType === AccountType.Person;
  const name = isPerson
    ? `${vismaUser.firstName} ${vismaUser.lastName}`
    : `${vismaUser.orgName}`;
  const icon = isPerson ? <ActionAccountCircle /> : <SocialLocationCity />;
  return (
    <ListItem
      leftIcon={icon}
      key={vismaUser.id}
      onClick={(e) => {
        e.preventDefault();
        onClick(vismaUser);
      }}
      primaryText={name}
      secondaryText={`id: ${vismaUser.vismaUserId}`}
    />
  );
};

export default VismaUserItem;
