import AccountUiStore, { accountUiStore } from "./account";
import ChangePasswordFormUiStore, {
  changePasswordFormUiStore,
} from "./account/change-password-form";
import CompanyInfoFormUiStore, {
  companyInfoFormUiStore,
} from "./account/company-info-form";
import LoginFormUiStore, { loginFormUiStore } from "./account/login-form";
import PersonInfoFormUiStore, {
  personInfoFormUiStore,
} from "./account/person-info-form";
import RegisterCompanyFormUiStore, {
  registerCompanyFormUiStore,
} from "./account/register-company-form";
import RegisterFormUiStore, {
  registerFormUiStore,
} from "./account/register-form";
import ResetPasswordUiStore, {
  resetPasswordUiStore,
} from "./account/reset-password";
import SetPasswordFormUiStore, {
  setPasswordFormUiStore,
} from "./account/set-password-form";
import BottlePriceListUiStore, {
  bottlePriceListUiStore,
} from "./bottle-price-list";
import CaskUiStore, { caskUiStore } from "./cask";
import CaskShopUiStore, { caskShopUiStore } from "./cask-shop";
import ShoppingCartUiStore, {
  shoppingCartUiStore,
} from "./cask-shop/shopping-cart";
import BottlingOrderUiStore, {
  bottlingOrderUiStore,
} from "./cask/bottling-order";
import CaskSelectUiStore, { caskSelectUiStore } from "./cask/cask-select";
import CaskHistoryUiStore, { caskHistoryUiStore } from "./cask/history";
import InviteMemberUiStore, { inviteMemberUiStore } from "./cask/invite-member";
import BottlingOrderFormUiStore, {
  bottlingOrderFormUiStore,
} from "./cask/orders/bottling-order-form";
import FlavourNoteOrderUiStore, {
  flavourNoteOrderUiStore,
} from "./cask/orders/flavour-note";
import SamplingOrderUiStore, {
  samplingOrderUiStore,
} from "./cask/orders/sampling";
import DrawerUiStore, { drawerUiStore } from "./drawer";
import HeaderUiStore, { headerUiStore } from "./header";
import NavbarUiStore, { navbarUiStore } from "./navbar";
import NewMemberUiStore, { newMemberUiStore } from "./new-member";
import CompanyFormUiStore, {
  companyFormUiStore,
} from "./new-member/company-form";
import PersonFormUiStore, { personFormUiStore } from "./new-member/person-form";
import SnackBarUiStore, { snackBarUiStore } from "./snackBar";
import VismaUserPickerUiStore, {
  vismaUserPickerUiStore,
} from "./visma-user-picker";

export default {
  accountUiStore,
  bottlingOrderUiStore,
  bottlingOrderFormUiStore,
  bottlePriceListUiStore,
  caskShopUiStore,
  caskUiStore,
  changePasswordFormUiStore,
  companyInfoFormUiStore,
  drawerUiStore,
  caskHistoryUiStore,
  inviteMemberUiStore,
  loginFormUiStore,
  registerFormUiStore,
  personInfoFormUiStore,
  registerCompanyFormUiStore,
  navbarUiStore,
  headerUiStore,
  samplingOrderUiStore,
  snackBarUiStore,
  resetPasswordUiStore,
  setPasswordFormUiStore,
  shoppingCartUiStore,
  flavourNoteOrderUiStore,
  newMemberUiStore,
  personFormUiStore,
  companyFormUiStore,
  vismaUserPickerUiStore,
  caskSelectUiStore,
};

export {
  AccountUiStore,
  LoginFormUiStore,
  RegisterFormUiStore,
  ChangePasswordFormUiStore,
  PersonInfoFormUiStore,
  CompanyInfoFormUiStore,
  CaskHistoryUiStore,
  RegisterCompanyFormUiStore,
  CaskUiStore,
  DrawerUiStore,
  NavbarUiStore,
  BottlingOrderUiStore,
  BottlingOrderFormUiStore,
  HeaderUiStore,
  SamplingOrderUiStore,
  SnackBarUiStore,
  BottlePriceListUiStore,
  ResetPasswordUiStore,
  SetPasswordFormUiStore,
  CaskShopUiStore,
  ShoppingCartUiStore,
  FlavourNoteOrderUiStore,
  InviteMemberUiStore,
  NewMemberUiStore,
  PersonFormUiStore,
  CompanyFormUiStore,
  VismaUserPickerUiStore,
  CaskSelectUiStore,
};
