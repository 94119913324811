import CaskType from "./cask-type";
import Recipe from "./recipe";

class CartCask {
  recipe!: Recipe;
  caskType!: CaskType;
  preStored!: Boolean;
  partOwners!: string[];
  caskSign!: string;
}

export default CartCask;
