import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";
import * as React from "react";
import CaskInstructions from "scenes/Home/components/CaskInstructions";
import styled from "styled-components";
import format from "utils/format";

const Instructions = styled.div`
  color: #5f5f5f;
`;

const CancelButtonWrapper = styled.div`
  margin-top: 25px;
  text-align: center;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default class InstructionsModal extends React.Component<Props, {}> {
  render() {
    return (
      <Dialog
        modal={true}
        open={this.props.isOpen}
        autoScrollBodyContent={true}
      >
        <Instructions>
          <CaskInstructions />
        </Instructions>
        <CancelButtonWrapper>
          <RaisedButton
            label={format("caskShop.instructionsCancel")}
            primary={false}
            onClick={this.props.onClose}
          />
        </CancelButtonWrapper>
      </Dialog>
    );
  }
}
