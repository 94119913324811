import { action, computed, makeObservable, observable } from "mobx";
import Recipe from "models/recipe";
import RecipesService from "services/recipes";

class RecipesStore {
  @observable recipes: Recipe[] = [];
  @observable isLoading!: boolean;

  constructor() {
    makeObservable(this);
  }

  @computed
  get totalRecipesCount() {
    return this.recipes.length;
  }

  @action
  async fetchRecipes() {
    this.isLoading = true;
    let response = await RecipesService.fetchRecipes();

    if (!response.succeeded) {
      throw new Error(`Couldn't fetch recipes`);
    }

    this.recipes = response.data;
    this.isLoading = false;
  }

  find(id: number): Recipe {
    let recipe = this.recipes.find((c) => c.id === id);

    if (!recipe) {
      throw new Error(`Recipe with id ${id} couldn't be found`);
    }
    return recipe;
  }
}

export default RecipesStore;
export const recipesStore = new RecipesStore();
