import Translatable from "models/translatable";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { localeStore } from "stores/domain/locale";

type Values = { [key: string]: string | number | JSX.Element };

export default function formatMessage(id: string | undefined, values?: Values) {
  if (!id) {
    return null;
  }

  let props: { id: string; values?: Values } = { id };

  if (values !== undefined) {
    props.values = values;
  }

  return <FormattedMessage {...props} />;
}

export function format<T extends Translatable>(information: Array<T>): T {
  if (!information || information.length < 1) {
    return {} as T;
  }

  let language = localeStore.languageInt;
  let result = information.find((info) => info.language === language);

  if (result !== undefined) {
    return result;
  }

  return {} as T;
}

export function formatToString(
  id: string,
  values?: { [key: string]: string | number | boolean | Date }
) {
  return localeStore.intl.formatMessage({ id }, values);
}

export function formatCaskAge(date1: Date, skipDays: boolean = false) {
  let today = moment();
  let filled = moment(date1);
  let years = today.diff(filled, "year");
  filled.add(years, "years");

  let months = today.diff(filled, "months");
  filled.add(months, "months");

  let days = skipDays && months > 0 ? 0 : today.diff(filled, "days");

  if (years === 0 && months === 0 && days === 0) {
    return <FormattedMessage id="cask.info.age.today" />;
  }

  return (
    <FormattedMessage id="cask.info.age" values={{ years, months, days }} />
  );
}
