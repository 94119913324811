import {
  Box,
  Divider,
  FormLabel,
  Heading,
  HStack,
  Radio,
  Stack,
} from "@chakra-ui/react";
import { CustomRadioButtonGroup } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import FormInput from "components/forms/FormInput";
import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import * as Yup from "yup";
import { intersectingSectionAtom } from "../components/BottlingStepper";
import { BottlingFormValues } from "./BottlingForm";

export interface BottlingLoanPartValues {
  isSwedishBankAccount: boolean;
  clearingNumber: string;
  swedishBankAccountNumber: string;
  foreignBankAccountNumber: string;
}

export const bottlingLoanSchema = (format: (id: string) => any) =>
  Yup.object().shape({
    isSwedishBankAccount: Yup.boolean().required(format("common.required")),
    clearingNumber: Yup.string().when("isSwedishBankAccount", {
      is: true,
      then: Yup.string()
        .min(2, format("common.too-short"))
        .max(50, format("common.too-long"))
        .required(format("common.required")),
    }),
    swedishBankAccountNumber: Yup.string().when("isSwedishBankAccount", {
      is: true,
      then: Yup.string()
        .min(2, format("common.too-short"))
        .max(50, format("common.too-long"))
        .required(format("common.required")),
    }),
    foreignBankAccountNumber: Yup.string().when("isSwedishBankAccount", {
      is: false,
      then: Yup.string()
        .min(2, format("common.too-short"))
        .max(50, format("common.too-long"))
        .required(format("common.required")),
    }),
  });

const LoanFormPart = () => {
  const [, setCurrentSection] = useAtom(intersectingSectionAtom);
  const isMobile = useMedia("(max-width: 48em)");
  const StackComponent = isMobile ? Stack : HStack;
  const {
    values: {
      loan: { isSwedishBankAccount },
    },
    setFieldValue,
  } = useFormikContext<BottlingFormValues>();

  return (
    <fieldset id="loan" onClick={() => setCurrentSection("loan")}>
      <Box mt={6} pb={8}>
        <Heading size="lg" fontWeight={500} mb={4}>
          <FormattedMessage id="cask.orders.bottling.loan.title" />
        </Heading>
        <Divider mt={2} />
        <Box mt={4}>
          <CustomAlert
            labelKey="orders.bottlingOrder.isSwedishBankAccount.infoWindowText"
            status="info"
          />
        </Box>
        <Box mt={4}>
          <FormLabel
            fontSize="lg"
            fontWeight={300}
            htmlFor="loan.isSwedishBankAccount"
          >
            <FormattedMessage id="cask.orders.bottling.loan.bank-type" />
          </FormLabel>
          <CustomRadioButtonGroup
            id="loan.isSwedishBankAccount"
            value={isSwedishBankAccount ? "1" : "0"}
            onChange={(v: string) => {
              setFieldValue("loan.isSwedishBankAccount", v === "1");
              setFieldValue("loan.clearingNumber", "");
              setFieldValue("loan.swedishBankAccountNumber", "");
              setFieldValue("loan.foreignBankAccountNumber", "");
            }}
          >
            <StackComponent spacing={4} alignItems="stretch">
              <Radio value="1" w="full">
                <FormattedMessage id="orders.bottlingOrder.isSwedishBankAccount.true" />
              </Radio>
              <Radio value="0" w="full">
                <FormattedMessage id="orders.bottlingOrder.isSwedishBankAccount.false" />
              </Radio>
            </StackComponent>
          </CustomRadioButtonGroup>
        </Box>
        <Box mt={4}>
          {isSwedishBankAccount ? (
            <>
              <Box mt={4}>
                <FormInput
                  labelKey="orders.bottlingOrder.clearingNumber.label"
                  name="loan.clearingNumber"
                  placeholderKey={
                    "cask.orders.bottling.loan.clearingNumber-placeholder"
                  }
                />
              </Box>
              <Box mt={4}>
                <FormInput
                  labelKey="orders.bottlingOrder.swedishBankAccountNumber.label"
                  name="loan.swedishBankAccountNumber"
                  placeholderKey={
                    "cask.orders.bottling.loan.swedishBankAccountNumber-placeholder"
                  }
                />
              </Box>
            </>
          ) : (
            <Box mt={4}>
              <FormInput
                labelKey="orders.bottlingOrder.foreignBankAccountNumber.label"
                name="loan.foreignBankAccountNumber"
                placeholderKey={
                  "cask.orders.bottling.loan.foreignBankAccountNumber-placeholder"
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </fieldset>
  );
};

export default LoanFormPart;
