import { inject, observer } from "mobx-react";
import CartCask from "models/cart-cask";
import * as React from "react";
import ShoppingCartUiStore from "stores/ui/cask-shop/shopping-cart";
import styled from "styled-components";
import CartCaskOptionsComponent from "./CartCaskOptionsComponent";
import CompanyInfoComponent from "./CompanyInfoComponent";
import PersonInfoComponent from "./PersonInfoComponent";

interface Props {
  cartCasks: CartCask[];
  shoppingCartUiStore?: ShoppingCartUiStore;
  removeCask: (cartCask: CartCask) => void;
  isPersonalAccount: boolean;
}

const BottomOptionsContainer = styled.div`
  padding: 30px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
`;

@inject("shoppingCartUiStore")
@observer
class ShoppingCartOptions extends React.Component<Props, {}> {
  componentWillMount() {
    this.props.cartCasks.forEach((cartCask) =>
      this.shoppingCartUiStore.createCaskOption(cartCask)
    );
  }

  get shoppingCartUiStore() {
    return this.props.shoppingCartUiStore!;
  }

  removeCaskPartOwner = (cartCask: CartCask, index: number) => {
    this.shoppingCartUiStore.removeCaskPartOwner(cartCask, index);
  };

  addCaskPartOwner = (cartCask: CartCask) => {
    this.shoppingCartUiStore.addCaskPartOwner(cartCask);
  };

  setCaskSign = (cartCask: CartCask, caskSign: string) => {
    this.shoppingCartUiStore.setCaskSign(cartCask, caskSign);
  };

  toggleCaskTypePreStored = () => {
    this.shoppingCartUiStore.toggleTakePartCaskFilling();
  };

  get cartCaskOptionsComponents() {
    return this.props.cartCasks.map((cartCask) => (
      <CartCaskOptionsComponent
        key={this.props.cartCasks.indexOf(cartCask)}
        caskOption={this.shoppingCartUiStore.findCaskOption(cartCask)}
        addCaskPartOwner={this.addCaskPartOwner}
        removeCaskPartOwner={this.removeCaskPartOwner}
        setCaskSign={this.setCaskSign}
        removeCask={this.props.removeCask}
      />
    ));
  }

  get responsiveWidth() {
    return {
      maxWidth: "100%",
      width: 328,
    };
  }

  render() {
    let {
      takePartCaskFilling,
      socialSecurityNumber,
      phoneNumber,
      address,
      city,
      zipCode,
      country,
      invoiceAddress,
      invoiceCity,
      invoiceZipCode,
      invoiceCountry,
      email,
      contactPhoneNumber,
      contactFirstName,
      contactLastName,
    } = this.shoppingCartUiStore;
    let { isPersonalAccount } = this.props;

    return (
      <div>
        {this.cartCaskOptionsComponents}

        <BottomOptionsContainer>
          {isPersonalAccount ? (
            <PersonInfoComponent
              socialSecurityNumber={socialSecurityNumber}
              phoneNumber={phoneNumber}
              address={address}
              city={city}
              zipCode={zipCode}
              country={country}
            />
          ) : (
            <CompanyInfoComponent
              invoiceAddress={invoiceAddress}
              invoiceCity={invoiceCity}
              invoiceZipCode={invoiceZipCode}
              invoiceCountry={invoiceCountry}
              email={email}
              contactPhoneNumber={contactPhoneNumber}
              contactFirstName={contactFirstName}
              contactLastName={contactLastName}
            />
          )}
        </BottomOptionsContainer>
      </div>
    );
  }
}

export default ShoppingCartOptions;
