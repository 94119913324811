import { MenuItem } from 'models/menu-item';
import { toJsonApiResponse, JsonApiResponse } from 'utils/json-api';
import Api from './api';

const Endpoint = 'informationpage';

export default class InformationService {
  static async fetchInformation(): Promise<JsonApiResponse<MenuItem[]>> {
    let response = await Api.get(Endpoint, false);
    return toJsonApiResponse<MenuItem[]>(response);
  }
}
