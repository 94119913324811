import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { ReactComponent as UserAddSvg } from "assets/images/user-add.svg";
import { CustomSelect } from "components/CommonStyles";
import FormInput from "components/forms/FormInput";
import { Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import CasksService from "services/casks";
import { useStores } from "stores";
import { Language } from "stores/domain/locale";
import { formatToString } from "utils/format";
import * as Yup from "yup";
import { useCurrentCask } from "../cask-hooks";

interface CaskMemberFormValues {
  firstname: string;
  lastname: string;
  email: string;
  language: Language;
}

const schema = (format: (id: string) => any) =>
  Yup.object().shape({
    firstname: Yup.string()
      .min(2, format("common.too-short"))
      .max(50, format("common.too-long"))
      .required(format("common.required")),
    lastname: Yup.string()
      .min(2, format("common.too-short"))
      .max(50, format("common.too-long"))
      .required(format("common.required")),
    email: Yup.string()
      .email(format("common.invalid-email"))
      .max(254, format("common.too-long"))
      .required(format("common.required")),
  });

const CaskMemberForm: React.FC = () => {
  const [submitError, setSubmitError] = useState<string>("");
  const [success, setSuccess] = useState(false);
  const { cask } = useCurrentCask();
  const { localeStore, casksStore } = useStores();
  const isMobile = useMedia("(max-width: 500px)");
  const onSubmit = async (
    values: CaskMemberFormValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    setSubmitError("");
    setSuccess(false);

    try {
      const response = await CasksService.addMember({
        ...values,
        caskId: cask!.id,
      });
      if (response.hasErrors) {
        const email = values.email;
        for (let error of response.errors) {
          if (error.code === "UserNotFoundException") {
            setSubmitError(
              formatToString("about.members.modal.error.userNotFound", {
                email: email,
              })
            );
          } else if (error.code === "UserAlreadyCaskMemberException") {
            setSubmitError(
              formatToString("about.members.modal.error.userAlreadyMember", {
                email: email,
              })
            );
          } else {
            setSubmitError(formatToString("about.members.modal.error.unknown"));
          }
        }
        return;
      }

      setSuccess(true);
      casksStore.fetchCasks(false);
      formikHelpers.resetForm();
    } catch (error) {
      return setSubmitError(formatToString("common.error.unknown"));
    }
  };

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton px={isMobile ? 6 : undefined}>
            <Box display="flex" flex={1}>
              <Box mr={4}>
                <UserAddSvg color="#1a202c" />
              </Box>
              <FormattedMessage id="about.members.inviteButtonLabel" />
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} px={isMobile ? 6 : undefined}>
          <Formik
            validationSchema={schema(formatToString)}
            onSubmit={onSubmit}
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              language: localeStore.language,
            }}
          >
            {(props) => (
              <>
                <Stack spacing={2} mb={6}>
                  <FormInput
                    name="email"
                    labelKey="about.members.modal.email"
                    placeholderKey="cask.members.invite.email-placeholder"
                  />
                  <FormInput
                    name="firstname"
                    labelKey="about.members.modal.firstname"
                    placeholderKey="cask.members.invite.firstname-placeholder"
                  />
                  <FormInput
                    name="lastname"
                    labelKey="about.members.modal.lastname"
                    placeholderKey="cask.members.invite.lastname-placeholder"
                  />
                  <FormControl>
                    <FormLabel fontWeight={300}>
                      <FormattedMessage id="cask.members.invite.language" />
                    </FormLabel>
                    <CustomSelect
                      name="language"
                      value={props.values.language}
                      onChange={(v: any) =>
                        props.setFieldValue("language", v.target.value)
                      }
                      placeholder={formatToString(
                        "cask.members.invite.pick-language"
                      )}
                    >
                      <option value={Language.Swedish}>
                        {formatToString("common.language.sv")}
                      </option>
                      <option value={Language.English}>
                        {formatToString("common.language.en")}
                      </option>
                    </CustomSelect>
                  </FormControl>
                </Stack>
                <Box>
                  <Box mb={4} hidden={!success}>
                    <Alert status="success">
                      <AlertIcon />
                      <AlertDescription>
                        <FormattedMessage id="cask.members.invite.success" />
                      </AlertDescription>
                    </Alert>
                  </Box>
                  <Box mb={4} hidden={!submitError}>
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{submitError}</AlertDescription>
                    </Alert>
                  </Box>
                </Box>
                <Box px={0} justifyContent="flex-start">
                  <Button
                    isLoading={props.isSubmitting}
                    colorScheme="brand"
                    isDisabled={!props.isValid}
                    mr={3}
                    onClick={props.submitForm}
                    minWidth={160}
                  >
                    <FormattedMessage id="about.members.modal.title" />
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => props.resetForm()}
                    isDisabled={props.isSubmitting}
                  >
                    <FormattedMessage id="common.reset" />
                  </Button>
                </Box>
              </>
            )}
          </Formik>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default observer(CaskMemberForm);
