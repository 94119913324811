import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import SessionStore from "stores/domain/session";
import LoginFormUiStore from "stores/ui/account/login-form";
import LoginForm from "./LoginForm";

interface Props {
  sessionStore?: SessionStore;
  loginFormUiStore?: LoginFormUiStore;
}

@inject("sessionStore", "loginFormUiStore")
@observer
export default class RegisterFormContainer extends React.Component<Props, {}> {
  @observable isSubmitting: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  get loginForm() {
    return this.props.loginFormUiStore!;
  }

  get sessionStore() {
    return this.props.sessionStore!;
  }

  componentWillMount() {
    this.loginForm.resetForm();
  }

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let { loginFormUiStore } = this.props;
    let form = loginFormUiStore!.form;

    loginFormUiStore!.hasAuthenticationError = false;

    let res = await form.validate();

    if (res.hasError) {
      // show validation error...
      return;
    }

    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    let email = form.$.email.$;
    let password = form.$.password.$;

    let response = await this.sessionStore.logIn(email, password);

    this.isSubmitting = false;
    if (response.succeeded) {
      // Redirect to home and show login success message
      return;
    }

    loginFormUiStore!.hasAuthenticationError = true;
  };

  render() {
    let { loginFormUiStore } = this.props;

    let hasFieldError = loginFormUiStore!.form.hasFieldError;
    return (
      <div>
        {this.sessionStore.isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <LoginForm
            hasFieldError={hasFieldError}
            loginFormUiStore={loginFormUiStore!}
            onSubmit={this.onSubmit}
            isSubmitting={this.isSubmitting}
            isFetchingUserData={this.sessionStore.isFetchingUserData}
          />
        )}
      </div>
    );
  }
}
