import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  InputLeftAddon,
  Radio,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomRadioButtonGroup, CustomSelect } from "components/CommonStyles";
import CustomAlert from "components/CustomAlert";
import FormInput from "components/forms/FormInput";
import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import { AfterBottlingAction } from "models/bottling-order";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import { formatToString } from "utils/format";
import * as Yup from "yup";
import { BottlingFormValues } from "./BottlingForm";
import { intersectingSectionAtom } from "./BottlingStepper";
import RefillAlertDialog from "./RefillAlertDialog";

export const getRefillLabel = (refillType: AfterBottlingAction) => {
  switch (refillType) {
    case AfterBottlingAction.Refill:
      return "cask.orders.bottling.info.refill.select.refill";

    case AfterBottlingAction.RefillSmokey:
      return "cask.orders.bottling.info.refill.select.refill-smokey";

    case AfterBottlingAction.RefillTwoYear:
      return "cask.orders.bottling.info.refill.select.refill-two-year";

    case AfterBottlingAction.RefillTwoYearSmokey:
      return "cask.orders.bottling.info.refill.select.refill-two-year-smokey";

    case AfterBottlingAction.RefillGin:
      return "cask.orders.bottling.info.refill.select.refill-gin";

    case AfterBottlingAction.Save:
      return "cask.orders.bottling.info.after.send-cask.desc";

    case AfterBottlingAction.Sell:
      return "cask.orders.bottling.info.after.sell-cask.desc";

    case AfterBottlingAction.Scrap:
      return "cask.orders.bottling.info.after.scrap.desc";

    default:
      return "?";
  }
};

export interface BottlingInfoPartValues {
  fullBottling: boolean;
  dialute: boolean;
  refillAction?: "REFILL" | "SEND" | "SELL" | "SCRAP";
  afterBottling?: AfterBottlingAction;
  numberOfBottles?: number;
  alcoholContent?: number;
  caskSignText?: string;
}

export const bottlingInfoSchema = (format: (id: string) => any) =>
  Yup.object().shape({
    fullBottling: Yup.boolean().required(format("common.required")),
    dialute: Yup.boolean().required(format("common.required")),
    refillAction: Yup.mixed<AfterBottlingAction>().when(["fullBottling"], {
      is: (fullBottling: boolean) => fullBottling,
      then: Yup.string().required(format("common.required")),
    }),
    afterBottling: Yup.mixed<AfterBottlingAction>().when(
      ["fullBottling", "refillAction"],
      {
        is: (fullBottling: boolean, refillAction: string) =>
          fullBottling && refillAction === "REFILL",
        then: Yup.mixed<AfterBottlingAction>().required(
          format("common.required")
        ),
      }
    ),
    caskSignText: Yup.string().when(["fullBottling", "refill"], {
      is: (fullBottling: boolean, refill: boolean) => fullBottling && refill,
      then: Yup.string()
        .max(20, format("common.too-long"))
        .required(format("common.required")),
    }),
    numberOfBottles: Yup.number().when("fullBottling", {
      is: false,
      then: Yup.number().required(format("common.required")),
    }),
    alcoholContent: Yup.number().when("dialute", {
      is: true,
      then: Yup.number()
        .min(46, format("common.too-little"))
        .max(100, format("common.too-much"))
        .typeError(format("common.invalid-number"))
        .required(format("common.required")),
    }),
  });

const BottlingInfoFormPart = () => {
  const refillAlertDisclosure = useDisclosure();
  const refillAlertHookState = useState<() => void | undefined>();
  const [, setCurrentSection] = useAtom(intersectingSectionAtom);
  const { cask } = useCurrentCask();
  const isGin = cask?.recipe?.id === 3;
  const isMobile = useMedia("(max-width: 48em)");
  const {
    values: {
      bottlingInfo: {
        fullBottling,
        dialute,
        numberOfBottles,
        refillAction,
        afterBottling,
      },
    },
    setFieldValue,
  } = useFormikContext<BottlingFormValues>();
  const StackComponent = isMobile ? Stack : HStack;
  const beenPeated = !cask?.recipe?.id || cask?.recipe?.id === 2;
  const refillActions = [
    AfterBottlingAction.Refill,
    AfterBottlingAction.RefillSmokey,
    AfterBottlingAction.RefillTwoYear,
    AfterBottlingAction.RefillTwoYearSmokey,
  ];

  if (!beenPeated) refillActions.push(AfterBottlingAction.RefillGin);

  const showBuyBack =
    !isGin &&
    (!cask?.oldestOrderDate ||
      new Date(cask?.oldestOrderDate).getTime() <
        new Date("07/01/2022").getTime());
  const AfterBottlingStackComponent = isMobile || showBuyBack ? Stack : HStack;

  return (
    <fieldset
      id="bottling-information"
      onClick={() => setCurrentSection("information")}
    >
      <RefillAlertDialog
        hookState={refillAlertHookState}
        disclosure={refillAlertDisclosure}
      />
      <Box mt={6} pb={8}>
        <Heading size="lg" fontWeight={500} mb={4}>
          <FormattedMessage id="cask.orders.bottling.info.title" />
        </Heading>
        <Divider mt={2} />
        <Box mt={4}>
          <FormLabel
            fontSize="lg"
            fontWeight={300}
            htmlFor="bottlingInfo.fullBottling"
          >
            <FormattedMessage id="orders.bottlingOrder.bottlingAmount.label" />
          </FormLabel>
          <CustomRadioButtonGroup
            id="bottlingInfo.fullBottling"
            value={fullBottling ? "1" : "0"}
            onChange={(v: string) => {
              setFieldValue("bottlingInfo.fullBottling", v === "1");

              if (v !== "1") {
                setFieldValue("bottlingInfo.refillAction", undefined);
                setFieldValue(
                  "bottlingInfo.afterBottling",
                  AfterBottlingAction.NotSet
                );
              } else {
                setFieldValue("bottlingInfo.numberOfBottles", undefined);
                setFieldValue("bottlingInfo.dialute", false);
                setFieldValue("bottlingInfo.alcoholContent", undefined);
              }
            }}
          >
            <StackComponent spacing={4} alignItems="stretch">
              <Radio value="1" w="full">
                <FormattedMessage id="cask.orders.bottling.info.amount.whole" />
                <Text fontWeight={300}>
                  <FormattedMessage id="cask.orders.bottling.info.amount.whole.desc" />
                </Text>
              </Radio>
              <Radio value="0" w="full">
                <FormattedMessage id="cask.orders.bottling.info.amount.part" />
                <Text fontWeight={300}>
                  <FormattedMessage id="cask.orders.bottling.info.amount.part.desc" />
                </Text>
              </Radio>
            </StackComponent>
          </CustomRadioButtonGroup>
        </Box>
        <Box mt={4}>
          {fullBottling ? (
            <>
              <Box mt={8}>
                <FormLabel
                  fontSize="lg"
                  fontWeight={300}
                  htmlFor="bottlingInfo.refill"
                >
                  <FormattedMessage id="cask.orders.bottling.info.after.label" />
                </FormLabel>
                <CustomRadioButtonGroup
                  id="bottlingInfo.refill"
                  value={refillAction}
                  onChange={(v: string) => {
                    setFieldValue("bottlingInfo.refillAction", v);

                    if (v !== "REFILL") {
                      refillAlertHookState[1](() => () => {
                        setFieldValue("bottlingInfo.refillAction", "REFILL");
                        setFieldValue("bottlingInfo.afterBottling", undefined);
                      });
                    } else {
                      refillAlertHookState[1](undefined);
                    }

                    if (v === "REFILL")
                      setFieldValue("bottlingInfo.afterBottling", undefined);

                    if (v === "SELL")
                      setFieldValue(
                        "bottlingInfo.afterBottling",
                        AfterBottlingAction.Sell
                      );

                    if (v === "SEND")
                      setFieldValue(
                        "bottlingInfo.afterBottling",
                        AfterBottlingAction.Save
                      );

                    if (v === "SCRAP")
                      setFieldValue(
                        "bottlingInfo.afterBottling",
                        AfterBottlingAction.Scrap
                      );
                  }}
                >
                  <Stack spacing={4} alignItems="stretch">
                    <Radio value="REFILL" w="full">
                      <FormattedMessage id="cask.orders.bottling.info.after.refill" />
                      <Text fontWeight={300}>
                        <FormattedMessage id="cask.orders.bottling.info.after.refill.desc" />
                      </Text>
                    </Radio>
                    <Radio value="SEND" w="full">
                      <FormattedMessage id="cask.orders.bottling.info.after.send-cask" />
                      <Text fontWeight={300}>
                        <FormattedMessage id="cask.orders.bottling.info.after.send-cask.desc" />
                      </Text>
                    </Radio>
                    {showBuyBack ? (
                      <Radio value="SELL" w="full">
                        <FormattedMessage id="cask.orders.bottling.info.after.sell-cask" />
                        <Text fontWeight={300}>
                          <FormattedMessage id="cask.orders.bottling.info.after.sell-cask.desc" />
                        </Text>
                      </Radio>
                    ) : (
                      <Radio value="SCRAP" w="full">
                        <FormattedMessage id="cask.orders.bottling.info.after.scrap" />
                        <Text fontWeight={300}>
                          <FormattedMessage id="cask.orders.bottling.info.after.scrap.desc" />
                        </Text>
                      </Radio>
                    )}
                  </Stack>
                </CustomRadioButtonGroup>
              </Box>
              {refillAction === "REFILL" && (
                <>
                  <Box mt={4}>
                    <FormControl>
                      <FormLabel fontWeight={300}>
                        <FormattedMessage id="cask.orders.bottling.info.refill.select.label" />
                      </FormLabel>
                      <CustomSelect
                        w={isMobile ? "full" : "50%"}
                        name="afterBottling"
                        value={afterBottling}
                        onChange={(v: any) =>
                          setFieldValue(
                            "bottlingInfo.afterBottling",
                            v.target.value
                          )
                        }
                        placeholder={formatToString(
                          "cask.orders.bottling.info.refill.select.placeholder"
                        )}
                      >
                        {refillActions.map((a) => (
                          <option value={a} key={a}>
                            {formatToString(getRefillLabel(a))}
                          </option>
                        ))}
                      </CustomSelect>
                    </FormControl>
                  </Box>
                  <FormInput
                    mt={4}
                    labelKey="orders.bottlingOrder.newCaskText.label"
                    name="bottlingInfo.caskSignText"
                    placeholderKey={
                      "cask.orders.bottling.info.after.cask-sign.placeholder"
                    }
                  />
                </>
              )}
            </>
          ) : (
            <>
              <CustomAlert
                labelKey="orders.bottlingOrder.bottlingAmount.note"
                status="warning"
                values={{ amount: "1500" }}
              />
              <Box mt={4}>
                <FormControl>
                  <FormLabel fontWeight={300}>
                    <FormattedMessage id="cask.orders.bottling.info.amount.part.no-bottles-label" />
                  </FormLabel>
                  <CustomSelect
                    w={isMobile ? "full" : "50%"}
                    name="numberOfBottles"
                    value={numberOfBottles}
                    onChange={(v: any) =>
                      setFieldValue(
                        "bottlingInfo.numberOfBottles",
                        v.target.value
                      )
                    }
                    placeholder={formatToString(
                      "cask.orders.bottling.info.amount.part.pick-number-of"
                    )}
                  >
                    {[6, 12, 18, 24, 30].map((a) => (
                      <option value={a} key={a}>
                        {formatToString(
                          "cask.orders.bottling.info.amount.part.bottles",
                          { amount: a }
                        )}
                      </option>
                    ))}
                  </CustomSelect>
                </FormControl>
              </Box>
            </>
          )}
          {!isGin && (
            <Box mt={8}>
              <FormLabel
                fontSize="lg"
                fontWeight={300}
                htmlFor="bottlingInfo.dialute"
              >
                <FormattedMessage id="orders.bottlingOrder.useDefaultAlcoholContent.label" />
              </FormLabel>
              <CustomRadioButtonGroup
                id="bottlingInfo.dialute"
                value={dialute ? "1" : "0"}
                onChange={(v: string) => {
                  setFieldValue("bottlingInfo.dialute", v === "1");
                }}
              >
                <StackComponent spacing={4} alignItems="stretch">
                  <Radio value="0" w="full">
                    <FormattedMessage id="orders.bottlingOrder.useDefaultAlcoholContent.true" />
                  </Radio>
                  <Radio value="1" w="full">
                    <FormattedMessage id="orders.bottlingOrder.useDefaultAlcoholContent.false" />
                  </Radio>
                </StackComponent>
              </CustomRadioButtonGroup>
            </Box>
          )}
          {dialute && (
            <Box mt={4}>
              <CustomAlert
                labelKey="orders.bottlingOrder.alcoholContent.note"
                status="warning"
                values={{ amount: "500" }}
              />
              <Box mt={4}>
                <CustomAlert
                  labelKey="cask.orders.bottling.info.dialute.info"
                  status="info"
                />
              </Box>
              <FormInput
                mt={4}
                w={isMobile ? "full" : "50%"}
                inputLeftAddon={<InputLeftAddon children="%" />}
                labelKey="cask.orders.bottling.info.dialute.label"
                name="bottlingInfo.alcoholContent"
                placeholderKey={"cask.orders.bottling.info.dialute.placeholder"}
                inputProps={{
                  onChange: (e: any) => {
                    if (
                      (e.target.value as string)
                        .replaceAll(",", "")
                        .replaceAll(".", "").length <= 3
                    )
                      setFieldValue(
                        "bottlingInfo.alcoholContent",
                        (e.target.value as string).replaceAll(",", ".")
                      );
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </fieldset>
  );
};

export default BottlingInfoFormPart;
