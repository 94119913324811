import BottleLabel from "models/bottle-label";
import BottlingOrder, {
  AfterBottlingAction,
  BankAccountType,
  BottlingAmount,
} from "models/bottling-order";
import CartCask from "models/cart-cask";
import CaskOrder from "models/cask-order";
import { DeliveryOccasionLocation } from "models/delivery-occasion";
import FlavourNoteOrder, {
  FlavourNoteLanguage,
} from "models/flavour-note-order";
import SamplingOrder, { SamplingOrderType } from "models/sampling-order";
import { Language } from "stores/domain/locale";
import { PaymentType } from "stores/ui/cask-shop/shopping-cart";
import { JsonApiResponse, toJsonApiResponse } from "utils/json-api";
import Api from "./api";
interface IsVisma {
  localVismaUserId: number;
}
export interface OrderSamplingVm extends IsVisma {
  caskId: number;
  type?: SamplingOrderType;
  eventId?: number;
  distilleryDateTime?: Date;
  amount?: number;
  systembolagetRequestNumber?: string;
  language: Language;
}

export interface OrderBottlingVm extends IsVisma {
  caskId: number;
  bottlingAmount: BottlingAmount;
  numberOfBottles: number | undefined;
  alcoholContent: number | undefined;
  additionalLabelInfo?: string;
  deliveryOccasionId: number | undefined;
  deliveryLocation: DeliveryOccasionLocation;
  bottleLabels: BottleLabel[];
  afterBottlingAction: AfterBottlingAction;
  caskSignText: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  bankAccountType: BankAccountType;
  clearingNumber?: string;
  bankAccountNumber: string;
  language: Language;
  comment?: string;
}

export interface OrderFlavourNoteVm extends IsVisma {
  caskId: number;
  obtainedLanguage: FlavourNoteLanguage;
  language: Language;
}

export interface OrderCasksVm extends IsVisma {
  cartCasks: CartCask[];
  takePartCaskFilling: boolean;
  socialSecurityNumber?: string;
  phoneNumber: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  email?: string;
  contactFirstName?: string;
  contactLastName?: string;
  language: Language;
  paymentType: PaymentType;
  ecsterCartKey: string;
}

export default class OrdersService {
  static async orderSampling(
    order: OrderSamplingVm
  ): Promise<JsonApiResponse<SamplingOrder>> {
    let response = await Api.post(
      `casks/${order.caskId}/order/sampling`,
      true,
      undefined,
      order
    );
    return await toJsonApiResponse<SamplingOrder>(response);
  }

  static async orderBottling(
    order: OrderBottlingVm
  ): Promise<JsonApiResponse<BottlingOrder>> {
    let response = await Api.post(
      `casks/${order.caskId}/order/bottling`,
      true,
      undefined,
      order
    );
    return await toJsonApiResponse<BottlingOrder>(response);
  }

  static async fetchBottlingOrder(
    orderId: string
  ): Promise<JsonApiResponse<BottlingOrder>> {
    let response = await Api.get(`orders/bottling/${orderId}`, true, undefined);
    return await toJsonApiResponse<BottlingOrder>(response);
  }

  static async orderCasks(
    order: OrderCasksVm
  ): Promise<JsonApiResponse<CaskOrder>> {
    let response = await Api.post(`order/casks`, true, undefined, order);
    return await toJsonApiResponse<CaskOrder>(response);
  }

  static async orderFlavourNote(
    order: OrderFlavourNoteVm
  ): Promise<JsonApiResponse<FlavourNoteOrder>> {
    let response = await Api.post(
      `casks/${order.caskId}/order/flavour-note`,
      true,
      undefined,
      order
    );
    return await toJsonApiResponse<FlavourNoteOrder>(response);
  }
}
