import { action, makeObservable, observable } from "mobx";
import CaskHistory from "models/cask-order-history";
import CaskHistoryService from "services/cask-order-history";

class CaskHistoryStore {
  @observable orderHistory: CaskHistory[] = [];
  @observable isLoading!: boolean;

  constructor() {
    makeObservable(this);
  }

  @action
  async fetchOrderHistory(caskId: number) {
    this.isLoading = true;

    let response = await CaskHistoryService.getCaskOrderHistory(caskId);
    if (!response.succeeded) {
      throw new Error(`Couldn't fetch history for cask`);
    }

    this.orderHistory = response.data;
    this.isLoading = false;
  }
}

export default CaskHistoryStore;
export const caskHistoryStore = new CaskHistoryStore();
