export enum AccountType {
  Person,
  Company,
}

export default class User {
  id!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  vismaUsers!: Array<LocalVismaUser>;
  vismaUser!: LocalVismaUser;
}

export class LocalVismaUser {
  id!: number;
  vismaUserId!: number;
  firstName!: string;
  lastName!: string;
  email!: string;
  address!: string;
  city!: string;
  zipCode!: string;
  country!: string;
  phoneNumber!: string;
  accountType!: AccountType;
  orgName!: string;
  orgNumber!: string;
}
