import Content from "components/Content";
import * as React from "react";

class InformationPage extends React.Component<{ children?: React.ReactNode }> {
  render() {
    return <Content>{this.props.children}</Content>;
  }
}

export default InformationPage;
