import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import BackButton from "components/BackButton";
import HomeBackground from "components/HomeBackground";
import LayoutWrapper from "components/LayoutWrapper";
import { observer } from "mobx-react-lite";
import { CaskStatus } from "models/cask-status";
import { FormattedMessage } from "react-intl";
import { useMedia } from "react-use";
import { useCurrentCask } from "scenes/Cask/cask-hooks";
import CaskDetails from "scenes/Cask/components/CaskDetails";
import CaskHeader from "scenes/Cask/components/CaskHeader";
import CaskHistory from "scenes/Cask/components/CaskHistory";
import CaskMembers from "scenes/Cask/components/CaskMembers";
import CaskOrdersInfo from "scenes/Cask/components/CaskOrdersInfo";
import styled from "styled-components";

const MobileNavigation = styled(Box)`
  position: fixed;
  top: 59px;
  z-index: 100;
  left: -1px;
  right: -1px;
`;

const scrollToAnchor = (id: string) => {
  const element = document.getElementById(id);
  if (!element) return;

  const y = element.getBoundingClientRect().top + window.pageYOffset - 116;

  window.scrollTo({ top: y, behavior: "smooth" });
};

const AboutPage: React.FC = () => {
  const isMobile = useMedia("(max-width: 600px)");
  const { cask, isCaskOwner } = useCurrentCask();

  const showOrdersInfo = isCaskOwner && cask?.status === CaskStatus.Filled;

  return (
    <Box h="full" pos="relative" mt={isMobile ? "40px" : undefined}>
      <HomeBackground />
      <LayoutWrapper>
        <Box minW="full" maxW={["full", "full", 1024]} pt={4}>
          <BackButton path="/" />
          <CaskHeader />
          <CaskDetails />
          {showOrdersInfo && <CaskOrdersInfo />}
          <CaskMembers />
          {isCaskOwner && <CaskHistory />}
        </Box>
        <Box maxW={["full", "full", 512]} w="full" />
      </LayoutWrapper>
      {isMobile && (
        <MobileNavigation>
          <ButtonGroup w="full" bgColor="white" isAttached variant="outline">
            {showOrdersInfo && (
              <Button
                w="full"
                borderRadius={0}
                onClick={() => scrollToAnchor("orders-info")}
              >
                <FormattedMessage id="cask.orders-info.title" />
              </Button>
            )}
            <Button
              w="full"
              borderRadius={0}
              onClick={() => scrollToAnchor("members")}
            >
              <FormattedMessage id="cask.members.title" />
            </Button>
            {isCaskOwner && (
              <Button
                w="full"
                borderRadius={0}
                onClick={() => scrollToAnchor("history")}
              >
                <FormattedMessage id="cask.tabs.history" />
              </Button>
            )}
          </ButtonGroup>
        </MobileNavigation>
      )}
    </Box>
  );
};

export default observer(AboutPage);
