import { observer } from "mobx-react";
import { FC, PropsWithChildren } from "react";
import { Redirect, RouteProps } from "react-router";
import { Route } from "react-router-dom";
import { useCurrentCask } from "scenes/Cask/cask-hooks";

const CaskOwnerRoute: FC<PropsWithChildren & RouteProps> = (props) => {
  const { isCaskOwner, cask, loading } = useCurrentCask();

  if (loading || !cask) return null;

  return (
    <div>
      {isCaskOwner ? (
        <Route {...props} />
      ) : (
        <Redirect to={{ pathname: `/cask/${cask.id}`, state: {} }} />
      )}
    </div>
  );
};

export default observer(CaskOwnerRoute);
