import { inject, observer } from "mobx-react";
import * as React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { BottlePriceListUiStore, BottlingPriceVariablesStore } from "stores";
import styled from "styled-components";
import {
  DEFAULT_ALCOHOL_CONTENT,
  DEFAULT_NUMBER_OF_BOTTLES,
} from "utils/forms/constants";
import BottlePriceList from "./BottlePriceListContainer";

const Wrapper = styled.div`
  margin-top: 50px;
  padding: 30px 35px;
  border: 1px solid #aaaaaa;
  color: #929292;
`;

const DescriptionField = styled.div`
  padding: 0 15px;
`;

const DescriptionTitle = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
`;

interface Props {
  bottlePriceListUiStore?: BottlePriceListUiStore;
  bottlingPriceVariablesStore?: BottlingPriceVariablesStore;
  numberOfBottles: number | undefined;
  alcoholContent: number | undefined;
  loanAmount: number;
  loanClaimExists: boolean;
  showWarningMessage?: boolean;
}

@inject("bottlePriceListUiStore", "bottlingPriceVariablesStore")
@observer
class ExamplePriceWindow extends React.Component<Props, {}> {
  descriptionMessageId = "";
  warningMessageId = "";

  constructor(props: Props) {
    super(props);
    this.descriptionMessageId = props.loanClaimExists
      ? "orders.bottlingOrder.priceExample.description"
      : "orders.bottlingOrder.priceExample.descriptionNoLoanClaim";

    this.warningMessageId = props.showWarningMessage
      ? "orders.bottlingOrder.priceExample.descriptionNoLoanClaimWarning"
      : "";
  }

  render() {
    return (
      <Wrapper>
        <DescriptionField>
          <DescriptionTitle>
            <FormattedMessage id="orders.bottlingOrder.priceExample.title" />
          </DescriptionTitle>
          <FormattedMessage
            id={this.descriptionMessageId}
            values={{
              numberOfBottles:
                this.props.numberOfBottles || DEFAULT_NUMBER_OF_BOTTLES,
              alcoholAmount:
                this.props.alcoholContent || DEFAULT_ALCOHOL_CONTENT,
            }}
          />
          {this.warningMessageId && (
            <FormattedHTMLMessage
              id={this.warningMessageId}
              values={{ smokedRecipePrice: this.props.loanAmount }}
            />
          )}
        </DescriptionField>
        <BottlePriceList
          numberOfBottles={
            this.props.numberOfBottles || DEFAULT_NUMBER_OF_BOTTLES
          }
          alcoholContent={this.props.alcoholContent || DEFAULT_ALCOHOL_CONTENT}
          loanAmount={this.props.loanAmount}
          bottlePriceListUiStore={this.props.bottlePriceListUiStore}
          bottlingPriceVariablesStore={this.props.bottlingPriceVariablesStore}
        />
      </Wrapper>
    );
  }
}

export default ExamplePriceWindow;
