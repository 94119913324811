import { FieldState } from "formstate";
import { IconButton, Paper, TextField } from "material-ui";
import ActionDelete from "material-ui/svg-icons/action/delete";
import { observer } from "mobx-react";
import CartCask from "models/cart-cask";
import * as React from "react";
import styled from "styled-components";
import formatMessage, { format } from "utils/format";

interface Props {
  caskOption: {
    cartCask: CartCask;
    partOwners: {
      mail: FieldState<string>;
    }[];
    caskSign: string;
  };
  addCaskPartOwner: (cartCask: CartCask) => void;
  removeCaskPartOwner: (cartCask: CartCask, index: number) => void;
  setCaskSign: (cartCask: CartCask, caskSign: string) => void;
  removeCask: (cartCask: CartCask) => void;
}

const Wrapper = styled(Paper)`
  margin-top: 30px;
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ebebeb !important;
  font-size: 12px;
`;

const RemoveCaskButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

const StyledHeader = styled.h2`
  flex: 1 1 0px;
`;

@observer
class CartCaskOptionsComponent extends React.Component<Props, {}> {
  get responsiveTextField() {
    return {
      maxWidth: "100%",
    };
  }

  render() {
    let { caskOption, setCaskSign, removeCask } = this.props;
    let { cartCask, caskSign } = caskOption;
    let { caskType } = cartCask;
    let caskTypeInfo = format(caskType.caskTypeInformation);

    return (
      <Wrapper>
        <RemoveCaskButtonContainer>
          <StyledHeader>{caskTypeInfo.name}</StyledHeader>
          <IconButton onClick={() => removeCask(cartCask)}>
            <ActionDelete />
          </IconButton>
        </RemoveCaskButtonContainer>
        <div>
          <TextField
            style={this.responsiveTextField}
            floatingLabelText={formatMessage(
              "caskShop.cart.options.caskSign.label"
            )}
            value={caskSign}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setCaskSign(cartCask, e.currentTarget.value)
            }
          />
        </div>
      </Wrapper>
    );
  }
}

export default CartCaskOptionsComponent;
