import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import CartCask from "models/cart-cask";
import Cask from "models/cask";
import OrdersService, { OrderCasksVm } from "services/orders";
import { casksStore } from "stores/domain/casks";
import { localeStore } from "stores/domain/locale";
import { sessionStore } from "stores/domain/session";
import { shoppingCartStore } from "stores/domain/shopping-cart";
import { countryRequired, isEmail, required } from "utils/forms/validators";

export enum CartStep {
  CartView = 1,
  CartOptions = 2,
  CartPayment = 3,
}

export enum PaymentType {
  Invoice,
  Ecster,
}

type PersonDataFormState = {
  socialSecurityNumber: FieldState<string>;
  address: FieldState<string>;
  city: FieldState<string>;
  zipCode: FieldState<string>;
  phoneNumber: FieldState<string>;
  country: FieldState<string>;
};

type CompanyDataFormState = {
  email: FieldState<string>;
  invoiceAddress: FieldState<string>;
  invoiceCity: FieldState<string>;
  invoiceZipCode: FieldState<string>;
  invoiceCountry: FieldState<string>;
  contactFirstName: FieldState<string>;
  contactLastName: FieldState<string>;
  contactPhoneNumber: FieldState<string>;
};

class ShoppingCartUiStore {
  @observable caskOptions: {
    cartCask: CartCask;
    partOwners: {
      mail: FieldState<string>;
    }[];
    caskSign: string;
  }[] = [];
  @observable takePartCaskFilling: boolean = false;
  @observable
  orderId!: string;
  @observable orderIsComplete: boolean = false;
  @observable sendingOrder: boolean = false;
  @observable
  newCask!: Cask;
  @observable
  errorMessage!: string;

  constructor() {
    makeObservable(this);
  }

  @computed get ecsterCartKey() {
    return shoppingCartStore.ecsterCartKey;
  }
  socialSecurityNumber: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.socialSecurity.error.required")
  );
  phoneNumber: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.phoneNumber.error.required")
  );
  address: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.address.error.required")
  );
  country: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.country.error.required")
  );
  city: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.city.error.required")
  );
  zipCode: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.zipCode.error.required")
  );

  email: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.email.error.required"),
    isEmail("register.email.error.invalidEmail")
  );
  invoiceAddress: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.address.error.required")
  );
  invoiceCity: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.city.error.required")
  );
  invoiceZipCode: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.zipCode.error.required")
  );
  invoiceCountry: FieldState<string> = new FieldState("").validators(
    countryRequired("settings.contactInfo.company.country.error.required")
  );
  contactPhoneNumber: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.phoneNumber.error.required")
  );
  contactFirstName: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.firstName.error.required")
  );
  contactLastName: FieldState<string> = new FieldState("").validators(
    required("settings.contactInfo.company.lastName.error.required")
  );

  personForm = new FormState<PersonDataFormState>({
    socialSecurityNumber: this.socialSecurityNumber,
    address: this.address,
    city: this.city,
    zipCode: this.zipCode,
    phoneNumber: this.phoneNumber,
    country: this.country,
  });

  companyForm = new FormState<CompanyDataFormState>({
    email: this.email,
    invoiceAddress: this.invoiceAddress,
    invoiceCity: this.invoiceCity,
    invoiceZipCode: this.invoiceZipCode,
    invoiceCountry: this.invoiceCountry,
    contactPhoneNumber: this.contactPhoneNumber,
    contactFirstName: this.contactFirstName,
    contactLastName: this.contactLastName,
  });

  async validate(): Promise<boolean> {
    if (sessionStore.isPersonalAccount) {
      let validation = await this.personForm.validate();
      return validation.hasError;
    } else {
      let validation = await this.companyForm.validate();
      return validation.hasError;
    }
  }

  @computed
  get hasFieldError(): boolean {
    if (sessionStore.isPersonalAccount) {
      return this.personForm.hasFieldError;
    } else {
      return this.companyForm.hasFieldError;
    }
  }

  @action
  createCaskOption(cartCask: CartCask) {
    let caskOption = this.caskOptions.find(
      (element) => element.cartCask === cartCask
    );

    let partOwners = [
      {
        mail: new FieldState("").validators(
          isEmail("register.email.error.invalidEmail")
        ),
      },
    ];
    if (cartCask.partOwners && cartCask.partOwners.length > 0) {
      partOwners = cartCask.partOwners.map((partOwner) => ({
        mail: new FieldState(partOwner).validators(
          isEmail("register.email.error.invalidEmail")
        ),
      }));
    }

    let caskSign = "";
    if (cartCask.caskSign) {
      caskSign = cartCask.caskSign;
    }

    if (!caskOption) {
      this.caskOptions.push({ cartCask, partOwners, caskSign });
    }
  }

  @action
  findCaskOption(cartCask: CartCask): {
    cartCask: CartCask;
    partOwners: { mail: FieldState<string> }[];
    caskSign: string;
  } {
    let caskOption = this.caskOptions.find((e) => e.cartCask === cartCask);

    if (!caskOption) {
      throw new Error(`Cask ${cartCask} couldn't be found.`);
    }

    return caskOption;
  }

  @action
  removeCaskPartOwner(cartCask: CartCask, index: number) {
    let caskOption = this.caskOptions.find(
      (element) => element.cartCask === cartCask
    );

    if (!caskOption) {
      throw new Error(`Cask ${cartCask} couldn't be found.`);
    }

    if (caskOption.partOwners.length > 1) {
      caskOption.partOwners.splice(index, 1);
    }
  }

  @action
  addCaskPartOwner(cartCask: CartCask) {
    let caskOption = this.caskOptions.find(
      (element) => element.cartCask === cartCask
    );

    if (!caskOption) {
      throw new Error(`Cask ${cartCask} couldn't be found.`);
    }

    if (
      caskOption.partOwners[caskOption.partOwners.length - 1].mail.value &&
      caskOption.partOwners.every((partOwner) => !partOwner.mail.hasError)
    ) {
      let newPartOwner = {
        mail: new FieldState("").validators(
          isEmail("register.email.error.invalidEmail")
        ),
      };
      caskOption.partOwners.push(newPartOwner);
    }
  }

  @action
  setCaskSign(cartCask: CartCask, caskSign: string) {
    let caskOption = this.caskOptions.find(
      (element) => element.cartCask === cartCask
    );

    if (!caskOption) {
      throw new Error(`Cask ${cartCask} couldn't be found.`);
    }
    caskOption.caskSign = caskSign;
  }

  @action
  resetForm() {
    this.socialSecurityNumber.reset();
    this.phoneNumber.reset();
    this.address.reset();
    this.city.reset();
    this.zipCode.reset();
    this.country.reset();

    this.email.reset();
    this.invoiceAddress.reset();
    this.invoiceCity.reset();
    this.invoiceZipCode.reset();
    this.invoiceCountry.reset();
    this.contactPhoneNumber.reset();
    this.contactFirstName.reset();
    this.contactLastName.reset();
    this.errorMessage = "";
  }

  @action
  initUserData() {
    const { vismaUser } = sessionStore.user;
    this.resetForm();

    this.socialSecurityNumber.value = "";
    this.phoneNumber.value = vismaUser?.phoneNumber || "";
    this.address.value = vismaUser?.address || "";
    this.city.value = vismaUser?.city || "";
    this.zipCode.value = vismaUser?.zipCode || "";
    this.country.value = vismaUser?.country || "";

    this.email.value = vismaUser?.email || "";
    this.invoiceAddress.value = vismaUser?.address || "";
    this.invoiceCity.value = vismaUser?.city || "";
    this.invoiceZipCode.value = vismaUser?.zipCode || "";
    this.invoiceCountry.value = vismaUser?.country || "";
    this.contactPhoneNumber.value = vismaUser?.phoneNumber || "";
    this.contactFirstName.value = vismaUser?.firstName || "";
    this.contactLastName.value = vismaUser?.lastName || "";
  }

  @action
  toggleTakePartCaskFilling() {
    this.takePartCaskFilling = !this.takePartCaskFilling;
  }

  // @action setPaymentType(paymentType: PaymentType) {
  //     shoppingCartStore.paymentType = paymentType;
  // }

  @computed
  get shoppingCartData(): OrderCasksVm {
    if (shoppingCartStore.paymentType === undefined) {
      throw new Error(`Couldn't get the payment type for this order`);
    }

    let order: OrderCasksVm = {
      cartCasks: shoppingCartStore.cartCasks,
      takePartCaskFilling: this.takePartCaskFilling,
      language: localeStore.language,
      paymentType: shoppingCartStore.paymentType,
      ecsterCartKey:
        shoppingCartStore.paymentType === PaymentType.Ecster
          ? this.ecsterCartKey
          : "",
      socialSecurityNumber: sessionStore.isPersonalAccount
        ? this.socialSecurityNumber.value
        : undefined,
      phoneNumber: sessionStore.isPersonalAccount
        ? this.phoneNumber.value
        : this.contactPhoneNumber.value,
      address: sessionStore.isPersonalAccount
        ? this.address.value
        : this.invoiceAddress.value,
      city: sessionStore.isPersonalAccount
        ? this.city.value
        : this.invoiceCity.value,
      zipCode: sessionStore.isPersonalAccount
        ? this.zipCode.value
        : this.invoiceZipCode.value,
      country: sessionStore.isPersonalAccount
        ? this.country.value
        : this.invoiceCountry.value,
      email: sessionStore.isPersonalAccount ? undefined : this.email.value,
      contactFirstName: sessionStore.isPersonalAccount
        ? undefined
        : this.contactFirstName.value,
      contactLastName: sessionStore.isPersonalAccount
        ? undefined
        : this.contactLastName.value,
      localVismaUserId: sessionStore.vismaUser.id,
    };

    return order;
  }

  @action
  async submit() {
    this.sendingOrder = true;
    let success = true;
    this.errorMessage = "";
    try {
      let orderResponse = await OrdersService.orderCasks(this.shoppingCartData);
      if (orderResponse.hasErrors) {
        success = false;
      } else {
        shoppingCartStore.clear();

        this.orderId = orderResponse.data.order.guid;
        this.newCask = orderResponse.data.orderedCasks[0].cask;
        this.orderIsComplete = true;
        orderResponse.data.orderedCasks.forEach(async (orderedCask) =>
          casksStore.addCask(orderedCask.cask)
        );
      }
    } catch (e) {
      success = false;
    }

    this.errorMessage = success ? "" : "caskShop.cart.payment.error.unkown";
    this.sendingOrder = false;
    return success;
  }
}
export default ShoppingCartUiStore;
export const shoppingCartUiStore = new ShoppingCartUiStore();
