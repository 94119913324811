import { makeObservable, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import RegisterService, { RegistrationViewModel } from "services/register";
import LocaleStore from "stores/domain/locale";
import RegisterFormUiStore from "stores/ui/account/register-form";
import RegisterForm from "./RegisterForm";

interface Props extends RouteComponentProps<{}> {
  registerFormUiStore?: RegisterFormUiStore;
  localeStore?: LocaleStore;
}

@inject("registerFormUiStore", "localeStore")
@observer
class RegisterFormContainer extends React.Component<Props, {}> {
  @observable isSubmitting: boolean = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.props.registerFormUiStore!.resetForm();
  }

  get localeStore() {
    return this.props.localeStore!;
  }

  get store() {
    return this.props.registerFormUiStore!;
  }

  get registrationViewModel(): RegistrationViewModel {
    return {
      email: this.store.email.value,
      password: this.store.password.value,
      repeatedPassword: this.store.repeatedPassword.value,
      firstName: this.store.firstName.value,
      lastName: this.store.lastName.value,
      language: this.localeStore.language,
    };
  }

  onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validation = await this.store.form.validate();
    if (validation.hasError) {
      return;
    }

    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    let registration = this.registrationViewModel;
    RegisterService.registerUser(registration)
      .then((response) => {
        this.isSubmitting = false;
        if (!response.succeeded) {
          if (
            response.error &&
            response.error.detail.startsWith("DuplicateUserName")
          ) {
            this.store.setEmailUnavailable(true);
            return;
          }

          throw new Error("Could't register user.");
        }

        this.store.registeredEmail = registration.email;
        this.props.history.push(`/register-complete`);
      })
      .catch((error) => {
        this.isSubmitting = false;
        throw error;
      });
  };

  render() {
    let hasFieldError = this.store.form.hasFieldError;
    return (
      <div>
        <RegisterForm
          hasFieldError={hasFieldError}
          formStore={this.store}
          onSubmit={this.onSubmit}
          isSubmitting={this.isSubmitting}
        />
      </div>
    );
  }
}

export default withRouter(RegisterFormContainer);
