import { Box } from "@chakra-ui/react";
import { useMedia } from "react-use";
import MenuDrawer from "./MenuDrawer";
import Navlinks from "./Navlinks";

const Menu = () => {
  const isMobile = useMedia("(max-width: 48em)");

  return (
    <>
      <Box display={isMobile ? "none" : "block"}>
        <Navlinks />
      </Box>
      <Box display={isMobile ? "block" : "none"}>
        <MenuDrawer />
      </Box>
    </>
  );
};

export default Menu;
