import * as React from 'react';
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LoadingSplash from 'components/Loading/LoadingSplash';
import { SessionStore } from 'stores';
import OrderComplete from 'components/OrderComplete';
import ShoppingCartUiStore from 'stores/ui/cask-shop/shopping-cart';

interface Props {
  sessionStore?: SessionStore;
  shoppingCartUiStore?: ShoppingCartUiStore;
}

@inject('sessionStore', 'shoppingCartUiStore')
@observer
class ShoppingCompleteContainer extends Component<Props, {}> {
  
  get store() {
      return this.props.shoppingCartUiStore!;
  }

  get newCask() {
      return this.store.newCask;
  }

  get orderId() {
      return this.store.orderId;
  }

  get email() {
      return this.props.sessionStore!.user.email;
  }

  render() {
    return (
      <div>
        {this.store.orderIsComplete ?
          <OrderComplete
            backButtonPrimary={true}
            orderId={this.orderId} 
            email={this.email}
            textIds={{
              title: 'caskShop.complete.title',
              summary: 'caskShop.complete.orderCompleteText',
              order: 'caskShop.complete.orderIdLabel',
              back: 'caskShop.complete.backButton',
            }}
            backRoute={`/cask/${this.newCask.id}`}
          />
        :
          <LoadingSplash />
        }
      </div>
    );
  }
}

export default ShoppingCompleteContainer;