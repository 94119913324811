import { inject, observer } from "mobx-react";
import CaskOrderHistory from "models/cask-order-history";
import moment from "moment-timezone";
import { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import OrderSettlementService from "services/order-settlement";
import LocaleStore from "stores/domain/locale";
import SessionStore from "stores/domain/session";
import CaskHistoryUiStore from "stores/ui/cask/history";
import styled from "styled-components";
import HistoryItemModal from "./HistoryItemModal";
import HistoryTimelineItem from "./HistoryTimelineItemContainer";

const Container = styled.div`
  margin-left: 40px;
  padding: 0;
  list-style-type: none;
`;

interface Props {
  id: number;
  caskOrderHistory: Array<CaskOrderHistory>;
  caskHistoryUiStore?: CaskHistoryUiStore;
  localeStore?: LocaleStore;
  sessionStore?: SessionStore;
}

@inject("caskHistoryUiStore", "localeStore", "sessionStore")
@observer
class HistoryTimelineContainer extends PureComponent<Props> {
  get store() {
    return this.props.caskHistoryUiStore!;
  }

  showCaskHistoryItem = (orderId: number) => {
    this.store.showCaskHistoryItem(orderId);
  };

  hideCaskHistoryItem = () => {
    this.store.hideCaskHistoryItem();
  };

  getSettlement = (orderId: number) => {
    OrderSettlementService.getOrderSettlement(
      orderId,
      this.props.localeStore!.language,
      moment.tz.guess()
    );
  };

  get isOwner() {
    return (
      this.props.sessionStore !== undefined &&
      this.props.sessionStore.isCaskOwner(this.props.id)
    );
  }

  render() {
    let { caskOrderHistory } = this.props;
    let { currentHistoryItem } = this.store;

    return (
      <Container>
        {caskOrderHistory.length === 0 && (
          <FormattedMessage id="history.order.emptyHistory" />
        )}
        {caskOrderHistory.map((history) => (
          <HistoryTimelineItem
            key={history.id}
            caskOrderHistory={history}
            onShowTimelineItem={(orderId) => this.showCaskHistoryItem(orderId)}
            onGetSettlement={(orderId) => this.getSettlement(orderId)}
            isOwner={this.isOwner}
          />
        ))}

        {currentHistoryItem && (
          <HistoryItemModal
            isOpen={true}
            onHideModal={this.hideCaskHistoryItem}
            historyItem={currentHistoryItem}
          />
        )}
      </Container>
    );
  }
}

export default HistoryTimelineContainer;
