import CaskType from "models/cask-type";
import { JsonApiResponse, toJsonApiResponse } from "utils/json-api";
import Api from "./api";

export default class CaskTypesService {
  static async fetchOfferedCaskTypes(): Promise<JsonApiResponse<CaskType[]>> {
    let response = await Api.get("caskTypes?offered=true", false);
    return await toJsonApiResponse<CaskType[]>(response);
  }
}
