import ScrollToTop from "components/router/ScrollToTop";
import { Route, Switch } from "react-router-dom";
import AccountPage from "scenes/Account";
import LoginPage from "scenes/Account/scenes/Login";
import RegisterPage from "scenes/Account/scenes/Register";
import RegisterForm from "scenes/Account/scenes/Register/components/RegisterFormContainer";
import RegisterCompanyFormContainer from "scenes/Account/scenes/Register/scenes/RegisterCompanyFormContainer";
import RegisterVerifyNewPage from "scenes/Account/scenes/Register/scenes/VerifyNewPage";
import RegisterVerifyPage from "scenes/Account/scenes/Register/scenes/VerifyPage";
import AboutPage from "scenes/Cask/scenes/About";
import HistoryPage from "scenes/Cask/scenes/History";
import CaskOrdersPage from "scenes/Cask/scenes/Orders";
import BottlingOrderPage from "scenes/Cask/scenes/Orders/scenes/Bottling";
import BottlingOrderCompletePage from "scenes/Cask/scenes/Orders/scenes/Bottling/scenes/complete";
import FlavourNoteOrderPage from "scenes/Cask/scenes/Orders/scenes/FlavourNote";
import FlavourNoteOrderCompletePage from "scenes/Cask/scenes/Orders/scenes/FlavourNote/components/FlavourNoteOrderCompleteContainer";
import CaskOrdersIndexPage from "scenes/Cask/scenes/Orders/scenes/Index";
import CaskOrderSamplingPage from "scenes/Cask/scenes/Orders/scenes/Sampling";
import CaskSamplingCompletePage from "scenes/Cask/scenes/Orders/scenes/Sampling/components/SamplingOrderCompleteContainer";
import CaskShopPage from "scenes/CaskShop";
import CaskShopContainer from "scenes/CaskShop/components/CaskShopContainer";
import ShoppingCartPage from "scenes/CaskShop/scenes/ShoppingCart";
import ShoppingCompleteContainer from "scenes/CaskShop/scenes/ShoppingCart/components/ShoppingCompleteContainer";
import DevPage from "scenes/DevPage";
import Home from "scenes/Home";
import InformationPage from "scenes/Information";
import InformationContainer from "scenes/Information/components/InformationContainer";
import NewMemberPage from "scenes/NewMember";
import RegisterCompanyMemberPage from "scenes/NewMember/scenes/Company";
import RegisterPersonMemberPage from "scenes/NewMember/scenes/Person";
import ResendInvitationPage from "scenes/NewMember/scenes/Resend";
import RegisterCompletePage from "scenes/RegisterComplete/components/RegisterCompleteContainer";
import ResetPasswordPage from "scenes/ResetPassword";
import ResetPasswordContainer from "scenes/ResetPassword/components/ResetPasswordContainer";
import ResetPasswordCompletePage from "scenes/ResetPassword/scenes/CompletePage";
import SetPasswordPage from "scenes/ResetPassword/scenes/SetPasswordPageContainer";
import SettingsPage from "scenes/Settings";
import UserTermsPage from "scenes/UserTerms";
import { usePageTracking } from "tracking";
import CaskOwnerRoute from "utils/routes/CaskOwnerRoute";
import ProtectedRoute from "utils/routes/ProtectedRoute";
import PublicOnlyRoute from "utils/routes/PublicOnlyRoute";

// import BoxesOrderPage from 'scenes/Cask/scenes/Orders/scenes/Boxes';

const Routes = () => {
  usePageTracking();

  return (
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/user-terms" component={UserTermsPage} />
        <Route path="/shop">
          <CaskShopPage>
            <Switch>
              <Route exact path="/shop" component={CaskShopContainer} />
              <Route exact path="/shop/cart" component={ShoppingCartPage} />
              <Route
                exact
                path="/shop/cart/complete"
                component={ShoppingCompleteContainer}
              />
            </Switch>
          </CaskShopPage>
        </Route>
        <ProtectedRoute exact path="/settings" component={SettingsPage} />
        <Route path="/devpage" component={DevPage} />
        <ProtectedRoute path="/cask/:id">
          <Switch>
            <CaskOwnerRoute path="/cask/:id/orders">
              <CaskOrdersPage>
                <Switch>
                  <Route
                    exact
                    path="/cask/:id/orders"
                    component={CaskOrdersIndexPage}
                  />
                  <Route
                    exact
                    path="/cask/:id/orders/bottling"
                    component={BottlingOrderPage}
                  />
                  <Route
                    exact
                    path="/cask/:id/orders/bottling/complete"
                    component={BottlingOrderCompletePage}
                  />
                  <Route
                    exact
                    path="/cask/:id/orders/sampling"
                    component={CaskOrderSamplingPage}
                  />
                  <Route
                    exact
                    path="/cask/:id/orders/sampling/complete"
                    component={CaskSamplingCompletePage}
                  />
                  <Route
                    exact
                    path="/cask/:id/orders/flavour-note"
                    component={FlavourNoteOrderPage}
                  />
                  <Route
                    exact
                    path="/cask/:id/orders/flavour-note/complete"
                    component={FlavourNoteOrderCompletePage}
                  />
                </Switch>
              </CaskOrdersPage>
            </CaskOwnerRoute>
            <Route exact path="/cask/:id/history" component={HistoryPage} />
            <Route exact path="/cask/:id" component={AboutPage} />
          </Switch>
        </ProtectedRoute>
        <PublicOnlyRoute
          path="/register-complete"
          component={RegisterCompletePage}
        />
        <PublicOnlyRoute path="/account">
          <AccountPage>
            <Switch>
              <Route exact path="/account/login" component={LoginPage} />
              <Route path="/account/register">
                <RegisterPage>
                  <Switch>
                    <Route
                      exact
                      path="/account/register"
                      component={RegisterForm}
                    />
                    <Route
                      exact
                      path="/account/register/company"
                      component={RegisterCompanyFormContainer}
                    />
                    <Route
                      exact
                      path="/account/register/verify"
                      component={RegisterVerifyPage}
                    />
                    <Route
                      exact
                      path="/account/register/verify/new"
                      component={RegisterVerifyNewPage}
                    />
                  </Switch>
                </RegisterPage>
              </Route>
            </Switch>
          </AccountPage>
        </PublicOnlyRoute>
        <PublicOnlyRoute path="/reset-password">
          <ResetPasswordPage>
            <Switch>
              <Route
                exact
                path="/reset-password"
                component={ResetPasswordContainer}
              />
              <Route
                exact
                path="/reset-password/complete"
                component={ResetPasswordCompletePage}
              />
              <Route
                exact
                path="/reset-password/set-password"
                component={SetPasswordPage}
              />
            </Switch>
          </ResetPasswordPage>
        </PublicOnlyRoute>
        <PublicOnlyRoute path="/new-member">
          <NewMemberPage>
            <Switch>
              <Route
                exact
                path="/new-member"
                component={RegisterPersonMemberPage}
              />
              <Route
                exact
                path="/new-member/person"
                component={RegisterPersonMemberPage}
              />
              <Route
                exact
                path="/new-member/company"
                component={RegisterCompanyMemberPage}
              />
              <Route
                exact
                path="/new-member/resend"
                component={ResendInvitationPage}
              />
            </Switch>
          </NewMemberPage>
        </PublicOnlyRoute>
        <Route path="/information">
          <InformationPage>
            <Route
              exact
              path="/information/:id?/:child?"
              component={InformationContainer}
            />
          </InformationPage>
        </Route>
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
