export default class DeliveryOccasion {
  id!: number;
  deadline!: Date;
  pickup!: Date;
  bottlingCountries!: DeliveryOccasionLocation[];
  name!: string;
}

export enum DeliveryOccasionLocation {
  Sweden,
  Germany,
  Denmark,
  Estonia,
}
