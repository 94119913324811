import { ContentState, EditorState } from "draft-js";
import { Paper } from "material-ui";
import * as React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from "styled-components";

interface Props {
  content: ContentState;
  title: string;
}

const StyledPaper = styled(Paper)`
  flex: 5 0 200px;
  padding: 20px;
`;

const StyledH1 = styled.h1`
  text-align: center;
`;

export default class BodyComponent extends React.Component<Props, {}> {
  doNothing() {
    return;
  }
  render() {
    return (
      <StyledPaper>
        <StyledH1>{this.props.title}</StyledH1>
        <Editor
          toolbarHidden={true}
          editorState={EditorState.createWithContent(this.props.content)}
          onChange={this.doNothing}
          readOnly={true}
        />
      </StyledPaper>
    );
  }
}
