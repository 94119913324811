import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { PASSWORD_MIN_LENGTH } from "utils/forms/constants";
import { hasNumber, minLength, required } from "utils/forms/validators";

type PersonFormState = {
  password: FieldState<string>;
  repeatedPassword: FieldState<string>;
  firstName: FieldState<string>;
  lastName: FieldState<string>;
};

class PersonFormUiStore {
  @observable
  registeredEmail!: string;
  @observable
  emailUnavailable!: boolean;

  constructor() {
    makeObservable(this);
  }

  password: FieldState<string> = new FieldState("").validators(
    required("register.password.error.required"),
    minLength(PASSWORD_MIN_LENGTH, "register.password.error.minLength"),
    hasNumber("register.password.error.hasNumber")
  );

  repeatedPassword: FieldState<string> = new FieldState("").validators(
    required("register.repeatedPassword.error.required"),
    () => this.passwordsMustMatch()
  );
  firstName: FieldState<string> = new FieldState("").validators(
    required("register.firstName.error.required")
  );
  lastName: FieldState<string> = new FieldState("").validators(
    required("register.lastName.error.required")
  );

  form = new FormState<PersonFormState>({
    password: this.password,
    repeatedPassword: this.repeatedPassword,
    firstName: this.firstName,
    lastName: this.lastName,
  });

  passwordsMustMatch() {
    return this.password.value === this.repeatedPassword.value
      ? false
      : "register.repeatedPassword.error.match";
  }

  isAvailableEmail(message: string) {
    return () => this.emailUnavailable && message;
  }

  @action
  validateRepeatedPassword = async () => {
    if (!this.repeatedPassword.dirty) {
      return;
    }

    this.repeatedPassword.validate();
  };

  @action
  resetForm() {
    this.password.reset();
    this.repeatedPassword.reset();
    this.firstName.reset();
    this.lastName.reset();
  }
}

export default PersonFormUiStore;
export const personFormUiStore = new PersonFormUiStore();
