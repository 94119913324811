import {
  Collapse,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { CustomInput } from "components/CommonStyles";
import { useField } from "formik";
import React, { ReactNode } from "react";
import { GoCheck } from "react-icons/go";
import { FormattedMessage } from "react-intl";
import { formatToString } from "utils/format";

const FormInput: React.FC<
  {
    name: string;
    labelKey: string;
    placeholderKey: string;
    inputLeftAddon?: ReactNode;
    inputProps?: InputProps;
  } & FormControlProps
> = ({
  name,
  labelKey,
  placeholderKey,
  inputLeftAddon,
  inputProps,
  ...boxProps
}) => {
  const [field, meta] = useField(name);
  const hasError = !!meta.error && meta.touched;

  return (
    <FormControl isInvalid={hasError} {...boxProps}>
      <FormLabel fontWeight={300}>
        <FormattedMessage id={labelKey} />
      </FormLabel>
      <InputGroup>
        {inputLeftAddon && inputLeftAddon}
        <CustomInput
          placeholder={formatToString(placeholderKey)}
          {...field}
          {...inputProps}
        />
        {meta.touched && !meta.error && (
          <InputRightElement
            children={<GoCheck size="1.25rem" color="#2a9d8f" />}
          />
        )}
      </InputGroup>
      <Collapse in={!!meta.error} animateOpacity>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </Collapse>
    </FormControl>
  );
};

export default FormInput;
