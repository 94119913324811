import { Button } from "@chakra-ui/react";
import styled, { css } from "styled-components";

const BrandButton = styled(Button)<{ large?: boolean }>`
  font-weight: 500 !important;
  border-radius: 9999px !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  height: 36px !important;
  padding: 1rem 2rem !important;

  @media (max-width: 600px) {
    padding: 1rem 1rem !important;
  }

  ${({ large }) =>
    large &&
    css`
      font-size: 16px !important;
      padding: 1.5rem 2.5rem !important;
      height: 46px !important;
      text-transform: uppercase !important;
    `}
`;

export default BrandButton;
