import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import { TextField } from 'material-ui';
import CountryPicker from 'components/CountryPickerContainer';
import format from 'utils/format';
import { Row, Col } from 'react-flexbox-grid';

interface Props {
    socialSecurityNumber: FieldState<string>;
    phoneNumber: FieldState<string>;
    address: FieldState<string>;
    city: FieldState<string>;
    zipCode: FieldState<string>;
    country: FieldState<string>;
}

@observer
export default class PersonInfoComponent extends React.Component<Props, {}> {

  render() {
    let { socialSecurityNumber, phoneNumber, address, city, zipCode, country } = this.props;

    return (
        <div>
            <h4>
                {format('caskShop.cart.options.person.information.label')}
            </h4>
            <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.socialSecurity.label')}
                        value={socialSecurityNumber.value}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => 
                            socialSecurityNumber.onChange(e.currentTarget.value)}
                        errorText={format(socialSecurityNumber.error)}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.phoneNumber.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            phoneNumber.onChange(e.currentTarget.value)}
                        value={phoneNumber.value}
                        errorText={format(phoneNumber.error)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.address.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            address.onChange(e.currentTarget.value)}
                        value={address.value}
                        errorText={format(address.error)}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.city.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            city.onChange(e.currentTarget.value)}
                        value={city.value}
                        errorText={format(city.error)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <TextField
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.zipCode.label')}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            zipCode.onChange(e.currentTarget.value)}
                        value={zipCode.value}
                        errorText={format(zipCode.error)}
                    />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <CountryPicker
                        fullWidth={true}
                        floatingLabelText={format('settings.contactInfo.country.label')}
                        onChange={(e: {}, i: {}, aCountry: string) => country.onChange(aCountry)}
                        value={country.value}
                        errorText={format(country.error)}
                    />
                </Col>
            </Row>
        </div>
    );
  }
}