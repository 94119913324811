import * as React from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import CaskUiStore from 'stores/ui/cask';
import { Col, Row } from 'react-flexbox-grid';
import OrderCard, { OrderCardProps } from './components/OrderCard';
import { ordersEnabled } from 'models/cask-status';

const Wrapper = styled.div`
  margin-top: 10px;
`;

interface Props {
  caskUiStore?: CaskUiStore;
  history?: Array<{ date: Date, event: string }> | undefined;
}

interface State {
  popoverIsOpen: boolean;
  anchorElement?: EventTarget;
}

const bottlingImage = require('assets/images/bottle.png');
const samplingImage = require('assets/images/glass.png');
const flavourNoteImage = require('assets/images/nose.png');

@inject('caskUiStore')
@observer
class CaskOrderIndexPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popoverIsOpen: false,    
    };
  }

  get caskId() {
    return this.props.caskUiStore!.caskId;
  }

  get caskStatus() {
    return this.props.caskUiStore!.cask.status;
  }

  createOrderCardProps(
    baseRoute: string, linkRoute: string, titleId: string, textId: string, image: {}, enabled: boolean
  ): OrderCardProps {
    return {
      baseRoute: baseRoute,
      linkRoute: linkRoute,
      titleId: titleId,
      textId: textId, 
      image: image,
      enabled: enabled
    };
  }

  render() {
    let baseOrderLink = `/cask/${this.caskId}/orders`;
    let enabled = ordersEnabled(this.caskStatus);

    return (
      <Wrapper>
          <Row>
            <Col xs={12} sm={6} md={4} lg={4}>
              <OrderCard 
                {...this.createOrderCardProps(
                  baseOrderLink,
                  '/bottling',
                  'orders.orderPage.bottling.title',
                  'orders.orderPage.bottling.text', 
                  bottlingImage,
                  enabled)
                }
              />
            </Col>
            <Col xs={12} sm={6} md={4} lg={4}>
              <OrderCard 
                {...this.createOrderCardProps(
                  baseOrderLink,
                  '/sampling',
                  'orders.orderPage.sampling.title',
                  'orders.orderPage.sampling.text', 
                  samplingImage,
                  enabled)
                } 
              />
            </Col>
            <Col xs={12} sm={6} md={4} lg={4}>
              <OrderCard 
                {...this.createOrderCardProps(
                  baseOrderLink,
                  '/flavour-note',
                  'orders.orderPage.flavourNote.title',
                  'orders.orderPage.flavourNote.text', 
                  flavourNoteImage,
                  enabled)
                }
              />
            </Col>
          </Row>
      </Wrapper>
    );
  }
}

export default CaskOrderIndexPage;